import React, { useEffect, useState, useRef } from 'react';
import closeButon from "../../../../assests/images/close-o.png"

const SelectVoicePackage = ({ packageInfo, setPackageInfo, setIsOpenVoice, data, setSelectedVioceObj }) => {
    const colorArray = ['#852D84', '#852D84', '#8B949B', '#E3BA44', '#E28A74', '#727473', '#B3B3B3', '#737824']
    const [open, setOpen] = useState(false);
    const ref = useRef(null);

    useEffect(() => {
        setTimeout(() => {
            setOpen(true);
            document.addEventListener('mousedown', handleClickOutside);
        }, 0);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [open]);

    const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
            setIsOpenVoice(false);
        }
    };

    const handleClosePopup = () => {
        setIsOpenVoice(false);
    };

    return (
        <div className='packageSelect' ref={ref}>
            {open && (
                <div className={open ? 'voicePack show' : 'voicePack'}>
                    <img src={closeButon} alt="Close" className='close-icon' style={{ position: 'absolute',maxHeight:'20px', top: '10px', right: '10px', cursor: 'pointer', textAlign: 'right' }} onClick={handleClosePopup} />
                    <div className='voicePackages'>
                        {data.map((obj, index) => {
                            if (packageInfo.connection === 'cVoice') {
                                return obj.VOICE_PACKAGE_NAME === 'Voice Pal - Baisc' ? (
                                    <div className='package-item-wrapper' key={index}>
                                        <div
                                            onClick={() => {
                                                setSelectedVioceObj(obj);
                                                setPackageInfo({
                                                    ...packageInfo,
                                                    voicePackages: obj.VOICE_PACKAGE_NAME,
                                                    voicePackagesData: obj,
                                                });
                                                setTimeout(() => {
                                                    setIsOpenVoice(false);
                                                }, 150);
                                            }}
                                            className={
                                                packageInfo.voicePackages === obj.VOICE_PACKAGE_NAME
                                                    ? 'package-item selected'
                                                    : 'package-item'
                                            }
                                            style={{ backgroundColor: colorArray[index] }}>
                                            <div className='packageName'>{obj.VOICE_PACKAGE_NAME}</div>
                                            <div>{obj.FREE_MINUTES}</div>
                                            <div style={{fontSize:'13px'}}>{obj.PHONE_UNIT}</div>
                                            <div className='priceDetails' style={{fontSize:'15px'}}> 
                                                Rs.{obj.MONTHLY_RENTAL} + Tax <br/>
                                                (monthly rental) <br/>
                                                Call charges applicable <br/> 
                                            </div>
                                        </div>
                                    </div>
                                ) : null;
                            } else if (packageInfo.connection === 'cBoth') {
                                return obj.VOICE_PACKAGE_NAME === 'Home ' ? (
                                    <div className='package-item-wrapper' key={index}>
                                        <div
                                            onClick={() => {
                                                setSelectedVioceObj(obj);
                                                setPackageInfo({
                                                    ...packageInfo,
                                                    voicePackages: obj.VOICE_PACKAGE_NAME,
                                                    voicePackagesData: obj,
                                                });
                                                setTimeout(() => {
                                                    setIsOpenVoice(false);
                                                }, 150);
                                            }}
                                            className={
                                                packageInfo.voicePackages === obj.VOICE_PACKAGE_NAME
                                                    ? 'package-item selected'
                                                    : 'package-item'
                                            }
                                            style={{ backgroundColor: colorArray[index] }}>
                                            <div className='packageName'>{obj.VOICE_PACKAGE_NAME}</div>
                                            <div>{obj.FREE_MINUTES}</div>
                                            <div style={{fontSize:'13px'}}>{obj.PHONE_UNIT}</div>
                                            <div className='priceDetails' style={{fontSize:'15px'}}> 
                                                Rs.{obj.MONTHLY_RENTAL} + Tax <br/>
                                                (monthly rental) <br/>
                                                Call charges applicable <br/> 
                                            </div>
                                        </div>
                                    </div>
                                ) : null;
                            } else {
                                return  (
                                    <div className='package-item-wrapper' key={index}>
                                        <div
                                            onClick={() => {
                                                setSelectedVioceObj(obj);
                                                setPackageInfo({
                                                    ...packageInfo,
                                                    voicePackages: obj.VOICE_PACKAGE_NAME,
                                                    voicePackagesData: obj,
                                                });
                                                setTimeout(() => {
                                                    setIsOpenVoice(false);
                                                }, 150);
                                            }}
                                            className={
                                                packageInfo.voicePackages === obj.VOICE_PACKAGE_NAME && packageInfo.voicePackagesData?.MONTHLY_RENTAL === obj.MONTHLY_RENTAL
                                                    ? 'package-item selected'
                                                    : 'package-item'
                                            }
                                            style={{ backgroundColor: colorArray[index] }}>
                                            <div className='packageName'>{obj.VOICE_PACKAGE_NAME}</div>
                                            <div>{obj.FREE_MINUTES}</div>
                                            <div style={{fontSize:'13px'}}>{obj.PHONE_UNIT}</div>
                                            <div className='priceDetails' style={{fontSize:'15px'}}> 
                                                {obj.MONTHLY_RENTAL} LKR + Tax <br/>
                                                (monthly rental) <br/>
                                                {obj.VOICE_PACKAGE_NAME !== "Unlimited" && (
                                                    <>
                                                        Call charges applicable <br/>
                                                    </>
                                                )}
                                            </div>                                           
                                        </div>
                                    </div>
                                );
                            }
                        })}
                    </div>
                </div>
            )}
        </div>
    );
};

export default SelectVoicePackage;

import React from 'react';

const EbillTermsConditions = ({setAgree, setShowTerms}) => {
    return (
        <div className='termConditon'>
            <div className='headerTerm'> Supplementary Agreement for On-line Registration for E-bill Service.
            </div>

            <div className='bodyList'>
                <div className='discription'>

                    <div> This Agreement is Supplementary to the Megaline (single/double/Triple Play) / FTTH
                        (single/double/Triple Play) / LTE Single Play-voice(voice PAL / LTE broadband only /LTE double
                        play CDMA(Pre & Post Paid) Agreements.
                    </div>

                    <div> This Agreement shall be read together with the aforesaid telecommunication Service Agreement/s
                        entered into between the parties for the existing connection and shall form a part and parcel of
                        the said Agreement/s.
                    </div>

                    <div>The following terms and conditions serve as guidelines in addition to the information published
                        in the SLT web site. I agree to carefully read & understand the Terms & Conditions set forth
                        herein and in the SLT website.
                    </div>


                    <div>By clicking the “I Accept” [* Pleases refer the Asterisk below] box below, Customer
                        acknowledges that the Customer is fully aware of the content of the Agreement and Customer
                        has read, understood and accept the Terms and Conditions of the Agreement;
                    </div>
                </div>


                <div className='title'>1. Consent to online self registration
                </div>

                <div className='discription'>1.1 “agree that I have read carefully, understood and agree to be bound by
                    the terms and conditions of Online E-Bill Service of SLT (hereinafter referred to as ‘Service’)
                    before registering myself for the said Service.
                </div>


                <div className='title'>2. Amendments and Modifications</div>

                <div className='discription'> 2.1 I acknowledge that the website (www.slt.lk) (herein after referred to
                    as the ‘vide Website’) may be modified from time to time without notice to me, as such I agree to
                    update myself and be bound by the Terms and Conditions published in the Website. I agree that my use
                    or access of this Service and/or Website is conclusive proof of my agreement to be bound by the
                    terms and conditions applicable for the Service and in the event I am not agreeable to the terms and
                    conditions hereof I shall immediately stop using or disconnect the Service without prejudice to the
                    liabilities incurred by me.
                </div>


                <div className='title'> 3. Customer Obligations.
                </div>

                <div className='discription'> 3.1 I shall maintain the availability of email access and active email
                    account at all times material to this Agreement to receive the e-bill.
                </div>

                <div className='discription'> 3.2 In the event of any change of my e-mail address, I shall immediately
                    inform SLT (either to SLT regional office/ Teleshop/ SLT account manager for enterprise customer/s)
                    to update SLT systems with the new e-mail address to which I wish to receive the e-bill.
                </div>

                <div className='discription'> 3.3 In the event of my failure to maintain the said email account, I shall
                    immediately inform SLT (either to SLT regional office/ Teleshop/ SLT account manager for enterprise
                    customer/s) the request to receive the bills in print format.
                </div>

                <div className='discription'>3.4 It shall be construed that I am aware of the bill arrears and/or is in
                    receipt of the e-bills in the event if I have not informed any inability to receive e-bills and/or
                    any change of the e-mail address and/or requested to receive bills in hardcopy.
                </div>

                <div className='discription'> 3.5 Nothing hereof shall in anyway discharge my liability with regard to
                    settlement of any outstanding bill arrears.
                </div>

                <div className='discription'>3.6 The e-bill sent to the e-mail address given by me shall be constructive
                    proof of receipt of the e-bill/invoice for SLT services by me for all purposes.
                </div>

                <div className='discription'> 3.7 In the event I wish to change the format of receiving the bill (from
                    e-bill to hardcopy), I shall inform SLT of the same in writing either to SLT regional office/
                    Teleshop/ SLT account manager for enterprise customer/s or by email addressed to 1212@slt.com.lk).
                    The requested change will be effected from the next billing month.
                </div>

                <div className='discription'>
                    3.8 I hereby undertakes not to plead prescription in any action by SLT.
                </div>

                <div className='discription'>
                    3.9 I understand that in the event I register for SMS alerts for this Service, I may receive SLT
                    promotional SMS, bill payment reminders or any other special messages related to SLT product and
                    services.
                </div>

                <div className='title'>4. Termination</div>

                <div className='discription'> 4.1 In the event the telephone services provided to me are disconnected,
                    this Service will be automatically suspended / terminated.
                </div>

                <div className='discription'> 4.2 SLT may suspend/terminate the Service if it detects any misuse by
                    me.
                </div>

                <div className='discription'> 4.3 In the event I detect a misuse by a licensee under me, I shall
                    forthwith change the password and if I so wish will, immediately notify SLT to terminate the
                    Service.
                </div>


                <div className='title'> 05. Fees and Payments</div>

                <div className='discription'> 5.1 This Service shall be offered to me free of charge.
                </div>

                <div className='discription'> 5.2 I shall bear any cost incurred in using the service through the
                    internet, which cost shall be calculated in preparing the monthly bill.
                </div>

                <div className='title'> 06. Indemnity</div>

                <div className='discription'>
                    6.1 I shall indemnify SLT of any misuse or negligence of using the Service on my part.
                </div>
                <div className='discription'>
                    6.2 I shall indemnify SLT of any transaction effected under my user ID.
                </div>
                <div className='discription'>
                    6.3 I shall indemnify SLT and keep SLT indemnified against non-receipt of any e-bill due to internet
                    outage or disruption.
                </div>

                <div className='title'>07. Intellectual Property Rights</div>

                <div className='discription'>
                    I acknowledge that all the intellectual property in relation to this Service belongs to SLT and I
                    shall not misuse, modify, reverse engineer, decompile or dissemble any software used by SLT in
                    provisioning the Service.
                </div>


                <div className='title'> 08. Confidentiality</div>

                <div className='discription'> I hereby undertake to SLT, that I shall keep in the strictest confidence,
                    except where disclosure is required by law, any confidential or proprietary information or
                    intellectual property of any nature belonging to SLT which may come into my possession or to my
                    knowledge during my association with SLT, except where the prior written consent of SLT is obtained.

                </div>

                <div className='title'> 09. Content Ownership</div>

                <div className='discription'> 9.1 I agree that all content created and published on the Website and SMS
                    alert facility belongs to SLT or SLT Group of companies or authorized to use it. I agree that non of
                    such content or intellectual property rights shall be used by me without proper written authority of
                    SLT.
                </div>

                <div className='discription'> 9.2 I further agree that I shall not publish any content in the Website or
                    in the Online SelfRegistration Portal of SLT.
                </div>

                <div className='title'> 10. Links to other websites</div>

                <div className='discription'> 10.1 I understand that SLT site may contain links to third party web sites
                    which are not owned or controlled by SLT and therefore would not be responsible for the content,
                    privacy policies or practices of such third party websites.
                </div>

                <div className='discription'> 10.2 I agree that access to such sites are at my risk and SLT is not
                    liable for any loss or damage caused to me by relying on the information in such linked sites.
                </div>

                <div className='title'>11. SLT Rights</div>

                <div className='discription'> 11.1 I agree that the provision of the Service will be at the discretion
                    of SLT.
                </div>

                <div className='discription'> 11.2 I agree that SLT has right to request further information and
                    documentation whether on line or not prior to provisioning of the Services.
                </div>

                <div className='discription'> 11.3 I understand that SLT has right to monitor and control my access
                    rights.
                </div>
                <div className='discription'>11.4 I agree that SLT may terminate this Service for its convenience.</div>

                <div className='d-flex justify-content-end mb-3 clearfix'>
                    <button className='medium noline blue mr-1' onClick={() => {
                        setShowTerms(false)
                        setAgree(true)
                    }}>Accept
                    </button>
                    &nbsp; &nbsp; &nbsp;
                    <button className='medium noline blue' onClick={() => {
                        setShowTerms(false)
                        setAgree(false)
                    }}>Close
                    </button>


                </div>

            </div>

        </div>
    );
};

export default EbillTermsConditions;

import React, {useEffect, useState} from 'react';
import UserInformation from "./userInformation/UserInformation";
import LocationInformation from "./location/LocationInformation";
import PackageInformation from "./packages/PackageInformation";
import FileUploadState from './subFileUploadComponent/FileUploadState';
import FtthPayment from "./payment/FtthPayment";
import axiosInstance from "../../redux/axiosInstance";
import {getViewUserInfoUrl} from "../../redux/URL";
import {CATCH_ERROR_MSG} from "../../redux/Types";
import {connect} from "react-redux";
import {Redirect} from "react-router-dom";
import LoadingBbBody from '../boardBand/LoadingBbBody';


function MainFTTH({draftDetails, payLog, charge, invoice, invoiceLte, payLogLte, loadingDraft, loadingInvoice, loadingInvoiceLte, loadingPayment}) {

    const [loading, setLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [error, setError] = useState('');

    const [state, setState] = useState(1);
    const [userInfo, setUserInfo] = useState({
        title: '',
        fName: '',
        lName: '',
        gender: 'Male',
        iType: 'nic',
        nic: '',
        isNicVerify: false,
        passport: '',
        isPpVerify: false,
        lType: '',
        mobile: '',
        isMobileVerify: false,
        isEmailVerify: false,
        email: '',
        nationality: 'Sri Lankan',
        dateOfBirth: '04/11/1997',
        isExistCus: false,
        altContact: '',
        sltnic: ''

    });
    const [locationInfo, setLocationInfo] = useState({
        district: 'Colombo',
        city: '',
        postalCode: '',
        address1: '',
        address2: '',
        rTom: '',
        lat: null,
        lng: null,
        dLat: 7.8742,
        dLng:  80.6511,
    });
    const [packageInfo, setPackageInfo] = useState({
        peoTvPackages: '',
        bbPackages: '',
        peoPackagesData: {},
        bbPackagesData: {},
        isSameAsBilling: false,
        address1: '',
        address2: '',
        province: '',
        district: '',
        city: '',
        postalCode: '',
        billType: 'eBill',
        iddActivate: true
    });
    const [cityList, setCityList] = useState([]);

    const [orderRef, setOrderRef] = useState('');

    const [chargeFTTH, setChargeFTTH] = useState(0);

    useEffect(() => {
        getUserDetails()
    }, [])// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
       if(charge.length > 0){
            for(let i = 0; i < charge.length; i++){
                if(charge[i].BB_TYPE === 'SLT Fiber'){
                    setChargeFTTH(charge[i].INITIAL_FEE)
                    // setChargeFTTH(1000)
                }
            }
       }
    }, [charge]);// eslint-disable-line react-hooks/exhaustive-deps

    const getUserDetails = () => {
        setLoading(true)
        let log = localStorage.getItem('userName')

        axiosInstance.get(getViewUserInfoUrl).then(response => {

            if (response.data.isSuccess && response.data.dataBundle.altrContact) {

                if (localStorage.getItem('userType') === 'MOBILE') {
                    setUserInfo({
                        ...userInfo,
                        email: response.data.dataBundle.altrContact,
                        isEmailVerify: true,
                        mobile: log,
                        lType: 'MOBILE',
                        isMobileVerify: true,
                        altContact: response.data.dataBundle.altrContact
                    })
                    setLoading(false)
                } else {
                    setUserInfo({
                        ...userInfo,
                        mobile: response.data.dataBundle.altrContact,
                        isMobileVerify: true,
                        email: log,
                        lType: 'EMAIL',
                        isEmailVerify: true,
                        altContact: response.data.dataBundle.altrContact
                    })
                    setLoading(false)
                }

            } else {

                if (localStorage.getItem('userType') === 'MOBILE') {
                    setUserInfo({...userInfo, mobile: log, lType: 'MOBILE', isMobileVerify: true})
                } else {
                    setUserInfo({...userInfo, email: log, lType: 'EMAIL', isEmailVerify: true})
                }
                setLoading(false)
            }
        }).catch(() => {
            setError(CATCH_ERROR_MSG)
            setIsError(true)

            if (localStorage.getItem('userType') === 'MOBILE') {
                setUserInfo({...userInfo, mobile: log, lType: 'MOBILE', isMobileVerify: true})
            } else {
                setUserInfo({...userInfo, email: log, lType: 'EMAIL', isEmailVerify: true})
            }
            setLoading(false)
        })
    }

    if(loadingDraft && loadingInvoice && loadingInvoiceLte && loadingPayment){
        <div className='mainViewFTTH'>
            <LoadingBbBody/>
        </div>
    }

    if(draftDetails && payLog && (payLog[0].gw_status === '1')){
        return <Redirect to='/connectionOrder' />
    }
    if(
        (invoice && invoice.length > 0  && ((payLog && (payLog[0].gw_status !== '1')) || !payLog)) || 
        (invoiceLte && invoiceLte.length > 0 && ((payLogLte && (payLogLte[0].gw_status !== '1')) || !payLogLte))
    ){
        return <Redirect to='/cart' />
    }

    return (
        <div className='mainViewFTTH'>
            <div className='header'>
                {/*<div className='btn-back'>*/}
                {/*    <div className='grey'><BiArrowBack/></div>*/}
                {/*    <div className='grey' onClick={}>Back to Home</div>*/}
                {/*</div>*/}
                <div className='title'>NEW SERVICE REQUEST - SLTMOBITEL FIBRE HOME LINE</div>
            </div>


            <div className='bodyFTTH wizard-container'>
                <div className='navigation'>

                    <div className="wizard-navigation-set">
                        <div className="wizard-item-container">
                            <div
                                className={state === 1 ? "wizard-item active" : state > 1 ? "wizard-item done" : "wizard-item"}>
                                <div className="item-icon">1</div>
                                <div className="item-name">Package Selection</div>
                            </div>
                            <div
                                className={state === 2 ? "wizard-item active" : state > 2 ? "wizard-item done" : "wizard-item"}>
                                <div className="item-icon">2</div>
                                <div className="item-name">Feasibility Check</div>
                            </div>
                            <div
                                className={(state === 3 ||( 3 < state) && (state < 5) ) ? "wizard-item active" : state > 3 ? "wizard-item done" : "wizard-item"}>
                                <div className="item-icon">3</div>
                                <div className="item-name">Customer Details</div>
                            </div>
                            <div
                                className={state === 5 ? "wizard-item active" : state > 5 ? "wizard-item done" : "wizard-item"}>
                                <div className="item-icon">4</div>
                                <div className="item-name">Summary</div>
                            </div>
                        </div>
                    </div>

                </div>
                {
                    state === 1 ? <PackageInformation packageInfo={packageInfo} setPackageInfo={setPackageInfo}
                    locationInfo={locationInfo} userInfo={userInfo} setState={setState}
                    setOrderRef={setOrderRef} orderRef={orderRef}/> : state === 2 ? 
                    <LocationInformation locationInfo={locationInfo} setLocationInfo={setLocationInfo}
                                             cityList={cityList} setCityList={setCityList} userInfo={userInfo}  packageInfo={packageInfo}
                                             setState={setState} setOrderRef={setOrderRef} orderRef={orderRef}/>  : state === 3 ? 
                    <UserInformation userInfo={userInfo} setUserInfo={setUserInfo} packageInfo={packageInfo} setPackageInfo={setPackageInfo}
                                     locationInfo={locationInfo}  setState={setState} loading={loading} setLoading={setLoading} error={error} 
                                     setError={setError} setIsError={setIsError}  setOrderRef={setOrderRef} orderRef={orderRef} isError={isError}/>  : state === 4 ? 
                    <FileUploadState setState={setState} loading={loading} setLoading={setLoading} userInfo={userInfo} 
                    locationInfo={locationInfo} packageInfo={packageInfo} orderRef={orderRef} /> : state === 5 ?
                    <FtthPayment userInfo={userInfo} orderRef={orderRef} locationInfo={locationInfo}
                                 packageInfo={packageInfo} setState={setState} chargeFTTH={chargeFTTH}/> : null
                }
                {/* {
                    state === 1 ? <UserInformation userInfo={userInfo} setUserInfo={setUserInfo}
                                                   setState={setState} loading={loading} setLoading={setLoading}
                                                   error={error} setError={setError} setIsError={setIsError}
                                                   isError={isError}/> : state === 2 ?
                        <LocationInformation locationInfo={locationInfo} setLocationInfo={setLocationInfo}
                                             cityList={cityList} setCityList={setCityList} userInfo={userInfo}  packageInfo={packageInfo}
                                             setState={setState} setOrderRef={setOrderRef} orderRef={orderRef}/> : state === 3 ?
                            <PackageInformation packageInfo={packageInfo} setPackageInfo={setPackageInfo}
                                                locationInfo={locationInfo} userInfo={userInfo} setState={setState}
                                                setOrderRef={setOrderRef} orderRef={orderRef}/> : state === 4 ?
                                <FtthPayment userInfo={userInfo} orderRef={orderRef} locationInfo={locationInfo}
                                             packageInfo={packageInfo} setState={setState} chargeFTTH={chargeFTTH}/> : null
                } */}

            </div>
           {/* <button onClick={() => setState(state + 1)}>Click</button>
           <button onClick={() => setState(state - 1)}>Click back</button> */}
        </div>
    );
}
const mapStateToProps = state => ({
    draftDetails: state.ftth.draftDetails,
    loadingDraft: state.ftth.loadingDraft,
    payLog: state.ftth.paymentsLog,
    loadingPayment: state.ftth.loadingPayment,
    charge: state.charge.chargeDetails,
    invoice: state.ftth.invoice,
    loadingInvoice: state.ftth.loadingInvoice,
    invoiceLte: state.lte.invoiceLte,
    loadingInvoiceLte: state.lte.loadingInvoiceLte,
    payLogLte: state.lte.paymentsLogLte,
})

export default connect(mapStateToProps, null) (MainFTTH);

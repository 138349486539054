import {ERROR_GET_BANNERS, LOADING_BANNERS, SET_BANNER_SLIDER_NO, SUCCESS_GET_BANNERS} from "../Types";

const initialState = {
    loading :true,
    banners : [],
    error : '',
    slideNO : ''

}

export default function bannerReducers(state= initialState, action){
    switch (action.type){
        case LOADING_BANNERS : {
            return {
                ...state,
                loading: true
            }
        }

        case SUCCESS_GET_BANNERS : {
            return {
                ...state,
                loading: false,
                banners: action.payload
            }
        }

        case ERROR_GET_BANNERS : {
            return {
                ...state,
                loading: false
            }
        }

        case SET_BANNER_SLIDER_NO : {
            return {
                ...state,
                slideNO: action.payload
            }
        }

        default :
            return state
    }
}

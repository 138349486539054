import React, {useEffect, useState} from 'react'
import axiosInstance from '../../../redux/axiosInstance'
import Autocomplete, {createFilterOptions} from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import {Map, Marker, GoogleApiWrapper} from 'google-maps-react';
import SearchFeasibility from './SearchFeasibility';
import { 
  getCityListUrl,  
  getFeasibilityCheck,
  saveDraftDetails, updateDraftDetails, kmlLayerUrl
} from '../../../redux/URL';
import Circle from './Circle'
import ErrorAlert from '../../alerts/ErrorAlert';
import { CATCH_ERROR_MSG } from '../../../redux/Types';
import mapMarkerBlue from '../../../assests/images/mapMarkerBlue.svg'
import { MAP_KEY } from '../../../redux/MAPKEYS';

const FeasibilityCheck = ({
  locationInfo,
  setLocationInfo,
  setState,
  setCityList,
  cityList,
  google,
  userInfo,
  packageInfo,
  orderRef,
  setOrderRef
}) => {

  const containerStyle = {
    position: 'relative',
    width: '100%',
}

var circleStyle = {
  padding:5,
  marginLeft: 5,
  marginRight: 5,
  display:"inline-block",
  // position:'absolute',
  backgroundColor: 'red',
  borderRadius: "50%",
  width:20,
  height:20,
  left:0,
  top:0
}


const Querystring = require('querystring');

const [zoom, setZoom] = useState(7);

const [errorList, setErrorList] = useState({});
const [checkFesibilty, setCheckFesibilty] = useState(null);

const [loading, setLoading] = useState(false);
const [isError, setIsError] = useState(false);
const [error, setError] = useState('');

const [isCitySelect, setIsCitySelect] = useState(true);

const [valueLocSearch, setValueLocSearch] = useState('');

useEffect(() => {
    if (cityList.length === 0)
        getCityList()

}, [])// eslint-disable-line react-hooks/exhaustive-deps

const getCityList = () => {
  setLoading(true)
  axiosInstance.get(getCityListUrl).then((response) => {

      if (response.data.isSuccess) {
          setCityList(...[response.data.dataBundle])
          setLoading(false)
      } else {
          setError(CATCH_ERROR_MSG)
          setIsError(true)
          setLoading(false)
      }
  }).catch(() => {
      setError(CATCH_ERROR_MSG)
      setIsError(true)
      setLoading(false)
  })
}

const handleChange = event => {
  setErrorList({})
  const {name, value} = event.target
  setLocationInfo({...locationInfo, [name]: value})

}

const dragEndLatLon = (coord) => {
  const {latLng} = coord;
  const lat = latLng.lat();
  const lng = latLng.lng();
  console.log(lat);
  setCheckFesibilty(null)
  setLocationInfo({...locationInfo, lat: lat, lng: lng, dLat: lat, dLng: lng})
  setZoom(16)
};
//https://sm.youcab-apps.com/kml/doc.kml
//http://link.lakmobile.com/KmlExport/CNP_KML.kml
//https://s3.ap-southeast-1.amazonaws.com/dlg.dialog.lk/s3fs-public/kml/4G_Mobile_Coverage_2020_Q4.kml
//https://link.lakmobile.com/kmz/kml/2022-07_RSRP_Common_M_reprojected/2022-07_RSRP_Common_M_reprojected.kml
//http://link.lakmobile.com/kmz/kml/4G_COVERAGE_PREDICTION_YELLOW_RED.kml
const drawKmlLayer = (map) => {
 // var kmzLayer = new google.maps.KmlLayer(null);
  //kmzLayer.setMap(null);
 var kmzLayer = new google.maps.KmlLayer({
    url: kmlLayerUrl,
    //suppressInfoWindows: true,
    //preserveViewport:true,    
    map: map,
  });  

  // ctaLayer.addListener('click', function(kmlEvent) {
  //   console.log(kmlEvent);
  //   console.log(kmlEvent.latLng.lat()+","+kmlEvent.latLng.lng());
  //  // const {latLng} = kmlEvent.latLng;
    
  //   const lat = kmlEvent.latLng.lat();
  //   const lng = kmlEvent.latLng.lng();
    
  //   setCheckFesibilty(null)
  //   setLocationInfo({...locationInfo, lat: lat, lng: lng, dLat: lat, dLng: lng})
  //   setZoom(16)



  // });
};

const searchLatLon = (place) => {
  const {location} = place.geometry;
  const lat = location.lat();
  const lng = location.lng();
  setCheckFesibilty(null)

  setLocationInfo(prevState => ({...prevState, lat: null, lng: null, dLat: lat, dLng: lng}))
  setZoom(16)
};

const setCity = (e, option) => {

  if (option) {
      setLocationInfo({
          ...locationInfo,
          district: option.district,
          city: option.city,
          rTom: option.rtom,
          dLat: parseFloat(option.latitude),
          dLng: parseFloat(option.longitude),
          postalCode: option.postalcode,
          address1: '',
          address2: '',
          lat: null,
          lng: null,
      })
      setZoom(14)
      setCheckFesibilty(null)
      setIsCitySelect(true)
      setValueLocSearch('')
  } else {
      setLocationInfo({
          ...locationInfo,
          district: '',
          city: '',
          rTom: '',
          postalCode: '',
          address1: '',
          address2: '',
          lat: null,
          lng: null,
      })
      setZoom(14)
      setCheckFesibilty(null)
      setIsCitySelect(false)
      setValueLocSearch('')
  }


}

const validation = () => {
  let errors = {};
  let formIsValid = true;
// console.log(locationInfo.grant)
  // if (!locationInfo.district) {
  //     errors['district'] = 'Cannot be Empty'
  //     formIsValid = false
  // } else
  if (!locationInfo.city) {
      errors['city'] = 'Cannot be Empty'
      formIsValid = false
  } else if (!locationInfo.postalCode) {
      errors['postalCode'] = 'Cannot be Empty'
      formIsValid = false
  } else if (locationInfo.postalCode.length !== 5) {
      errors['postalCode'] = 'Invalid postal code'
      formIsValid = false
  } else if (!locationInfo.grant) {
    errors['grant'] = 'Please accept the terms conditions'
    formIsValid = false
  } else if (!locationInfo.address1) {
      errors['address1'] = 'Cannot be Empty'
      formIsValid = false
  } else if (!locationInfo.address2) {
      errors['address2'] = 'Cannot be Empty'
      formIsValid = false
  }

  if (formIsValid) {
      setState(4)
  } else {
      setErrorList(errors)
  }
}

const isGrant = () => {
  // let errors = {};
  if (locationInfo.city && locationInfo.lat && isCitySelect) {
    setLocationInfo({...locationInfo, grant: !locationInfo.grant})
  }else{
    // errors['address2'] = 'Cannot be Empty'
    // setErrorList(errors)
    if (!locationInfo.city) {
      setError('Please select the your City to continue')
    }else{
      setError('Please drag the home icon to your exact location')
    }    
    setIsError(true)
  }
}

const isFesibilty = () => {
  if (locationInfo.city && locationInfo.lat && isCitySelect) {
              
    setCheckFesibilty(true)
     
  } else {
      setError('Please Select Your Service Delivery Location')
      setIsError(true)
  }

}

const filterOptions = createFilterOptions({
  matchFrom: 'start',
  stringify: option => option.city,
});


  return (
    <div className='locationInformation'>
      {
        isError ? <ErrorAlert error={error} setError={setError} setIsError={setIsError}/> : null
      }
      <div className='bodyView'>
        <div className='form-container'>

          <div className='form-inputs-container'>

            <div className='input-container'>

              <label>
              <p className='text-white'>City/Home Town <p
                className='d-inline red m-0 ml-1'>* {!isCitySelect ? 'Please select a city' : null}</p>
              </p></label>

              <Autocomplete
                className='autoCom'
                options={cityList}
                getOptionLabel={(option) => option.city}
                // style={{height: 40, padding: 0}}
                onChange={setCity}
                value={cityList.find(obj => {
                    return obj.city === locationInfo.city
                })}
                filterOptions={filterOptions}
                // onInputChange={()=>InputChanges()}
                freeSolo
                renderInput={(params) => <TextField  {...params} label="" placeholder="First select your nearest city/home town/village name" variant="outlined"
                                                     style={{margin: '0px'}}/>}
              />

            </div>

            
            <div className='input-container'> 
            <p className='text-white'>Place the pin in your home/business location</p>
              <label>
                 <span>Coverage : </span> 
                 <Circle bgColor={"#FE1A18"}/><span>High </span> 
                 {/* <Circle bgColor={"#FFA300"}/><span>Medium </span>  */}
                 <Circle bgColor={"#FFFD15"}/><span>Low </span> 

              </label>
            </div>

            <div className='map-container'>
            {!locationInfo.city ?   <div className='mask'/> : null}
              <SearchFeasibility searchLatLon={searchLatLon} valueLocSearch={valueLocSearch}
                                            setValueLocSearch={setValueLocSearch}/>
              <span className='btn-remove' hidden={!valueLocSearch}
                                  onClick={() => setValueLocSearch('')}/>
              
              <Map google={window.google} zoom={zoom} containerStyle={containerStyle}
                  // initialCenter={{lat: locationInfo.dLat, lng: locationInfo.dLng}}
                  center={{lat: locationInfo.dLat, lng: locationInfo.dLng}}
                  mapTypeControl={false}
                 onClick={(t, map, coord) => dragEndLatLon(coord)}
                 onReady={(mapProps, map) => drawKmlLayer(map)}
                >

               {
                  locationInfo.dLat ? <Marker
                  title="Drag the icon to your location"
                  name={'Current location'} draggable={true}
                  position={{lat: locationInfo.dLat, lng: locationInfo.dLng}}
                  onDragend={(t, map, coord) => dragEndLatLon(coord)}
                  icon={{
                  url: mapMarkerBlue,
                  scaledSize: new google.maps.Size(40, 70)
                  }}
                  /> : null
                }


              </Map>
            </div>
            <div className='input-container'>    
            <div className='coverageNote'>
            <strong>Note :</strong> Coverage may vary by location. This map provides  a guide only, and it is not a guarantee of actual signal coverage. <br/>
            If your area does not have signal coverage, you can request it <strong onClick={() => setState(3)}>here.</strong>              
            </div>
            </div>

            <div className='input-container'>   
              <div className='input-field'>
                {errorList.grant ? <div className="error-msg">{errorList.grant}</div> : null} 
                <input id="chk1" type='checkbox' onClick={() => isGrant()} checked={locationInfo.grant} className='sign' />
                <span for="chk1" className='ltecheckboxlbl' >I agree and proceed with purchasing LTE connection. </span>
              </div>
            </div>



          </div>

          <div className="form-inputs-container d-flex flex-column justify-content-start mt-2 pl-5">

          <p className='text-white'>Your Postal Address</p>

          <div className='attempt-info-lte'><p className='d-inline red m-0 ml-1'></p>The address you provided will be used as your delivery & Installation address and cannot be changed later
            </div>
            
            

            <div className='input-container'>
                <label>
                    <div className="label">Address Line 1</div>
                    <p className='d-inline red m-0 ml-1'>*</p>
                </label>
                <div className="input-field">
                    {errorList.address1 ? <div className="error-msg">{errorList.address1}</div> : null}
                    <input type='text' name='address1' disabled={!locationInfo.city || !locationInfo.lat}
                          className={errorList.address1 ? 'w-100 error' : 'w-100'}
                          value={locationInfo.address1}
                          onChange={handleChange}/>
                </div>
            </div>

            <div className='input-container'>
                <label>
                    <div className="label">Address Line 2</div>
                    <p className='d-inline red m-0 ml-1'>*</p>
                </label>
                <div className="input-field">
                    {errorList.address2 ? <div className="error-msg">{errorList.address2}</div> : null}
                    <input type='text' name='address2' disabled={!locationInfo.city || !locationInfo.lat}
                          className={errorList.address2 ? 'w-100 error' : 'w-100'}
                          value={locationInfo.address2}
                          onChange={handleChange}/>
                </div>
            </div>

            <div className='input-container'>
                <label>
                    <div className="label">Postal Code</div>
                    <p className='d-inline red m-0 ml-1'>*</p>
                </label>
                <div className="input-field">

                    {errorList.postalCode ? <div className="error-msg">{errorList.postalCode}</div> : null}
                    <input autoComplete="new-password" type='number' name='postalCode' disabled={true}
                          className={errorList.postalCode ? 'w-100 error' : 'w-100'}
                          value={locationInfo.postalCode}
                          onChange={handleChange}/>
                </div>
            </div>

            {/* <div className='attempt-info'><p className='d-inline red m-0 ml-1'></p>The address you provided will be used as your delivery & Installation address and cannot be changed later
            </div> */}

          </div>

        </div>
      </div>
        {
            checkFesibilty === null ? null : checkFesibilty ? <div className='successFeasibility'>
               We are happy to inform you that
              SLTMOBITEL LTE is <strong>available </strong> for your area.
              <div>Click next to proceed</div>
            </div> : <div className='noSuccessFeasibility'>
                Sorry SLT Fiber is <strong> not available </strong> at the selected location, You can request SLT
                 Megaline service.
            </div>
        }

        <div className='container-footer'>
            <div className="action-container right">
                <button className="btn-back" onClick={() => setState(1)}>Back</button>
                
                  <button onClick={() => validation()}>Next</button> 
            </div>
        </div>
    </div>
  )
}

export default GoogleApiWrapper({
  apiKey: (MAP_KEY)
})(FeasibilityCheck)

// export default FeasibilityCheck
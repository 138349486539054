import React, { useState } from 'react'
import { AiFillCaretDown, AiFillCaretUp} from "react-icons/ai";

export const SingaleAccordion = ({title,content} ) => {
    const [isActive, setIsActive] = useState(false);
    return (
        <div className="accordion">
        <div className="accordion-item">
            <div className={isActive ? 'accordion-title active' : 'accordion-title'}
            onClick={() => setIsActive(!isActive)}
            >
                <div className= 'mr-2'>{title}</div>
                <div>{isActive ? <AiFillCaretUp/> : <AiFillCaretDown />}</div>
            </div>
            {isActive && <div className="accordion-content">{content}</div>}
        </div>
    </div>
    )
}



export function loadingDispatch(type){
    return {
        type : type
    }
}

export function dispatchSuccess(type, data){
    return {
        type: type,
        payload: data
    }
}

export function dispatchError(type, error){
    return {
        type: type,
        payload: error
    }
}

import React, {useEffect, useState} from 'react';
import ProgressBarView from "./ProgressBarView";
import {connect} from "react-redux";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import {Carousel} from 'react-responsive-carousel';
import {BiChevronRight, BiChevronLeft} from "react-icons/bi";
import {addOnsData} from "../../redux/action/broadBand.action";
import LoadingGraphs from "./LoadingGraphs";
import NoDataToShow from "../common/NoDataToShow";
import noAddOns from '../../assests/images/nodatashow/add ons.png'
import NoPreviledge from "../alerts/NoPreviledge";
import {getVASBundleUnsubscriptionUrl} from "../../redux/URL";
import axiosInstance from "../../redux/axiosInstance";
import successLog from "../../assests/images/success.png";
import ErrorAlert from "../alerts/ErrorAlert";
import {CATCH_ERROR_MSG} from "../../redux/Types";

function AddonsData({addOnsData, loading, addOns_Data, serviceList, profile, addOns_DataError}) {

    const [count, setCount] = useState(0);
    const [confirmShow, setConfirmShow] = useState(false);

    const [packageName, setPackageName] = useState('');
    const [subscription, setSubscription] = useState('');
    const [timestamp, setTimestamp] = useState('');

    const [loadingState, setLoadingState] = useState(false);
    const [success, setSuccess] = useState(false);
    const [isError, setIsError] = useState(false);
    const [error, setError] = useState('');

    useEffect(() => {
        if (profile.hasOwnProperty('priviledges') && profile.priviledges.data_addons) {
            addOnsData(serviceList.listofBBService[0].serviceID)
        }
    }, [addOnsData, profile, serviceList]);

    function increment() {
        let length = addOns_Data.usageDetails.length
        if (length - 1 > count) {
            setCount(count + 1)
        }


    }

    function decrement() {
        if (count > 0) {
            setCount(count - 1)
        }

    }

    function unSubscribe() {
        setLoadingState(true)
        let url = getVASBundleUnsubscriptionUrl + 'subscriberID=' + profile.subscriberid + '&subscriptionid=' + subscription + '&timestamp=' + timestamp
        axiosInstance.post(url).then((response) => {
            if (response.data.dataBundle.status === 'SUCCESS') {
                setSuccess(true)
                setLoadingState(false)

            } else {
                setError(response.data.errorShow)
                setIsError(true)
                setLoadingState(false)
            }
        }).catch(() => {
            setError(CATCH_ERROR_MSG)
            setIsError(true)
            setLoadingState(false)
        })

    }

    function confirm() {
        return <div className='alertView'>
            <div>
                <div className='title'>Unsubscribe Package</div>
  
                <div className='mainText'>
                    <div className="message"> Without {packageName} data add-on your said protocol data will be deducted from your base bundle.</div>
                </div>

                <div className='mainText'>
                        <div className="message">  Do you really want to unsubscribe {packageName} data add-on?  </div>
             
                </div>
                <div className='actionButton'>
                    <button className='blue p-1 pl-3 pr-3' onClick={() => setConfirmShow(false)}>Cancel</button>
                    <button className='blue p-1 pl-3 pr-3' onClick={() => {
                        unSubscribe()
                        setConfirmShow(false)
                    }}>Continue
                    </button>
                </div>
            </div>

        </div>
    }

    function successAlert() {
        return (
            <div className='alertView'>
                <div>
                    <div className='title'>Success</div>
                    <div className='mainText'>
                    <div className="img-container">
                    <img src={successLog} alt='errorIcon'/></div>
                    <div className="message">You’ve been successfully unsubscribed from {packageName} data add-on. Your data will be
                            deducted from your <span className='font-weight-bold'>base bundle</span></div>
                </div>
                    <div className='actionButton'>
                        <button className='blue p-1 pl-3 pr-3' onClick={() => {
                            addOnsData(serviceList.listofBBService[0].serviceID)
                            setSuccess(false)
                        }}>Ok
                        </button>
                    </div>
                </div>
            </div>
        );
    }


    if (profile.hasOwnProperty('priviledges') && !profile.priviledges.data_addons) {
        return <div className='graphBody-wrapper'><div className='graphBody noPadding'><NoPreviledge
            msg={'Please change the SLT package for purchase Add-on Data'}/></div></div>
    } else if (loading || loadingState) {
        return <LoadingGraphs loading={loading}/>
    } else if (confirmShow) {
        return <div className='graphBody-wrapper'><div className='graphBody noPadding'>{confirm()}</div></div>
    } else if (success) {
        return <div className='graphBody-wrapper'><div className='graphBody noPadding'>{successAlert()}</div></div>
    } else if (isError) {
        return <div className='graphBody-wrapper'><div className='graphBody noPadding'><ErrorAlert error={error} setError={setError}
                                                                setIsError={setIsError}/></div></div>
    } else if (addOns_DataError) {
        return <div className='graphBody-wrapper'><div className='graphBody noPadding'><ErrorAlert error={addOns_DataError} setIsError={null}
                                                                setError={null}/></div></div>
    } else if (addOns_Data.hasOwnProperty('usageDetails')) {
        return (

            <div className='graphBody-wrapper'>
            <div className='graphBody'>
                {confirmShow ? confirm() : null}
                <div>
                    <p className='graph-body-title'>Usage Volume as of : {(addOns_Data.reported_time)}</p>
                </div>

                {
                    addOns_Data.usageDetails.length === 0 ?
                        <NoDataToShow imgPath={noAddOns} msg={'Wait for data addons'}/> :
                        <div className='d-flex justify-content-between align-items-center'>
                    <span style={{minWidth: '20px', cursor: 'pointer'}} className=''
                          onClick={() => decrement()}><BiChevronLeft
                        size={40} hidden={count === 0}/></span>

                            <div>
                                <Carousel showArrows={false} showStatus={false} showIndicators={false}
                                          selectedItem={count}
                                          width={500}>


                                    {
                                        addOns_Data.usageDetails.map((val, key) => {
                                            return <div key={key} className='h-auto m-auto' >
                                                <div className='text-center'>
                                                    <p>{val.name}</p>
                                                    <div className="progress-bar-container">
                                                {
                                                    val.limit && val.remaining ?
                                                        <ProgressBarView progress={val.percentage} word={val.remaining}
                                                                         unit={val.volume_unit} /> :
                                                        <div className='unlimited'>
                                                            <div
                                                                className='unlimitedUsage'>{val.used}{val.volume_unit}</div>
                                                            <div className='used-of'>used</div>
                                                        </div>
                                                }

                                            </div>
                                                    
                                                        <div className='used-of'>{`${val.used} ${val.volume_unit} USED
                                                        OF ${val.limit ? val.limit : 'UNLIMITED'} ${val.limit ? val.volume_unit : ''}`}</div>
                                                    {
                                                        addOns_Data.usageDetails.length > 0 ?
                                                            <p className='text-center blue '>(Valid Till
                                                                : {val.expiry_date + ' '}{val.unsubscribable ? new Date(val.timestamp).toLocaleTimeString("en-US") : null})</p> : null
                                                    }
                                                    {
                                                        val.unsubscribable ? <div className='text-center'>
                                                            <button className='blue pl-4 pr-4 pt-1 pb-1'
                                                                    onClick={() => {
                                                                        setPackageName(val.name)
                                                                        setSubscription(val.subscriptionid)
                                                                        setTimestamp(val.timestamp)
                                                                        setConfirmShow(true)
                                                                    }
                                                                    }>Unsubscribe
                                                            </button>
                                                        </div> : null
                                                    }
                                                </div>
                                            </div>
                                        })
                                    }


                                </Carousel>
                            </div>
                            <span style={{minWidth: '20px', cursor: 'pointer'}}
                                  onClick={() => increment()}><BiChevronRight size={40}
                                                                              hidden={count === addOns_Data.usageDetails.length - 1 || addOns_Data.usageDetails.length === 0}/></span>
                        </div>
                }


            </div>
            </div>
        );
    } else
        return null

}

const mapStateToProps = state => (
    {
        addOns_Data: state.broadBand.addOnsData,
        addOns_DataError: state.broadBand.addOnsDataError,
        loading: state.broadBand.loadingAddOns,
        serviceList: state.accountDetails.service_list,
        profile: state.broadBand.profile,
    }
)

export default connect(mapStateToProps,
    {
        addOnsData
    }
)(AddonsData);


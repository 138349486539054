import React, {useEffect, useState} from 'react';
import banner from "../../assests/images/loginUI/login-banner.jpg";
import normalAxios from "../../redux/normalAxios";
import {Carousel} from "react-bootstrap";
import {connect} from "react-redux";
import {setBannerSlide} from "../../redux/action/banner.action";


const LoginBannerViews = () => {

    const [loading, setLoading] = useState(false);
    const [images, setImages] = useState([]);


    useEffect(() => {
        if (images.length === 0) {
            getBanners();
        }
    })

    const getBanners = () => {

        setLoading(true)
        const params = new FormData()
        params.append('category_ids', '33')
        params.append('aspect_ratio', '0.6557')

        normalAxios.post('https://bannerportal.slt.lk/BannerPortal/API/getBannerUrls', params).then((response) => {

            setImages(...[response.data])
        }).catch(error => {

        })

    }

    if (loading && images.length === 0)
        return <div className='banner'>
            <img src={banner} alt='banner' className='banner'/>
        </div>

    else {
        return (
            <div className='banner'>

                <Carousel fade={true} defaultActiveIndex={0} onSlide={(eventKey => setBannerSlide(eventKey))}>
                    {/*defaultActiveIndex={slideNO}  onSlide={(eventKey => setBannerSlide(eventKey))}*/}
                    {
                        images.map((val, key) => {
                            return <Carousel.Item key={key}>
                                <img src={val.url} alt='temporaryBanner' className='banner'/>
                            </Carousel.Item>
                        })
                    }


                </Carousel>


            </div>
        )
    }
};


const mapStateToProps = state => ({
    slideNO: state.banners.slideNO,
})

export default connect(mapStateToProps, {setBannerSlide})(LoginBannerViews);
import React from 'react';
import successLog from "../../../assests/images/success.png";

function HappyDaySuccessAlert({setSuccess, msg, hide}) {
    return (
        <div className='alertView'>
            <div>
                <div className='title'>Congratulation!</div>
                <div className='mainText'>
                    <div className="img-container">
                    <img src={successLog} alt='errorIcon'/></div>
                    <div className="message">{msg}</div>
                   
                </div>
                <div className="message text-center">Enjoy unlimited data</div>
                <div className='actionButton'>
                    <button className='blue btn-ok' onClick={() => {
                        setSuccess(false)
                        if(hide){
                            hide();
                        }
                    }}>Ok</button>
                </div>
            </div>
        </div>
    );
}

export default HappyDaySuccessAlert;
import React, {useEffect, useState} from 'react';
import NewReg from "./NewReg";
import NewRegOtp from "./NewRegOtp";
import {connect} from "react-redux";
import {Redirect, useHistory, useParams} from "react-router-dom";
import NewSignIn from "./NewSignIn";
import NewNavBar from "./NewNavBar";
import NewForgotPassword from "./NewForgotPassword";
import NewOtpValidation from "./NewOtpValidation";
import NewLandingBannerView from "../common/NewLandingBannerView";

function LandingPage(props) {
    const history = useHistory();
    const {path} = useParams();
    const [state, setState] = useState('reg');
    const [regId, setRegId] = useState('');
    const [naviagetpath, setNaviagetpath] = useState('');

    const [chargeADSL, setChargeADSL] = useState('');
    const [chargeLTE, setChargeLTE] = useState('');
    const [chargeFTTH, setChargeFTTH] = useState(0);


    // console.log(props)


    useEffect(() => {
        if (path === 'ftth')
            // setNaviagetpath('/ftthrequest')
            setNaviagetpath('/applyonline')
        else if (path === 'megaline')
            setNaviagetpath('/newService/new/Megaline')
        else if (path === '4G LTE')
            // setNaviagetpath('/lterequest')
            setNaviagetpath('/newService/new/4G LTE')

    }, [naviagetpath])// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if(props.charge.length > 0){
             for(let i = 0; i < props.charge.length; i++){
                 if(props.charge[i].BB_TYPE === 'ADSL'){
                     setChargeADSL(props.charge[i].INITIAL_FEE)
                    // setChargeADSL(3500)
                 }
                 if(props.charge[i].BB_TYPE === 'SLT 4G'){
                     setChargeLTE(props.charge[i].INITIAL_FEE)
                    // setChargeLTE(3900)
                 }
                 if(props.charge[i].BB_TYPE === 'SLT Fiber'){
                     setChargeFTTH(props.charge[i].INITIAL_FEE)
                    // setChargeFTTH(10000)
                 }
             }
        }
     }, [props.charge]);// eslint-disable-line react-hooks/exhaustive-deps



    if (props.loggedIn && naviagetpath) {
        return <Redirect to={naviagetpath}/>
    }

    return (

        <div>
            <NewNavBar/>

            <div className='landingPage'>

                <div className='imageBanner' hidden={path && !props.loggedIn}>
                    <NewLandingBannerView/>
                </div>

                {
                    path && !props.loggedIn ? <div className='body-container'>
                        {
                            state === 'reg' ?
                                <NewReg setState={setState} regId={regId} setRegId={setRegId}/> : state === 'regotp' ?
                                    <NewRegOtp regId={regId}/> : state === 'signin' ?
                                        <NewSignIn setState={setState}/> : state === 'fg' ?
                                            <NewForgotPassword setState={setState}/> : state === 'otp' ? <NewOtpValidation setState={setState} />
                                                : null}

                    </div> : <div className='body-container'>
                        <div className='buttonSet'>
                            <div className="button-set-item">
                                <div className="image-container">
                                <div className="image-fiber"/>
                                </div>
                                <div className='payment-reg'>
                                    <div>
                                        <div className='con-test'>Connection Fee</div>
                                        <div className='con-free'>Rs {chargeFTTH ? chargeFTTH : 10000}/-</div>
                                    </div>
                                    <div className='mx-auto mt-3'>
                                        <button className='blue medium' onClick={() => {
                                            setNaviagetpath('/ftthrequest')
                                            history.push('/applyonline')
                                        }}>Buy Now
                                        </button>
                                    </div>
                                </div>

                            </div>
                            <div className="button-set-item">
                                <div className="image-container">
                                <div className="image-adsl"/>
                                </div>
                                <div className='payment-reg'>
                                    <div>
                                        <div className='con-test'>Connection Fee</div>
                                        <div className='con-free'>Rs {chargeADSL ? chargeADSL : 3500}/-</div>
                                    </div>
                                    <div className='mx-auto mt-3'>
                                        <button className='blue medium' onClick={() => {
                                            setNaviagetpath('/newService/new/Megaline')
                                            history.push('/promo/buyonline/megaline')
                                        }}>Buy Now</button>
                                    </div>
                                </div>

                            </div>
                            <div className="button-set-item">
                                <div className="image-container">
                                <div className="image-4g"/>
                                </div>
                                <div className='payment-reg'>
                                    <div>
                                        <div className='con-test'>Connection Fee</div>
                                        <div className='con-free'>Rs {chargeLTE ? chargeLTE : 3900}/-</div>
                                    </div>
                                    <div className='mx-auto mt-3' onClick={() => {
                                        // setNaviagetpath('/newService/new/4G LTE')
                                        setNaviagetpath('/lterequest')
                                        history.push('/promo/buyonline/4G LTE')
                                    }}>
                                        <button className='blue medium'>Buy Now</button>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>

                }

            </div>


        </div>
    );
}

const mapStateToProps = state => ({
    loggedIn: state.login.loggedIn,
    charge: state.charge.chargeDetails,
})
export default connect(mapStateToProps, null)(LandingPage);

import React from 'react'

const ConfirmRecurrentAlert = ({setConfirmShow, onClick, msg, pId, rId, setIsRecurrent, isRecurrent}) => {
  return (
    <div className='alertView'>
        <div>
            <div className='title'>Confirm</div>
            <div className='mainText'>
                <div className="message">{msg}</div>
            </div>
            {
                rId ?  <>
                            <div className="switch-button mx-auto mt-4">
                                <input className="switch-button-checkbox" type="checkbox" 
                                checked={isRecurrent}
                                       onChange={e => setIsRecurrent(e.target.checked)}
                                    />
                                <label className="switch-button-label white"><span
                                    className="switch-button-label-span">One Time</span></label>
                            </div><br/>
                       </> : null
            }
           
           {/* <select  defaultValue={'c'} className='number-select recurrent'
                                        // onClick={() => setShow(true)}
            >
                <option value='new'>One Time</option>
                <option value='new'>Recurrent</option>
            </select> */}


                       




            <div className='actionButton'>
                <button className='blue p-1 pl-3 pr-3 mr-2' onClick={() => setConfirmShow(false)}>No</button>
                <button className='blue p-1 pl-3 pr-3' onClick={() => {
                    onClick()
                    setConfirmShow(false)
                }}>Yes
                </button>
            </div>
        </div>

    </div>
  )
}

export default ConfirmRecurrentAlert
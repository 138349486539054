import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getUsagePackages, activePrepaidPackageUrl } from "../../../redux/URL";
import LoadingBbBody from "../../boardBand/LoadingBbBody";
import NoDataToShow from "../../common/NoDataToShow";
import axiosInstance from "../../../redux/axiosInstance";
import InfiniteCarousel from "react-leaf-carousel";
import ConfirmAlert from "../../alerts/ConfirmAlert";
import SuccessAlert from "../../alerts/SuccessAlert";
import ErrorAlert from "../../alerts/ErrorAlert";
import { CATCH_ERROR_MSG } from "../../../redux/Types";
import { getPaymentDetailsPrepaid } from "../../../redux/action/accountDetails.action";
import { getPrepaidDataBalance } from "../../../redux/action/broadBand.action";

function DataPackages({
  state,
  getPaymentDetailsPrepaid,
  getPrepaidDataBalance,
  payments,
}) {
  const [loading, setLoading] = useState(true);
  const [packages, setPackages] = useState([]);

  const [confirmShow, setConfirmShow] = useState(false);
  const [pName, setPName] = useState("");
  const [pId, setPId] = useState("");
  const [offerName, setOfferName] = useState("");
  const [amount, setAmount] = useState("");
  const [success, setSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    setLoading(true);
    let url;
    if (state === "mainPackage")
      url = getUsagePackages + "packageType=Main Packages";
    else url = getUsagePackages + "packageType=Add-ons";

    axiosInstance
      .get(url)
      .then((response) => {
        if (response.status === 200) {
          setPackages(...[response.data.dataBundle.data]);
          setLoading(false);
        } else setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [state]);

  const isEnough = () =>
    parseFloat(payments[0].totalAmount) / 100 >= parseFloat(amount);

  const activePackage = () => {
    if (isEnough()) {
      setLoading(true);
      let url =
        activePrepaidPackageUrl +
        "LTESLTNumber=" +
        localStorage.getItem("selectedAcc") +
        "&productId=" +
        pId +
        "&operationType=" +
        "ADD_OFFERING" +
        "&channelName=" +
        "MY_SLT" +
        "&ChannelSeq=" +
        3 +
        "&offerName=" +
        offerName;
      axiosInstance
        .post(url)
        .then((response) => {
          if (response.status === 200) {
            if (
              response.data.dataBundle &&
              response.data.dataBundle.finalStatus === "success"
            ) {
              getPrepaidDataBalance(localStorage.getItem("selectedAcc"));
              getPaymentDetailsPrepaid(localStorage.getItem("selectedAcc"));
              setSuccess(true);
              setLoading(false);
            } else {
              setIsError(true);
              setError(response.data.dataBundle.dataOcsresultDesc);
              setLoading(false);
            }
          } else {
            setIsError(true);
            setError(response.data.errorShow);
            setLoading(false);
          }
        })
        .catch(() => {
          setIsError(true);
          setError(CATCH_ERROR_MSG);
          setLoading(false);
        });
    } else {
      setIsError(true);
      setError(
        "Balance insufficient in your Prepaid wallet. Please recharge & try again"
      );
    }
  };

  if (loading) {
    return <LoadingBbBody loading={loading} border={true} />;
  } else if (packages && packages.length === 0) {
    return (
      <div className="getExtraGB common-panel">
        <NoDataToShow />
      </div>
    );
  } else if (packages) {
    return (
      <div className="packageUpgrade common-panel">
        {confirmShow ? (
          <ConfirmAlert
            setConfirmShow={setConfirmShow}
            msg={`Do you want to activate ${pName} package?`}
            onClick={() => activePackage()}
          />
        ) : null}

        {success ? (
          <SuccessAlert
            setSuccess={setSuccess}
            msg={`${pName} Package Activated Successfully`}
          />
        ) : null}

        {isError ? (
          <ErrorAlert
            error={error}
            setError={setError}
            setIsError={setIsError}
          />
        ) : null}

        <div className="new-package-container">
          <div className="bodyView">
            <InfiniteCarousel
              key={packages.length}
              breakpoints={[
                {
                  breakpoint: 500,
                  settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                  },
                },
                {
                  breakpoint: 768,
                  settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                  },
                },
              ]}
              dots={false}
              showSides={true}
              sidesOpacity={0.5}
              sideSize={0.1}
              slidesToScroll={3}
              slidesToShow={3}
              scrollOnDevice={true}
              autoCycle={true}
            >
              {packages.map((val, key) => {
                return (
                  <div key={key}>
                    <div className="package-item">
                      <div className="packageName">{val.MYSLT_PKG_NAME}</div>

                      <div className="amountDetails">
                        <div className="package-box any">
                          <div className="package-type">
                            Total Volume <span>{val.DATA_VOLUME_GB}GB</span>
                          </div>
                        </div>
                      </div>

                      <div className="priceDetails">
                        <div className="price">
                          {" "}
                          Rs.{val.PRICE_LKR_WITH_TAX} /-
                        </div>
                        <div className="per-month">(With Tax)</div>
                        <div className="per-month mt-2">
                          Validity Period{" "}
                          <span className="">{val.VALIDITY} Days</span>{" "}
                        </div>
                      </div>
                      <div className="btn-container">
                        <button
                          className="btn-upgrade"
                          onClick={() => {
                            setPName(val.MYSLT_PKG_NAME);
                            setOfferName(val.OFFERING_NAME);
                            setPId(val.OFFERING_ID);
                            setAmount(val.PRICE_LKR_WITH_TAX);
                            setConfirmShow(true);
                          }}
                        >
                          Activate
                        </button>
                      </div>
                    </div>
                  </div>
                );
              })}
            </InfiniteCarousel>
          </div>
        </div>
      </div>
    );
  } else return <h3>null</h3>;
}

const mapStateToProps = (state) => ({
  payments: state.accountDetails.payments,
});

export default connect(mapStateToProps, {
  getPaymentDetailsPrepaid,
  getPrepaidDataBalance,
})(DataPackages);

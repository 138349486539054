import { 
    SET_SMART_BILL_REGISTRATION,
    SUCCESS_SET_SMART_BILL_REGISTRATION,
    ERROR_SET_SMART_BILL_REGISTRATIONT
} from "../../Types";

const initialState = {
    loading: false,
    dataBundle: '',
    error:''
}


export default function smartBillRegistrationReducer(state= initialState, action){
    switch (action.type){

        case SET_SMART_BILL_REGISTRATION : {
            return {
                ...state,
                loading: true
            }
        }

        case SUCCESS_SET_SMART_BILL_REGISTRATION : {
            return {
                ...state,
                loading: false,
                error:'',
                dataBundle: action.payload
            }
        }

        case ERROR_SET_SMART_BILL_REGISTRATIONT : {
            return {
                loading: false,
                error: action.payload
            }
        }

        default :
            return state
    }
}
import React from 'react'

const EmptyCart = () => {
  return (
    <div className='bodyLTE wizard-container'>
        <div className='LTEpaymentInformation'>
            <div className="empty-cart-container">
                <div className='header'>
                    <svg width="100" height="97" viewBox="0 0 100 97" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M46.0023 59.9258C46.1935 61.5922 47.7535 62.8326 49.5713 62.8326C49.6949 62.8326 49.8194 62.827 49.9449 62.8154C51.9184 62.6314 53.353 61.0397 53.1489 59.2602L51.4921 44.8047C51.288 43.0252 49.522 41.7333 47.5495 41.9156C45.576 42.0996 44.1415 43.6912 44.3455 45.4707L46.0023 59.9258Z" fill="white"/>
                        <path d="M68.4836 62.8147C68.6091 62.8264 68.7336 62.832 68.8572 62.832C70.6754 62.832 72.235 61.5915 72.4262 59.9252L74.0825 45.4697C74.2866 43.6897 72.852 42.0981 70.8785 41.9145C68.9036 41.7327 67.1395 43.0246 66.9355 44.8036L65.2791 59.2592C65.075 61.0391 66.5096 62.6307 68.4836 62.8147Z" fill="white"/>
                        <path d="M40.6687 76.8965C34.5371 76.8965 29.5488 81.3945 29.5488 86.9235C29.5488 92.4538 34.5371 96.9532 40.6687 96.9532C46.8003 96.9532 51.789 92.4538 51.789 86.9235C51.789 81.3945 46.8003 76.8965 40.6687 76.8965ZM40.6687 90.4739C38.4988 90.4739 36.7337 88.881 36.7337 86.9231C36.7337 84.9665 38.4988 83.3749 40.6687 83.3749C42.8385 83.3749 44.6041 84.9665 44.6041 86.9231C44.6041 88.881 42.8385 90.4739 40.6687 90.4739Z" fill="white"/>
                        <path d="M77.7609 76.8965C71.6293 76.8965 66.6406 81.3945 66.6406 86.9235C66.6406 92.4538 71.6289 96.9532 77.7609 96.9532C83.893 96.9532 88.8822 92.4538 88.8822 86.9235C88.8822 81.3945 83.893 76.8965 77.7609 76.8965ZM77.7609 90.4739C75.5911 90.4739 73.8255 88.881 73.8255 86.9231C73.8255 84.9665 75.5911 83.3749 77.7609 83.3749C79.9312 83.3749 81.6973 84.9665 81.6973 86.9231C81.6973 88.881 79.9317 90.4739 77.7609 90.4739Z" fill="white"/>
                        <path d="M99.2493 31.1091C98.5692 30.3157 97.5197 29.8509 96.4075 29.8509H24.8004L21.7932 19.4239C21.3813 17.9964 19.952 17 18.3162 17H3.59245C1.60846 17 0 18.4504 0 20.2394C0 22.0284 1.60846 23.4788 3.59245 23.4788H15.5385L18.5212 33.8216C18.5361 33.8795 18.5529 33.9369 18.5711 33.9935L29.6631 72.4543C30.075 73.8818 31.5044 74.8782 33.1401 74.8782H85.29C86.9258 74.8782 88.3551 73.8818 88.7671 72.4543L99.8845 33.9054C100.164 32.9357 99.9295 31.903 99.2493 31.1091ZM82.5124 68.4003H35.9178L26.6689 36.3302H91.7617L82.5124 68.4003Z" fill="white"/>
                        <circle cx="62" cy="21" r="21" fill="#FFB917"/>
                        <path d="M62.125 31.2585C60.7339 31.2585 59.549 30.88 58.5703 30.1229C57.5916 29.3596 56.8438 28.2547 56.3267 26.8082C55.8097 25.3556 55.5511 23.6013 55.5511 21.5455C55.5511 19.5019 55.8097 17.7569 56.3267 16.3104C56.8499 14.8577 57.6009 13.7498 58.5795 12.9865C59.5644 12.2171 60.7462 11.8324 62.125 11.8324C63.5038 11.8324 64.6825 12.2171 65.6612 12.9865C66.6461 13.7498 67.397 14.8577 67.9141 16.3104C68.4373 17.7569 68.6989 19.5019 68.6989 21.5455C68.6989 23.6013 68.4403 25.3556 67.9233 26.8082C67.4062 28.2547 66.6584 29.3596 65.6797 30.1229C64.701 30.88 63.5161 31.2585 62.125 31.2585ZM62.125 29.2273C63.5038 29.2273 64.5748 28.5625 65.3381 27.233C66.1013 25.9034 66.483 24.0076 66.483 21.5455C66.483 19.9081 66.3075 18.514 65.9567 17.3629C65.612 16.2119 65.1134 15.3348 64.4609 14.7315C63.8146 14.1283 63.036 13.8267 62.125 13.8267C60.7585 13.8267 59.6906 14.5007 58.9212 15.8487C58.1518 17.1906 57.767 19.0895 57.767 21.5455C57.767 23.1828 57.9394 24.5739 58.2841 25.7188C58.6288 26.8636 59.1243 27.7346 59.7706 28.3317C60.4231 28.9287 61.2079 29.2273 62.125 29.2273Z" fill="#0B2A58"/>
                    </svg>
                </div>
                <div className='header'>
                    Your Cart is Empty
               </div>
            </div>
        </div>
    </div>
  )
}

export default EmptyCart

import React, { useState } from "react";
import { connect } from 'react-redux'
import GetGiftData from './GetGiftData';
import LoadingBbBody from '../LoadingBbBody';
import SuccessAlert from '../../alerts/SuccessAlert'; 
import ErrorAlert from '../../alerts/ErrorAlert';
import axiosInstance from "../../../redux/axiosInstance";
import { getValidateDataGiftSubUrl } from "../../../redux/URL";


const GiftData = (props) => {

    const [isValide, setIsValide] = useState(false)
    const [lodding, setLodding] = useState(false)
    const [success, setSuccess] = useState(false)
    const [isError, setIsError] = useState(false)
    const [error, seterror] = useState('')


    const validateButtonClick = () => {
        validateReceiverServiceCall()

    }
    const [receiverId, setReceiverId] = useState('');

    const validateDataGiftSusses = () =>{
        // <Link to ='/complaints'/>
        // props.complaintCreateList = false
        // history.push('/complaints')
        setIsValide(true)
      }


    const validateReceiverServiceCall = () => {
        setLodding(true)
        seterror('')
        setSuccess(false)
        setIsError(false)
        // let url2 = getAccountDetailsUrl + 'username=' + localStorage.getItem('userName') https://omnitest.slt.com.lk/api/BBVAS/ValidateDataGiftSub?
        let url = getValidateDataGiftSubUrl + "reciever="+receiverId+"&subscriberID="+props.profile.subscriberid
        axiosInstance.get(url).then(response => {
            setLodding(false)
            seterror(response.data.errorMessege)

            if(response.data.isSuccess){
                setSuccess(true)
            }else{
                setIsError(true)
            }
         

        }).catch(() => {
            seterror('errorPost')
            setIsError(true)
        })

    }


    if (!isValide) {

        return (
            <div className='giftData'>
                    {
                        lodding?(
                            <LoadingBbBody loading={props.loading}/>
                        ):(
                            isError?(
                                <ErrorAlert error ={error} setError = {seterror} setIsError = {setIsError}/>
                            ):(
                                
                            success?(
                                <SuccessAlert msg='Receiver Validate Success' setSuccess={validateDataGiftSusses}/>
                                ):(
                                    <div>
                                        <div className='container-box' >
                                            <div className='text-wrapper'>
                                                <p>Enter the username of the person to whom  you wish  to Gift Data  </p>
                                                <p>Select the package  by tapping  <span>VALIDATE RECEIVER </span></p>
                                            </div>
                                        </div>
                                        <div className='container-box' >
                                            <div className='text-wrapper'>
                                                <label>Receiver's  username  : </label>
                                                <input type="text" value={receiverId}  onChange={(e) => setReceiverId(e.target.value)} />
                                            </div>
                                        </div>
                                        <div className='container-box text-wrapper'  >
                                            <div>
                                                <button onClick={validateButtonClick}>VALIDATE RECEIVER</button>
                                            </div>

                                        </div>
                                        <div className='container-box'  >
                                            <div className='text-wrapper' >
                                                <p >Ensure  the username is correct, as  this  transaction  cannot be reversed. </p>
                                            </div>
                                        </div>
                                    </div>
                                )
                            )
                        )
                        
                    }
                




            </div>

        )

    } else {
        return (
            // <div className='mainBody'>
                <div className='giftData'>
                    <GetGiftData receiverId = {receiverId}/>
                </div>
            // </div>
           
        )



    }


}

const mapStateToProps = state => ({
    // loading: state.voucher.loading,
    // voucherResponse: state.voucher.dataBundle,
    // error: state.voucher.error,
  
    // serviceList: state.accountDetails.service_list,
    profile: state.broadBand.profile,
})



export default connect(mapStateToProps) (GiftData)

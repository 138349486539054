import {
    ERROR_GET_BILL_HISTORY,
    GET_BILL_HISTORY,
    SUCCESS_GET_BILL_HISTORY,
    } from "../../Types";

const initialState = {
    loading: true,
    dataBundle: {},
    error:''
}

export default function billHistoryReducer(state= initialState, action){
    switch (action.type){

        case GET_BILL_HISTORY : {
            return {
                ...state,
                loading: true
            }
        }

        case SUCCESS_GET_BILL_HISTORY : {
            return {
                ...state,
                loading: false,
                dataBundle: action.payload
            }
        }

        case ERROR_GET_BILL_HISTORY : {
            return {
                loading: false,
                error: action.payload
            }
        }

        default :
            return state
    }
}

import React, {useEffect, useState} from 'react'

const SelectVoicePackage = ({packageInfo, setPackageInfo, setIsOpenVoice, data, setSelectedVioceObj}) => {

    const [open, setOpen] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setOpen(true)
        }, 0)
    }, [open]);

  return (
    <div className='packageSelect'>
        {
            <div className={open?'voicePack show':'voicePack'}>
                <div className='voicePackages'>
                    {
                        data.map((obj, index) => {
                            if (packageInfo.connection === 'cVoice'){
                                return obj.VOICE_PACKAGE_NAME === "Voice Pal - Baisc" ? <div className="package-item-wrapper" key={index}>
                                    <div  onClick={() => {
                                        setSelectedVioceObj(obj)
                                            setPackageInfo({
                                                ...packageInfo,
                                                voicePackages: obj.VOICE_PACKAGE_NAME,
                                                voicePackagesData: obj
                                            })
                                            setTimeout(() => {
                                                setIsOpenVoice(false)
                                            }, 150)

                                        }}
                                        className={packageInfo.voicePackages === obj.VOICE_PACKAGE_NAME ? 'package-item selected' : "package-item"}
                                    >
                                        <div className='packageName'>
                                            {obj.VOICE_PACKAGE_NAME}
                                        </div>
                                        <div>
                                            {obj.FREE_MINUTES}
                                        </div>
                                        <div className='priceDetails'>
                                            <div className="price"> Rs.{obj.MONTHLY_RENTAL} + Tax</div>
                                            <div className="per-month">(Free Minutes)</div>

                                        </div>
                                    </div>
                                </div> : null
                            }else if (packageInfo.connection === 'cBoth'){
                                return obj.VOICE_PACKAGE_NAME === "Home " ? <div className="package-item-wrapper" key={index}>
                                    <div  onClick={() => {
                                        setSelectedVioceObj(obj)
                                            setPackageInfo({
                                                ...packageInfo,
                                                voicePackages: obj.VOICE_PACKAGE_NAME,
                                                voicePackagesData: obj
                                            })
                                            setTimeout(() => {
                                                setIsOpenVoice(false)
                                            }, 150)

                                        }}
                                        className={packageInfo.voicePackages === obj.VOICE_PACKAGE_NAME ? 'package-item selected' : "package-item"}
                                    >
                                        <div className='packageName'>
                                            {obj.VOICE_PACKAGE_NAME}
                                        </div>
                                        <div>
                                            {obj.FREE_MINUTES}
                                        </div>
                                        <div className='priceDetails'>
                                            <div className="price"> Rs.{obj.MONTHLY_RENTAL} + Tax</div>
                                            <div className="per-month">(Free Minutes)</div>

                                        </div>
                                    </div>
                                </div> : null
                            }else{
                                return <div className="package-item-wrapper" key={index}>
                                    <div  onClick={() => {
                                        setSelectedVioceObj(obj)
                                            setPackageInfo({
                                                ...packageInfo,
                                                voicePackages: obj.VOICE_PACKAGE_NAME,
                                                voicePackagesData: obj
                                            })
                                            setTimeout(() => {
                                                setIsOpenVoice(false)
                                            }, 150)

                                        }}
                                        className={packageInfo.voicePackages === obj.VOICE_PACKAGE_NAME ? 'package-item selected' : "package-item"}
                                    >
                                        <div className='packageName'>
                                            {obj.VOICE_PACKAGE_NAME}
                                        </div>
                                        <div>
                                            {obj.FREE_MINUTES}
                                        </div>
                                        <div className='priceDetails'>
                                            <div className="price"> Rs.{obj.MONTHLY_RENTAL} + Tax</div>
                                            <div className="per-month">(Free Minutes)</div>

                                        </div>
                                    </div>
                                </div>
                            }
                        })
                    }
                </div>
            </div>
        }
    </div>
  )
}

export default SelectVoicePackage
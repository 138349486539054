import React, {useState} from 'react';
import {BsFillCaretRightFill} from "react-icons/bs";
import MyVoicePackage from "./MyVoicePackage";
import CallForwarding from "./CallForwarding";
import { isPrepaid } from '../../redux/action/accountDetails.action';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';


function VoiceMain({serviceList}) {
    const [state, setState] = useState(1);

    if (isPrepaid(serviceList && serviceList))
        return <Redirect to='/prepaid/boardBand'/>;


    return (

        <div className='mainBody'>
            <div className='voice-container common-panel'>
                <div className='leftSide'>
                    <div className={state === 1 ? 'buttonTab blue' : 'buttonTab'} onClick={() => setState(1)}>
                        <div >
                            <p className='m-0'>My Package</p>
                        </div>
                        {/* <span hidden={state !==1}>
                            <BsFillCaretRightFill size={20} color='#007ed6'/>
                        </span> */}
                        <span hidden={state !==1}
                                  className='inputIcon'><BsFillCaretRightFill size={20} color='#007ed6'/></span>
                    </div>


                    <div className={state === 2 ? 'buttonTab blue' : 'buttonTab'} onClick={() => setState(2)}>
                        <div >
                            <p className='m-0'>Call Forwarding</p>
                        </div>
                        <span hidden={state !==2}
                                  className='inputIcon'><BsFillCaretRightFill size={20} color='#007ed6'/></span>
                    </div>


                </div>

                {/*<div className='rightSide'>*/}
                    {state === 1 ? <MyVoicePackage/> : null}
                    {state === 2 ? <CallForwarding/> : null}

                {/*</div>*/}
            </div>
        </div>

    );
}

const mapStateToProps = state => ({
    serviceList: state.accountDetails.service_list,
})

export default connect(mapStateToProps, {})(VoiceMain);
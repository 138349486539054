import React from 'react';
import {Redirect, Route} from 'react-router-dom';
import {connect} from 'react-redux'
import sltLogo from "../assests/images/Logo-large-white.png";
import LoginBannerViews from "../component/common/LoginBannerViews";

const PublicRoute = ({loggedIn, type, component: Component, ...rest}) => (
    <Route {...rest} component={(props) => (
       ( type === 'any' || type === 'FTTHNEW') ? (<Component {...props}/>) : loggedIn ? (
            <Redirect to='/boardBand/summary'/>
        ) : (
            <div className='containerLogin'>

                <LoginBannerViews/>

                <div className='formView'>
                    <div className='logo-container'>
                        <img src={sltLogo} alt='sltLogo' className='d-block m-auto logo'/>
                    </div>

                    <Component {...props}/>

                </div>

            </div>

        )
    )}/>
)

const mapStateToProps = state => ({
    loggedIn: state.login.loggedIn,

})
export default connect(mapStateToProps, null)(PublicRoute);

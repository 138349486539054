import React, {useEffect, useState} from 'react';

function SelectBroadbandPackage({packageInfo, setPackageInfo, setIsOpenBb, data, setSelectedBBObj}) {

    const [bbType, setBbType] = useState('S');
    const [open, setOpen] = useState(false);
    useEffect(() => {
        setTimeout(() => {
            setOpen(true)
        }, 0)
    }, [open]);

    const selectArray = () => {
        if (data) {
            if (bbType === 'S') {
                return data.Standard[0]
            } else if (bbType === 'A') {
                return data.Any[0]
            } else if (bbType === 'U') {
                return [...data.Unlimited[0], ...data.Flash[0]]
            } else
                return []
        } else
            return []
    }

    return (
        <div className='packageSelect'>
            {
             <div  className={open?'broadbandPack show':'broadbandPack'}>

                    <div className='topButtons'>

                        <button onClick={() => setBbType('S')} className={bbType === 'S' ? 'blue' : null}>Value Packages
                        </button>
                        <button onClick={() => setBbType('A')} className={bbType === 'A' ? 'blue' : null}>Any Time Packages
                        </button>
                        <button onClick={() => setBbType('U')} className={bbType === 'U' ? 'blue' : null}>Unlimited Packages

                        </button>
                    </div>
                    <div className='bbPackages'>
                        {
                            selectArray().map((obj, index) => {
                                return <div className="package-item-wrapper" key={index}>
                                    <div
                                        className={packageInfo.bbPackages === obj.BB_PACKAGE_NAME ? 'package-item selected' : "package-item"}
                                        onClick={() => {
                                            setPackageInfo({
                                                ...packageInfo,
                                                bbPackages: obj.BB_PACKAGE_NAME,
                                                bbPackagesData:obj,
                                                bbType:bbType
                                            })
                                            setSelectedBBObj(obj)
                                            setTimeout(() => {
                                                setIsOpenBb(false)
                                            }, 150)

                                        }}>
                                        <div className='packageName'>
                                            {obj.BB_PACKAGE_NAME}
                                        </div>

                                        {
                                            bbType === 'U' ? <div className='amountDetails'>
                                                <div className="package-box any">
                                                    {/*<div className="package-type">Any</div>*/}
                                                    <div className="package-type">Unlimited Data</div>
                                                </div>
                                                {/*<div className="plus">*/}
                                                {/*</div>*/}
                                                {/*<div className="package-box free">*/}
                                                {/*    <div className="package-type">Free</div>*/}
                                                {/*    <div className="package-amount">{val.FREE_GB}GB</div>*/}
                                                {/*</div>*/}

                                            </div> : bbType === 'A' ?
                                                <div className='amountDetails'>
                                                    <div className="package-box any">
                                                        <div className="package-type">{obj.STANDARD_GB}GB
                                                        </div>
                                                    </div>
                                                    {/*<div className="plus">*/}
                                                    {/*</div>*/}
                                                    {/*<div className="package-box free">*/}
                                                    {/*    <div className="package-type">Free</div>*/}
                                                    {/*    <div className="package-amount">{val.FREE_GB}GB</div>*/}
                                                    {/*</div>*/}

                                                </div> : <div className='amountDetails'>
                                                    <div className="package-box standard">
                                                        <div className="package-type">Standard</div>
                                                        <div className="package-amount">{obj.STANDARD_GB}GB</div>
                                                    </div>
                                                    <div className="plus">
                                                    </div>
                                                    <div className="package-box free">
                                                        <div className="package-type">Free</div>
                                                        <div className="package-amount">{obj.FREE_GB}GB</div>
                                                    </div>

                                                </div>
                                        }
                                        <div className='priceDetails'>
                                            <div className="price"> Rs.{obj.MONTHLY_RENTAL} + Tax</div>
                                            <div className="per-month">(Per Month)</div>

                                        </div>
                                    </div>

                                </div>
                            })
                        }
                    </div>
                    {/*<div className='popup-footer'>*/}
                    {/*    <div className="container-right">*/}
                    {/*        <button className='btn-ok' onClick={() => setIsOpenBb(false)}>Select</button>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </div> 
            }


        </div>
    );
}

export default SelectBroadbandPackage;

import React from 'react';
import {connect} from "react-redux";
import LoadingBbBody from "../boardBand/LoadingBbBody";
import {Redirect} from "react-router-dom";
import peoTvGo from "../../assests/images/mobitelLogo.png";
import { isPrepaid } from '../../redux/action/accountDetails.action';

function Mobile({loadingServices, account, loadingAccounts, loadingProfile, serviceList}) {

    if (isPrepaid(serviceList && serviceList))
        return <Redirect to='/prepaid/boardBand'/>;

    if (loadingAccounts || loadingServices || loadingProfile) {
        return <div className='mainBody eBill'>
            <LoadingBbBody/>
        </div>
    } else if (!account || account.length === 0) {
        return <Redirect to='/noAccount'/>;
    }
    return (
        <div className='mainBody eBill common-panel'>
            <div className='mobitel-container mt-5'>
                <img src={peoTvGo} alt={'searchIcon'} className='mobitelLogo'/>
                <button className='btn-mobitel' onClick={() => window.open('https://www.mobitel.lk/selfcare-app', "_blank")}>Go</button>
            </div>
        </div>
    );
}


const mapStateToProps = state => ({
    loadingAccounts: state.accountDetails.loading,
    loadingServices: state.accountDetails.loadingServiceList,
    loadingProfile: state.broadBand.loadingProfile,
    serviceList: state.accountDetails.service_list,
    account: state.accountDetails.account_Details,
    profile: state.broadBand.profile,
})

export default connect(mapStateToProps, {})(Mobile);
import {
    ERROR_GET_ADD_ONS,
    ERROR_GET_BONUS_DATA,
    ERROR_GET_EXTRAGB, ERROR_GET_FREE_DATA,
    ERROR_GET_PROFILE,
    ERROR_GET_SUMMARY,
    LOADING_ADD_ONS,
    LOADING_BONUS_DATA,
    LOADING_EXTRAGB, LOADING_FREE_DATA,
    LOADING_GET_SUMMARY, LOADING_PROFILE, SUCCESS_GET_ADD_ONS, SUCCESS_GET_BONUS_DATA,
    SUCCESS_GET_EXTRAGB, SUCCESS_GET_FREE_DATA, SUCCESS_GET_PROFILE,
    SUCCESS_GET_SUMMARY
} from "../Types";

const initialState = {
    loading: true,
    summary: {},
    errorSummary : 'abc',

    loadingProfile: false,
    profile:{},
    errorProfile:'',

    loadingExtraGB:'',
    loadingBonusData:'',
    loadingAddOns:'',
    loadingFreeData:'',

    extraGB:{},
    bonusData: {},
    addOnsData: {},
    freeData:{},

    extraGbError: '',
    bonusDataError: '',
    addOnsDataError : '',
    freeDataError: ''
}

export default function broadBandReducers(state= initialState, action){
    switch (action.type) {

        case LOADING_GET_SUMMARY: {
            return {
                ...state,
                loading: true,
                summary: {},
                errorSummary: ''
            }
        }

        case SUCCESS_GET_SUMMARY: {
            return {
                ...state,
                loading: false,
                summary: action.payload
            }
        }

        case ERROR_GET_SUMMARY: {
            return {
                ...state,
                loading: false,
                errorSummary: action.payload
            }
        }

        case LOADING_PROFILE: {
            return {
                ...state,
                loadingProfile: true,
                profile: {},
                errorProfile: ''
            }
        }

        case SUCCESS_GET_PROFILE: {
            return {
                ...state,
                loadingProfile: false,
                profile: action.payload,
            }
        }

        case ERROR_GET_PROFILE: {
            return {
                ...state,
                loadingProfile: false,
                profile: {},
                errorProfile: action.payload
            }
        }

        case LOADING_EXTRAGB:{
            return {
                ...state,
                loadingExtraGB: true,
                extraGB: {},
                extraGbError: ''
            }
        }

        case SUCCESS_GET_EXTRAGB: {
            return {
                ...state,
                loadingExtraGB: false,
                extraGB: action.payload
            }
        }

        case ERROR_GET_EXTRAGB: {
            return {
                ...state,
                loadingExtraGB: false,
                extraGB: {},
                extraGbError: action.payload
            }
        }

        case LOADING_BONUS_DATA:{
            return {
                ...state,
                loadingBonusData: true,
                bonusData: {},
                bonusDataError: ''
            }
        }

        case SUCCESS_GET_BONUS_DATA: {
            return {
                ...state,
                loadingBonusData: false,
                bonusData: action.payload
            }
        }

        case ERROR_GET_BONUS_DATA: {
            return {
                ...state,
                loadingBonusData: false,
                bonusData: {},
                bonusDataError: action.payload
            }
        }

        case LOADING_ADD_ONS: {
            return {
                ...state,
                loadingAddOns: true,
                addOnsData: {},
                addOnsDataError: ''
            }
        }

        case SUCCESS_GET_ADD_ONS: {
            return {
                ...state,
                loadingAddOns: false,
                addOnsData: action.payload

            }
        }

        case ERROR_GET_ADD_ONS: {
            return {
                ...state,
                loadingAddOns: false,
                addOnsData: {},
                addOnsDataError: action.payload
            }
        }

        case LOADING_FREE_DATA: {
            return {
                ...state,
                loadingFreeData: true,
                freeData: {},
                freeDataError: ''
            }
        }

        case SUCCESS_GET_FREE_DATA: {
            return {
                ...state,
                loadingFreeData: false,
                freeData: action.payload
            }
        }

        case ERROR_GET_FREE_DATA: {
            return {
                ...state,
                loadingFreeData: false,
                freeData: {},
                freeDataError: action.payload
            }
        }

        default :
            return state

    }
}


import React, {useState} from 'react';
import OtpInput from "react-otp-input";
import axiosInstance from "../../redux/axiosInstance";
import {OTPValidation} from "../../redux/URL";
import ErrorAlert from "../alerts/ErrorAlert";
import {AiFillCloseCircle} from "react-icons/ai";
import {CATCH_ERROR_MSG} from "../../redux/Types";

function OtpRequestProfile({
                               setLoading,
                               error,
                               setError,
                               isError,
                               setIsError,
                               isMail,
                               setOtpStatus,
                               updateContact,
                               sendOTPRequest
                           }) {

    const [OTP, setOTP] = useState("");

    const validateOtp = () => {
        let type = localStorage.getItem('userType') === 'MOBILE' ? 'EMAIL' : 'MOBILE'
        if (OTP.length === 6) {
            // eslint-disable-next-line no-useless-concat
            let url = OTPValidation + 'requestType=' + 'eBill' + '&otpCode=' + OTP + '&otpSource=' + type
            setLoading(true)
            axiosInstance.post(url).then(response => {

                if (response.data.isSuccess) {
                    setOtpStatus(false)
                    updateContact()
                } else if (response.data.errorShow) {
                    setError(response.data.errorShow)
                    setIsError(true)
                    setLoading(false)
                }
            }).catch(() => {
                setError(CATCH_ERROR_MSG)
                setIsError(true)
                setOtpStatus(false)
                setLoading(false)
            })
        } else {
            setError('Enter Valid Otp')
            setIsError(true)
            setLoading(false)
        }
    }


    return (
        <div className='mainBody eBill common-panel '>
            <div className="body-container">
                {isError ? <ErrorAlert error={error} setError={setError} setIsError={setIsError}/> : null}
                <div className='title'>
                    <p>Enter OTP</p>

                </div>
                <div className='wrapper'>


                    <div className='content'>
                        <div className='card'>
                            <div className='d-flex justify-content-end pr-3 pt-3'>
                                <span onClick={() => setOtpStatus(false)}><AiFillCloseCircle color='red'
                                                                                             size={20}/></span>
                            </div>

                            <div className='text-center font-weight-bold'>Otp send
                                to {isMail ? 'Email' : 'Mobile'}</div>

                            <div className='form d-flex justify-content-center'>

                                <OtpInput
                                    value={OTP}
                                    onChange={setOTP}
                                    numInputs={6}
                                    isInputNum={true}
                                    shouldAutoFocus={true}
                                    inputStyle='otp'
                                />

                            </div>
                            <div className='text-center mb-4'>
                                <button className='medium blue mr-1' onClick={() => sendOTPRequest()}>Resend</button>
                                <button className='medium blue ml-1' onClick={() => validateOtp()}>Submit</button>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>

    );
}

export default OtpRequestProfile;
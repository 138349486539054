import React, {useState} from 'react';
import ExtraGBConfirmPayments from "./ExtraGBConfirmPayments";
import ExtraGBPaymentType from "./ExtraGBPaymentType";
import {connect} from "react-redux";
import ExtraGbDetails from "./ExtraGBDetails";
import NoPreviledge from "../../alerts/NoPreviledge";

const GetExtraGb = ({profile}) => {


    const [amount, setAmount] = useState(null);
    const [amountUnit, setAmountUnit] = useState(null);
    const [amountPrice, setAmountPrice] = useState(null);
    const [payType, setPayType] = useState('');
    const [pId, setPId] = useState('');

    const [state, setState] = useState('Select');

    if (!profile.priviledges.extra_gb) {
        return <div className='graphBody-wrapper'>
            <div className='graphBody noPadding'>
                <NoPreviledge msg={'Please change the SLT package for Extra GB'} />
            </div>
        </div>
    } else if (state === 'Select') {
        return <ExtraGbDetails amount={amount} setAmount={setAmount} amountUnit={amountUnit}
                               setAmountUnit={setAmountUnit} amountPrice={amountPrice} setAmountPrice={setAmountPrice}
                               setState={setState} setPId={setPId}/>
    } else if (state === 'SelectType') {
        return <ExtraGBPaymentType setState={setState} amount={amount} amountUnit={amountUnit}
                                   amountPrice={amountPrice} payType={payType} setPayType={setPayType}/>
    } else if (state === 'Confirm') {
        return <ExtraGBConfirmPayments setState={setState} amount={amount} amountUnit={amountUnit}
                                       amountPrice={amountPrice} payType={payType} pId={pId}/>
    } else
        return null
}


const mapStateToProps = state => ({
    profile: state.broadBand.profile,

})

export default connect(mapStateToProps, {})(GetExtraGb);

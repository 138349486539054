import React from 'react';

const ShowConditionFtthPayment = ({setShowTerms, setAgree}) => {

    return (
        <div className='termConditon'>
            <div className='headerTerm'>TERMS AND CONDITIONS</div>

            <div className='bodyList'>
                <div className='discription'>
                    <div>Customer acknowledges and agrees to make Service Enabling Fee via Online payment service.</div>
                    <div>It is the responsibility of the Customer to have read the terms and conditions carefully before
                        using the Service.
                    </div>
                    <div>By authorizing a payment to SLT through the online payment service, it would be treated as a
                        deemed acceptance to these terms and conditions.
                    </div>
                </div>

                <div className='title'>1. Debit/Credit Card Details
                </div>

                <div className='discription'>1.1 The Customer shall pay Service Enabling Fee to SLT by using a
                    debit/credit card or through an online banking account.
                </div>

                <div className='discription'> 1.2 The Customer acknowledges and agrees that the debit/credit card
                    details provided by the Customer are correct and lawfully owned by the Customer or the use of which
                    is not authorized by the lawful owner thereof. 1.2 “Site/Premises” shall mean the location where the
                    Service is required
                    or affixed or
                    provided or is to be affixed or is to be provided, as the case may be.
                </div>


                <div className='title'>2 Personal Information
                </div>

                <div className='discription'> 2.1 Customer shall provide true, correct, accurate and complete
                    information including personal information pertaining to the Service and packages provided by SLT.
                </div>
                <div className='discription'> 2.2 Customer shall submit genuine Documents/additional Documents requested
                    in the Application for New Services pertaining to the Service and packages provided by SLT.
                </div>

                <div className='discription'> 2.3 SLT shall comply with all applicable confidentiality obligations and
                    privacy law that govern the Personal Information shared by the Customer.
                </div>

                <div className='discription'> 2.4 Willful dishonesty of personal information and/or the documents of the
                    Customer may render for immediate termination of the Service at any level without refunding the
                    Service Enabling Fee and Criminal Liability will occur to that effect.
                </div>

                <div className='title'>3 Payment
                </div>

                <div className='discription'>3.1 The Customer shall undertake to make the Service Enabling Fee as a One
                    Time Charge at the end of this Online Payment Process.
                </div>
                <div className='discription'> 3.2 Receiving the Service Enabling Fee shall not ensure that SLT will be
                    able to provide the Service, and it will depend on condition/capacity of the connection and/or the
                    availability of technology and the resources
                </div>

                <div className='discription'> 3.3 SLT shall start the Service provisioning process once the Customer
                    shall complete the Online Payment Process.
                </div>
                <div className='discription'> 3.4 Subject to Clause 3.2, SLT shall refund the Service Enabling Fee
                    already collected from the Customer, within one (01) month from the Online Payment date .
                </div>
                <div className='discription'> 3.5 In the event if additional resources are required to provide the
                    Service, during the Service provisioning process, SLT shall impose Additional Fees from the
                    Customer.
                </div>
                <div className='discription'> 3.6 Customer shall make the payment of Additional Fees for the same within
                    07 days from the date the Additional Fees requested by SLT.
                </div>
                <div className='discription'> 3.7 In the event if Customer is not accepted the payment of Additional
                    Fees, Customer shall terminate the Agreement and request in writing to refund the Service Enabling
                    Fee.
                </div>
                <div className='discription'> 3.8 Subject to Clause 3.7 SLT shall refund the Service Enabling Fee within
                    One (01) Month from the date SLT received the written request from the Customer.
                </div>

                <div className='title'>4 No warranty
                </div>

                <div className='discription'> 4.1 No warranty, representation or guarantee, express or implied, is given
                    by SLT in respect of the operation of the online payment service other than what is specified in the
                    Website for this purpose.
                </div>

                <div className='title'>5 Disclaimer and Limitation of liability
                </div>

                <div className='discription'> 5.1 By accepting/ agreeing to these Terms and Conditions, the Customer
                    expressly agrees that his/ her use of the aforesaid online payment Service is entirely at own risk
                    and responsibility of the Customer.
                </div>


                <div className='discription'> 5.2 SLT shall disclaims any representation made by any third party at any
                    stage as to the ownership of the Service/site/Premises.
                </div>


                <div className='title'>6 Governing Law
                </div>

                <div className='discription'> These terms and conditions are governed by the laws of Sri Lanka and the
                    Parties agree to submit to the exclusive jurisdiction of the competent courts of Sri Lanka having
                    jurisdiction over the place on which SLT’s Registered Office is situated
                </div>


                <div className='title'>7 Refund/Cancellation of the payment
                </div>

                <div className='discription'> Subject to Clause 3.4 and Clause 3.8, Service Enabling Fee once paid will
                    not be refunded under any circumstances and no cancellations from the side of the Customer is
                    accepted.
                </div>

                <div className='discription'> <div className='discription'> Subject to Clause 3.4 and Clause 3.8, Service Enabling Fee once paid will
                    not be refunded under any circumstances and no cancellations from the side of the Customer is
                    accepted.By clicking the “Accept”  box below, I acknowledge that I have read, understood and accept the Terms and Conditions mentioned above
                </div>
                </div>

                <div className='text-center'>
                    <button className='medium blue' onClick={() => {
                        setShowTerms(false)
                        setAgree(true)
                    }}>Accept
                    </button>
                    &nbsp; &nbsp; &nbsp;
                    <button className='medium blue' onClick={() => {
                        setShowTerms(false)
                        setAgree(false)
                    }}>Close
                    </button>


                </div>
            </div>
        </div>
    );
};

export default ShowConditionFtthPayment;

import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import {bonusData} from "../../redux/action/broadBand.action";
import { addAddOnsDataV2 } from '../../redux/action/broadBand/dataAddOns.action';
import ProgressBarView from "./ProgressBarView";
import LoadingGraphs from "./LoadingGraphs";
import {BiChevronLeft, BiChevronRight} from "react-icons/bi";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import {Carousel} from 'react-responsive-carousel';
import NoDataToShow from "../common/NoDataToShow";
import noBonusData from '../../assests/images/nodatashow/bonus data.png'
import NoPreviledge from "../alerts/NoPreviledge";
import {getSubmitClaimUrl} from "../../redux/URL";
import axiosInstance from "../../redux/axiosInstance";
import SuccessAlert from "../alerts/SuccessAlert";
import ErrorAlert from "../alerts/ErrorAlert";
import {CATCH_ERROR_MSG} from "../../redux/Types";

function BonusData({bonusData, serviceList, loading, loadingDataAddOns, bonus_Data, bonusDataError, profile, addAddOnsDataV2}) {
    const [count, setCount] = useState(0);
    const [loadingState, setLoadingState] = useState(false);
    const [success, setSuccess] = useState(false);
    const [successMsg, setSuccessMsg] = useState('');
    const [isError, setIsError] = useState(false);
    const [error, setError] = useState('');

    function increment() {
        let length = bonus_Data.usageDetails.length
        if (length - 1 > count) {
            setCount(count + 1)
        }


    }

    function decrement() {
        if (count > 0) {
            setCount(count - 1)
        }

    }

    function splitWord(word) {
        let day = word.split('T')[0]
        let time = word.split('T')[1]

        return day + ' ' + time
    }

    useEffect(() => {
        if (profile.hasOwnProperty('priviledges') && profile.priviledges.bonus_data) {
            bonusData(serviceList.listofBBService[0].serviceID)
        }
    }, [bonusData, profile, serviceList]);

    const dataAddOns = () => {
        addAddOnsDataV2(profile.subscriberid, 28, localStorage.getItem('userName'), 'SCP', setSuccess, setSuccessMsg, setIsError, setError)
    }

    function upgrade() {
        let url = getSubmitClaimUrl + 'subscriberID=' + profile.subscriberid
        setLoadingState(true)
        axiosInstance.put(url).then(response => {

            if (response.data.dataBundle.status === 'SUCCESS') {
                setSuccessMsg(response.data.dataBundle.message)
                setSuccess(true)
                setLoadingState(false)
                bonusData(serviceList.listofBBService[0].serviceID)
            } else {
                setError(response.data.errorShow)
                setIsError(true)
                setLoadingState(false)
            }
        }).catch(() => {
            setError(CATCH_ERROR_MSG)
            setIsError(true)
            setLoadingState(false)
        })
    }

    if (profile.hasOwnProperty('priviledges') && !profile.priviledges.bonus_data) {
        return <div className='graphBody-wrapper'><div className='graphBody noPadding'><NoPreviledge msg={'Please change the SLT package for Bonus Data'}/>
        </div></div>
    } else if (loading || loadingState) {
        return <LoadingGraphs loading={loading}/>
    } else if (bonusDataError) {
        return <div className='graphBody-wrapper'><div className='graphBody noPadding'><ErrorAlert error={bonusDataError} setIsError={null}
                                                                setError={null}/></div></div>
    } else if (success || isError) {
        return <div className='graphBody-wrapper'><div className='graphBody noPadding'>{success ?
            <SuccessAlert setSuccess={setSuccess} msg={successMsg}/> : null}
            {isError ? <ErrorAlert error={error} setError={setError} setIsError={setIsError}/> : null}
        </div></div>
    } else if (bonus_Data.hasOwnProperty('usageDetails')) {
        return (
            
            <div className='graphBody-wrapper'>
            <div className='graphBody'>
                <div>
                    <p className='graph-body-title'>Remaining Volume as of
                        : {splitWord(bonus_Data.reported_time)}</p>
                </div>

                {
                    bonus_Data.usageDetails.length === 0 ?
                        <NoDataToShow imgPath={noBonusData} msg='Wait for bonus data'/> :
                        <div className='d-flex justify-content-between align-items-center'>
                    <span style={{minWidth: '20px', cursor: 'pointer'}} className=''
                          onClick={() => decrement()}><BiChevronLeft
                        size={40} hidden={count === 0}/></span>

                            <div>
                                <Carousel showArrows={false} showStatus={false} showIndicators={false}
                                          selectedItem={count}
                                          width={500}>
                                    {
                                        bonus_Data.usageDetails.map((val, key) => {
                                            if (val.name === "Data Hamper") {
                                                return (
                                                    <>
                                                    <div key={key} className='h-auto m-auto' >
                                                        <div className='text-center'>
                                                            <p className='m-0'>{val.name}</p>
                                                            <ProgressBarView progress={val.percentage} word={val.remaining}
                                                                             unit={val.volume_unit}/>
                                                            <div className='used-of'>{val.used} {val.volume_unit} USED
                                                                OF {val.limit} {val.volume_unit}</div>
                                                            {
                                                                bonus_Data.usageDetails.length > 0 ?
                                                                    <p className='text-center blue'>(Valid Till
                                                                        : {val.expiry_date})</p> : null
                                                            }
                                                        </div>
                                                    </div>
                                                    {/* <div className='text-center upgradeButton'> 
                                                            <button className="btn-upgrade" onClick={() => dataAddOns()}> Redeem 20GB offer</button>
                                                    </div> */}
                                                    </>
                                                )
                                            } else {
                                                return (
                                                    <div key={key} className='h-auto m-auto' >
                                                        <div className='text-center'>
                                                            <p className='m-0'>{val.name}</p>
                                                            <ProgressBarView progress={val.percentage} word={val.remaining}
                                                                            unit={val.volume_unit}/>
                                                            <div className='used-of'>{val.used} {val.volume_unit} USED
                                                                OF {val.limit} {val.volume_unit}</div>
                                                            {
                                                                bonus_Data.usageDetails.length > 0 ?
                                                                    <p className='text-center blue'>(Valid Till
                                                                        : {val.expiry_date})</p> : null
                                                            }
                                                        </div>
                                                    </div>
                                                )
                                            }
                                            
                                        })
                                    }
                                </Carousel>
                            </div>
                            <span style={{minWidth: '20px', cursor: 'pointer'}}
                                  onClick={() => increment()}><BiChevronRight size={40}
                                                                              hidden={count === bonus_Data.usageDetails.length - 1 || bonus_Data.usageDetails.length === 0}/></span>
                        </div>
                }

                {
                    bonus_Data ? bonus_Data.hasOwnProperty('usageDetails') ? bonus_Data.usageDetails.length > 0 ? bonus_Data.usageDetails[0].claim ?
                        <div className='text-center upgradeButton'>
                            <button className="btn-upgrade" onClick={() => upgrade()}>Upgrade
                                to {bonus_Data.usageDetails[0].claim.volume}{bonus_Data.usageDetails[0].claim.volume_unit}</button>
                        </div> : null : null : null : null
                }
            </div>
            </div>
        );


    } else
        return null
}


const mapStateToProps = state => ({
        loading: state.broadBand.loadingBonusData,
        bonus_Data: state.broadBand.bonusData,
        bonusDataError: state.broadBand.bonusDataError,
        serviceList: state.accountDetails.service_list,
        profile: state.broadBand.profile,
        addOnsList: state.dataAddOns.dataBundle,
        loadingDataAddOns: state.dataAddOns.loading,
        errorDataAddOns: state.dataAddOns.error,
    }
)

export default connect(mapStateToProps, {bonusData, addAddOnsDataV2})(BonusData);

import React, {Component} from 'react';

class ErrorShow extends Component {
    render() {
        return (
            <div className='mainBody eBill common-panel'>
                Error show view
            </div>
        );
    }
}

export default ErrorShow;

import { LOADING_REDEEM_VOUCHER, SUCCESS_REDEEM_VOUCHER, ERROR_REDEEM_VOUCHER } from "../../Types"

const initialState = {
    loading: false,
    redeemVoucher: '',
    error: '',
   
}

export default function redeemVoucherReducer(state = initialState, action){
    switch(action.type){

        case LOADING_REDEEM_VOUCHER : {
           return {
                ...state,
                loading : true,

           }
        }

        case SUCCESS_REDEEM_VOUCHER : {
            return {
                 ...state,
                 loading: false,
                 redeemVoucher: action.payload
            }
        }

        case ERROR_REDEEM_VOUCHER : {
            return {
                loading : false,
                error : action.payload
            }
        }

        default:
            return state

    }
}

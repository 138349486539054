import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import getProtocolReport from "../../../redux/action/broadBand/protocolReport.action";
import LoadingBbBody from "../LoadingBbBody";
import {PieChart} from 'react-minimal-pie-chart';
import {useParams} from "react-router-dom";
import NoDataToShow from "../../common/NoDataToShow";

const DailyUsageOneDay = ({getProtocolReport, profile, report, loading}) => {

    const [type, setType] = useState('Total');

    let {date} = useParams();
    useEffect(() => {
        getProtocolReport(profile.subscriberid, date)
    }, [getProtocolReport, profile]);// eslint-disable-line react-hooks/exhaustive-deps


    const checkArray = () => {
        if (report && !loading) {
            if (type === 'Total') {
                return report.total
            } else if (type === 'Download')
                return report.download
            else if (type === 'Upload')
                return report.upload
            else
                return []
        } else
            return []
    }

    const defaultLabelStyle = {
        fontSize: '4px',
        fontWeight: 'bold',
        fill: '#ffffff'
    };

    const createChartArray = (dataArray) => {
        let data = []

        dataArray.forEach((val, key) => {

            let obj = {
                title: val.protocol,
                value: val.presentage,
                color: createColorArray()[key]

            }
            data.push(obj)
        })

        return data
    }

    const createColorArray = () => {
        let colors = []
        let randomColorArray = ['#f75f3a', '#9241f7', '#49f7ad', '#f73d84', '#00e6f7', '#f7c00c', '#7961e8', '#69f74d', '#f78450', '#ff00ea']
        let index = 0
        checkArray().forEach(val => {

            if (val.protocol === 'Facebook Video')
                colors.push('#408DFF')
            else if (val.protocol === 'Whatsapp Media')
                colors.push('#F75F3A')
            else if (val.protocol === 'Facebook')
                colors.push('#005AE0')
            else if (val.protocol === 'Google')
                colors.push('#D58D00')
            else if (val.protocol === 'Google Play')
                colors.push('#FFA900')
            else if (val.protocol === 'Apple Maps')
                colors.push('#9241F7')
            else if (val.protocol === 'TLS 1.3')
                colors.push('#49F7AD')
            else if (val.protocol === 'IMAPS')
                colors.push('#F73D84')
            else if (val.protocol === 'NASA TV')
                colors.push('#00E6F7')
            else if (val.protocol === 'Others')
                colors.push('#8D8D8D')
            else {
                // setRandomColorIndex(randomColorIndex + 1)
                colors.push(randomColorArray[index])
                index = index + 1
            }
        })
        return colors
    }

    if (!profile.priviledges.protocol_report) {
        return <div className='bbSubBody'>
            <NoDataToShow/>
        </div>
    } else if (loading) {
        return (
            <div className='bbSubBody'>
                <div className="body-container">
                <div className='title'>
                    Daily Usage : {date}
                </div>
                <LoadingBbBody loading={loading} border={false}/>
                </div>
            </div>
        )
    } else if (checkArray()) {
        return (
            <div className='bbSubBody'>

                <div className="body-container">
                    <div className='title '>
                        <p>Details Usage for Date : {date}</p>
                    </div>
                    <div className="wrapper">
                        <div className='oneDayViewBody'>
                            <div className='leftSide'>
                                <p className='text-center m-2 font-weight-bold'>{type}</p>

                                {
                                    checkArray().map((val, key) => {
                                        return (
                                            <div key={key} className='singleRow p-1 mx-2'>
                                                <div className='colorView'
                                                     style={{background: createColorArray()[key]}}/>
                                                <div className='text'>{val.protocol}</div>
                                                <div>{parseFloat(val.presentage).toFixed(2)}%</div>
                                            </div>
                                        )
                                    })
                                }


                            </div>

                            <div className='rightSide'>
                                <div className='topButtons'>

                                    <button className={type === 'Total' ? 'blue' : null}
                                            onClick={() => setType('Total')}>Total
                                    </button>
                                    <button className={type === 'Download' ? 'blue' : null}
                                            onClick={() => setType('Download')}>Download
                                    </button>
                                    <button className={type === 'Upload' ? 'blue' : null}
                                            onClick={() => setType('Upload')}>Upload

                                    </button>
                                </div>

                                <div className='d-flex'>
                                    <PieChart lineWidth={45} className='chart' totalValue={100}
                                              label={({dataEntry}) => dataEntry.value > 5 ?  parseFloat(dataEntry.value).toFixed(2) + '%' : null}
                                              data={createChartArray(checkArray())}
                                              labelStyle={{
                                                  ...defaultLabelStyle,
                                              }}
                                              labelPosition={80}
                                              paddingAngle={1}
                                              animate={true}
                                              animationEasing={'linear'}

                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    } else
        return null
}

const mapStateToProps = state => ({
    loadingProfile: state.broadBand.loadingProfile,
    profile: state.broadBand.profile,
    report: state.protocolReport.dataBundle,
    loading: state.protocolReport.loading,
    error: state.protocolReport.error,
})

export default connect(mapStateToProps, {getProtocolReport})(DailyUsageOneDay);

import React, {useState} from 'react';
import {connect} from "react-redux";
import {Redirect, useHistory} from "react-router-dom";
import {payBillUrl} from "../../redux/URL";
import {numberWithCommas} from "../../redux/action/bill/billHistory.action";
import LoadingBbBody from '../boardBand/LoadingBbBody';

function BillDetails({loading, payments, error, loadingServices, account, loadingAccounts, loadingProfile}) {

    const history = useHistory();

    const [currentState, setCurrentState] = useState(false);

    const [showEBillButton, setShowEBillButton] = useState(false);

    const [mobile, setMobile] = useState(localStorage.getItem('selectedAcc'));
    const [amount, setAmount] = useState(0);

    const moveToPay = () => {
        setAmount(payments.listofbillingInquiryType[0].outstandingBalance)
        setCurrentState(true)
    }

    // const submitPay = (e) => {
    //     e.preventDefault()
    //     if(mobile.length < 10){
    //          alert('error')
    //     }
    // }

    if (loading || loadingAccounts || loadingServices || loadingProfile) {
        return <LoadingBbBody />
    } else if (!account || account.length === 0) {
        return <Redirect to='/noAccount'/>;
    } else if (error || !payments) {
        return <div className='billDetails'>
            <div className='totalPayable'>
                <div className='blueArea'>
                    <div className='header text-center'>
                        Loading Failed
                    </div>
                </div>
            </div>
        </div>
    }
    return (
        <div className='billDetails'>
            <div className='totalPayable'>
                <div className='blueArea clearfix'>
                    <div className='header'>
                        Total  Payable :
                        Rs. {payments.listofbillingInquiryType ? numberWithCommas(payments.listofbillingInquiryType[0].outstandingBalance) : 'Loading'}
                    </div>

                    {
                        !currentState ? <>


                            {
                               (payments.listofbillingInquiryType && payments.listofbillingInquiryType[0].lastBillDate !== '1970/01/01') ?  <div className='details clearfix'>
                                    {/* <div className='oneRow'>
                                        <div className="last-payment"> Last Bill :</div>
                                        <div  className="last-payment-value">Rs.{numberWithCommas(payments.listofbillingInquiryType[0].billAmount)}</div>
                                    </div>

                            </div> */}


                                    <div className='float-right month-end'> For month ending
                                        at {payments.listofbillingInquiryType[0].lastBillDate}</div>
                                </div> : null
                            }
                           
                            {
                                (payments.listofbillingInquiryType && payments.listofbillingInquiryType[0].lastPaymentDate !== '1970/01/01') ? <div className='details clearfix'>
                                    <div className='oneRow'>
                                        <div className="last-payment"> Last Payment :</div>
                                        <div className="last-payment-value">Rs.{numberWithCommas(payments.listofbillingInquiryType[0].lastPaymentAmount)}</div>
                                    </div>


                                        {/* <div className='float-right month-end' > For month ending
                                            at {payments.listofbillingInquiryType[0].lastPaymentDate}</div>
                                    </div> : null */}
                                         <div className='float-right month-end' > 
                                        On {payments.listofbillingInquiryType[0].lastPaymentDate}</div>
                                    </div> : null 
                            }

                        </> : <>
                            <div>
                                <label className='d-block'>Mobile <p className='d-inline red'>*</p></label>
                                <input type={"mobile"} className='pLeft' value={mobile} onChange={(e) => {
                                    setMobile(e.target.value.trim())
                                }} required/>
                            </div>

                            <div className='mt-3'>
                                <label className='d-block'>Amount (LKR) <p className='d-inline red'>*</p></label>
                                <input type={"mobile"} className='pLeft' value={amount} onChange={(e) => {
                                    setAmount(e.target.value.trim())
                                }} required/>
                            </div>
                        </>
                    }


                    {
                        !currentState && !showEBillButton ? <div className='submit float-right'>
                            <button className='medium blue' onClick={() => moveToPay()}>Pay Now</button>
                        </div> : 
                        !currentState && showEBillButton? <div className='submit float-right'>
                        <button className='medium blue ' onClick={() => history.push('/eBill')}>eBill</button>     <button className='medium blue' onClick={() => moveToPay()}>Pay Now</button>
                        </div> : <div className='submit mt-3'>
                            <form action={payBillUrl} method='POST' >
                                <input hidden={true} value={mobile} name={'EventSource'}/>
                                <input hidden={true} value={amount} name={'vpc_Amount'}/>
                                <button type={"submit"} className='medium blue' onClick={() => setCurrentState(true)}>Pay Now</button>
                            </form>

                        </div>
                    }

                </div>

            </div>

        </div>
    );
}


const mapStateToProps = (state) => ({
    loading: state.accountDetails.loadingPayments,
    error: state.accountDetails.errorPayments,
    payments: state.accountDetails.payments,
    loadingAccounts: state.accountDetails.loading,
    loadingServices: state.accountDetails.loadingServiceList,
    loadingProfile: state.broadBand.loadingProfile,
    account: state.accountDetails.account_Details,
});

export default connect(mapStateToProps, {})(BillDetails);

import React from 'react';
import {Route, Redirect} from 'react-router-dom';
import {connect} from 'react-redux'
import NavBar from "../component/common/NavBar";
import TopNavigation from "../component/common/TopNavigation";
import Services from "../component/common/Services";
import BannerSlider from "../component/common/BannerSlider";
import Payments from "../component/common/Payments";
import ValueAdded from "../component/common/ValueAdded";
import NotificationBannerPopup from '../component/notificationBannerPopup';


const PrivateRoute = ({user, loggedIn,type, component: Component, viewSingle, ...rest}) => (
    <Route {...rest} component={(props) => (
        
        loggedIn ? (

            <div className='main-div'>
                <NavBar/>
                {
                    (type === 'FTTH' || type === 'LTE') ?  <Component {...props}/> : <div className='container main-container'>
                        <TopNavigation/>
                        <Services/>
                        <BannerSlider/>
                        <Payments/>
                        <ValueAdded/>
                        <NotificationBannerPopup/>
                        <Component {...props}/>


                    </div>
                }

            </div>


        ) : (
            // <Redirect to='/promo/buyonline'/>
            <></>
        )
    )}/>
)

const mapStateToProps = state => ({
    loggedIn: state.login.loggedIn,
    user: state.login.user,


})
export default connect(mapStateToProps, null)(PrivateRoute);

import React, { useState } from 'react';
import BillDetails from "./BillDetails";
import BillHistory from "./BillHistory";
import BillMode from "./BillMode";
import { connect } from "react-redux";
import LoadingBbBody from "../boardBand/LoadingBbBody";
import { Redirect, useParams } from "react-router-dom";
import SuccessAlert from "../alerts/SuccessAlert";
import ErrorAlert from "../alerts/ErrorAlert";
import { isPrepaid } from '../../redux/action/accountDetails.action';

function Bill({ loadingServices, account, loadingAccounts, loadingProfile, serviceList }) {
  let { type } = useParams();
  const [isBillView, setIsBillView] = React.useState(Boolean(Number(type)));
  const [viewType, setViewType] = useState(Number(type))
  const [success, setSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [error, setError] = useState('');

  if (isPrepaid(serviceList && serviceList))
    return <Redirect to='/prepaid/boardBand'/>

  if (loadingAccounts || loadingServices || loadingProfile) {
    return <div className='mainBody eBill common-panel'>
      <LoadingBbBody loading={true} border={false} />
    </div>
  } else if (!account || account.length === 0) {
    return <Redirect to='/noAccount' />;
  }

  return (
    <div className="mainBody bill common-panel">
      {success ? (
        <SuccessAlert setSuccess={setSuccess} msg="Successfully requested" />
      ) : null}
      {isError ? (
        <ErrorAlert
          error={error}
          setError={setError}
          setIsError={setIsError}
        />
      ) : null}
      <div className="body-container">
        <div className="title">Bill</div>
        <div className="wrapper">
          <div className="mt-3">
            {/* <div className="switch-button">
                            <input className="switch-button-checkbox" type="checkbox" checked={isBillView}
                                   onChange={e => setIsBillView(e.target.checked)}/>
                            <label className="switch-button-label white"><span
                                className="switch-button-label-span">Bill Summary</span></label>
                        </div> */}
            <div className="tab-button">
              <button className={viewType === 0 ? "active" : ''} onClick={() => setViewType(0)}>Total Payable</button>
              <button className={viewType === 1 ? "active" : ''} onClick={() => setViewType(1)}>Bill History</button>
              <button className={viewType === 2 ? "active" : ''} onClick={() => setViewType(2)}>Bill Methods</button>
            </div>

            {viewType === 0 ? (
              <BillDetails />
            ) : (
              viewType === 1 ? <BillHistory
                success={success}
                setSuccess={setSuccess}
                isError={isError}
                setIsError={setIsError}
                error={error}
                setError={setError}
              /> : <BillMode />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}


const mapStateToProps = state => ({
  loadingAccounts: state.accountDetails.loading,
  loadingServices: state.accountDetails.loadingServiceList,
  loadingProfile: state.broadBand.loadingProfile,
  account: state.accountDetails.account_Details,
  serviceList: state.accountDetails.service_list,
})

export default connect(mapStateToProps, {})(Bill);

import {
    ERROR_GET_DRAFT_DETAILS, ERROR_GET_PAYMENT_LOG, ERROR_GET_INVOICE,
    LOADING_GET_DRAFT_DETAILS, LOADING_GET_PAYMENT_LOG, LOADING_GET_INVOICE,
    SUCCESS_GET_DRAFT_DETAILS, SUCCESS_GET_PAYMENT_LOG, SUCCESS_GET_INVOICE,

} from "../../Types";

const initialState = {
    loadingDraft: false,
    loadingPayment: false,
    loadingInvoice: false,
    draftDetails: null,
    paymentsLog: null,
    invoice: null,
    errorDraft: '',
    errorPayments: '',
    errorInvoice: '',
}

export default function ftthReducers(state = initialState, action) {
    switch (action.type) {

        case LOADING_GET_DRAFT_DETAILS : {
            return {
                ...state,
                loadingDraft: true,
            }
        }

        case SUCCESS_GET_DRAFT_DETAILS : {
            return {
                ...state,
                loadingDraft: false,
                loadingPayment: true,
                draftDetails: action.payload
            }
        }

        case ERROR_GET_DRAFT_DETAILS : {
            return {
                ...state,
                loadingDraft: false,
                loadingPayment: false,
                errorDraft: action.payload,
            }
        }

        case LOADING_GET_PAYMENT_LOG : {
            return {
                ...state,
                loadingPayment: true
            }
        }

        case SUCCESS_GET_PAYMENT_LOG : {
            return {
                ...state,
                paymentsLog: action.payload,
                loadingPayment: false
            }
        }

        case ERROR_GET_PAYMENT_LOG : {
            return {
                ...state,
                errorPayments: action.payload,
                loadingPayment: false,
            }
        }

         case LOADING_GET_INVOICE : {
            return {
                ...state,
                loadingInvoice: true
            }
        }

        case SUCCESS_GET_INVOICE : {
            return {
                ...state,
                invoice: action.payload,
                loadingInvoice: false
            }
        }

        case ERROR_GET_INVOICE : {
            return {
                ...state,
                errorInvoice: action.payload,
                loadingInvoice: false,
            }
        }

        default:
            return state

    }
}

import React, {useState} from 'react';

function MainChannels({obj}) {

    const [state, setState] = useState(false);

    const createArray = () => {
        let imageArray = []
            obj.forEach(val => {
                if(!state){
                    if(val.category !== 'Ala-Carte'){

                        val.channels.forEach(item => {

                            if(item.Show_IN_Guide === "1"){
                                let object = {
                                    img :item.Package_Logo,
                                    channelNo : item.ChannelNumberOnTv
                                }
                                imageArray.push(object)
                            }
                        })
                    }
                }else {
                    if(val.category === 'Ala-Carte'){
                        val.channels.forEach(item => {
                            if(item.Show_IN_Guide === "1"){
                                let object = {
                                    img :item.Package_Logo,
                                    price : 'Rs.' + item.ala_carte_price,
                                    channelNo : item.ChannelNumberOnTv
                                }
                                imageArray.push(object)
                            }
                        })
                    }
                }
            })
        return imageArray

    }

    return (
        <div className='channelList pt-2'>
            <div className="switch-button m-auto">
                <input className="switch-button-checkbox" type="checkbox" checked={state}
                       onChange={e => setState(e.target.checked)}/>
                <label className="switch-button-label white"><span
                    className="switch-button-label-span">Current</span></label>
            </div>

            <div className='images'>
                {
                    !state ?  createArray().map((val, index) => {
                        return <div key={index} className='details'>
                            <div className='tag'>{val.channelNo}</div>
                            <img src={val.img} alt={'searchIcon'} className='imageView'/>
                        </div>
                    }) :  createArray().map((val, index) => {
                        return <div key={index} className='details'>
                            <div className='tag'>{val.channelNo}</div>
                            <img src={val.img} alt={'searchIcon'} className='imageView'/>
                            <div className='title'>{val.price}</div>
                        </div>
                    })
                }
                {

                }

            </div>
        </div>
    );
}

export default MainChannels;
import React, {useState} from 'react';
import {connect} from "react-redux";
import LoadingBbBody from "../LoadingBbBody";
import {ChangeBBPassword} from "../../../redux/URL";
import axiosInstance from "../../../redux/axiosInstance";
import SuccessAlert from "../../alerts/SuccessAlert";
import ErrorAlert from "../../alerts/ErrorAlert";
import eyeIcon from "../../../assests/images/loginUI/br2128px.png";
import eyeCloseIcon from "../../../assests/images/loginUI/br1128px.png";
import {CATCH_ERROR_MSG} from "../../../redux/Types";


function ChangeBroadbandPassword({profile}) {

    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [loading, setLoading] = useState(false)
    const [success, setSuccess] = useState(false);
    const [isError, setIsError] = useState(false);
    const [error, setError] = useState('');
    const [isPasswordShown, setIsPasswordShown] = useState(false);
    const updatePassword = () => {
        setLoading(true)
        let url = ChangeBBPassword + 'subscriberID=' + profile.subscriberid + '&currentpass=' + currentPassword + '&newpass=' + newPassword
        axiosInstance.post(url).then(response => {

            if (response.status === 200 && response.data.isSuccess) {
                setSuccess(true)
                setLoading(false)
            } else {
                setError(response.data.errorShow)
                setIsError(true)
                setLoading(false)
            }

        }).catch(Onerror => {
            setError(CATCH_ERROR_MSG)
            setIsError(true)
            setLoading(false)
        })
    }

    if (loading) {
        return <div className='changeBBDetails common-panel'>
            <div className='body-container'>
                <div className='title'>Change Broadband Password</div>
                <LoadingBbBody loading={loading} border={false}/>
            </div>
        </div>
    } else {
        return (
            <div className='changeBBDetails common-panel'>
                {success ? <SuccessAlert setSuccess={setSuccess} msg='Successfully updated broadband password'/> : null}
                {isError ? <ErrorAlert error={error} setError={setError} setIsError={setIsError}/> : null}
                <div className='body-container'>
                    <div className='title'> Change Broadband Password</div>


                    <div className='wrapper'>
                        <div className='body'>
                            <div className='form'>
                                <div className='input-container'>
                                    <label className='d-block'>Current Password <p className='d-inline red'>*</p>
                                    </label>
                                    <input type={"password"} className='pl-2' value={currentPassword} onChange={(e) => {
                                        setCurrentPassword(e.target.value)
                                    }}/>

                                </div>
                                <div className='input-container position-relative'>
                                    <label className='d-block'>New Password <p className='d-inline red'>*</p></label>
                                    <img src={isPasswordShown ? eyeIcon : eyeCloseIcon} alt='userLogo' className='changePassword'
                                         onClick={() => setIsPasswordShown(!isPasswordShown)}/>
                                    <input type={isPasswordShown ? "text" : "password"}   value={newPassword} onChange={(e) => {
                                        setNewPassword(e.target.value)
                                    }}/>

                                </div>

                                <div>
                                    <button className='medium blue float-right'
                                            onClick={() => updatePassword()}>Submit
                                    </button>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    profile: state.broadBand.profile,

})

export default connect(mapStateToProps, {})(ChangeBroadbandPassword);

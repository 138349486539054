import React from 'react';
import {connect} from "react-redux";
import BroadBandNav from "./BroadBandNav";
import {Redirect} from 'react-router-dom';
import LoadingBbBody from "./LoadingBbBody";
import NoBroadBandAccount from "./NoBroadBandAccount";
import ErrorAlert from "../alerts/ErrorAlert";


function InitialBroadBand({
                              serviceList,
                              loadingServices,
                              account,
                              loadingAccounts,
                              loadingProfile,
                              errorServices,
                              errorProfile
                          }) {

    if (loadingAccounts || loadingServices || loadingProfile) {
        return <div className='mainBody eBill'>
            <LoadingBbBody loading={true}/>
        </div>
    } else if (errorServices) {
        return <div className='mainBody eBill'><ErrorAlert error={errorServices} setIsError={null} setError={null}/>
        </div>
    } else if (errorProfile) {
        return <div className='mainBody eBill'><ErrorAlert error={errorProfile} setIsError={null} setError={null}/>
        </div>
    } else if (!account || account.length === 0) {
        return <Redirect to='/noAccount'/>;
    } else if (serviceList.hasOwnProperty('promotionType') && serviceList.promotionType === 'Prepaid') {
        return <Redirect to='/prepaid/boardBand'/>
    }else if (serviceList.hasOwnProperty('listofBBService')) {
        if (serviceList.listofBBService.length === 0) {
            if (serviceList.listofPEOService.length > 0) {
                return <Redirect to='/peoTV'/>
            } else if (serviceList.listofPEOService.length > 0) {
                return <Redirect to='/voice'/>
            } else
                return <NoBroadBandAccount/>
        } else
            return <BroadBandNav/>
    } else
        return <Redirect to='/noAccount'/>
}
const mapStateToProps = state => ({
    loadingAccounts: state.accountDetails.loading,
    loadingServices: state.accountDetails.loadingServiceList,
    loadingProfile: state.broadBand.loadingProfile,
    serviceList: state.accountDetails.service_list,
    account: state.accountDetails.account_Details,
    profile: state.broadBand.profile,
    errorServices: state.accountDetails.errorServiceList,
    errorProfile: state.broadBand.errorProfile
})

export default connect(mapStateToProps, {})(InitialBroadBand);

import React from 'react';
import './ProgressBar.css'; // Import CSS for styling

const ProgressBar = ({ progress }) => {
  return (
        <div className="loading-circle-container">
          <div className="loading-circle"></div>
        </div>
  );
};

export default ProgressBar;

import React from 'react'
import { Link } from 'react-router-dom'
// import OutStanding from './image/OutStanding.svg'
import OutStanding from './image/OutStanding'
import Eligibility from './image/Eligibility'

const CustomerStateAlert = ({checkCusStateAlert, cusStateType, cusStateMassege, cusStateMainMassege}) => {
  return (
    <div className='alertView'>
        <div className='cusstateAlert'>
            <div className='title'>{cusStateMainMassege}</div>
            <div className='mainText'>
                <div className="message">{cusStateMassege}</div>
                {
                    cusStateType === "outstanding" ? 
                    <div className='link'> <Link to={'/bill/0'} >Check from here</Link></div> : null
                }
            </div>
            <div className='cus-image'>
                {
                    cusStateType === "outstanding" ? <OutStanding /> :  <Eligibility/>
                }
            </div>
            <div className='actionButtonCus'>
                   
                <button className='blue p-1 pl-4 pr-4' onClick={() => {
                            checkCusStateAlert()
                    }}
                >Ok
                </button>
                   

            </div>
        </div>
    </div>
  )
}

export default CustomerStateAlert
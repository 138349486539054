import React, { useState } from "react";
import { connect } from "react-redux";
import LoadingBbBody from "../boardBand/LoadingBbBody";
import { Redirect } from "react-router-dom";
import { activePromoPackURL, getPromoPackURL } from "../../redux/URL";
import axiosInstance from "../../redux/axiosInstance";
import { useEffect } from "react";
import { CATCH_ERROR_MSG } from "../../redux/Types";
import SuccessAlert from "../alerts/SuccessAlert";
import ErrorAlert from "../alerts/ErrorAlert";
import ConfirmAlert from "../alerts/ConfirmAlert";
import NoDataToShow from "../common/NoDataToShow";

function Promo({
  loadingServices,
  account,
  loadingAccounts,
  loadingProfile,
  accountList,
  serviceList,
}) {
  const [loading, setLoading] = useState(true);
  const [packages, setPackages] = useState([]);
  const [pId, setPId] = useState("");
  const [confirmShow, setConfirmShow] = useState(false);
  const [success, setSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [error, setError] = useState("");

  const getPackages = async () => {
    setLoading(true);
    setPackages([]);
    await axiosInstance
      .get(getPromoPackURL + getTelephoneNumber())
      .then((response) => {
        if (response.status === 200 && response.data.isSuccess) {
          setPackages(...[response.data.dataBundle]);
          setLoading(false);
        } else {
          setIsError(true);
          setError(response.data.errorShow);
          setLoading(false);
        }
      })
      .catch(() => {
        setIsError(true);
        setError(CATCH_ERROR_MSG);
        setLoading(false);
      });
  };

  const activePackage = async () => {
    const data = packages.find((e) => e.packageid === pId);
    await axiosInstance
      .post(
        `${activePromoPackURL}telephoneno=${getServiceId()}&packageid=${pId}&refrenceNo=${
          data.refno
        }`
      )
      .then(async (response) => {
        if (response.status === 200 && response.data.isSuccess) {
          setSuccess(true);
          await getPackages();
        } else {
          setIsError(true);
          setError(response.data.errorShow);
          setLoading(false);
        }
      })
      .catch(() => {
        setIsError(true);
        setError(CATCH_ERROR_MSG);
        setLoading(false);
      });
  };

  const selectPackage = (pid) => {
    setPId(pid);
    setConfirmShow(true);
  };

  useEffect(() => {
    setLoading(true);
    if (account && account.length > 0) getPackages();
  }, [serviceList, account]);

  function getTelephoneNumber() {
    let saved = localStorage.getItem("selectedAcc");
    if (saved && accountList) {
      if (accountList.find((e) => e.telephoneno === saved)) {
        return saved;
      }
    } else return null;
  }

  function getServiceId() {
    if (serviceList.hasOwnProperty("listofBBService")) {
      if (serviceList.listofBBService.length > 0) {
        return serviceList.listofBBService[0].serviceID;
      }
    }
    return null;
  }

  if (loadingAccounts || loadingServices || loadingProfile || loading) {
    return (
      <div className="mainBody eBill">
        <LoadingBbBody />
      </div>
    );
  } else if (!account || account.length === 0) {
    return <Redirect to="/noAccount" />;
  } else if (!packages || packages?.length === 0)
    return (
      <div className="mainBody eBill common-panel">
        <div className="mobitel-container mt-5">
          <NoDataToShow />
        </div>
      </div>
    );

  return (
    <div className="mainBody ">
      <div className="promo common-panel">
        {confirmShow ? (
          <ConfirmAlert
            setConfirmShow={setConfirmShow}
            msg={`Do you want to redeem the offer?`}
            onClick={() => activePackage()}
          />
        ) : null}
        {success ? (
          <SuccessAlert
            setSuccess={setSuccess}
            msg={`Package Activated Successfully`}
          />
        ) : null}

        {isError ? (
          <ErrorAlert
            error={error}
            setError={setError}
            setIsError={setIsError}
          />
        ) : null}

        <div className="items-container">
          {packages.map((item, index) => {
            return (
              <div className="item-box" key={index}>
                <div className="title">{item.packageName}</div>
                <div div className="item">
                  <div className="image-container">
                    <img
                      src={item.imageURL}
                      alt="banner"
                      className="imageView"
                    />
                  </div>
                  <div className="details">
                    <div className="status">{item.amount}</div>
                    <div className="status">{item.eligiblePeriod}</div>
                    <div className="valid">{`Valid till ${item.expiredate}`}</div>
                  </div>

                  <div className="button-container">
                    <button
                      className="btn-mobitel"
                      onClick={() => selectPackage(item.packageid)}
                    >
                      Redeem
                    </button>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  loadingAccounts: state.accountDetails.loading,
  loadingServices: state.accountDetails.loadingServiceList,
  loadingProfile: state.broadBand.loadingProfile,
  serviceList: state.accountDetails.service_list,
  account: state.accountDetails.account_Details,
  profile: state.broadBand.profile,
  accountList: state.accountDetails.account_Details,
});

export default connect(mapStateToProps, {})(Promo);

import React from 'react';
import searchIcon from '../../assests/images/search.png'
function NoDataToShow({imgPath, msg}) {
    if(imgPath && msg){
        return (
            <div className='noDataToShow'>

                <div>
                    <div>
                        <img src={imgPath} alt={'searchIcon'} className='real'/>
                        <div className='text-center mt-3'>{msg}</div>
                    </div>

                </div>

            </div>
        );
    }else {
        return (
            <div className='noDataToShow'>

                <div>
                    <div>
                        <img src={searchIcon} alt={'searchIcon'} className='common'/>
                        <div>No data to show</div>
                    </div>

                </div>

            </div>
        );
    }

}

export default NoDataToShow;
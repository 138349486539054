import React, {useEffect, useState} from 'react';
import Querystring from "querystring";
import LoadingBbBody from "../boardBand/LoadingBbBody";
import ErrorAlert from "../alerts/ErrorAlert";
import OtpInput from "react-otp-input";
import {connect} from "react-redux";
import {otpVerification_Register, resendOTP, setPublicActionToInitialState} from "../../redux/action/login.action";

const NewRegOtp = (props) => {

    const [OTP, setOTP] = useState("");

    const [isError, setIsError] = useState(false);
    const [error, setError] = useState('');

    useEffect(() => {
        props.setPublicActionToInitialState()
    }, [props.setPublicActionToInitialState]);// eslint-disable-line react-hooks/exhaustive-deps

    const submit = () => {

        if (OTP.length === 6 && props.regId) {

            let body = Querystring['stringify']({
                id: props.regId,
                verifyOtp: OTP,
                channelID: 'WEB'
            })
            props.otpVerification_Register(body, setError, setIsError)
        }
    }

    if (props.loading)
        return <LoadingBbBody/>


    return (
        <div className='auth-container justify-content-center'>
            {isError ? <ErrorAlert error={error} setError={setError} setIsError={setIsError}/> : null}
            <div className='login-form'>
                <div className='form d-block m-auto'>
                    <p className='titles'>Confirm OTP</p>

                    <p className='message'>Enter 6 digits OTP code for active your account.</p>

                    <div className='mt-4'>


                        <div className='ml-lg-4 d-flex justify-content-center'>
                            <OtpInput
                                value={OTP}
                                onChange={setOTP}
                                numInputs={6}
                                isInputNum={true}
                                shouldAutoFocus={true}
                                inputStyle='otp'
                                className='otpContainer'
                            />

                        </div>


                        <div className='mt-4 text-center'>
                            <button className='medium blue'
                                    onClick={(e) => submit(e)}>{props.loading ? 'Please wait...' : 'Submit'}</button>
                        </div>

                        {/*<div className='mt-5'>*/}
                        {/*    <p className='small grey d-inline-block'>Have you received your OTP yet?</p>*/}
                        {/*    <button className='medium white ml-3'*/}
                        {/*            onClick={() => props.resendOTP(null, setError, setIsError)}>Resend*/}
                        {/*    </button>*/}
                        {/*</div>*/}

                        <div className='register-container mt-5' >
                            <p className='register-label'>Have you received your OTP yet?</p>
                            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                            <a className='btn-register'  onClick={() => {
                                props.resendOTP(null, setError, setIsError)
                                setOTP('')
                            }}>Resend
                            </a>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
};


const mapStateToProps = state => ({
    loading: state.login.loading,
    error: state.login.error
})
export default connect(mapStateToProps, {
    otpVerification_Register,
    resendOTP,
    setPublicActionToInitialState
})(NewRegOtp);

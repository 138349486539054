import React, {useEffect, useState} from 'react';
import SelectPeoTvPackages from "./SelectPeoTVPackages";
import SelectBroadbandPackage from "./SelectBroadbandPackage";
import axiosInstance from "../../../redux/axiosInstance";
import LoadingBbBody from "../../boardBand/LoadingBbBody";
import {getBbPackagesList, getPeoTvPackagesList, updateDraftDetails} from "../../../redux/URL";
import ErrorAlert from "../../alerts/ErrorAlert";
import Querystring from "querystring";

function PackageInformation({packageInfo, setPackageInfo, locationInfo, userInfo, setState, orderRef}) {

    const [isOpenPeo, setIsOpenPeo] = useState(false);
    const [isOpenBb, setIsOpenBb] = useState(false);


    const [loading, setLoading] = useState(true);
    const [dataBB, setDataBB] = useState([]);
    const [selectedBBObj, setSelectedBBObj] = useState({});
    const [dataPeo, setDataPeo] = useState([]);
    const [selectedPeoObj, setSelectedPeoObj] = useState({});
    const [errorList, setErrorList] = useState({});

    const [savingDraft, setSavingDraft] = useState(false);

    const [isError, setIsError] = useState(false);
    const [error, setError] = useState('');


    useEffect(() => {
        let url = getBbPackagesList + 'Mode=SLT Fiber'
        axiosInstance.get(url).then(response => {

            if (response.status === 200) {
                setDataBB(...[response.data.dataBundle[0]])
                setLoading(false)
                getPeoTvPackages()
            } else
                setLoading(false)
        }).catch(() => {
            setLoading(false)
        })
    }, []);

    const getPeoTvPackages = () => {
        let url = getPeoTvPackagesList + 'Mode=SLT Fiber'
        axiosInstance.get(url).then(response => {

            if (response.status === 200) {
                setDataPeo(...[response.data.dataBundle.Results])
                setLoading(false)
            } else
                setLoading(false)
        }).catch(() => {
            setLoading(false)
        })
    }

    // const removePeoTvFromList = (index) => {
    //     let pack = packageInfo.peoTvPackages
    //     pack.splice(index, 1);
    //     setPackageInfo({...packageInfo, peoTvPackages: pack})
    // }

    const removeBBPacks = () => {
        setPackageInfo({...packageInfo, bbPackages: ''})
        setSelectedBBObj({})
    }

    const removePeoPacks = () => {
        setPackageInfo({...packageInfo, peoTvPackages: ''})
        setSelectedPeoObj({})
    }

    const sameAsBilling = () => {
        if (!packageInfo.isSameAsBilling) {
            setPackageInfo({
                ...packageInfo,
                isSameAsBilling: true,
                city: locationInfo.city,
                postalCode: locationInfo.postalCode,
                address1: locationInfo.address1,
                address2: locationInfo.address2,
                district: locationInfo.district
            })
        } else {
            setPackageInfo({
                ...packageInfo,
                isSameAsBilling: false,
                city: '',
                postalCode: '',
                address1: '',
                address2: '',
                district: ''
            })
        }
    }

    const handleChange = event => {
        const {name, value} = event.target
        setPackageInfo({...packageInfo, [name]: value})

    }

    const validation = () => {
        let errors = {};
        let formIsValid = true;

        if (!packageInfo.bbPackages && !packageInfo.peoTvPackages) {
            // errors['packs'] = 'Please select minimum one of Internet or PeoTV package'
            setError('Please select minimum one of Internet or PEO TV package')
            setIsError(true)
            formIsValid = false
        } 
        else if (packageInfo.bbPackages === '' && packageInfo.peoTvPackages.length === 0) {
            errors['pack'] = 'Please select a package'
            formIsValid = false
        }

        if (formIsValid) {
            // saveDraft()
            setState(2)
        } else {
            setErrorList(errors)
        }
    }

    const saveDraft = () => {

        let body = Querystring['stringify']({
            'NIC': userInfo.nic,
            'PP': userInfo.passport,
            'TITLE': userInfo.title,
            'FIRSTNAME': userInfo.fName,
            'LASTNAME': userInfo.lName,
            'BIRTH_DATE': userInfo.dateOfBirth,
            'NATIONALITY': userInfo.nationality,
            'SERVICE_ADD_NO': 60,
            'SERVICE_ADD_STREET1': locationInfo.address1,
            'SERVICE_ADD_STREET2': locationInfo.address2,
            'SERVICE_ADD_CITY': locationInfo.city,
            'SERVICE_ADD_DISTRICT': locationInfo.district,
            'BILLING_ADD_NO': 60,
            'BILLING_ADD_STREET1': packageInfo.address1,
            'BILLING_ADD_STREET2': packageInfo.address2,
            'BILLING_ADD_CITY': packageInfo.city,
            'SERVICE_ADD_POSTAL_CODE': locationInfo.postalCode,
            'RTOM': locationInfo.rTom,
            'EQTYPE': 'FDP',
            'LONGITUDE': locationInfo.lng,
            'LATITUDE': locationInfo.lat,
            'SERVICE_TYPE': 'FTTH',
            'SERVICE_ADD_PROVINCE': '',
            'BILLING_ADD_PROVINCE': '',
            'VOICE_PACKAGE': 'NA',
            'BB_PACKAGE': packageInfo.bbPackages,
            'PEO_PACKAGE': packageInfo.peoTvPackages,
            'BILLING_ADD_POSTAL_CODE': packageInfo.postalCode,
            'gender': userInfo.gender,
            'iddstatus': packageInfo.iddActivate,
            'billinG_MODE': packageInfo.billinG_MODE,
            'email': userInfo.email,
            'mobileno': userInfo.mobile,
            'LOOP_AVAILABLITY': 'Y',
            'SLTNIC': userInfo.sltnic,
            'REF_NO': orderRef
        })

        setSavingDraft(true)
        axiosInstance.post(updateDraftDetails, body).then((response) => {
            if (response.data.isSuccess) {
                setSavingDraft(false)
                setState(4)
            } else {
                setSavingDraft(false)
                let errors = {};
                errors['pack'] = 'Save draft failed'
                setErrorList(errors)
            }
        }).catch(() => {
            let errors = {};
            errors['pack'] = 'Save draft failed'
            setErrorList(errors)
            setSavingDraft(false)
        })
    }
// console.log(packageInfo)
    return (
        <div className='packageInformation'>
            {
                isError ? <ErrorAlert error={error} setError={setError} setIsError={setIsError}/> : null
            }
            {isOpenPeo ?
                <SelectPeoTvPackages packageInfo={packageInfo} setPackageInfo={setPackageInfo}
                                     setIsOpenPeo={setIsOpenPeo} data={dataPeo}
                                     setSelectedPeoObj={setSelectedPeoObj}/> : null}
            {isOpenBb ? <SelectBroadbandPackage packageInfo={packageInfo} setPackageInfo={setPackageInfo}
                                                setIsOpenBb={setIsOpenBb} data={dataBB}
                                                setSelectedBBObj={setSelectedBBObj}/> : null}


            <div className='bodyView'>
                <div className="form-container">
                    {loading ? <div className='form-inputs-container'><LoadingBbBody/></div> :

                        <div className="pkg-selection-container">

                            <label>Select Your Packages {errorList.packs ?
                                <p className="d-inline red m-0 ml-1">{errorList.packs}</p>
                                : null}</label>

                            <div className="package-set">


                                <div className="package-item-contianer">
                                    <div className="package-item ">
                                        <div className="item-container voice">
                                            <div className="name">Home 1st Line - My Phone</div>
                                            <div className="price">390 LKR + Tax</div>
                                            <div className="voice-icon"/>
                                        </div>
                                        {/*<div className="change">*/}
                                        {/*    <div className="plus"></div>*/}
                                        {/*    <div className="name">Change Selection</div>*/}
                                        {/*</div>*/}
                                    </div>
                                </div>

                                {
                                    packageInfo.bbPackages ? <div className="package-item-contianer">
                                            <div className="package-item ">
                                                <div className="item-container internet">
                                                    <div className="name">{packageInfo.bbPackages}</div>
                                                    <div
                                                        className="pkg-size">{packageInfo.bbPackagesData.STANDARD_GB !== '0' ? packageInfo.bbPackagesData.STANDARD_GB : 'Unlimited Data'}{packageInfo.bbPackagesData.FREE_GB !== '0' ? ' + ' + packageInfo.bbPackagesData.FREE_GB : null}{packageInfo.bbPackagesData.STANDARD_GB !== '0' ? ' GB' : null}</div>
                                                    <div
                                                        className="pkg-price">Rs.{packageInfo.bbPackagesData ? packageInfo.bbPackagesData.MONTHLY_RENTAL : 'N/A'} +
                                                        Tax
                                                    </div>
                                                    <div className="pkg-month">(Per Month)</div>
                                                    <div className="internet-icon"/>
                                                </div>

                                                <div className="change">
                                                    <div className="name">Change Selection</div>
                                                    <div className="edit" onClick={() => setIsOpenBb(true)}>Edit</div>
                                                    <div className='remove' onClick={() => removeBBPacks()}>Remove</div>
                                                </div>
                                            </div>
                                        </div> :
                                        <div className="package-item-contianer">
                                            <div className="package-item" onClick={() => setIsOpenBb(true)}>
                                                <div className="item-container">
                                                    <div className="plus"/>
                                                    <div className="name">Select Internet Package</div>
                                                </div>
                                            </div>
                                        </div>
                                }


                                {
                                    packageInfo.peoTvPackages ? <div className="package-item-contianer">
                                        <div className="package-item">
                                            <div className="item-container peo">
                                                <div className="channels">
                                                    <span className="number">{packageInfo.peoPackagesData.NO_OF_CHANNELS}</span>
                                                    <span>Channels</span>
                                                </div>
                                                <div className="name">{packageInfo.peoPackagesData.PEO_PACKAGE_NAME}</div>
                                                <div className="name">{packageInfo.peoPackagesData.MONTHLY_RENTAL} LKR + Tax</div>
                                                <div className="peo-icon"/>
                                            </div>

                                            <div className="change">
                                                <div className="name">Change Selection</div>
                                                <div className="edit" onClick={() => setIsOpenPeo(true)}>Edit</div>
                                                    <div className='remove' onClick={() => removePeoPacks()}>Remove</div>
                                            </div>
                                        </div>
                                    </div> : <div className="package-item-contianer">
                                        <div className="package-item" onClick={() => setIsOpenPeo(true)}>
                                            <div className="item-container">
                                                <div className="plus"/>
                                                <div className="name">Select PeoTV Package</div>
                                            </div>
                                        </div>
                                    </div>
                                }


                            </div>
<div className='package-info'> <p className='d-inline red m-0 ml-1'>*</p>It is necessary to pick either the Internet or the PEO TV package.</div>
                        </div>
                    }
                    <div className='form-inputs-container'>
                        <div className='d-flex'>
                            <div className='w-50'>
                                <div className='input-container'>
                                    <label>Billing Mode</label>
                                    <div className="radio-group-container">
                                        <div className='radio-input'>
                                            <input type="radio" id="billType-e" name="billType" value={'eBill'}
                                                   checked={packageInfo.billType === 'eBill'}
                                                   onChange={handleChange}/><label htmlFor="billType-e">E-bill
                                            (via
                                            email)</label>
                                        </div>
                                        <div className='radio-input'>
                                            <input type="radio" id="billType-a" name="billType" value={'app'}
                                                    checked={packageInfo.billType === 'app'}
                                                    onChange={handleChange}/><label htmlFor="billType-a">e-Statement by MySLT APP
                                                        </label>
                                        </div>
                                        <div className='radio-input'>
                                            <input type="radio" id="billType-s" name="billType" value={'sms'}
                                                    checked={packageInfo.billType === 'sms'}
                                                    onChange={handleChange}/><label htmlFor="billType-s">e-Statement by SMS
                                                    </label>
                                        </div>
                                        {/* <div className='radio-input'>

                                            <input type="radio" id="billType-p" name="billType" value={'post'}
                                                   checked={packageInfo.billType === 'post'}
                                                   onChange={handleChange}/><label htmlFor="billType-p">Printed
                                            bill
                                            (via
                                            post)</label>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                            <div className='w-50'>
                                <div className='input-container'>
                                    <label>Do you want to deactivate IDD ?</label>
                                    <div className="radio-group-container">
                                        <div className='radio-input'>
                                            <input type="radio" id="iddActivate-y" name="iddActivate"
                                                   value={true}
                                                   checked={packageInfo.iddActivate}
                                                   onChange={handleChange}/><label
                                            htmlFor="iddActivate-y">Yes</label>
                                        </div>
                                        <div className='radio-input'>

                                            <input type="radio" id="iddActivate-n" name="iddActivate"
                                                   value={false}
                                                   checked={packageInfo.iddActivate}
                                                   onChange={handleChange}/><label
                                            htmlFor="iddActivate-n">No</label>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>
                  
                </div>
            </div>
            <div className='text-center font-weight-bold text-danger'>
                {errorList.pack}
            </div>
            <div className='container-footer'>
                <div className="action-container right">
                    <button onClick={() => validation()} disabled={savingDraft}>Next</button>
                </div>
            </div>
        </div>
    );
}

export default PackageInformation;

import React from "react";
import {Carousel} from "react-bootstrap";
import tempBanner from '../../assests/images/white font banner.png'
import {connect} from "react-redux";
import {setBannerSlide} from "../../redux/action/banner.action";

function BannerSlider({loading, banners, slideNO, setBannerSlide}) {

  // || (!loading && Object.keys(banners).length === 0)

    if (loading)
        return <div className='banners'>
            <Carousel>
                <Carousel.Item>
                    <img src={tempBanner} alt='temporaryBanner'/>
                </Carousel.Item>
            </Carousel>
        </div>
    else {
        return (
            <div className='banners'>
                <div>
                    <Carousel fade={true} defaultActiveIndex={0}  onSlide={(eventKey => setBannerSlide(eventKey))}>
                        {/*defaultActiveIndex={slideNO}  onSlide={(eventKey => setBannerSlide(eventKey))}*/}
                        {
                            banners.map((val, key) => {
                                return <Carousel.Item key={key}>
                                    <img src={val.url} alt='SLT Promotional Offers'/>
                                </Carousel.Item>
                            })
                        }


                    </Carousel>
                </div>

            </div>
        )
    }


}

const mapStateToProps = state => ({
    loading: state.banners.loading,
    banners: state.banners.banners,
    slideNO: state.banners.slideNO,
})

export default connect(mapStateToProps, {setBannerSlide})(BannerSlider);


import { 
    GET_BILL_DOWNLOAD_REQUEST,
    SUCCESS_GET_BILL_DOWNLOAD_REQUEST,
    ERROR_GET_BILL_DOWNLOAD_REQUEST
} from "../../Types";

const initialState = {
    loading: false,
    dataBundle: '',
    error:''
}


export default function billDownloadRequestReducer(state= initialState, action){
    switch (action.type){

        case GET_BILL_DOWNLOAD_REQUEST : {
            return {
                ...state,
                loading: true
            }
        }

        case SUCCESS_GET_BILL_DOWNLOAD_REQUEST : {
            return {
                ...state,
                loading: false,
                error:'',
                dataBundle: action.payload
            }
        }

        case ERROR_GET_BILL_DOWNLOAD_REQUEST : {
            return {
                loading: false,
                error: action.payload
            }
        }

        default :
            return state
    }
}
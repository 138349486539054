import React, {useEffect, useState} from "react";
import {connect} from 'react-redux';
import logo from "../../assests/images/logo-dark.png"
import userLog from '../../assests/images/naBarUserLogo.png'
import {logOut} from "../../redux/action/login.action";
import {getAccountDetails, getServiceDetails} from "../../redux/action/accountDetails.action";
import {getDraftDetailFTTH} from "../../redux/action/ftth/ftth.action";
import { getDraftDetailLTE } from "../../redux/action/lte/lte.action";
import { getCharge } from "../../redux/action/charge/charge.action";
import AddAccount from "./addAccount/AddAccount";
import {useHistory} from "react-router-dom";
import useComponentVisible from "./UseComponentVisible";

function NavBar({
                    accountList,
                    getAccountDetails,
                    getServiceDetails,
                    logOut,
                    loading,
                    getDraftDetailFTTH,
                    getDraftDetailLTE,
                    draftDetails,
                    draftDetailsLte,
                    payLog,
                    payLogLte,
                    invoice,
                    invoiceLte,
                    getCharge,
                }) {
    const history = useHistory();

    const [show, setShow] = useState(false);
    const [telNo, setTelNo] = useState('');
    // const [showMore, setShowMore] = useState(false);
    const {
        ref,
        isComponentVisible,
        setIsComponentVisible
    } = useComponentVisible(false);
    const [accList, setAccList] = useState(accountList);


    const closeModel = () => {
        setShow(false)
    }

    useEffect(() => {
        if (accountList && accountList.length === 0) {
            getAccountDetails()
            getDraftDetailFTTH()
            getDraftDetailLTE()
            
        }
        getCharge()
    }, []);// eslint-disable-line react-hooks/exhaustive-deps


    // function usePrevious(value) {
    //     const ref = useRef();
    //     useEffect(() => {
    //         ref.current = value;
    //     });
    //     return ref.current;
    // }
    //
    // const prev = usePrevious(accountList);

    useEffect(() => {
        if (accList !== accountList) {
            setTelNo(validation())
            // console.log(telNo) 
            if (accountList && accountList.length > 0) {
                if (validation()) {
                    getServiceDetails(validation(), getAccountNumber())
                    setTelNo(validation())
                } else {
                    localStorage.setItem('selectedAcc', accountList[0].telephoneno)
                    getServiceDetails(accountList[0].telephoneno, accountList[0].accountno)
                    setTelNo(accountList[0].telephoneno)
                }

            }
            setAccList(...[accountList])
        }

    }, [accountList])// eslint-disable-line react-hooks/exhaustive-deps

    function selectAccount(event) {

        if (event.target.value === 'new') {
            setShow(true)
        } else {
            localStorage.setItem('selectedAcc', event.target.value)
            sessionStorage.removeItem('notificationBannerPopupChecked')
            setTelNo(event.target.value)
            getServiceDetails(event.target.value, getAccountNumber())
        }

    }

    function validation() {
        let saved = localStorage.getItem('selectedAcc')
        if (saved && accountList) {
            if (accountList.find(e => e.telephoneno === saved)) {
                return saved
            }
        } else
            return null
    }

    function getAccountNumber() {
        let result
        if (validation()) {
            if (accountList) {
                result = accountList.find(obj => {
                    return obj.telephoneno === validation()
                })
                return result.accountno
            }
        }
        return null

    }

    function hiddenCondition() {
        let url = window.location.href
        return url.includes('ftthrequest') || url.includes('ftth') || url.includes('connectionOrder') || url.includes('lterequest')
    }

    function logoClick() {
        if(localStorage.getItem('loginBy') === 'ftth')
            history.push('/promo/buyonline')
        else
            history.push('/')
    }

    // console.log(draftDetails)
    // console.log(draftDetailsLte)

    return (
        <nav>
            <div className="nav-container clearfix">
                <img src={logo} alt='mobitel_Logo' className='logo' onClick={() => logoClick()}/>

                <div className='right-options-container float-right'>

                    <p className='portal-label'>My SLT Portal</p>

                    {
                        !hiddenCondition() ?
                            accountList && accountList.length > 0 ?
                                <select disabled={loading} value={validation()} className='number-select fancy'
                                        onChange={(e) => selectAccount(e)}>
                                    {
                                        accountList ? accountList.map((val, index) => {
                                            return <option key={index}
                                                           value={val.telephoneno}>{val.telephoneno}</option>
                                        }) : null
                                    }
                                    {accountList.length === 4 ? null :
                                        <option value='new' onClick={() => setShow(true)}>Add Account</option>}
                                </select> :
                                <select disabled={loading} defaultValue={'c'} className='number-select fancy'
                                        onClick={() => setShow(true)}>
                                    <option value='new'>Add Account</option>
                                </select>
                            : null
                    }

                    {/*<img className='user' src={userLog} alt='userLogo' onClick={() => logOut()}/>*/}
                    <img className='user' ref={ref}
                         src={localStorage.getItem('userImage') ? localStorage.getItem('userImage') : userLog}
                         alt='userLogo' onClick={() => setIsComponentVisible(!isComponentVisible)}/>
                    {draftDetails && payLog && payLog[0].gw_status === '1' || draftDetailsLte && payLogLte && payLogLte[0].gw_status === '1' ?
                        null : null}
                    <AddAccount show={show} hide={closeModel}/>

                    {
                        isComponentVisible ? <div className={hiddenCondition() ? 'moreSelect' : 'moreSelect all'}>
                            <div ref={ref}>
                                <div className="userdetails">
                                    <div>
                                        <img className='user'
                                             src={localStorage.getItem('userImage') ? localStorage.getItem('userImage') : userLog}
                                             alt='userLogo'/>

                                    </div>
                                    <div className="info">
                                        <div className='my-slt'>My SLT</div>
                                        <div className="number">{validation()}</div>
                                    </div>
                                </div>
                                <div className='items'>

                                    {
                                        !hiddenCondition() ?
                                            <div className="item" onClick={() => {
                                                // setIsComponentVisible(false)
                                                history.push('/myProfile')
                                            }}>My Profile
                                            </div> : null}

                                    {
                                        !hiddenCondition() ? <div className="item" onClick={() => {
                                            // setIsComponentVisible(false)
                                            history.push('/manageConnections')
                                        }}>Manage Connections
                                        </div> : null
                                    }


                                    {/* {
                                        draftDetails && payLog && (payLog[0].gw_status === '1') ?
                                        <div className="item" onClick={() => {
                                            // setIsComponentVisible(false)
                                            history.push('/connectionOrder')
                                        }}>FIBER Order <strong>1</strong>
                                        </div>                                      
                                        :
                                        ( 
                                            (invoice && invoice.length > 0  && ((payLog && (payLog[0].gw_status !== '1')) || !payLog)) || 
                                            (invoiceLte && invoiceLte.length > 0 && ((payLogLte && (payLogLte[0].gw_status !== '1')) || !payLogLte)) 
                                        ) ? <div className="item" onClick={() => {
                                            setIsComponentVisible(false)
                                            history.push('/cart')
                                        }}>Request Fiber Connection
                                        </div> :
                                         <div className="item" onClick={() => {
                                                // setIsComponentVisible(false)
                                                history.push('/ftthrequest')
                                            }}>Request Fiber Connection
                                            </div>

                                    } */}

                                    {/* {
                                        draftDetailsLte && payLogLte && (payLogLte[0].gw_status === '1') ?
                                        <div className="item" onClick={() => {
                                            // setIsComponentVisible(false)
                                            history.push('/lterequest')
                                        }}>4G LTE Order <strong>1</strong>
                                        </div> 
                                        : 
                                        ( 
                                            (invoice && invoice.length > 0  && ((payLog && (payLog[0].gw_status !== '1')) || !payLog)) || 
                                            (invoiceLte && invoiceLte.length > 0 && ((payLogLte && (payLogLte[0].gw_status !== '1')) || !payLogLte)) 
                                        ) ? <div className="item" onClick={() => {
                                            setIsComponentVisible(false)
                                            history.push('/cart')
                                        }}>Request LTE Connection
                                        </div> :
                                        <div className="item" onClick={() => {
                                                // setIsComponentVisible(false)
                                                history.push('/lterequest')
                                            }}>Request LTE Connection
                                            </div>

                                    } */}

                                    {/* {
                                       draftDetails && payLog && (payLog[0].gw_status === '1') || draftDetailsLte && payLogLte && (payLogLte[0].gw_status === '1') ?
                                        <div className="item" onClick={() => {
                                                // setIsComponentVisible(false)
                                                history.push('/connectionOrder')
                                            }}>My Orders <strong>1</strong>
                                        </div> : null
                                    } */}


                                    {/*<div className="item" onClick={() => {*/}
                                    {/*    setShowMore(false)*/}
                                    {/*    history.push('/faq/sltVoice')*/}
                                    {/*}}>FAQ*/}
                                    {/*</div>*/}

                                    <div className="item" onClick={() => {
                                        setIsComponentVisible(false)
                                        logOut(history)
                                    }}>Logout
                                    </div>
                                </div>

                            </div>

                        </div> : null
                    }

                    {/* {
                        draftDetailsLte && payLogLte && (payLogLte[0].gw_status === '1') ?
                            <div className="cart">
                                <div className="item" onClick={() => {
                                    // setIsComponentVisible(false)
                                    history.push('/lterequest')
                                    }}>
                                        <svg width="50" height="50" viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M31 0C48.1208 0 62 13.8792 62 31C62 48.1208 48.1208 62 31 62C13.8792 62 0 48.1208 0 31C0 13.8792 13.8792 0 31 0ZM46.6249 13.097L41.1075 14.8302C40.3771 15.0701 39.8906 15.7484 39.8058 16.4422L39.4955 19.4809L14.5123 22.2698C13.4396 22.4776 12.6858 23.4462 12.836 24.4419L14.3875 33.1833C14.5849 34.0976 15.3422 34.7244 16.1849 34.7348H38.3791L38.8143 37.2134H17.7364C16.6343 37.2522 15.827 38.0915 15.8141 39.0715C15.8598 40.1894 16.7609 40.9212 17.7364 40.9333H41.047C42.2227 40.836 43.0859 39.9043 42.905 38.7649L41.9136 33.3082L43.4651 18.0543L47.7412 16.6921C48.2372 16.5267 48.6096 16.2157 48.8576 15.7611C49.3268 14.7778 48.8885 13.689 48.0515 13.2181C47.5738 12.9698 47.1072 12.9388 46.6249 13.097ZM38.224 42.0044C36.6189 42.0044 35.3177 43.3056 35.3177 44.9106C35.3177 46.5157 36.6189 47.8169 38.224 47.8169C39.829 47.8169 41.1302 46.5157 41.1302 44.9106C41.1302 43.3056 39.8291 42.0044 38.224 42.0044ZM21.1649 42.0044C19.5599 42.0044 18.2587 43.3056 18.2587 44.9106C18.2587 46.5157 19.5599 47.8169 21.1649 47.8169C22.77 47.8169 24.0712 46.5157 24.0712 44.9106C24.0712 43.3056 22.77 42.0044 21.1649 42.0044Z" fill="url(#paint0_linear_3076_10283)"/>
                                    <defs>
                                    <linearGradient id="paint0_linear_3076_10283" x1="31" y1="0" x2="31" y2="62" gradientUnits="userSpaceOnUse">
                                    <stop stop-color="#ECEFF1"/>
                                    <stop offset="1" stop-color="white"/>
                                    </linearGradient>
                                    </defs>
                                    </svg>
                                    
                                </div>
                            </div>
                        : */}

                        {/* <div className="cart">
                            <div className="item" onClick={() => {
                                // setIsComponentVisible(false)
                                history.push('/cart')
                                }}>
                                    <svg width="50" height="50" viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M31 0C48.1208 0 62 13.8792 62 31C62 48.1208 48.1208 62 31 62C13.8792 62 0 48.1208 0 31C0 13.8792 13.8792 0 31 0ZM46.6249 13.097L41.1075 14.8302C40.3771 15.0701 39.8906 15.7484 39.8058 16.4422L39.4955 19.4809L14.5123 22.2698C13.4396 22.4776 12.6858 23.4462 12.836 24.4419L14.3875 33.1833C14.5849 34.0976 15.3422 34.7244 16.1849 34.7348H38.3791L38.8143 37.2134H17.7364C16.6343 37.2522 15.827 38.0915 15.8141 39.0715C15.8598 40.1894 16.7609 40.9212 17.7364 40.9333H41.047C42.2227 40.836 43.0859 39.9043 42.905 38.7649L41.9136 33.3082L43.4651 18.0543L47.7412 16.6921C48.2372 16.5267 48.6096 16.2157 48.8576 15.7611C49.3268 14.7778 48.8885 13.689 48.0515 13.2181C47.5738 12.9698 47.1072 12.9388 46.6249 13.097ZM38.224 42.0044C36.6189 42.0044 35.3177 43.3056 35.3177 44.9106C35.3177 46.5157 36.6189 47.8169 38.224 47.8169C39.829 47.8169 41.1302 46.5157 41.1302 44.9106C41.1302 43.3056 39.8291 42.0044 38.224 42.0044ZM21.1649 42.0044C19.5599 42.0044 18.2587 43.3056 18.2587 44.9106C18.2587 46.5157 19.5599 47.8169 21.1649 47.8169C22.77 47.8169 24.0712 46.5157 24.0712 44.9106C24.0712 43.3056 22.77 42.0044 21.1649 42.0044Z" fill="url(#paint0_linear_3076_10283)"/>
                                <defs>
                                <linearGradient id="paint0_linear_3076_10283" x1="31" y1="0" x2="31" y2="62" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#ECEFF1"/>
                                <stop offset="1" stop-color="white"/>
                                </linearGradient>
                                </defs>
                                </svg>

                                {
                                    ( 
                                        (invoice && invoice.length > 0  && ((payLog && (payLog[0].gw_status !== '1')) || !payLog)) || 
                                        (invoiceLte && invoiceLte.length > 0 && ((payLogLte && (payLogLte[0].gw_status !== '1')) || !payLogLte)) 
                                    ) ?
                                    <strong className='ftthLabel'>1</strong> : null
                                 }
                                
                            </div>
                        </div> */}
                    {/* } */}
                    

                </div>
            </div>

        </nav>
    )
}

const mapStateToProps = state => ({
    loading: state.accountDetails.loading,
    error: state.accountDetails.error,
    accountList: state.accountDetails.account_Details,
    draftDetails: state.ftth.draftDetails,
    payLog: state.ftth.paymentsLog,
    invoice: state.ftth.invoice,
    draftDetailsLte: state.lte.draftDetailsLte,
    payLogLte: state.lte.paymentsLogLte,
    invoiceLte: state.lte.invoiceLte,
})

export default connect(mapStateToProps, {logOut, getAccountDetails, getServiceDetails, getDraftDetailFTTH, getDraftDetailLTE, getCharge})(NavBar);
//export default NavBar

import React from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import NewNavBar from "./NewNavBar";
import ConnectionDirectPage from "./ConnectionDirectPage";
import MainFTTH from "./ftth/MainFTTH";
import Footer from "./Footer";

function LandingFTTHPage(props) {
  const { path } = useParams();

  // console.log(path);

  if (path === undefined) {
    return (
      <div className="ftthBody newFTTHLandingPage">
        <NewNavBar />
        <div className="landingFTTHPage">
          <ConnectionDirectPage props={props} />
        </div>
        <Footer />
      </div>
    );
  } else if (path === "fiber") {
    return (
      <div className="ftthBody newFTTHLandingPage">
        <NewNavBar />
        <div>
          <MainFTTH props={props} />
        </div>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  loggedIn: state.login.loggedIn,
  charge: state.charge.chargeDetails,
});
export default connect(mapStateToProps, null)(LandingFTTHPage);

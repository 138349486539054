import React, { useEffect, useState } from "react";
import UserInformation from "./userInformation/UserInformation";
import PackageInformation from "./packages/PackageInformation";
import FtthPayment from "./payment/FtthPayment";
import { getViewUserInfoUrl } from "../../../redux/URL";
import { CATCH_ERROR_MSG } from "../../../redux/Types";
import { connect } from "react-redux";

function MainFTTH({ charge }) {
  const [loading, setLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [error, setError] = useState("");

  const userName = localStorage.getItem("userName");

  const [state, setState] = useState(1);
  const [userInfo, setUserInfo] = useState({
    title: "",
    fName: "",
    lName: "",
    gender: "Male",
    iType: "nic",
    nic: "",
    isNicVerify: false,
    passport: "",
    address1: "",
    address2: "",
    isPpVerify: false,
    lType: "",
    mobile: "",
    isMobileVerify: false,
    isEmailVerify: false,
    email: "",
    nationality: "Sri Lankan",
    dateOfBirth: "04/11/1997",
    isExistCus: false,
    altContact: "",
    sltnic: "",
  });
  const [locationInfo, setLocationInfo] = useState({
    district: "Colombo",
    city: "",
    postalCode: "",
    address1: "",
    address2: "",
    rTom: "",
    lat: null,
    lng: null,
    dLat: 7.8742,
    dLng: 80.6511,
  });
  const [packageInfo, setPackageInfo] = useState({
    peoTvPackages: "",
    bbPackages: "",
    voicePackages: "",
    peoPackagesData: {},
    bbPackagesData: {},
    voicePackagesData: {},
    isSameAsBilling: false,
    address1: "",
    address2: "",
    province: "",
    district: "",
    city: "",
    postalCode: "",
    billType: "eBill",
    iddActivate: true,
  });
  const [cityList, setCityList] = useState([]);

  const [orderRef, setOrderRef] = useState("");

  const [chargeFTTH, setChargeFTTH] = useState(10000);

  const [checkFesibilty, setCheckFesibilty] = useState(null);

  useEffect(() => {
    if (charge.length > 0) {
      for (let i = 0; i < charge.length; i++) {
        if (charge[i].BB_TYPE === "SLT Fiber") {
          setChargeFTTH(charge[i].INITIAL_FEE);
          // setChargeFTTH(1000)
        }
      }
    }
  }, [charge]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="mainViewFTTH" style={{ marginTop: "20px" }}>
      {state !== 2 && state !== 3 && (
        <>
          <p className="titleNew" style={{ fontWeight: "bold" }}>
            Get a Life with Fibre
          </p>
          <div className="titleNew">
            It's Decision Time. Choose Your Package
          </div>
        </>
      )}

      {state !== 1 && state !== 3 && (
        <>
          <p className="titleNew" style={{ fontWeight: "bold" }}>
            Get a Life with Fibre
          </p>
          <div className="titleNew">Submit Your Details</div>
        </>
      )}

      {state !== 1 && state !== 2 && (
        <>
          <p className="titleNew" style={{ fontWeight: "bold" }}>
            Get a Life with Fibre
          </p>
          <div className="titleNew">A Snapshot of Your Purchase Details.</div>
        </>
      )}

      <div className="bodyFTTH wizard-container">
        <div className="navigation">
          <div className="wizard-navigation-set">
            <div className="wizard-item-container">
              <div
                className={
                  state === 1
                    ? "wizard-item active"
                    : state > 1
                    ? "wizard-item done"
                    : "wizard-item"
                }
              >
                <div className="item-icon">1</div>
                <div className="item-name">Package Selection</div>
              </div>
              <div
                className={
                  state === 2
                    ? "wizard-item active"
                    : state > 2
                    ? "wizard-item done"
                    : "wizard-item"
                }
              >
                <div className="item-icon">2</div>
                <div className="item-name">Customer Details</div>
              </div>
              <div
                className={
                  state === 3
                    ? "wizard-item active"
                    : state > 3
                    ? "wizard-item done"
                    : "wizard-item"
                }
              >
                <div className="item-icon">3</div>
                <div className="item-name">Summary</div>
              </div>
            </div>
          </div>
        </div>
        {state === 1 ? (
          <PackageInformation
            packageInfo={packageInfo}
            setPackageInfo={setPackageInfo}
            setState={setState}
          />
        ) : state === 2 ? (
          <UserInformation
            userInfo={userInfo}
            setUserInfo={setUserInfo}
            packageInfo={packageInfo}
            setPackageInfo={setPackageInfo}
            locationInfo={locationInfo}
            setLocationInfo={setLocationInfo}
            cityList={cityList}
            setCityList={setCityList}
            setState={setState}
            loading={loading}
            setLoading={setLoading}
            error={error}
            chargeFTTH={chargeFTTH}
            setError={setError}
            setIsError={setIsError}
            setOrderRef={setOrderRef}
            orderRef={orderRef}
            isError={isError}
            setCheckFesibilty={setCheckFesibilty}
            checkFesibilty={checkFesibilty}
          />
        ) : state === 3 ? (
          <FtthPayment
            userInfo={userInfo}
            orderRef={orderRef}
            locationInfo={locationInfo}
            packageInfo={packageInfo}
            setState={setState}
            chargeFTTH={chargeFTTH}
            loading={loading}
            setLoading={setLoading}
            setError={setError}
            setIsError={setIsError}
            error={error}
            isError={isError}
            checkFesibilty={checkFesibilty}
          />
        ) : null}
      </div>
      {/* <button onClick={() => setState(state + 1)}>Click</button>
      <button onClick={() => setState(state - 1)}>Click back</button> */}
    </div>
  );
}
const mapStateToProps = (state) => ({
  draftDetails: state.ftth.draftDetails,
  loadingDraft: state.ftth.loadingDraft,
  payLog: state.ftth.paymentsLog,
  loadingPayment: state.ftth.loadingPayment,
  charge: state.charge.chargeDetails,
  invoice: state.ftth.invoice,
  loadingInvoice: state.ftth.loadingInvoice,
  invoiceLte: state.lte.invoiceLte,
  loadingInvoiceLte: state.lte.loadingInvoiceLte,
  payLogLte: state.lte.paymentsLogLte,
});

export default connect(mapStateToProps, null)(MainFTTH);

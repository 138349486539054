import SearchLocation from "../ftth/location/SearchLocation";
import {GoogleApiWrapper, Map, Marker} from "google-maps-react";
import mapMarker from "../../assests/images/mapMarker.svg";
import * as PropTypes from "prop-types";
import React from "react";
import {MAP_KEY} from "../../redux/MAPKEYS";


function ComplainMap(props) {
    return <div>
            <div className="map-container" style={{height: "238px"}}>
                <p>Please Select Your Service Delivery Location</p>
                <SearchLocation searchLatLon={props.searchLatLon}/>

                <Map google={props.google} zoom={props.zoom} containerStyle={props.containerStyle}
                    //  initialCenter={{lat: locationInfo.lat, lng: locationInfo.lng}}
                     initialCenter={{lat: props.lat, lng: props.lng}}
                    //  center={{lat: locationInfo.lat, lng: locationInfo.lng}}
                     center={{lat: props.lat, lng: props.lng}}
                     mapTypeControl={false}
                     onClick={props.onClick}

                >

                    <Marker
                        name={"Current location"} draggable={true}
                        // position={{lat: locationInfo.lat, lng: locationInfo.lng}}
                        position={{lat: props.lat, lng: props.lng}}
                        icon={{
                            url: mapMarker,
                            // anchor: new props.google.maps.Point(5, 58),
                            scaledSize: new props.google.maps.Size(37, 37)
                        }}
                        onDragend={props.onClick}
                    />


                </Map>
            </div>

    </div>;
}

ComplainMap.propTypes = {
    searchLatLon: PropTypes.func,
    google: PropTypes.any,
    zoom: PropTypes.number,
    containerStyle: PropTypes.shape({width: PropTypes.string, position: PropTypes.string, height: PropTypes.string}),
    lat: PropTypes.number,
    lng: PropTypes.number,
    onClick: PropTypes.func
};
export default GoogleApiWrapper({
    // apiKey: ('AIzaSyBueJkoLK9q9p-8mDKsX8Kv12L0EMiVevc')
    apiKey: (MAP_KEY)
})(ComplainMap)

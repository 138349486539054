import React, {useState} from 'react';


function Circle({bgColor}) {

    let [color] = useState(bgColor); 

    // console.log(bgColor);

    var circleStyle = {
        padding:5,
        marginLeft: 10,
        marginRight: 10,
        display:"inline-block",
        // position:'absolute',
        backgroundColor:`${color}`,
        borderRadius: "50%",
        width:20,
        height:20,
        left:0,
        top:0
      };

    return (<div style={circleStyle}></div>);
}

export default Circle;
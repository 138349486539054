import axiosInstance from "../../axiosInstance";
import {dispatchError, dispatchSuccess, loadingDispatch} from "../../DispatchFunctions";
import {
    CATCH_ERROR_MSG, ERROR_GET_PACKAGE_UPGRADE,
    ERROR_GET_PACKAGE_UPGRADE_LIST,
    GET_PACKAGE_UPGRADE_LIST, SUCCESS_GET_PACKAGE_CURRENT_LIST,
    SUCCESS_GET_PACKAGE_UPGRADE_LIST, SUCCESS_UPGRADE_PACKAGE

} from "../../Types";
import {BBPackageChangeRequest, getBBPackageComparisonUrl, getCurrentBBPackageUrl} from "../../URL";


export function getPackageUpgradeList(serviceType, packageName) {
    let url = getBBPackageComparisonUrl + 'type=' + serviceType + '&package=' + packageName
    return (dispatch) => {
        dispatch(loadingDispatch(GET_PACKAGE_UPGRADE_LIST))
        axiosInstance.get(url).then((response) => {
            if (response.status === 200 && response.data.isSuccess) {
                dispatch(dispatchSuccess(SUCCESS_GET_PACKAGE_UPGRADE_LIST, response.data.dataBundle))
                dispatch(getCurrentPackageURl(serviceType, packageName))
            } else {
                dispatch(dispatchError(ERROR_GET_PACKAGE_UPGRADE_LIST, response.data.errorShow))
            }
        }).catch((error) => {
            dispatch(dispatchError(ERROR_GET_PACKAGE_UPGRADE_LIST, CATCH_ERROR_MSG))
        })
    }

}

function getCurrentPackageURl(serviceType, packageName) {
    let url = getCurrentBBPackageUrl + 'type=' + serviceType + '&package=' + packageName
    return (dispatch) => {
        axiosInstance.get(url).then(response => {
            if (response.status === 200 && response.data.isSuccess) {
                dispatch(dispatchSuccess(SUCCESS_GET_PACKAGE_CURRENT_LIST, response.data.dataBundle))
            } else {
                dispatch(dispatchError(ERROR_GET_PACKAGE_UPGRADE_LIST, response.data.errorShow))
            }
        }).catch((error) => {
            dispatch(dispatchError(ERROR_GET_PACKAGE_UPGRADE_LIST, CATCH_ERROR_MSG))
        })
    }
}

export function packageUpgradeAPI(subscriberID, type, name, nic, contactMobile, email, exsistingPackage, newPackage, currentMonthlyValue, newMonthlyValue, changeType, success, isError, error) {
     let url = BBPackageChangeRequest + 'subscriberID=' + subscriberID + '&type=' + type + '&name=' + name + '&nic=' + nic + '&contactMobile=' + contactMobile + '&email=' + email + '&exsistingPackage=' + exsistingPackage + '&newPackage=' + newPackage + '&currentMonthlyValue=' + currentMonthlyValue + '&newMonthlyValue=' + newMonthlyValue + '&changeType=' + changeType
    return (dispatch) => {
        dispatch(loadingDispatch(GET_PACKAGE_UPGRADE_LIST))
      axiosInstance.post(url).then((response) => {
          if (response.status === 200 && response.data.isSuccess) {
              success(true)
              dispatch(loadingDispatch(SUCCESS_UPGRADE_PACKAGE))
          }else {
              error(response.data.errorShow)
              isError(true)
              dispatch(dispatchError(ERROR_GET_PACKAGE_UPGRADE, response.data.errorShow))
          }
      }).catch((error) => {
          error(CATCH_ERROR_MSG)
          isError(true)
          dispatch(dispatchError(ERROR_GET_PACKAGE_UPGRADE, CATCH_ERROR_MSG))
      })
    }
}
import React, {useEffect, useState} from "react";
import OtpInput from 'react-otp-input';
import {otpVerification_Register, resendOTP, setPublicActionToInitialState} from "../../redux/action/login.action";
import backIcon from "../../assests/images/loginUI/back.png";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import LoadingBbBody from "../boardBand/LoadingBbBody";
import ErrorAlert from "../alerts/ErrorAlert";

const Querystring = require('querystring');

function RegisterOtp(props) {
    const [OTP, setOTP] = useState("");

    const [isError, setIsError] = useState(false);
    const [error, setError] = useState('');

    useEffect(() => {
        props.setPublicActionToInitialState()
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    const submit = () => {

        if (OTP.length === 6 && props.match.params.id) {

            let body = Querystring['stringify']({
                id: props.match.params.id,
                verifyOtp: OTP,
                channelID: 'WEB'
            })
            props.otpVerification_Register(body, setError, setIsError)
        }
    }

    if (props.loading)
        return <LoadingBbBody/>

    return (

        <div>
            {isError ? <ErrorAlert error={error} setError={setError} setIsError={setIsError}/> : null}

            <div className='form d-block m-auto login-form'>
                <p className='titles mt-4'>Confirm OTP</p>

                <p className='small message mt-4 text-center'>Enter 6 digits OTP code for active your account.</p>

                <div className='mt-4'>


                    <div className='ml-lg-4'>
                        <OtpInput
                            value={OTP}
                            onChange={setOTP}
                            numInputs={6}
                            isInputNum={true}
                            shouldAutoFocus={true}
                            inputStyle='otp'
                            className='otpContainer'
                        />

                    </div>


                    <div className='mt-4 text-center'>
                        <button className='medium blue'
                                onClick={(e) => submit(e)}>{props.loading ? 'Please wait...' : 'Submit'}</button>
                    </div>

                    {/*<div className='mt-5'>*/}
                    {/*    <p className='small message d-inline-block'>Have you received your OTP yet?</p>*/}
                    {/*    <button className='medium blue ml-3' onClick={() => props.resendOTP(null, setError, setIsError)}>Resend</button>*/}
                    {/*</div>*/}

                    <div className='register-container mt-5' >
                        <p className='register-label'>Have you received your OTP yet?</p>
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                        <a className='btn-register'  onClick={() => props.resendOTP(null, setError, setIsError)}>Resend
                        </a>
                    </div>

                </div>


            </div>

            <Link to='/' className="btn-back">
                <div className='back'>
                    <img src={backIcon} alt='backLogo' className='back'/>
                    <p>Back to sign in</p>

                </div>
            </Link>
        </div>
    )
}

const mapStateToProps = state => ({
    loading: state.login.loading,
    error: state.login.error
})
export default connect(mapStateToProps, {
    otpVerification_Register,
    resendOTP,
    setPublicActionToInitialState
})(RegisterOtp);

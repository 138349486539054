export const REQUEST_LOGIN = 'REQUEST_LOGIN'
export const REQUEST_SUCCESS = 'REQUEST_SUCCESS'
export const SUCCESS_LOGIN = 'SUCCESS_LOGIN'
export const ERROR_LOGIN = 'ERROR_LOGIN'

export const LOG_OUT = 'LOG_OUT'
export const SETPUBLICTOINITIALSTATE = 'SETPUBLICTOINITIALSTATE'


//related to banner details
export const LOADING_BANNERS = 'LOADING_BANNERS'
export const SUCCESS_GET_BANNERS = 'SUCCESS_GET_BANNERS'
export const ERROR_GET_BANNERS = 'ERROR_GET_BANNERS'
export const SET_BANNER_SLIDER_NO = 'SET_BANNER_SLIDER_NO'

//related to get account Details
export const LOADING_ACCOUNT_DETAILS = 'LOADING_ACCOUNT_DETAILS'
export const SUCCESS_GET_ACCOUNT_DETAILS = 'SUCCESS_GET_ACCOUNT_DETAILS'
export const ERROR_GET_ACCOUNT_DETAILS = 'ERROR_GET_ACCOUNT_DETAILS'

export const LOADING_GET_SERVICE_LIST = 'LOADING_GET_SERVICE_LIST'
export const SUCCESS_GET_SERVICE_LIST = 'SUCCESS_GET_SERVICE_LIST'
export const ERROR_GET_SERVICE_LIST = 'ERROR_GET_SERVICE_LIST'

export const LOADING_PAYMENT_LIST = 'LOADING_PAYMENT_LIST'
export const SUCCESS_PAYMENT_LIST = 'SUCCESS_PAYMENT_LIST'
export const ERROR_PAYMENT_LIST = 'ERROR_PAYMENT_LIST'
export const LOADING_ADD_ACCOUNT = 'LOADING_ADD_ACCOUNT'
export const SUCCESS_ADD_ACCOUNT = 'SUCCESS_ADD_ACCOUNT'

export const LOADING_GET_SUMMARY = 'LOADING_GET_SUMMARY'
export const SUCCESS_GET_SUMMARY = 'SUCCESS_GET_SUMMARY'
export const ERROR_GET_SUMMARY = 'ERROR_GET_SUMMARY'

export const LOADING_PROFILE = 'LOADING_PROFILE'
export const SUCCESS_GET_PROFILE = 'SUCCESS_GET_PROFILE'
export const ERROR_GET_PROFILE = 'ERROR_GET_PROFILE'

export const LOADING_EXTRAGB = 'LOADING_EXTRAGB'
export const SUCCESS_GET_EXTRAGB = 'SUCCESS_GET_EXTRAGB'
export const ERROR_GET_EXTRAGB = 'ERROR_GET_EXTRAGB'

export const LOADING_BONUS_DATA = 'LOADING_BONUS_DATA'
export const SUCCESS_GET_BONUS_DATA = 'SUCCESS_GET_BONUS_DATA'
export const ERROR_GET_BONUS_DATA = 'ERROR_GET_BONUS_DATA'

export const LOADING_ADD_ONS = 'LOADING_ADD_ONS'
export const SUCCESS_GET_ADD_ONS = 'SUCCESS_GET_ADD_ONS'
export const ERROR_GET_ADD_ONS = 'ERROR_GET_ADD_ONS'

export const LOADING_FREE_DATA = 'LOADING_FREE_DATA'
export const SUCCESS_GET_FREE_DATA = 'SUCCESS_GET_FREE_DATA'
export const ERROR_GET_FREE_DATA = 'ERROR_GET_FREE_DATA'

export const LOADING_SUSCRIBE_STATUS = 'LOADING_SUSCRIBE_STATUS'
export const SUCCESS_SUSCRIBE_STATUS = 'SUCCESS_SUSCRIBE_STATUS'
export const ERROR_SUSCRIBE_STATUS = 'ERROR_SUSCRIBE_STATUS'

//broadband purchase payment history
export const LOADING_PURCHASE_PAYMENT_HISTORY = 'LOADING_PAYMENT_HISTORY'
export const SUCCESS_PURCHASE_PAYMENT_HISTORY = 'SUCCESS_PAYMENT_HISTORY'
export const ERROR_PURCHASE_PAYMENT_HISTORY = 'ERROR_PAYMENT_HISTORY'

//broadband daily usage
export const LOADING_DAILY_USAGE = 'LOADING_DAILY_USAGE'
export const SUCCESS_DAILY_USAGE_CURRENT = 'SUCCESS_DAILY_USAGE_CURRENT'
export const SUCCESS_DAILY_USAGE_PREV1 = 'SUCCESS_DAILY_USAGE_PREV1'
export const SUCCESS_DAILY_USAGE_PREV2 = 'SUCCESS_DAILY_USAGE_PREV2'
export const SUCCESS_GETTING_DAILY_USAGE = 'SUCCESS_GETTING_DAILY_USAGE'
export const ERROR_DAILY_USAGE = 'ERROR_DAILY_USAGE'

export const LOADING_PROTOCOL_REPORT = 'LOADING_PROTOCOL_REPORT'
export const SUCCESS_GET_PROTOCOL_REPORT = 'SUCCESS_GET_PROTOCOL_REPORT'
export const ERROR_GET_PROTOCOL_REPORT = 'ERROR_GET_PROTOCOL_REPORT'

//package upgrade ListUrl
export const GET_PACKAGE_UPGRADE_LIST = 'GET_PACKAGE_UPGRADE_LIST'
export const SUCCESS_GET_PACKAGE_UPGRADE_LIST = 'SUCCESS_GET_PACKAGE_UPGRADE_LIST'
export const SUCCESS_GET_PACKAGE_CURRENT_LIST = 'SUCCESS_GET_PACKAGE_CURRENT_LIST'
export const ERROR_GET_PACKAGE_UPGRADE_LIST = 'ERROR_GET_PACKAGE_UPGRADE_LIST'
export const ERROR_GET_PACKAGE_UPGRADE = 'ERROR_GET_PACKAGE_UPGRADE'
export const SUCCESS_UPGRADE_PACKAGE = 'SUCCESS_UPGRADE_PACKAGE'

//addons data list
export const GET_ADDONS_LIST = 'GET_ADDONS_LIST'
export const SUCCESS_GET_ADDONS_LIST = 'SUCCESS_GET_ADDONS_LIST'
export const ERROR_GET_ADDONS_LIST = 'ERROR_GET_ADDONS_LIST'
export const SUCCESS_ADD_ADDONS_DATA = 'SUCCESS_ADD_ADDONS_DATA'

//get ExtraGb type
export const GET_EXTRA_GB_PACKAGES_DETAILS = 'GET_EXTRA_GB'
export const SUCCESS_GET_EXTRA_GB_PACKAGES_DETAILS = 'SUCCESS_GET_EXTRA_GB'
export const ERROR_GET_EXTRA_GB_PACKAGES_DETAILS = 'ERROR_GET_EXTRA_GB'

//get Bill History
export const GET_BILL_HISTORY = 'GET_BILL_HISTORY'
export const SUCCESS_GET_BILL_HISTORY = 'SUCCESS_GET_BILL_HISTORY'
export const ERROR_GET_BILL_HISTORY = 'ERROR_GET_BILL_HISTORY'

//get Bill Status
export const GET_BILL_STATUS = 'GET_BILL_STATUS'
export const SUCCESS_GET_BILL_STATUS = 'SUCCESS_GET_BILL_STATUS'
export const ERROR_GET_BILL_STATUS = 'ERROR_GET_BILL_STATUS'

//get Bill DownloadRequest
export const GET_BILL_DOWNLOAD_REQUEST = 'GET_BILL_DOWNLOAD_REQUEST'
export const SUCCESS_GET_BILL_DOWNLOAD_REQUEST = 'SUCCESS_GET_BILL_DOWNLOAD_REQUEST'
export const ERROR_GET_BILL_DOWNLOAD_REQUEST = 'ERROR_GET_BILL_DOWNLOAD_REQUEST'

//set SmartBillRegistration
export const SET_SMART_BILL_REGISTRATION = 'SET_SMART_BILL_REGISTRATION'
export const SUCCESS_SET_SMART_BILL_REGISTRATION = 'SUCCESS_SET_SMART_BILL_REGISTRATION'
export const ERROR_SET_SMART_BILL_REGISTRATIONT = 'ERROR_SET_SMART_BILL_REGISTRATIONT'

//User Complaints 
export const LOADING_GET_COMPLAINTS_LIST = 'LOADING_GET_COMPLAINTS_LIST'
export const SUCCESS_GET_COMPLAINTS_LIST = 'SUCCESS_GET_COMPLAINTS_LIST'
export const ERROR_GET_COMPLAINTS_LIST   = 'ERROR_GET_COMPLAINTS_LIST'

export const LOADING_POST_COMPLAINTS = 'LOADING_POST_COMPLAINTS'
export const SUCCESS_POST_COMPLAINTS = 'SUCCESS_POST_COMPLAINTS'
export const ERROR_POST_COMPLAINTS = 'ERROR_POST_COMPLAINTS'


//Redeem Voucher
export const LOADING_REDEEM_VOUCHER = 'LOADING_REDEEM_VOUCHER'
export const SUCCESS_REDEEM_VOUCHER = 'SUCCESS_REDEEM_VOUCHER'
export const ERROR_REDEEM_VOUCHER = 'ERROR_REDEEM_VOUCHER'

//get GiftData type
export const GET_GIFT_DATA_PACKAGES_DETAILS = 'GET_GIFT_DATA'
export const SUCCESS_GET_GIFT_DATA_PACKAGES_DETAILS = 'SUCCESS_GET_GIFT_DATA'
export const ERROR_GET_GIFT_DATA_PACKAGES_DETAILS = 'ERROR_GET_GIFT_DATA'


//ftth related
export const LOADING_GET_DRAFT_DETAILS = 'LOADING_GET_DRAFT_DETAILS'
export const SUCCESS_GET_DRAFT_DETAILS = 'SUCCESS_GET_DRAFT_DETAILS'
export const ERROR_GET_DRAFT_DETAILS = 'ERROR_GET_DRAFT_DETAILS'

export const LOADING_GET_PAYMENT_LOG = 'LOADING_GET_PAYMENT_LOG'
export const SUCCESS_GET_PAYMENT_LOG = 'SUCCESS_GET_PAYMENT_LOG'
export const ERROR_GET_PAYMENT_LOG = 'ERROR_GET_PAYMENT_LOG'

export const LOADING_GET_INVOICE = 'LOADING_GET_INVOICE'
export const SUCCESS_GET_INVOICE = 'SUCCESS_GET_INVOICE'
export const ERROR_GET_INVOICE = 'ERROR_GET_INVOICE'

//lte related
export const LOADING_GET_DRAFT_DETAILS_LTE = 'LOADING_GET_DRAFT_DETAILS_LTE'
export const SUCCESS_GET_DRAFT_DETAILS_LTE = 'SUCCESS_GET_DRAFT_DETAILS_LTE'
export const ERROR_GET_DRAFT_DETAILS_LTE = 'ERROR_GET_DRAFT_DETAILS_LTE'

export const LOADING_GET_PAYMENT_LOG_LTE = 'LOADING_GET_PAYMENT_LOG_LTE'
export const SUCCESS_GET_PAYMENT_LOG_LTE = 'SUCCESS_GET_PAYMENT_LOG_LTE'
export const ERROR_GET_PAYMENT_LOG_LTE = 'ERROR_GET_PAYMENT_LOG_LTE'

export const LOADING_GET_INVOICE_LTE = 'LOADING_GET_INVOICE_LTE'
export const SUCCESS_GET_INVOICE_LTE = 'SUCCESS_GET_INVOICE_LTE'
export const ERROR_GET_INVOICE_LTE = 'ERROR_GET_INVOICE_LTE'


export const LOADING_GET_PAYMENT = 'LOADING_GET_PAYMENT'
export const SUCCESS_GET_PAYMENT = 'SUCCESS_GET_PAYMENT'
export const ERROR_GET_PAYMENT = 'ERROR_GET_PAYMENT'

//prepaid related
export const LOADING_GET_PREPAID_DATA = 'LOADING_GET_PREPAID_DATA'
export const SUCCESS_GET_PREPAID_DATA = 'SUCCESS_GET_PREPAID_DATA'
export const ERROR_GET_PREPAID_DATA = 'ERROR_GET_PREPAID_DATA'

export const CATCH_ERROR_MSG = 'Oops! something went wrong. Please try again shortly.'
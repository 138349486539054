import React, { useState } from "react";
import { useEffect } from "react";
import Modal from 'react-modal';
import { connect } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import axiosInstance from "../../redux/axiosInstance";
import { popupMessageBanner } from "../../redux/URL";

const customStyles = {
        content: {
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                marginRight: '-50%',
                transform: 'translate(-50%, -50%)',
                background: 'white',
                width: '650px',
                height: "auto",
                outline: "none",
                borderRadius: '20px',

        },
};

function NotificationBannerPopup({ serviceList, billStatus, billStatusLoading }) {
        const { pathname } = useLocation();
        const history = useHistory();
        const [data, setData] = useState(null);
        const [isOpen, setIsOpen] = useState(true)
        const notificationBannerPopupChecked = sessionStorage.getItem('notificationBannerPopupChecked')

        useEffect(() => {
                if (serviceList.hasOwnProperty('listofBBService') && serviceList.listofBBService.length > 0 && !notificationBannerPopupChecked) {
                        getPopUpMessageBanner()
                }

        }, [serviceList])

        const getPopUpMessageBanner = () => {
                let url = popupMessageBanner + `subscriberID=${serviceList.listofBBService[0].serviceID}`
                axiosInstance.get(url).then(response => {
                        if (response.data.dataBundle) {
                                setData(response.data.dataBundle[0])
                        } else if (response.data.errorShow) {

                        }
                }).catch(error => {

                })
        }

        if (!data) return null

        if(billStatusLoading || !billStatus || billStatus.bill_code !== '01') return null

        if (data && (data.popuP_TYPE === "B" || data.popuP_TYPE === "C")) return null

        if (pathname === '/eBill') return null

        if (!notificationBannerPopupChecked) {
                return (
                        <div className="m-2">

                                <Modal
                                        isOpen={isOpen}
                                        style={customStyles}
                                        ariaHideApp={false}
                                        contentLabel="Example Modal"
                                >

                                        <span className='float-right' onClick={() => {
                                                sessionStorage.setItem('notificationBannerPopupChecked', true)
                                                setIsOpen(false)
                                        }}
                                                style={{ width: '30px', height: '36px', fontSize: '1.5rem', outline: 'none', color: '#1977d1', marginTop: '-18px', marginRight:'-18px', cursor: 'pointer' }}> <i
                                                        className='fa fa-times-circle mt-0' />
                                        </span>

                                        <div>
                                                <img width={'100%'} height={'100%'} src={data.popuP_URL} alt='temporaryBanner' />
                                        </div>

                                        <div style={{ width: 'fit-content', margin: '20px auto' }}>
                                                <button className='medium highLong blue' onClick={() => {
                                                        sessionStorage.setItem('notificationBannerPopupChecked', true)
                                                        history.push('/eBill')
                                                }} >{data.buttoN_NAME}</button>
                                        </div>
                                </Modal>
                        </div>
                );
        }

        return null


}


const mapStateToProps = state => ({
        serviceList: state.accountDetails.service_list,
        billStatus: state.billStatus.dataBundle,
        billStatusLoading: state.billStatus.loading,
})

export default connect(mapStateToProps, {})(NotificationBannerPopup);
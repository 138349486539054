import React from 'react';

function ExtraGBPaymentType({amount, amountUnit, amountPrice, setState, payType, setPayType}) {


    return (
        <div className='paymentBody common-panel'>
            <div className='body-container'>
                <div className='title'>Select Payment Type</div>
                <div className='wrapper'>
                    <div className='selectPaymentType'>

                        <div className='upperSection'>
                            <div className='details'>
                                <div className='gbAmount'>{amount}{amountUnit}</div>
                                <div className='price'>Rs.{amountPrice} + Tax</div>
                            </div>

                            <div className='typeButtons'>
                                <button onClick={() => setPayType('addToBill')}
                                        className={payType === 'addToBill' ? 'selectedType' : null}>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        version="1"
                                        viewBox="0 0 512 512"
                                    >
                                        <path
                                            d="M475 246h-74V10a10 10 0 00-17-7l-28 28-29-28c-4-4-10-4-14 0l-28 28-29-28c-3-4-10-4-14 0l-28 28-28-28c-4-4-11-4-14 0l-29 28-28-28c-4-4-10-4-14 0L72 31 44 3a10 10 0 00-17 7v450c0 29 23 52 52 52h354c29 0 52-23 52-52V256c0-6-4-10-10-10zM79 492c-18 0-32-14-32-32V34l18 18c4 4 11 4 14 0l29-28 28 28c4 4 10 4 14 0l29-28 28 28c4 4 10 4 14 0l28-28 29 28c4 4 10 4 14 0l28-28 29 28c3 4 10 4 14 0l18-18v426c0 12 4 23 11 32H79zm386-32a32 32 0 01-64 0V266h64v194z"/>
                                        <path
                                            d="M214 159c-10 0-18-7-18-16 0-8 8-15 18-15s18 7 18 15a10 10 0 1020 0c0-16-12-30-28-34v-6a10 10 0 10-20 0v6c-16 4-28 18-28 34 0 20 17 36 38 36 10 0 18 7 18 15s-8 16-18 16-18-7-18-16a10 10 0 10-20 0c0 17 12 30 28 34v9a10 10 0 1020 0v-9c16-4 28-17 28-34 0-19-17-35-38-35zm100 0h-19a10 10 0 100 20h19a10 10 0 100-20zm-181 0h-19a10 10 0 100 20h19a10 10 0 100-20zm187 111H161a10 10 0 100 20h159a10 10 0 100-20zm-204 3a10 10 0 00-15 0 10 10 0 000 14 10 10 0 0015 0 10 10 0 000-14zm204 63H161a10 10 0 100 20h159a10 10 0 100-20zm-204 3a10 10 0 00-15 0 10 10 0 000 14 10 10 0 0015 0 10 10 0 000-14zm204 63H161a10 10 0 100 20h159a10 10 0 100-20zm-204 3a10 10 0 00-15 0c-1 2-3 4-3 7s2 5 3 7a10 10 0 0015 0l2-7s-1-5-2-7z"/>
                                    </svg>
                                    <div>
                                        Add to Bill
                                    </div>
                                </button>


                                <button onClick={() => setPayType('payNow')}
                                        className={payType === 'payNow' ? 'selectedType' : null}>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        version="1"
                                        viewBox="0 0 612 612"
                                    >
                                        <path
                                            d="M589 113L214 34c-16-3-32 7-35 23l-7 45 432 92 7-46c4-16-7-31-22-35zm-158 91c-4-16-21-25-37-21l-71 20-168-36-18 87-115 32c-16 5-25 21-21 37l65 234c4 16 21 25 37 21l372-104c16-4 25-21 21-36l-11-41 42 9c15 4 31-7 35-22l26-125-151-32-6-23zm43 119l9-44c2-7 8-11 15-9l43 9c7 1 11 8 10 14l-10 44c-1 6-8 11-14 9l-44-9c-6-1-10-8-9-14zM28 310l103-29 244-67 26-8h1c2 0 5 1 6 4l3 11 9 35L37 362l-13-46c0-2 1-6 4-6zm445 134l-1 4-3 3L96 554l-1 1c-2 0-5-2-6-5L52 414l383-106 23 84 15 52z"/>
                                        <path
                                            d="M156 453c-1-6-8-10-15-8l-43 12c-6 2-10 9-9 15l13 44c1 6 8 10 15 8l43-12c6-2 10-9 8-15l-12-44z"/>
                                    </svg>
                                    <div>
                                        Pay Now
                                    </div>
                                </button>
                            </div>
                        </div>


                        <div className='actionButton'>
                            <button className='buttonClass' onClick={() => setState('Select')}>Back</button>
                            <button className='buttonClass' disabled={!payType} onClick={() => setState('Confirm')}>Next
                            </button>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
}

export default ExtraGBPaymentType;

import {
    ERROR_GET_ADDONS_LIST,
    GET_ADDONS_LIST,
    SUCCESS_GET_ADDONS_LIST,
    SUCCESS_ADD_ADDONS_DATA
} from "../../Types";

const initialState = {
    loading: true,
    dataBundle: {},
    error: ''
}

export default function dataAddOnsReducer(state = initialState, action) {
    switch (action.type) {

        case GET_ADDONS_LIST : {
            return {
                ...state,
                loading: true
            }
        }

        case SUCCESS_GET_ADDONS_LIST : {
            return {
                ...state,
                loading: false,
                dataBundle: action.payload
            }
        }

        case ERROR_GET_ADDONS_LIST : {
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        }

        case SUCCESS_ADD_ADDONS_DATA : {
            return {
                ...state,
                loading: false
            }
        }

        default :
            return state
    }
}

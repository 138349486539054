import React from 'react';
import alKuppiya from '../../assests/images/digitalLifeImg/ic_al_kuppiya.jpg'
import duthayaImg from '../../assests/images/digitalLifeImg/duthaya_logo.png'
import smartHome from '../../assests/images/digitalLifeImg/ic_smart_home.jpg'
import sltStorage from '../../assests/images/digitalLifeImg/ic_slt_storage.jpg'
import cctv from '../../assests/images/digitalLifeImg/ic_cctv.png'
import eSport from '../../assests/images/digitalLifeImg/ic_esport.jpg'
import kaspersky from '../../assests/images/digitalLifeImg/ic_kaspersky.jpg'
import eSiphala from '../../assests/images/digitalLifeImg/eSiphala-Logo.png'
import Lynked from '../../assests/images/digitalLifeImg/Lynked.png'
import {connect} from "react-redux";
import axios from "axios";
import {jwtURL} from "../../redux/URL";

export const CCTV = "https://www.slt.lk/en/cctv";
// export const LYNKED = "https://meet.slt.lk";
export const STORAGE = "https://storage.slt.lk/portal/register";
export const SMART_HOME = "https://www.slt.lk/en/smart-home";
export const E_SPORTS = "https://esports.slt.lk/landing";
// export const SLT_GO = "https://www.slt.lk/go";
// export const PEOTV_GO = "https://www.peotv.com/";
// export const SLT_HOT_DEVICES = "https://eteleshop.slt.lk/";
export const SLT_KUPPIYA = "https://www.alkuppiya.lk";
export const Lynked_SLT = "https://meet.sltmobitel.lk/";
export const eSiphala_SLT = "https://www.esiphala.lk/";
export const DUTHAYA = "https://duthaya.lk";
// export const SLT_KIMAKI = "https://play.google.com/store/apps/details?id=com.arimac.slt&hl=en&gl=US";
// export const SLT_HOSTING = "https://www.slt.lk/en/personal/internet/hosting-services/services-and-pricing";


function DigitalLife({profile}) {

    const getApi = () => {
        let body = {
            broadBandId: profile.subscriberid
        }
        axios.post(jwtURL, body).then(response => {
            if (response.status === 200) {
                let kasperyskyWebURL = "https://kaspersky-dp.slt.lk/landingpage?bbId=" + response.data.responseData.broadBandId + "&jwt=" + response.data.responseData.jwt;
                window.open(kasperyskyWebURL, "_blank")
            }
        })
    }

    return (
        <div className='mainBody'>
            <div className='digitalLife common-panel'>

                <div className='title'>
                    Digital Life
                </div>
                <div className='bodyView'>
                    <div className='header'>
                        Experience the Digital Lifestyle with SLT
                    </div>
                    <div className="item-set">
                        <div className="item">
                            <div onClick={() => window.open(DUTHAYA, "_blank")}>
                                    <div>Duthaya</div>
                                    <img src={duthayaImg} alt='duthaya'/>
                            </div>
                        </div>
                        {/* <div className="item">
                            <div onClick={() => window.open(SLT_KUPPIYA, "_blank")}>
                                <div>A/L Kuppiya</div>
                                <img src={alKuppiya} alt='alKuppiya'/>
                            </div>
                        </div> */}
                        <div className="item">

                            <div onClick={() => window.open(E_SPORTS, "_blank")}>
                                <div>eSport</div>
                                <img src={eSport} alt='eSport'/>
                            </div>
                        </div>
                        <div className="item">
                            <div onClick={() => getApi()}>
                                <div>Kaspersky</div>
                                <img src={kaspersky} alt='kaspersky'/>
                            </div>
                        </div>
                        <div className="item">
                            <div onClick={() => window.open(STORAGE, "_blank")}>
                                <div>SLT Storage</div>
                                <img src={sltStorage} alt='sltStorage'/>
                            </div>
                        </div>
                        <div className="item">
                            <div onClick={() => window.open(CCTV, "_blank")}>
                                <div>CCTV</div>
                                <img src={cctv} alt='cctv'/>
                            </div>
                        </div>
                        <div className="item">
                            <div onClick={() => window.open(SMART_HOME, "_blank")}>
                                <div>Smart Home</div>
                                <img src={smartHome} alt='smartHome'/>
                            </div>
                        </div>
                        <div className="item">
                            <div onClick={() => window.open(eSiphala_SLT, "_blank")}>
                                <div>eSiphala</div>
                                <img src={eSiphala} alt='smartHome'/>
                            </div>
                        </div>
                        <div className="item">
                            <div onClick={() => window.open(Lynked_SLT, "_blank")}>
                                <div>SLT Lynked</div>
                                <img src={Lynked} alt='smartHome'/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}


const mapStateToProps = state => ({
    loadingProfile: state.broadBand.loadingProfile,
    profile: state.broadBand.profile,
})

export default connect(mapStateToProps, {})(DigitalLife);
import React, {useEffect, useState} from 'react'
import axiosInstance from '../../../redux/axiosInstance';
import html2canvas from "html2canvas";
import {jsPDF} from "jspdf";
import logo from "../../../assests/images/logowithadd.jpg";
import ShowConditionLtePayment from './ShowConditionLtePayment';
import {numberWithCommas} from "../../../redux/action/bill/billHistory.action";
import {LTE_AMOUNT} from "../../../redux/MAPKEYS";
import { saveInvoice } from '../../../redux/URL';
import { CATCH_ERROR_MSG } from '../../../redux/Types';
import ErrorAlert from '../../alerts/ErrorAlert';
import { useHistory } from 'react-router-dom';
import Querystring from "querystring";


const LTEPayment = ({setState, userInfo, packageInfo, locationInfo, orderRef, chargeLTE}) => {

  const history = useHistory();

  const [agree1, setAgree1] = useState(false);
  const [agree2, setAgree2] = useState(false);
  const [agree3, setAgree3] = useState(false);

  const [bearerInvoce, setBearerInvoce] = useState(false);
  const [voiceInvoce, setVoiceInvoce] = useState(false);
  const [bbInvoce, setBBInvoce] = useState(false);

  const [errorList, setErrorList] = useState({});

  const [showCondition, setShowCondition] = useState(false);

  const cost = chargeLTE//LTE_AMOUNT

  // console.log(cost) 

  useEffect(() => {
    let errors = {};
    
    if(bearerInvoce && bbInvoce){
      if(packageInfo.voicePackages){
        if(voiceInvoce){
          history.push('/cart')
        }else{
          errors['voice'] = 'Save Voice Invoce failed'
          setErrorList(errors)
        }
      }else{
        history.push('/cart')
      }

    } else if (bearerInvoce && voiceInvoce){
      history.push('/cart')
    }
    
}, [voiceInvoce, bearerInvoce, bbInvoce])// eslint-disable-line react-hooks/exhaustive-deps

  function printDocument() {

    const input = document.getElementById('pdfView');



    var title = userInfo.title;
    var firstName = userInfo.fName;
    var lastName = userInfo.lName;
    var dob = userInfo.dateOfBirth;
    var gender = userInfo.gender;
    var nic = userInfo.nic ? userInfo.nic : userInfo.passport;
    var nationality = userInfo.nationality;
    var mobile = userInfo.mobile;
    var email = userInfo.email;

    var lat = locationInfo.lat;
    var lng = locationInfo.lng;

    var installationAddress1 = locationInfo.address1;
    var installationAddress2 = locationInfo.address2;

    // var googleLocation = "https://goo.gl/maps/Xkkr87VCR9HppxRU8";

    var googleLocation = "https://www.google.com/maps/?q="+lat+","+lng;
    var googleLocationView = "lat - "+lat+" /long - "+lng;

    var billingAddress1 = packageInfo.address1;
    var billingAddress2 = packageInfo.address2;

    var existTelAccNo = ""

    var broadbandPackage = packageInfo.bbPackages;

    var PeoTvPackage1 = packageInfo.peoTvPackages;
    var PeoTvPackage2 = "";
    var PeoTvPackage3 = "";
    var PeoTvPackage4 = "";

    var voice = packageInfo.voicePackages ? '1' : '0'
    var broadband = packageInfo.bbPackages ? '1' : '0'
    var peoTv = packageInfo.peoTvPackages ? '1' : '0'

    var billType = packageInfo.billType;//post eBill
    var isExistCus = userInfo.isExistCus;
    var activeIdd = userInfo.iddActivate;

    var myPhoneYes = "S";
    var myPhoneNo = "S";

    var exCusYes = "S";
    var exCuNo = "F";

    if(isExistCus === true){
        exCusYes = "F";
        exCuNo = "S";
    }

    var billMode_EBill = "S";
    var billMode_Print = "F";

    if(billType === "eBill"){
        billMode_EBill = "F";
        billMode_Print = "S";
    }

    var activeIddYes = "S";
    var activeIddNo = "F";

    if(activeIdd === true){
        activeIddYes = "F";
        activeIddNo = "S";
    }



    html2canvas(input)
        .then((canvas) => {

            const pdf = new jsPDF("p", "in", "a4");



            pdf.setFontSize(17);
            pdf.setFont("courier", "bold");
            pdf.text('SLT Application For New Services', 2.40,0.46)
            pdf.addImage(logo, "JPEG", 0.30, 0.15, 2.00, 0.70);

            pdf.setFontSize(14)
            pdf.setFont("courier", "bold");
           // pdf.text('Registration No : ' + regNo,2.40, 0.70)

            pdf.setLineWidth(0.01);
            pdf.line(0.30, 1.00, 8.00, 1.00, 'S');

            //sub headers
            pdf.text('Customer Information',0.30, 1.80)
            pdf.text('Contact Details',0.30, 4.00)
            pdf.text('Service Installation Address',0.30, 4.70)

            pdf.setFontSize(12)
            pdf.setFont("courier", "normal");

            // First Box Test
            pdf.text('Existing customer',0.50, 7.00)
            pdf.text('Yes',2.60, 7.00)
            pdf.text('No',3.40, 7.00)
            pdf.text('Existing ',4.10, 6.90)
            pdf.text('Tel/Acc No ',4.10, 7.10)
            pdf.text(existTelAccNo,5.30, 7.00)

            pdf.text('Your preferred billing',0.50, 7.35)
            pdf.text('mode',0.50, 7.55)
            pdf.text('SLT My Bill ',2.85, 7.45)
            pdf.text('E Bill ',4.35, 7.45)
            pdf.text('Printed ',5.45, 7.35)
            pdf.text('bill ',5.45, 7.55)

            pdf.setFontSize(14)
            pdf.setFont("courier", "bold");
            pdf.text('Mode of Connection',0.50, 8.20)

            pdf.setFontSize(12)
            pdf.setFont("courier", "normal");

            pdf.text('Voice(Telephone)',2.70, 8.45)
            pdf.text('Broadband',4.55, 8.45)
            pdf.text('Peo TV',6.25, 8.45)
            pdf.text('SLT LTE',1.00, 8.75)

            pdf.text(voice,3.40, 8.80)
            pdf.text(broadband,5.00, 8.80)
            pdf.text(peoTv,6.60, 8.80)

            pdf.setFontSize(14)
            pdf.setFont("courier", "bold");
            pdf.text('Package Details',0.50, 9.50)

            pdf.setFontSize(12)
            pdf.setFont("courier", "normal");

            pdf.text('Do you want to deactivate IDD ',0.70, 9.75)
            pdf.text('Yes ',4.50, 9.75)
            pdf.text('NO ',5.30, 9.75)

            pdf.text('My Phone ',0.70, 10.00)
            pdf.text('Yes ',2.70, 10.00)
            pdf.text('NO ',3.50, 10.00)

            pdf.text('Broadband Package ',0.70, 10.30)
            pdf.text(broadbandPackage,2.75, 10.30)
            pdf.text('Peo TV ',0.70, 10.60)
            pdf.text('Packages ',0.70, 10.80)

            pdf.setFontSize(10)

            pdf.text(PeoTvPackage1,1.75, 10.70)//Peo TV  Packages
            pdf.text(PeoTvPackage2,3.35, 10.70)
            pdf.text(PeoTvPackage3,4.95, 10.70)
            pdf.text(PeoTvPackage4,6.55, 10.70)



            //Check Box
            pdf.setFontSize(12)
            pdf.setFont("courier", "normal");

            pdf.text('Home  ',2.40, 1.35)
            pdf.text('Office  ',3.30, 1.35)
            pdf.text('Other  ',4.40, 1.35)
            pdf.text('CA Number  ',5.65, 1.35)


            pdf.text('Title  ',0.30, 2.25)
            pdf.text(title,2.45, 2.25)
            pdf.text('First Name  ',0.30, 2.60)
            pdf.text(firstName,2.45, 2.60)
            pdf.text('Last Name  ',0.30, 2.95)
            pdf.text(lastName,2.45, 2.95)
            pdf.text('Date of Birth  ',0.30, 3.30)
            pdf.text(dob,2.45, 3.30)
            pdf.text('Gender  ',4.00, 3.30)
            pdf.text(gender,5.30, 3.30)
            pdf.text('NIC / Passport ',0.30, 3.65)
            pdf.text(nic,2.45, 3.65)
            pdf.text('Nationality  ',4.00, 3.65)
            pdf.text(nationality,5.30, 3.65)

            pdf.text('Mobile  ',0.30, 4.35)
            pdf.text(mobile,2.45, 4.35)
            pdf.text('Email  ',4.00, 4.35)
            pdf.text(email,5.00, 4.35)

            pdf.text('Installation Address  ',0.30, 5.05)
            pdf.text(installationAddress1,2.45, 5.05)
            pdf.text(installationAddress2,2.45, 5.40)
            pdf.text('Google Location  ',0.30, 5.75)
            // pdf.text(googleLocation,2.45, 5.75)
            pdf.setTextColor(0,0,255)
            pdf.textWithLink(googleLocationView, 2.45, 5.75, { url: googleLocation });
            pdf.setTextColor(0,0,0)
            pdf.text('Billing Address  ',0.30, 6.10)
            pdf.text(billingAddress1,2.45, 6.10)
            pdf.text(billingAddress2,2.45, 6.45)

            // all boxes view in pdf
            pdf.setFillColor(0,0,0);
            pdf.setLineWidth(0.01)
            pdf.rect(7.10, 0.30, 0.80, 0.60 ); //top box

            pdf.rect(3.00, 1.20, 0.2, 0.2, 'F'); //home
            pdf.rect(4.10, 1.20, 0.2, 0.2, 'S'); //Office
            pdf.rect(5.10, 1.20, 0.2, 0.2); //Other

            pdf.rect(6.90, 1.15, 1.0,0.3) //ca number

            pdf.rect(2.40, 2.05, 2.0,0.3) //title
            pdf.rect(2.40, 2.40, 5.35,0.3) //fname
            pdf.rect(2.40, 2.75, 5.35,0.3) //lname
            pdf.rect(2.40, 3.10, 1.5,0.3) //dob
            pdf.rect(5.25, 3.10, 2.0,0.3) //gender
            pdf.rect(2.40, 3.45, 1.5,0.3) //nic
            pdf.rect(5.25, 3.45, 2.0,0.3) //nationalyty

            pdf.rect(2.40, 4.15, 1.5,0.3) //mobile
            pdf.rect(4.95, 4.15, 2.8,0.3) //email

            pdf.rect(2.40, 4.85, 5.35,0.3) //installatin address part1
            pdf.rect(2.40, 5.20, 5.35,0.3) //installatin address part1
            pdf.rect(2.40, 5.55, 5.35,0.3) //Google Location
            pdf.rect(2.40, 5.90, 5.35,0.3) //Billing Address  part 1
            pdf.rect(2.40, 6.25, 5.35,0.3) //Billing Address  part 2


            pdf.rect(0.30, 6.60, 7.70,1.2) //first form box

            pdf.rect(3.00, 6.85, 0.2,0.2,exCusYes) //Yes
            pdf.rect(3.70, 6.85, 0.2,0.2, exCuNo) //No
            pdf.rect(5.25, 6.80, 2.5,0.3) //Tel/Acc No

            pdf.rect(4.00, 7.30, 0.2,0.2) //SLT My Bill
            pdf.rect(5.00, 7.30, 0.2,0.2, billMode_EBill) //E Bill
            pdf.rect(6.25, 7.30, 0.2,0.2, billMode_Print) //Printed Bill

            pdf.rect(0.30, 7.90, 7.70,1.2) //second form box

            pdf.rect(2.70, 8.60, 1.5,0.3) //MOF Voice
            pdf.rect(4.30, 8.60, 1.5,0.3) //MOF Broadband
            pdf.rect(5.90, 8.60, 1.5,0.3) //MOF Peo TV

            pdf.rect(0.30, 9.20, 7.70,2.0) //third form box
            pdf.rect(4.85, 9.60, 0.2,0.2, activeIddYes) //Yes
            pdf.rect(5.55, 9.60, 0.2,0.2, activeIddNo) //No
            pdf.rect(3.05, 9.85, 0.2,0.2,myPhoneYes) //Yes
            pdf.rect(3.75, 9.85, 0.2,0.2,myPhoneNo) //No

            pdf.rect(2.70, 10.10, 2.0,0.3) //Broadband Packages

            pdf.rect(1.70, 10.50, 1.5,0.3) //Peo TV  Packages
            pdf.rect(3.30, 10.50, 1.5,0.3) //Peo TV  Packages
            pdf.rect(4.90, 10.50, 1.5,0.3) //Peo TV  Packages
            pdf.rect(6.50, 10.50, 1.4,0.3) //Peo TV  Packages






            /// add new page
            pdf.addPage("a4", "p");
            pdf.setFillColor(0,0,0);
            pdf.setLineWidth(0.01)
            pdf.rect(0.30, 0.50, 7.70,2.5) //Comment

            pdf.setFontSize(14)
            pdf.setFont("courier", "bold");
            pdf.text('Comment/Remark',0.50, 0.90)

            pdf.text('Customer Agreement',0.50, 3.50)

            var pageWidth = 8.5,
                lineHeight = 4.80,
                margin = 0.6,
                maxLineWidth = pageWidth - margin * 2,
                fontSize = 24,
                ptsPerInch = 72,
                oneLineHeight = (fontSize * lineHeight) / ptsPerInch,
                text ="I do hereby declare and confirm that the information above, furnished by me are true and correct. I confirm that I have received, read and understood the 'Terms and Conditions for the Provision of Telecommunication Services by Sri Lanka Telecom PLC' (SLT), which are also available on SLT website www.slt.lk. I shall abide by the said 'Terms and Conditions for the Provision of Telecommunication Services by Sri Lanka Telecom PLC' as may be revisedmodified and supplemented by SLT from time to time and morefully stated/updated under the said SLT website www.slt.lk.";

            var textLines =    pdf.setFontSize(12)
                .setFont("courier", "normal")
                .splitTextToSize(text, maxLineWidth);

            pdf.text(textLines, margin, margin + 2 * oneLineHeight);

            // pdf.text('Terms and Conditions http://www.slt.lk/en/terms-conditions',0.70, 5.60)
            pdf.setTextColor(0,0,255)
            pdf.textWithLink('Terms and Conditions http://www.slt.lk/en/terms-conditions', 0.70, 5.60, { url: 'http://www.slt.lk/en/terms-conditions' });
            pdf.setTextColor(0,0,0)
            pdf.text('I agree terms and Conditions ',1.05, 5.90)
            // pdf.text('Yes ',3.90, 5.90)
            // pdf.text('NO ',4.70, 5.90)

            pdf.rect(0.80, 5.75, 0.2,0.2,'F') //Yes
            // pdf.rect(5.00, 5.75, 0.2,0.2) //No

            let fileName = userInfo.nic ? userInfo.nic + '.pdf' : userInfo.passport + '.pdf'
            pdf.save(fileName);
        }) ;

  }

  const validation = () => {

    // if(packageInfo.bbPackages){
    //   saveBearerInvoce()
    // }else{
    //   let errors = {};
    //   errors['valid'] = 'It is required to select an internet package'
    //   setErrorList(errors)
    // }
    if(!packageInfo.bbPackages && packageInfo.connection === "cBroadBand"){
      let errors = {};
      errors['valid'] = 'It is required to select an internet package'
      setErrorList(errors)
      
    }else if(!packageInfo.voicePackages && packageInfo.connection === "cVoice") {
      let errors = {};
      errors['valid'] = 'It is required to select a Voice package'
      setErrorList(errors)
    }else if(!packageInfo.voicePackages && !packageInfo.bbPackages && packageInfo.connection === "cBoth") {
      let errors = {};
      errors['valid'] = 'It is required to select Both Voice and internet package'
      setErrorList(errors)
    }else{
      saveBearerInvoce()
    }
    
  }

  const saveBearerInvoce = () => {
    let body = Querystring['stringify']({ 
      'REF_NO': orderRef,
      'SERVICE_TYPE': 'BEARER',
      'PACKAGE_NAME': '',
      'PACKAGE_COUNT': 1,
      'RENTAL': null,
      'INI_CHARGE': cost,
      'STATUS': 1,
    })
    axiosInstance.post(saveInvoice, body).then((response) => {
      // console.log(response)
      if (response.data.isSuccess) {
        setBearerInvoce(true)  
        if(packageInfo.voicePackages){
          // console.log("voice log")
          saveVoiceInvoce()
        }
        if(packageInfo.bbPackages){
          // console.log("bbPackages log")
          saveBBInvoce()
        }
      } else {
        setBearerInvoce(false)
        let errors = {};
        errors['bearer'] = 'Save Bearer Invoce failed'
        setErrorList(errors)
      }
    }).catch(() => {
      let errors = {};
      errors['bearer'] = 'Save Bearer Invoce failed'
      setErrorList(errors)
      setBearerInvoce(false)
    })
  }

  const saveVoiceInvoce = () => {
    let body = Querystring['stringify']({ 
      'REF_NO': orderRef,
      'SERVICE_TYPE': 'VOICE',
      'PACKAGE_NAME': packageInfo.voicePackages,
      'PACKAGE_COUNT': 1,
      'RENTAL': packageInfo.voicePackagesData.MONTHLY_RENTAL,
      'INI_CHARGE': null,
      'STATUS': 1,
    })
    axiosInstance.post(saveInvoice, body).then((response) => {
      // console.log(response)
      if (response.data.isSuccess) {
        setVoiceInvoce(true)  
      } else {
        setVoiceInvoce(false)
        let errors = {};
        errors['voice'] = 'Save Voice Invoce failed'
        setErrorList(errors)
      }
    }).catch(() => {
      let errors = {};
      errors['voice'] = 'Save Voice Invoce failed'
      setErrorList(errors)
      setVoiceInvoce(false)
    })
  }

  const saveBBInvoce = () => {
    let body = Querystring['stringify']({ 
      'REF_NO': orderRef,
      'SERVICE_TYPE': 'BROADBAND',
      'PACKAGE_NAME': packageInfo.bbPackages,
      'PACKAGE_COUNT': 1,
      'RENTAL': packageInfo.bbPackagesData.MONTHLY_RENTAL,
      'INI_CHARGE': null,
      'STATUS': 1,
    })
    axiosInstance.post(saveInvoice, body).then((response) => {
      // console.log(response)
      if (response.data.isSuccess) {
        setBBInvoce(true)  
      } else {
        setBBInvoce(false)
        let errors = {};
        errors['broadband'] = 'Save broadband Invoce failed'
        setErrorList(errors)
      }
    }).catch(() => {
      let errors = {};
      errors['broadband'] = 'Save broadband Invoce failed'
      setErrorList(errors)
      setBBInvoce(false)
    })
  }

// console.log(packageInfo)

if(showCondition)
    return <div>
        <ShowConditionLtePayment setShowTerms={setShowCondition} setAgree={setAgree3}/>
    </div>

  return (
    <>
    <div className='LTEpaymentInformation'>
      <div className="payment-container">

        <div className='payment-container-upper'>
          <div className='header'>
            LTE Connection Fee (One Time)
          </div>
          <div className='header'>
            Rs. {cost}
          </div>
        </div>

        <div className='payment-container-medium'>

          {
            packageInfo.voicePackages ? 
              <div className="package-item-contianer">
                <div className="package-item ">
                  <div className="item-container voice">
                    <div className="name">{packageInfo.voicePackages}</div>
                    <div className="freemin">{packageInfo.voicePackagesData.FREE_MINUTES} </div>
                    <div className="freemin">(Free Minutes)</div>
                    <div className="price">{packageInfo.voicePackagesData.MONTHLY_RENTAL} LKR</div>
                    <div className="voice-icon"/>
                  </div>
                </div>
              </div>
            : null
          }

          {
            packageInfo.bbPackages ?
              <div className="package-item-contianer">
                <div className="package-item ">
                  <div className="item-container internet">
                    <div className="name">{packageInfo.bbPackages}</div>
                    <div
                      className="pkg-size">
                        {packageInfo.bbPackagesData.STANDARD_GB !== '0' ? packageInfo.bbPackagesData.STANDARD_GB : 'Unlimited Data'}
                        {packageInfo.bbPackagesData.FREE_GB !== '0' ? ' + ' + packageInfo.bbPackagesData.FREE_GB : null}
                        {packageInfo.bbPackagesData.STANDARD_GB !== '0' ? ' GB' : null}
                    </div>
                    <div
                        className="pkg-price">Rs.{packageInfo.bbPackages ? packageInfo.bbPackagesData.MONTHLY_RENTAL : 'N/A'} +
                          Tax
                    </div>
                    <div className="pkg-month">(Per Month)</div>
                    <div className="internet-icon"/>
                  </div>

                </div>
              </div>
            :null
          }

        </div>

        <div className='payment-container-down'>
          <div className='declaration'>
            <div className='header'>
              Declaration
            </div>

            <div className='conditions'>
              <input className='sign' type='checkbox' checked={agree1}
                     onChange={() => setAgree1(true)} id="agree1"/>
              <div className="condtion"><label for ="agree1">I read and downloaded copy of this <strong className='link'
                    onClick={() => printDocument()}
                    >Application</strong></label>
              </div>

            </div>

            <div className='conditions'>
              <input className='sign' type='checkbox' checked={agree2}
                     onChange={(e) => setAgree2(e.target.checked)}  id="agree2"/>
              <div  className="condtion"><label for ="agree2">I hereby declare that the information given above is true and accurate to the best of my
                  knowledge. In case any of the above information is found to be false or untrue or misleading
                  or
                  misrepresenting, I am aware that I may be held liable for it.</label>
              </div>
            </div>

            <div className='conditions'>
                <input className='sign' type='checkbox' checked={agree3}
                       onChange={(e) => setAgree3(e.target.checked)} id="agree3"/>
                <div  className="condtion"><label for ="agree3">I Agree to the general <strong className='link' onClick={()=>setShowCondition(true)}> Terms and Conditions</strong></label></div>
            </div>

          </div>
        </div>
       
        {errorList.valid ?
                  <div className="error-msg mt-2">{errorList.valid}</div> : null}
        {errorList.bearer ?
                  <div className="error-msg mt-2">{errorList.bearer}</div> : null}
        {errorList.voice ?
                  <div className="error-msg mt-2">{errorList.voice}</div> : null}
        {errorList.broadband ?
                  <div className="error-msg mt-2">{errorList.broadband}</div> : null}

      </div>

      <div className='container-footer'>
        <div className="action-container right">
          <button className="btn-back" onClick={() => setState(5)}>Back</button>
          {/* <form action='https://billpay.slt.lk/intpay/confirm.php' method='post'>
              <input hidden={true} name='ContactNumber' value={userInfo.mobile}/>
              <input hidden={true} name='CustEmail' value={userInfo.email}/>
              <input hidden={true} name='channel' value={'LTE'}/>
              <input hidden={true} name='AmountPay' value={cost}/>
              <input hidden={true} name='orderRefNo' value={orderRef}/>
              <input hidden={true} name='NIC' value={userInfo.nic}/>
              <input hidden={true} name='PassportNo' value={userInfo.passport}/>
              <button type='submit' disabled={!agree1 || !agree2 || !agree3 }>Pay Now</button>
          </form> */}
          <button className="btn-cart" onClick={() => validation() } disabled={!agree1 || !agree2 || !agree3 } >Add to Cart</button>
        </div>
      </div>

    </div>
    <div id='pdfView'/>
   </>
  )
}

export default LTEPayment
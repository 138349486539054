import React from "react";
import digitalLife from '../../assests/images/services/b/digital_life.png'
import hotDevices from '../../assests/images/services/b/hot_devices.png'
import newServices from '../../assests/images/services/b/new_services.png'
import bill from '../../assests/images/services/b/bill.png'
import complaint from '../../assests/images/services/b/complaints.png'
import {useHistory} from "react-router-dom";
import {connect} from "react-redux";
import { isPrepaid } from "../../redux/action/accountDetails.action";


function Services({accountList, serviceList}) {
    const history = useHistory();

    const isPrepaidAcc = isPrepaid(serviceList && serviceList)

    function classNameSet(link) {
        let word = window.location.href
        if (word.split("/").includes(link)) {
            return 'service-item selected'
        } else {
            return 'service-item'
        }

    }

    return (
        <div className='services clearfix'>
<div className="service-item-wrapper">
            <button className={classNameSet('newService')} onClick={() => history.push('/newService/new/fiber')}>
                {/*<Link to='/newService/new/Megaline'>*/}
                <img src={newServices} alt='newServices'/>
                <p className='item-label'>New Services</p>
                {/*</Link>*/}
            </button>
            </div>
<div className="service-item-wrapper">
            <button className={classNameSet('digitalLife')} onClick={() => history.push('/digitalLife')}>
                <img src={digitalLife} alt='digitalLife'/>
                <p className='item-label'>Digital Life</p>
            </button>

            </div>
<div className="service-item-wrapper">
            <button className={classNameSet('hotdevice')} onClick={() => window.open('https://eteleshop.slt.lk/', "_blank")}>
                <img src={hotDevices} alt='hotDevices'/>
                <p className='item-label'>Hot Device</p>
            </button>

</div>
<div className="service-item-wrapper">

            <button className={classNameSet('bill')} disabled={!accountList || accountList.length === 0 || isPrepaidAcc}
                    onClick={() => isPrepaidAcc ? null :history.push('/bill/0')}>
                <img src={bill} alt='bill'/>
                <p className='item-label'>{isPrepaidAcc ? 'Reload' : 'Bill'}</p>
            </button>

</div>
<div className="service-item-wrapper">

            <button className={classNameSet('complaints')} disabled={!accountList || accountList.length === 0 || isPrepaidAcc}
                    onClick={() => history.push('/complaints')}>
                <img src={complaint} alt='complaint'/>
                <p className='item-label'>Complaints</p>
            </button>

</div>
<div className="service-item-wrapper">

            <button className={classNameSet('more')} disabled={!accountList || accountList.length === 0}>
                <div className="item-more">
                    <p className='more'>More</p>
                    <i className="fa fa-caret-down "/>
                </div>
                <div className="dropdown-content" hidden={true}>
                    {/*<a>Link 1</a>*/}
                    {/*<a>Link 2</a>*/}
                    {/*<a>Link 3</a>*/}
                </div>
            </button>

</div>

        </div>
    )
}

const mapStateToProps = state => ({
    accountList: state.accountDetails.account_Details,
    serviceList: state.accountDetails.service_list,
})
export default connect(mapStateToProps, {})(Services);

import React, {useEffect, useState} from 'react';
import userLog from "../../assests/images/naBarUserLogo.png";
import {
    getViewUserInfoUrl,
    OTPRequest,
    terminateProfileUrl,
    updatesContacttnfoUrl
} from "../../redux/URL";
import axiosInstance from "../../redux/axiosInstance";
import ErrorAlert from "../alerts/ErrorAlert";
import {CATCH_ERROR_MSG} from "../../redux/Types";
import LoadingBbBody from "../boardBand/LoadingBbBody";
import {connect} from "react-redux";
import {logOut} from "../../redux/action/login.action";
import OtpRequestProfile from "./OtpRequestProfile";
import SuccessAlert from "../alerts/SuccessAlert";
import {useHistory} from "react-router-dom";


const Querystring = require('querystring');

const MyProfile = ({logOut}) => {

    const [alertShow, setAlertShow] = useState(false);
    const [password, setPassword] = useState('');

    const [uname, setUname] = useState('');
    const [altContact, setAltContact] = useState('');

    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [isError, setIsError] = useState(false);
    const [error, setError] = useState('');

    const [otpStatus, setOtpStatus] = useState(false);

    const history = useHistory();

    useEffect(() => {
        getUserDetails()
    }, []);


    const confirmAlert = () => {
        return <div className='alertView'>
            <div>
                <div className='title'>Notice</div>
                {
                    localStorage.getItem('userType') === 'EMAIL' || localStorage.getItem('userType') === 'MOBILE' ? <>
                        <div className='mainText'>
                            <div className="message">If you want to delete your profile,</div>
                        </div>
                        <div className='mainText' style={{marginTop: '-1.2rem'}}>
                            <div className="message">first enter password and then click
                                OK
                            </div>
                        </div>
                    </> : <div className='mainText'>
                        <div className="message">Are you sure to delete your profile</div>
                    </div>

                }

                {
                    localStorage.getItem('userType') === 'EMAIL' || localStorage.getItem('userType') === 'MOBILE' ?
                        <div className='inputField'>
                            <input type='password' placeholder='Enter your password'
                                   onChange={(e) => setPassword(e.target.value.trim())}/>
                        </div> : null
                }


                <div className='actionButton mt-2'>
                    <button className='medium blue mr-1' onClick={() => setAlertShow(false)}>Cancel</button>
                    <button className='medium blue ml-1' onClick={() => terminateProfile()}>Ok</button>
                </div>
            </div>
        </div>
    }

    const terminateProfile = () => {
        setLoading(true)
        let body = Querystring['stringify']({
            userName: localStorage.getItem('userName'),
            userType: localStorage.getItem('userType'),
            password: password
        })
        axiosInstance.post(terminateProfileUrl, body).then(response => {
            if (response.data.isSuccess) {
                setLoading(false)
                logOut(history)
            } else {
                setError(response.data.erroShow)
                setIsError(true)
                setLoading(false)
            }
        }).catch(() => {
            setError(CATCH_ERROR_MSG)
            setIsError(true)
            setLoading(false)
        })
    }

    const getUserDetails = () => {
        setLoading(true)
        axiosInstance.get(getViewUserInfoUrl).then(response => {
            if (response.data.isSuccess) {
                setLoading(false)
                setUname(response.data.dataBundle.name)
                setAltContact(response.data.dataBundle.altrContact)
            } else {
                setError(response.data.erroShow)
                setIsError(true)
                setLoading(false)
            }
        }).catch(() => {
            setError(CATCH_ERROR_MSG)
            setIsError(true)
            setLoading(false)
        })
    }

    const validate = (type) => {
        let emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        let mobileRegex = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im

        if (type === 'MOBILE') {

            if (!mobileRegex.test(altContact)) {
                setError('Enter valid mobile number')
                setIsError(true)
                return false
            } else
                return true
        } else {
            if (!emailRegex.test(altContact)) {
                setError('Enter valid email')
                setIsError(true)
                return false
            } else
                return true
        }
    }

    const sendOTPRequest = () => {
        let type = localStorage.getItem('userType') === 'MOBILE' ? 'EMAIL' : 'MOBILE'

        if (validate(type)) {
            setLoading(true)
            let url = OTPRequest + 'requestType=eBill&requestPeriod=10&otpSource=' + type + '&otpContact=' + altContact

            axiosInstance.post(url).then((response) => {

                if (response.status === 200 && response.data.isSuccess) {
                    setOtpStatus(true)
                    setLoading(false)
                } else {
                    setIsError(true)
                    setError(response.data.errorShow)
                    setLoading(false)
                }
            }).catch(() => {
                setError(CATCH_ERROR_MSG)
                setIsError(true)
                setLoading(false)
            })
        }

    }

    const updateContact = () => {
        let body = Querystring['stringify']({
            userName: localStorage.getItem('userName'),
            name: uname,
            altrContact: altContact
        })
        axiosInstance.post(updatesContacttnfoUrl, body).then(response => {
            if (response.data.isSuccess) {
                setLoading(false)
                setSuccess(true)
            } else {
                setError(response.data.erroShow)
                setIsError(true)
                setLoading(false)
            }
        }).catch(() => {
            setError(CATCH_ERROR_MSG)
            setIsError(true)
            setLoading(false)
        })
    }

    if (loading) {
        return <div className='mainBody eBill common-panel '>
            <div className="body-container">
                <div className='title'>
                    <p>My Profile</p>
                </div>
                <LoadingBbBody/>
            </div>
        </div>
    }
    if (otpStatus) {
        return <OtpRequestProfile error={error} setError={setError} setIsError={setIsError} isError={isError}
                                  setLoading={setLoading} setOtpStatus={setOtpStatus} altContact={altContact}
                                  isMail={localStorage.getItem('userType') === 'EMAIL'} updateContact={updateContact}
                                  sendOTPRequest={sendOTPRequest}/>
    }
    return (
        <div className='mainBody eBill common-panel '>
            <div className="body-container">
                {alertShow ? confirmAlert() : null}
                {success ?
                    <SuccessAlert setSuccess={setSuccess}
                                  msg={'You have successfully update your ' + (localStorage.getItem('userType') === 'MOBILE' ? 'EMAIL' : 'MOBILE')}/> : null}
                {isError ? <ErrorAlert error={error} setError={setError} setIsError={setIsError}/> : null}
                <div className='title'>
                    <p>My Profile</p>
                </div>

                <div className='wrapper'>
                    <div className='content sameMargin'>
                        <div className='card p-3'>
                            <img className='user'
                                 src={localStorage.getItem('userImage') ? localStorage.getItem('userImage') : userLog}
                                 alt='userLogo'/>
                            <div className='text-center mt-2'>{localStorage.getItem('userName')}</div>
                            <div className='text-center'>You are logged as a {localStorage.getItem('userType')} user
                            </div>
                            <div className='d-flex justify-content-center mt-2'>
                                {/*<AiFillDelete size={23} onClick={() => setAlertShow(true)}*/}
                                {/*                                       color='#ef2626' className='pointer'/>*/}
                                {/*<div className='imgDelete' onClick={() => setAlertShow(true)}/>*/}
                                <svg onClick={() => {
                                    setAlertShow(true)
                                }}
                                     xmlns="http://www.w3.org/2000/svg"
                                     width="20"
                                     height="20"
                                     fill="none"
                                     viewBox="0 0 183 192"
                                >
                                    <path fill="#3388C0" d="M39.715 174h103.57L149 51H34l5.715 123z"/>
                                    <path
                                        fill="#49ACD6"
                                        d="M175.375 34.56h-30.5v-19.2c0-8.472-6.839-15.36-15.25-15.36h-76.25c-8.411 0-15.25 6.888-15.25 15.36v19.2h-30.5C3.407 34.56 0 37.992 0 42.24v7.68c0 1.056.858 1.92 1.906 1.92h14.392l5.886 125.52c.381 8.184 7.1 14.64 15.226 14.64h108.18c8.149 0 14.845-6.432 15.226-14.64l5.886-125.52h14.392A1.919 1.919 0 00183 49.92v-7.68c0-4.248-3.407-7.68-7.625-7.68zM55.281 17.28h72.438v17.28H55.281V17.28zm88.474 157.44H39.245L33.478 51.84h116.043l-5.766 122.88z"
                                    />
                                    <path
                                        fill="#49ACD6"
                                        d="M58.328 73.943H65.532V155.99099999999999H58.328z"
                                    />
                                    <path
                                        fill="#49ACD6"
                                        d="M87.846 73.943H95.05V155.99099999999999H87.846z"
                                    />
                                    <path
                                        fill="#49ACD6"
                                        d="M117.363 73.943H124.567V155.99099999999999H117.363z"
                                    />
                                </svg>
                            </div>
                        </div>

                    </div>

                    <div className='content'>
                        <div className='card p-3'>
                            {
                                localStorage.getItem('userType') === 'MOBILE' ? <div>
                                    <label>Enter Your EMAIL</label>
                                    <input type='email' value={altContact}
                                           onChange={(e) => setAltContact(e.target.value.trim())}/>
                                </div> : <div>
                                    <label>Enter Your MOBILE</label>
                                    <input type='mobile' value={altContact}
                                           onChange={(e) => setAltContact(e.target.value.trim())}/>
                                </div>
                            }


                            <div className='mt-3'>
                                <label>Name</label>
                                <input type='text' value={uname} onChange={(e) => setUname(e.target.value)}/>
                            </div>

                            <div className='mt-3 d-flex justify-content-end'>
                                <button className='medium blue'
                                        onClick={() => sendOTPRequest()}>{localStorage.getItem('userType') === 'MOBILE' ? 'UPDATE' : 'UPDATE'}</button>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>

    );
}

export default connect(null, {logOut})(MyProfile);

import React from 'react'

const ShowConditionLtePayment = ({setShowTerms, setAgree}) => {
  return (
    <div className='termConditon'>
        <div className='bodyList'>
            <iframe 
                src="https://bannerportal.slt.lk/LTE/terms_of_use.php"  
                width="100%" 
                height="600" 
                title="TERMS AND CONDITIONS"
            >
            </iframe>

            <div className='text-center'>
                <button className='medium blue' onClick={() => {
                    setShowTerms(false)
                    setAgree(true)
                }}>Accept
                </button>
                &nbsp; &nbsp; &nbsp;
                <button className='medium blue' onClick={() => {
                    setShowTerms(false)
                    setAgree(false)
                }}>Close
                </button>


            </div>
        </div>
    </div>
  )
}

export default ShowConditionLtePayment
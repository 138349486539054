import React from 'react'
import { SingaleAccordion } from './SingaleAccordion';

const BillAndPayment = () => {

    const accordionData = [
        {
            title: 'How do I find out the current bill balance?',
            content: <ul>
                    <li>You can access your bill summary information on the home screen of SLT Selfcare app.</li>
                    <li>Call Automated self IVR 0112121212 </li>
                </ul>
          },
          {
            title: 'How to pay a bill online? ',
            content: <ul>
                    <li>You can use the pay online option on SLT Selfcare App</li>
                    <li>You can also pay online using our web based portal via  
                        <a href="https://www.slt.lk/en/payonline"> https://www.slt.lk/en/payonline  </a> 
                    </li>
                </ul>
          },
          {
            title: ' How long does it take to reconnect a disconnected connection?',
            content: <ul>
                    <li> If the required balance is paid, a disconnected connection will be activated within an hour </li>
                </ul>
          },
          {
            title: ' How do I obtain a detailed report of an SLT bill? ',
            content: <ul>
                    <li>Provide a written request to your closest Telecom office.     </li>
                    <li>A charge will be applicable according to the period of your requested details.</li>
                </ul>
          },
          {
            title: ' How do I report a fault regarding an SLT bill?',
            content: <ul>
                    <li>Dial 1212 from any telephone network to connect to our 24/7 Contact Centre </li>
                </ul>
          },
          {
            title: 'How do I receive the bill via Email?',
            content: <ul>
                    <li>You can receive monthly bills through email by registering to our e-Bill facility   </li>
                    <li>To register, just select the e-Bill tab on the main menu of the SLT Selfcare App    </li>
                </ul>
          }
    ];

    return (
        <div className = 'faqSubBody'>
            <div className='title'>
                <p>Bill & Payment </p>
            </div>
            {accordionData.map(({ title, content }) => (
                 <SingaleAccordion title={title} content={content} />
                ))}
        </div>
    )
}

export default BillAndPayment

import React, {useEffect} from 'react'
import {connect} from 'react-redux';
import {Link, Redirect} from 'react-router-dom';
import {getUserComplaintsList} from '../../redux/action/complaints/complaints.action'
import LoadingBbBody from '../boardBand/LoadingBbBody';
import { isPrepaid } from '../../redux/action/accountDetails.action';


const ComplaintsList = (props) => {

    useEffect(() => {
        getUserComplaintsList(props.getUserComplaintsList(localStorage.getItem('selectedAcc')))
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    if (isPrepaid(props.serviceList && props.serviceList))
        return <Redirect to='/prepaid/boardBand'/>

    return (
        <div className="mainBody complaintList common-panel">
            <div className='title'>
               Complaints
            </div>
            <div className="complaintListContanner">
                {
                    props.loading ? (
                        <LoadingBbBody loading={props.loading}/>
                    ) : (
                        props.error ? null : (
                            props.complaintList.asset ? (

                                props.complaintList.asset.map((val, index) => {
                                    return val.fault ? (
                                        <div key={index} className="complaintContanner">
                                            <p className="packageName">{val.serviceName} <em
                                                className="complaintId">{val.fault[0].faultRef}</em></p>
                                            <p className="packageName">{val.fault[0].status} </p>
                                            <p className="openDate">{val.fault[0].date}</p>
                                        </div>
                                    ) : null

                                })


                            ) : (
                                <Redirect to='/add_complaint'/>
                                // history.push('/add_complaint')
                                // <h6>{props.complaintList.errorMessage}</h6>
                                // <h6></h6>
                            )
                        )
                    )

                }

                <div className="complaintButtonContanner" hidden={props.loading}>
                    <Link to='/add_complaint'>
                        <button className="complaintButton">Submit Your Complaint</button>
                    </Link>

                </div>
            </div>

        </div>
    )
}

const mapStateToProps = state => ({
    complaintList: state.complaint.complaintList,
    loading: state.complaint.loading,

    error: state.complaint.error,
    // bonus_Data: state.broadBand.bonusData,
    serviceList: state.accountDetails.service_list,
    profile: state.broadBand.profile

})

export default connect(mapStateToProps, {getUserComplaintsList})(ComplaintsList)

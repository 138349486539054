import React, {useState} from 'react';
import ProgressBar from 'react-customizable-progressbar';

function ProgressBarView(props) {
    const [viewType, setViewType] = useState(true);

    return (
        <ProgressBar
            radius={100}
            progress={props.progress}
            strokeWidth={19}
            strokeColor="#3ccd6a"
            trackStrokeColor="#3077b4"
            trackStrokeWidth={16}

        >

            <div className='indicator'>
                <p className='progress-count' onClick={()=> setViewType(!viewType)}> {viewType ? props.progress + '%' : props.word + '' + props.unit} </p>
                <p className='label'>  REMAINING</p>

            </div>

        </ProgressBar>
    );
}

export default ProgressBarView;

import { getNewConInitiationCharges } from "../../URL";
import {dispatchError, dispatchSuccess, loadingDispatch} from "../../DispatchFunctions";
import { CATCH_ERROR_MSG, LOADING_GET_PAYMENT, SUCCESS_GET_PAYMENT, ERROR_GET_PAYMENT } from "../../Types";
import axiosInstance from "../../axiosInstance";

export function getCharge() {

    return (dispatch) => {
        dispatch(loadingDispatch(LOADING_GET_PAYMENT))
        axiosInstance.get(getNewConInitiationCharges).then((response) => {
            if (response.data.isSuccess && response.data.dataBundle.Results.length > 0){
                dispatch(dispatchSuccess(SUCCESS_GET_PAYMENT, response.data.dataBundle.Results))
                // dispatch(getPaymentLogOfLte(response.data.dataBundle[0].reF_NO))
            }
            else {
                dispatch(dispatchError(ERROR_GET_PAYMENT, response.data.errorShow))
            }
        }).catch(() => {
            dispatch(dispatchError(ERROR_GET_PAYMENT, CATCH_ERROR_MSG))
        })
    }
}
import React, {useState} from 'react';
import {connect} from "react-redux";
import axiosInstance from "../../../redux/axiosInstance";
import {UnsubscribeAdvancedReportsUrl} from "../../../redux/URL";
import {getProfile} from "../../../redux/action/broadBand.action";
import LoadingBbBody from "../LoadingBbBody";
import SuccessAlert from "../../alerts/SuccessAlert";
import ErrorAlert from "../../alerts/ErrorAlert";
import ConfirmAlert from "../../alerts/ConfirmAlert";
import {CATCH_ERROR_MSG} from "../../../redux/Types";

function UnSubcribeDetailsReport({profile, getProfile}) {

    const [loading, setLoading] = useState(false)

    const [confirmShow, setConfirmShow] = useState(false);
    const [success, setSuccess] = useState(false);
    const [isError, setIsError] = useState(false);
    const [error, setError] = useState('');

    const unSubscribe = () => {
        setLoading(true)
        setError('')
        let url = UnsubscribeAdvancedReportsUrl + 'subscriberID=' + profile.subscriberid + '&activatedBy=' + profile.subscriberid
        axiosInstance.post(url).then(response => {
            if (response.status === 200 && response.data.isSuccess) {
                setSuccess(true)
                setLoading(false)
                getProfile(profile.subscriberid)
            } else {
                setError(response.data.errorShow)
                setIsError(true)
                setLoading(false)
            }

        }).catch(Onerror => {
            setError(CATCH_ERROR_MSG)
            setIsError(true)
            setLoading(false)
        })
    }


    if (loading) {
        return (
            <div className='subscribeDetailReport common-panel'>
                <div className='body-container'>
                    <div className='title'>Unsubscribe for detailed reports</div>
                    <LoadingBbBody loading={loading} border={false}/>
                </div>

            </div>
        )
    } else if (profile.priviledges.purchase_protocol_report) {
        return (
            <div className='subscribeDetailReport common-panel'>
                {confirmShow ? <ConfirmAlert setConfirmShow={setConfirmShow} onClick={unSubscribe}
                                             msg={'Do you want to unsubscribe usage report ?'}/> : null}

                {success ?
                    <SuccessAlert setSuccess={setSuccess}
                                  msg='Successfully unsubscribe for daily usage report'/> : null}

                {isError ? <ErrorAlert error={error} setError={setError} setIsError={setIsError}/> : null}
                <div className='body-container'>
                    <div className='title'>Unsubscribe for detailed reports</div>
                    <div className='wrapper'>

                        <div className='confirmPayment'>
                            <div className='topDetails'>Unsubscribe to usage report</div>

                            <div className='text-center mt-5'>
                                <button className='medium blue' onClick={() => setConfirmShow(true)}>Submit</button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        );
    } else {
        return (
            <div className='subscribeDetailReport common-panel'>

                <div className='body-container'>
                    <div className='title'>Unsubscribe for detailed reports</div>
                    <div className='wrapper'>
                        <div className='confirmPayment'>
                            <div className='topDetails'>You can't unsubscribe till end of month</div>
                        </div>
                    </div>
                </div>

            </div>
        )
    }


}

const mapStateToProps = state => ({
    loadingProfile: state.broadBand.loadingProfile,
    profile: state.broadBand.profile,
})

export default connect(mapStateToProps, {getProfile})(UnSubcribeDetailsReport);

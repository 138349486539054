import React from 'react';
import noBbAccountImage from '../../assests/images/nobbAcccountPng.png'
import {useHistory} from "react-router-dom";
function NoBroadBandAccount(props) {
    const history = useHistory();

    return (
        <div className='mainBody noBroadbandAccount'>
            <div>
                <img src={noBbAccountImage} alt='no broadband account'/>

                <div className='mt-2'>Currenty You Dont Have Broadband Service</div>

                <div className='lastLine mt-3'>
                    <div>Request For Broadband</div>
                    <button className='blue'  onClick={() => history.push('/newService/upgrade/Broadband')}>Request</button>
                </div>
            </div>
        </div>
    );
}

export default NoBroadBandAccount;
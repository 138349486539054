import axiosInstance from "../../axiosInstance";
import {dispatchError, dispatchSuccess, loadingDispatch} from "../../DispatchFunctions";
import {
    CATCH_ERROR_MSG,
    ERROR_GET_BILL_HISTORY,
    GET_BILL_HISTORY,
    SUCCESS_GET_BILL_HISTORY,
} from "../../Types";
import {getBillHistoryRequestUrl} from "../../URL";

export default function getBillHistory(telephoneNo, accountNo){
    let url = getBillHistoryRequestUrl + 'telephoneNo=' + telephoneNo + '&accountNo=' + accountNo

    return (dispatch) => {
        dispatch(loadingDispatch(GET_BILL_HISTORY))
        axiosInstance.get(url).then((response) => {

            if(response.status=== 200 && response.data.isSuccess){

                dispatch(dispatchSuccess(SUCCESS_GET_BILL_HISTORY, response.data.dataBundle))
            }else {

                dispatch(dispatchError(ERROR_GET_BILL_HISTORY, response.data.errorShow))
            }
        }).catch((error) => {
            dispatch(dispatchError(ERROR_GET_BILL_HISTORY, CATCH_ERROR_MSG))
        })
    }

}

export function numberWithCommas(x) {
    var parts = x.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
}

import React from 'react';
import { DATE_CAN_UPDATE, DATE_NOT_SET } from './HappyDay';

function HappyDayTitle({status, date}) {
    if(status === DATE_NOT_SET)
        return <div>Choose your Happy Day and enjoy <div className='highlight'>Unlimited Data, FREE</div> for the entire day.</div>
    else if(status === DATE_CAN_UPDATE)
        return <div>You have set your Happy Day <div className='date_text'>{`as ${date}`}</div> 
            </div>
    return <div>You have successfully redeemed your Happy Day <div className='date_text'>{`on ${date}`}</div> 
    </div>
    
}

export default HappyDayTitle
import React, {useEffect, useState} from "react";
import backIcon from "../../assests/images/loginUI/back.png";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import {resendOTP, setPublicActionToInitialState} from "../../redux/action/login.action";
import LoadingBbBody from "../boardBand/LoadingBbBody";
import ErrorAlert from "../alerts/ErrorAlert";

function ForgotPassword(props) {
    const [userName, setUserName] = useState("");
    const [userNameError, setUserNameError] = useState('');

    const [isError, setIsError] = useState(false);
    const [error, setError] = useState('');

    useEffect(() => {
        props.setPublicActionToInitialState()
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    const checkStatus = (value) => {
        setUserNameError('')
        let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        let regExp = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im
        if (re.test(value))
            return 'EMAIL'
        else if (value.match(regExp)) {
            return 'MOBILE'
        } else {
            setUserNameError('Enter valid email or mobile ')
            return false
        }

    }

    const submit = () => {
        if (checkStatus(userName) !== false) {
            localStorage.setItem('userType', checkStatus(userName))
            localStorage.setItem('userName', userName)
            props.resendOTP(props.history, setError, setIsError)
        }
    }

    if (props.loading)
        return <LoadingBbBody/>

    return (
        <div>
            {isError ? <ErrorAlert error={error} setError={setError} setIsError={setIsError}/> : null}
            <div className='form d-block m-auto  login-form'>
                <p className='titles'>Forgot Password</p>

                <p className='message'>Enter your Registered email Address or Mobile Number and we
                    will send an OTP to Reset your password.</p>

                <div className="input-group">
                    <div className={userNameError ? "input-container error" : "input-container"}>

                        <span className='error-message float-right'>{userNameError}</span>
                        <label className='d-block'>User ID <p className='d-inline red'>*</p></label>
                        <input type='text' className='pLeft' value={userName} placeholder='Email or mobile number'
                               onChange={(e) => setUserName(e.target.value.trim())}/>
                    </div>
                </div>
                <div className='mt-4'>
                    <button className='medium blue float-right'
                            onClick={(e) => submit(e)}>{props.loading ? 'Please wait...' : 'Submit'}</button>
                </div>


            </div>

            <Link to='/' className="btn-back">
                <div className='back'>
                    <img src={backIcon} alt='backLogo' className='back'/>
                    <p>Back to sign in</p>

                </div>
            </Link>
        </div>
    )
}

const mapStateToProps = state => ({
    loading: state.login.loading,
    error: state.login.error
})
export default connect(mapStateToProps, {resendOTP, setPublicActionToInitialState})(ForgotPassword);

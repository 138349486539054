import React from 'react'

const Eligibility = () => {
  return (
    <svg width="240" height="151" viewBox="0 0 240 151" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_3076_10049)">
        <path d="M175.12 36.7735C175.12 51.2606 152.418 93.9843 139.401 117.29C138.519 118.871 137.228 120.188 135.664 121.105C134.099 122.022 132.318 122.506 130.503 122.506C128.689 122.506 126.907 122.022 125.343 121.105C123.778 120.188 122.488 118.871 121.605 117.29C108.589 93.9843 85.8867 51.2606 85.8867 36.7735C85.8867 16.4641 105.862 0 130.503 0C155.144 0 175.12 16.4641 175.12 36.7735Z" fill="#0056A3"/>
        <path d="M130.712 60.5923C144.132 60.5923 155.011 49.741 155.011 36.3552C155.011 22.9695 144.132 12.1182 130.712 12.1182C117.293 12.1182 106.414 22.9695 106.414 36.3552C106.414 49.741 117.293 60.5923 130.712 60.5923Z" fill="white"/>
        <path d="M29.3174 58.286C37.3551 58.286 43.871 51.7865 43.871 43.769C43.871 35.7514 37.3551 29.252 29.3174 29.252C21.2796 29.252 14.7637 35.7514 14.7637 43.769C14.7637 51.7865 21.2796 58.286 29.3174 58.286Z" fill="#FF6584"/>
        <path d="M130.503 151C149.959 151 165.732 149.785 165.732 148.287C165.732 146.789 149.959 145.574 130.503 145.574C111.046 145.574 95.2734 146.789 95.2734 148.287C95.2734 149.785 111.046 151 130.503 151Z" fill="#6C63FF"/>
        <path opacity="0.4" d="M26.355 56.6788C27.0957 52.1258 23.9959 47.8359 19.4314 47.0971C14.867 46.3582 10.5663 49.4502 9.82556 54.0032C9.08484 58.5561 12.1846 62.846 16.7491 63.5849C21.3136 64.3237 25.6143 61.2318 26.355 56.6788Z" fill="#FFA617"/>
        <path d="M213.98 98.4236C148.044 73.7686 67.6252 97.8051 21.1945 146.973C20.9306 147.249 20.6667 147.529 20.4111 147.809H19.7031C19.9461 147.529 20.1975 147.249 20.4446 146.973C67.8389 94.0651 147.189 72.9788 213.98 98.4236Z" fill="#3F3D56"/>
        <path d="M231.999 106.602C226.171 103.501 220.153 100.77 213.98 98.4238C220.169 100.731 226.189 103.464 231.999 106.602Z" fill="#3F3D56"/>
        <path opacity="0.4" d="M195.912 82.0026C200.983 82.0026 205.095 77.9016 205.095 72.8426C205.095 67.7837 200.983 63.6826 195.912 63.6826C190.84 63.6826 186.729 67.7837 186.729 72.8426C186.729 77.9016 190.84 82.0026 195.912 82.0026Z" fill="#FFA617"/>
        <path d="M196.933 143.3C197.643 145.917 200.073 147.537 200.073 147.537C200.073 147.537 201.354 144.916 200.644 142.299C199.934 139.682 197.504 138.062 197.504 138.062C197.504 138.062 196.223 140.683 196.933 143.3Z" fill="#3F3D56"/>
        <path d="M197.976 142.737C199.923 144.628 200.161 147.535 200.161 147.535C200.161 147.535 197.242 147.376 195.295 145.485C193.347 143.594 193.109 140.688 193.109 140.688C193.109 140.688 196.029 140.846 197.976 142.737Z" fill="#6C63FF"/>
        <path d="M1.40813 106.917V110.953L1.20703 111.171V106.875C1.27406 106.888 1.34111 106.904 1.40813 106.917Z" fill="#3F3D56"/>
        <path d="M14.303 146.971C14.2443 147.067 14.1815 147.163 14.1186 147.259H1.20703V107.118L1.40813 106.917L3.12157 108.86V106.019H8.56774V115.036L14.0684 121.267L14.1186 121.326C14.1857 121.534 14.2485 121.743 14.3113 121.952C14.3118 121.956 14.3133 121.958 14.3155 121.961C15.1405 124.594 15.8174 127.271 16.3432 129.98C17.5413 136.369 17.3612 142.228 14.303 146.971Z" fill="#CCCCCC"/>
        <path d="M5.7399 129.105H2.27344V131.987H5.7399V129.105Z" fill="#3F3D56"/>
        <path d="M5.7399 134.292H2.27344V137.173H5.7399V134.292Z" fill="#3F3D56"/>
        <path d="M112.52 123.63L110.787 120.749L126.964 106.918C138.923 101.176 153.339 102.833 168.562 106.918V110.952L153.54 127.088L136.208 130.546L112.52 123.63Z" fill="#3F3D56"/>
        <path d="M175.722 115.035C178.595 111.848 178.994 108.817 175.722 106.019H170.276V108.862L168.562 106.919L154.696 120.75L152.385 123.055L149.496 134.581L154.696 147.259H181.272V121.326L175.722 115.035Z" fill="#CCCCCC"/>
        <path d="M110.787 120.749H154.696V147.258H110.787C105.475 141.526 108.235 131.077 110.787 120.749Z" fill="#F2F2F2"/>
        <path d="M148.631 129.105H139.387V137.174H148.631V129.105Z" fill="#3F3D56"/>
        <path d="M135.631 128.817H127.543V147.259H135.631V128.817Z" fill="#CCCCCC"/>
        <path d="M118.298 131.699V128.817H114.832V131.699H118.009H118.298Z" fill="#3F3D56"/>
        <path d="M118.009 134.004H114.832V136.886H118.298V134.004H118.009Z" fill="#3F3D56"/>
        <path d="M124.076 128.817H120.609V131.699H124.076V128.817Z" fill="#3F3D56"/>
        <path d="M124.076 134.004H120.609V136.885H124.076V134.004Z" fill="#3F3D56"/>
        <path d="M167.119 131.987V129.105H163.652V131.987H166.83H167.119Z" fill="#3F3D56"/>
        <path d="M166.83 134.292H163.652V137.173H167.119V134.292H166.83Z" fill="#3F3D56"/>
        <path d="M172.896 129.105H169.43V131.987H172.896V129.105Z" fill="#3F3D56"/>
        <path d="M172.896 134.292H169.43V137.173H172.896V134.292Z" fill="#3F3D56"/>
        <path d="M34.3378 75.2225L31.4492 72.917L47.626 58.5098H88.6457V71.1883L62.0697 89.0535L34.3378 75.2225Z" fill="#3F3D56"/>
        <path d="M96.1246 66.5916V58.7977H90.6784V60.7066L88.6457 58.5098L75.3575 72.917L70.1581 76.375L62.0957 135.13L74.2545 147.258H102.512C106.841 125.534 110.275 103.216 102.512 73.4933L96.1246 66.5916Z" fill="#CCCCCC"/>
        <path d="M31.4498 72.917H75.3584V147.258H31.4498C26.645 118.377 24.2984 91.5943 31.4498 72.917Z" fill="#F2F2F2"/>
        <path d="M56.5806 129.105H48.4922V147.259H56.5806V129.105Z" fill="#CCCCCC"/>
        <path d="M39.8276 134.292V131.411H36.3613V134.292H39.539H39.8276Z" fill="#3F3D56"/>
        <path d="M39.539 136.598H36.3613V139.479H39.8276V136.598H39.539Z" fill="#3F3D56"/>
        <path d="M45.6051 131.41H42.1387V134.292H45.6051V131.41Z" fill="#3F3D56"/>
        <path d="M63.2265 136.598H60.0488V139.479H63.5151V136.598H63.2265Z" fill="#3F3D56"/>
        <path d="M69.2926 131.41H65.8262V134.292H69.2926V131.41Z" fill="#3F3D56"/>
        <path d="M69.2926 136.598H65.8262V139.479H69.2926V136.598Z" fill="#3F3D56"/>
        <path d="M87.7788 76.6635V73.7822H84.3125V76.6635H87.4901H87.7788Z" fill="#3F3D56"/>
        <path d="M87.4901 78.9688H84.3125V81.85H87.7788V78.9688H87.4901Z" fill="#3F3D56"/>
        <path d="M93.5563 73.7812H90.0898V76.6627H93.5563V73.7812Z" fill="#3F3D56"/>
        <path d="M93.5563 78.9688H90.0898V81.8502H93.5563V78.9688Z" fill="#3F3D56"/>
        <path d="M87.7788 92.7997V89.918H84.3125V92.7997H87.4901H87.7788Z" fill="#3F3D56"/>
        <path d="M87.4901 95.1045H84.3125V97.9858H87.7788V95.1045H87.4901Z" fill="#3F3D56"/>
        <path d="M93.5563 89.918H90.0898V92.7994H93.5563V89.918Z" fill="#3F3D56"/>
        <path d="M93.5563 95.1045H90.0898V97.9859H93.5563V95.1045Z" fill="#3F3D56"/>
        <path d="M87.7788 108.935V106.054H84.3125V108.935H87.4901H87.7788Z" fill="#3F3D56"/>
        <path d="M87.4901 111.24H84.3125V114.122H87.7788V111.24H87.4901Z" fill="#3F3D56"/>
        <path d="M93.5563 106.054H90.0898V108.935H93.5563V106.054Z" fill="#3F3D56"/>
        <path d="M93.5563 111.24H90.0898V114.122H93.5563V111.24Z" fill="#3F3D56"/>
        <path d="M87.7788 125.071V122.189H84.3125V125.071H87.4901H87.7788Z" fill="#3F3D56"/>
        <path d="M87.4901 127.377H84.3125V130.258H87.7788V127.377H87.4901Z" fill="#3F3D56"/>
        <path d="M93.5563 122.19H90.0898V125.072H93.5563V122.19Z" fill="#3F3D56"/>
        <path d="M93.5563 127.377H90.0898V130.258H93.5563V127.377Z" fill="#3F3D56"/>
        <path d="M36.3613 76.6631H69.8705V83.5786C59.1298 80.6218 47.9293 80.8339 36.3613 83.5786V76.6631Z" fill="#3F3D56"/>
        <path d="M36.3613 90.4941C46.8199 93.5677 57.9896 93.5677 69.8705 90.4941V97.4096H36.3613V90.4941Z" fill="#3F3D56"/>
        <path d="M36.3613 104.325H69.8705V111.241C58.4507 107.603 47.2692 107.432 36.3613 111.241V104.325Z" fill="#3F3D56"/>
        <path d="M36.3613 118.156C47.2897 122.357 58.4695 122.183 69.8705 118.156V125.072H36.3613V118.156Z" fill="#3F3D56"/>
        <path d="M216.456 106.142H240.001V117.458L237.897 118.114L230.901 120.292L219.925 117.756L208.962 115.222L203.172 113.884L200.281 112.817C202.686 109.526 207.06 106.937 216.456 106.142Z" fill="#3F3D56"/>
        <path d="M240 112.817V147.259H200.281C200.268 147.238 200.26 147.219 200.247 147.198V147.196C200.238 147.172 200.227 147.148 200.214 147.126C199.594 145.881 199.038 144.639 198.546 143.401C194.378 132.921 194.83 122.718 200.281 112.817H240Z" fill="#F2F2F2"/>
        <path d="M225.413 129.105H217.324V147.259H225.413V129.105Z" fill="#CCCCCC"/>
        <path d="M208.658 134.292V131.411H205.191V134.292H208.369H208.658Z" fill="#3F3D56"/>
        <path d="M208.369 136.598H205.191V139.479H208.658V136.598H208.369Z" fill="#3F3D56"/>
        <path d="M214.435 131.41H210.969V134.292H214.435V131.41Z" fill="#3F3D56"/>
        <path d="M214.435 136.598H210.969V139.479H214.435V136.598Z" fill="#3F3D56"/>
        <path d="M232.345 134.292V131.411H228.879V134.292H232.057H232.345Z" fill="#3F3D56"/>
        <path d="M232.057 136.598H228.879V139.479H232.345V136.598H232.057Z" fill="#3F3D56"/>
        <path d="M238.123 131.41H234.656V134.292H238.123V131.41Z" fill="#3F3D56"/>
        <path d="M238.123 136.598H234.656V139.479H238.123V136.598Z" fill="#3F3D56"/>
        <path d="M208.963 92.7695H213.942C218.853 92.7695 223.563 94.7156 227.036 98.1795C230.509 101.644 232.46 106.342 232.46 111.24H208.963V92.7695Z" fill="#3F3D56"/>
        <path d="M58.1445 42.624H63.1239C68.0351 42.624 72.7451 44.5701 76.2179 48.034C79.6906 51.498 81.6416 56.1962 81.6416 61.095H58.1445V42.624Z" fill="#3F3D56"/>
        <path d="M137.742 89.0088H142.722C147.633 89.0088 152.343 90.9548 155.816 94.4188C159.288 97.8828 161.239 102.581 161.239 107.48H137.742V89.0088Z" fill="#3F3D56"/>
        <path d="M232.46 118.156H208.963V125.072H232.46V118.156Z" fill="#3F3D56"/>
        <path d="M240 146.973H1.20703V147.808H240V146.973Z" fill="#3F3D56"/>
        <path d="M23.2304 113.958H22.3926V146.971H23.2304V113.958Z" fill="#3F3D56"/>
        <path d="M30.9622 118.166C31.0156 129.709 22.8735 139.105 22.8735 139.105C22.8735 139.105 12.4465 129.585 14.5916 118.241C16.7489 106.833 23.4726 97.3502 22.6803 97.3018C22.6803 97.3018 30.9089 106.622 30.9622 118.166Z" fill="#2FD664"/>
        <path d="M107.018 113.958H106.18V146.971H107.018V113.958Z" fill="#3F3D56"/>
        <path d="M114.75 118.166C115.505 129.685 106.661 139.105 106.661 139.105C106.661 139.105 98.4325 129.785 98.3792 118.241C98.3258 106.697 106.468 97.3018 106.468 97.3018C105.674 100.466 114.139 108.845 114.75 118.166Z" fill="#2FD664"/>
        <path d="M52.582 60.2955L88.9767 58.5195L104.244 71.231L101.545 73.6618L77.3684 73.119L52.582 60.2955Z" fill="#3F3D56"/>
        <path d="M16.2673 119.286L14.3067 121.054L14.0679 121.267L13.5693 121.718L1.20654 121.442V106.875C0.804359 106.787 0.402178 106.704 0 106.624L1.00126 106.574L1.20654 106.746L3.12108 108.338L8.56724 112.876L14.6502 117.941L16.2673 119.286Z" fill="#3F3D56"/>
        <path d="M132.18 108.352L168.574 106.576L183.842 119.288L181.142 121.718L156.966 121.176L132.18 108.352Z" fill="#3F3D56"/>
        </g>
        <defs>
        <clipPath id="clip0_3076_10049">
        <rect width="240" height="151" fill="white"/>
        </clipPath>
        </defs>
    </svg>

  )
}

export default Eligibility
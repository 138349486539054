import React, { useEffect, useState } from "react";
import SelectPeoTvPackages from "./SelectPeoTVPackages";
import SelectBroadbandPackage from "./SelectBroadbandPackage";
import LoadingBbBody from "../../../boardBand/LoadingBbBody";
import {
  getBbPackagesListV2,
  getPeoTvPackagesListV2,
  getVoicePackagesListV2,
} from "../../../../redux/URL";
import ErrorAlert from "../../../alerts/ErrorAlert";
import axiosInstanceFtth from "../../../../redux/axiosInstanceFtth";
import SelectVoicePackage from "./SelectVoicePackage";

function PackageInformation({ packageInfo, setPackageInfo, setState }) {
  const [isOpenPeo, setIsOpenPeo] = useState(false);
  const [isOpenBb, setIsOpenBb] = useState(false);
  const [isOpenVoice, setIsOpenVoice] = useState(false);

  const [loading, setLoading] = useState(true);
  const [dataBB, setDataBB] = useState([]);
  const [selectedBBObj, setSelectedBBObj] = useState({});
  const [selectedVioceObj, setSelectedVioceObj] = useState({});
  const [dataPeo, setDataPeo] = useState([]);
  const [dataVioce, setDataVioce] = useState([]);
  const [selectedPeoObj, setSelectedPeoObj] = useState({});
  const [errorList, setErrorList] = useState({});

  const [isError, setIsError] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    let url = getBbPackagesListV2 + "Mode=SLT Fiber";
    axiosInstanceFtth
      .get(url)
      .then((response) => {
        if (response.status === 200) {
          setDataBB(...[response.data.dataBundle[0]]);
          setLoading(false);
          getPeoTvPackages();
          getVoicePackages();
        } else setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  const getPeoTvPackages = () => {
    let url = getPeoTvPackagesListV2 + "Mode=SLT Fiber";
    axiosInstanceFtth
      .get(url)
      .then((response) => {
        if (response.status === 200) {
          setDataPeo(...[response.data.dataBundle.Results]);
          setLoading(false);
        } else setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const getVoicePackages = () => {
    let url = getVoicePackagesListV2 + 'Mode=SLT Fiber'
    axiosInstanceFtth.get(url).then(response => {

        if (response.status === 200) {
            setDataVioce(...[response.data.dataBundle.Results])
            setLoading(false)
        } else
            setLoading(false)
    }).catch(() => {
        setLoading(false)
    })
  }

  const removeBBPacks = () => {
    setPackageInfo({ ...packageInfo, bbPackagesData: "" , bbPackages: "" });
    setSelectedBBObj({});
  };

  const removePeoPacks = () => {
    setPackageInfo({ ...packageInfo, peoPackagesData: "" , peoTvPackages: "" });
    setSelectedPeoObj({});
  };

  const removeVoicePacks = () => {
    setPackageInfo({...packageInfo, voicePackagesData: "", voicePackages: ''})
    setSelectedVioceObj({})
  }

  const handleChange = (event) => {
    const { name, value } = event.target;
    setPackageInfo({ ...packageInfo, [name]: value });
  };

  const validation = () => {
    let errors = {};
    let formIsValid = true;

    if (!packageInfo.voicePackages) {
      setError('Please select a Voice package.')
      setIsError(true)
      formIsValid = false
    }else if (!packageInfo.bbPackages && !packageInfo.peoTvPackages) {
      // errors['packs'] = 'Please select minimum one of Internet or PeoTV package'
      setError("Please select at least an Internet or a PEOTV package.");
      setIsError(true);
      formIsValid = false;
    } else if (
      packageInfo.bbPackages === "" &&
      packageInfo.peoTvPackages.length === 0
    ) {
      errors["pack"] = "Please select a package";
      formIsValid = false;
    } 
    if (formIsValid) {
      setState(2);
    } else {
      setErrorList(errors);
    }
  };

  return (
    <div className="packageInformation">
      {isError ? (
        <ErrorAlert error={error} setError={setError} setIsError={setIsError} />
      ) : null}
      {isOpenPeo ? (
        <SelectPeoTvPackages
          packageInfo={packageInfo}
          setPackageInfo={setPackageInfo}
          setIsOpenPeo={setIsOpenPeo}
          data={dataPeo}
          setSelectedPeoObj={setSelectedPeoObj}
        />
      ) : null}
      {isOpenBb ? (
        <SelectBroadbandPackage
          packageInfo={packageInfo}
          setPackageInfo={setPackageInfo}
          setIsOpenBb={setIsOpenBb}
          data={dataBB}
          setSelectedBBObj={setSelectedBBObj}
        />
      ) : null}

      {isOpenVoice ? (
        <SelectVoicePackage 
          packageInfo={packageInfo} 
          setPackageInfo={setPackageInfo}
          setIsOpenVoice={setIsOpenVoice} 
          data={dataVioce}
          setSelectedVioceObj={setSelectedVioceObj}
          /> 
        ): null}

      <div className="bodyView">
        <div className="form-container">
          {loading ? (
            <div className="form-inputs-container">
              <LoadingBbBody />
            </div>
          ) : (
            <div className="pkg-selection-container">
              <label>
              Select Services & Packages{" "}
                {errorList.packs ? (
                  <p className="d-inline red m-0 ml-1">{errorList.packs}</p>
                ) : null}
              </label>

              <div className="package-set">
                {/* <div className="package-item-contianer">
                  <div className="package-item ">
                    <div className="item-container voice">
                      <div className="name">Home 1st Line - My Phone</div>
                      <div className="price">390 LKR + Tax</div>
                      <div className="voice-icon" />
                    </div>
                  </div>
                </div> */}

                {
                   packageInfo.voicePackages ? 
                   <div className="package-item-contianer">
                     <div className="package-item ">
                       <div className="item-container voice">
                           <div className="name" style={{ fontSize: '13px' }} >{packageInfo.voicePackages}</div>
                           {/* <div className="price">{selectedVioceObj.MONTHLY_RENTAL} LKR</div> */}
                           <div className="price">{packageInfo.voicePackagesData.MONTHLY_RENTAL} LKR +
                               Tax</div>
                           <div className="voice-icon"/>
                       </div>
                       <div className="change">
                           <div className="name">Change Selection</div>
                           <div className="edit" onClick={() => setIsOpenVoice(true)}>Edit</div>
                           <div className='remove' onClick={() => removeVoicePacks()}>Remove</div>
                       </div>
                     </div>
                   </div>
                   :
                   <div className="package-item-contianer">
                     <div className="package-item" onClick={() => setIsOpenVoice(true)}>
                       <div className="item-container">
                         <div className="plus"/>
                         <div className="name">Select Voice Package</div>
                       </div>
                     </div>
                   </div>
                }

                {packageInfo.bbPackages ? (
                  <div className="package-item-contianer">
                    <div className="package-item ">
                      <div className="item-container internet">
                        <div className="name">{packageInfo.bbPackages}</div>
                        <div className="pkg-size">
                          {packageInfo.bbPackagesData.STANDARD_GB !== "0"
                            ? packageInfo.bbPackagesData.STANDARD_GB
                            : "Unlimited Data"}
                          {packageInfo.bbPackagesData.FREE_GB !== "0"
                            ? " + " + packageInfo.bbPackagesData.FREE_GB
                            : null}
                          {packageInfo.bbPackagesData.STANDARD_GB !== "0"
                            ? " GB"
                            : null}
                        </div>
                        <div className="pkg-price">
                          {packageInfo.bbPackagesData
                            ? packageInfo.bbPackagesData.MONTHLY_RENTAL
                            : "N/A"}{" "} LKR 
                          + Tax
                        </div>
                        <div className="pkg-month">(Per Month)</div>
                        <div className="internet-icon" />
                      </div>

                      <div className="change">
                        <div className="name">Change Selection</div>
                        <div className="edit" onClick={() => setIsOpenBb(true)}>
                          Edit
                        </div>
                        <div className="remove" onClick={() => removeBBPacks()}>
                          Remove
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="package-item-contianer">
                    <div
                      className="package-item"
                      onClick={() => setIsOpenBb(true)}
                    >
                      <div className="item-container">
                        <div className="plus" />
                        <div className="name">Select Internet Package</div>
                      </div>
                    </div>
                  </div>
                )}

                {packageInfo.peoTvPackages ? (
                  <div className="package-item-contianer">
                    <div className="package-item">
                      <div className="item-container peo">
                      <div className="name">
                          {packageInfo.peoPackagesData.PEO_PACKAGE_NAME}
                        </div>
                        <div className="channels">
                          <span className="number">
                            {packageInfo.peoPackagesData.NO_OF_CHANNELS}
                          </span>
                          <span>Channels</span>
                        </div>

                        <div className="name">
                          {packageInfo.peoPackagesData.MONTHLY_RENTAL} LKR + Tax
                        </div>
                        <div className="peo-icon" />
                      </div>

                      <div className="change">
                        <div className="name">Change Selection</div>
                        <div
                          className="edit"
                          onClick={() => setIsOpenPeo(true)}
                        >
                          Edit
                        </div>
                        <div
                          className="remove"
                          onClick={() => removePeoPacks()}
                        >
                          Remove
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="package-item-contianer">
                    <div
                      className="package-item"
                      onClick={() => setIsOpenPeo(true)}
                    >
                      <div className="item-container">
                        <div className="plus" />
                        <div className="name">Select PEOTV Package</div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="package-info">
                {" "}
                <p className="d-inline red m-0 ml-1">*</p>Select your <span style={{color:'red'}}>Voice</span>, Internet and/or PEOTV package.
              </div>
              {/* <div className="package-info">
                {" "}
                <p className="d-inline red m-0 ml-1">*</p>Select your Internet and/or  PEOTV Package.
              </div> */}
            </div>
          )}
        </div>
      </div>
      <div className="text-center font-weight-bold text-danger">
        {errorList.pack}
      </div>
      <div style={{ textAlign: 'left' , marginLeft:'30px', fontSize: '12px' , color:'#1057a6'}}>
        Taxes are applicable 
      </div>

      {/* <div className="text ml-5" style={{ textAlign: 'right' , marginRight:'20px', fontSize: '12px'}}>
        More details : <a href="https://www.sltmobitel.lk/sltmobitel/" target="_blank" rel="noopener noreferrer">sltmobitel.lk</a>
      </div> */}

      <div className="container-footer">
        <div className="action-container right" style={{marginTop:'20px'}}>
          <button onClick={() => validation()}>Next</button>
        </div>
      </div>
    </div>
  );
}

export default PackageInformation;

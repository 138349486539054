import React, {useState} from 'react';
import {BiChevronLeft, BiChevronRight} from "react-icons/bi";
import {Carousel} from "react-responsive-carousel";
import {connect} from "react-redux";
import LoadingBbBody from "../boardBand/LoadingBbBody";
import NoPeoTvAccount from "./NoPeoTvAccount";


function MyPackagePeoTv({loading, serviceList}) {
    const [count, setCount] = useState(0);

    function increment() {
        let length = serviceList.listofPEOService.length
        if (length - 1 > count) {
            setCount(count + 1)
        }


    }

    function decrement() {
        if (count > 0) {
            setCount(count - 1)
        }
    }

    if (loading) {
        return <LoadingBbBody loading={loading} border={false}/>
    } else if (serviceList.hasOwnProperty('listofPEOService')) {
        if (serviceList.listofPEOService.length === 0) {
            return <NoPeoTvAccount/>
        } else {
            return (
                <div className='myPackage'>
                    <div>
                        <div className='d-flex justify-content-between align-items-center'>
                    <span style={{minWidth: '20px', cursor: 'pointer'}}>
                        <BiChevronLeft
                            hidden={serviceList.listofPEOService.length === 1 || count === 0}
                            size={40} onClick={() => decrement()}/>
                    </span>
                            <Carousel showArrows={false} showStatus={false} showIndicators={false} selectedItem={count}
                                      showThumbs={false}
                                      width={367}>
                                {
                                    serviceList.listofPEOService.map((val, index) => {
                                        return <div className='bodyView' key={index}>
                                            <div className='firstRow'>Service ID: {val.serviceID}</div>
                                            <div className='secondRow'>{val.packageName}</div>
                                            <div className='thirdRow'>
                                                <div>Status</div>
                                                <div className='blue'>{val.serviceStatus}</div>
                                            </div>
                                        </div>
                                    })
                                }


                            </Carousel>

                            <span style={{minWidth: '20px', cursor: 'pointer'}}
                                  onClick={() => increment()}>
                                <BiChevronRight
                                    hidden={serviceList.listofPEOService.length === 1 || count === serviceList.listofPEOService.length - 1}
                                    size={40}
                                />
                            </span>
                        </div>
                    </div>

                </div>
            );
        }

    } else
        return null
}

const mapStateToProps = state => ({
    loading: state.accountDetails.loadingServiceList,
    serviceList: state.accountDetails.service_list,
})
export default connect(mapStateToProps, {})(MyPackagePeoTv);
import React, {useEffect, useState}  from 'react'
import SelectLTEPackage from './SelectLTEPackage';
import SelectPeoTvPackages from './SelectPeoTVPackages';
import SelectVoicePackage from './SelectVoicePackage';
import axiosInstance from '../../../redux/axiosInstance';
import LoadingBbBody from '../../boardBand/LoadingBbBody';
import { getVoicePackagesList, getPeoTvPackagesList, getBbPackagesList } from '../../../redux/URL';
import ErrorAlert from '../../alerts/ErrorAlert';
import Querystring from "querystring";


const PackageSelect = ({packageInfo, setPackageInfo, locationInfo, userInfo, setState, orderRef}) => {
    const [isOpenPeo, setIsOpenPeo] = useState(false);
    const [isOpenBb, setIsOpenBb] = useState(false);
    const [isOpenVoice, setIsOpenVoice] = useState(false);


    const [loading, setLoading] = useState(true);
    const [dataBB, setDataBB] = useState([]);
    const [selectedBBObj, setSelectedBBObj] = useState({});
    const [dataPeo, setDataPeo] = useState([]);
    const [selectedPeoObj, setSelectedPeoObj] = useState({});
    const [dataVioce, setDataVioce] = useState([]);
    const [selectedVioceObj, setSelectedVioceObj] = useState({});
    const [errorList, setErrorList] = useState({});

    const [savingDraft, setSavingDraft] = useState(false);

    const [isError, setIsError] = useState(false);
    const [error, setError] = useState('');

    useEffect(() => {
      let url = getBbPackagesList + 'Mode=SLT 4G'
      axiosInstance.get(url).then(response => {

          if (response.status === 200) {
              setDataBB(...[response.data.dataBundle[0]])
              setLoading(false)
              getPeoTvPackages()
          } else
              setLoading(false)
      }).catch(() => {
          setLoading(false)
      })
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const getPeoTvPackages = () => {
      let url = getPeoTvPackagesList + 'Mode=SLT 4G'
      axiosInstance.get(url).then(response => {

          if (response.status === 200) {
              setDataPeo(...[response.data.dataBundle.Results])
              setLoading(false)
              getVoicePackages()
          } else
              setLoading(false)
      }).catch(() => {
          setLoading(false)
      })
    }

    const getVoicePackages = () => {
      let url = getVoicePackagesList + 'Mode=SLT 4G'
      axiosInstance.get(url).then(response => {

          if (response.status === 200) {
              setDataVioce(...[response.data.dataBundle.Results])
              setLoading(false)
          } else
              setLoading(false)
      }).catch(() => {
          setLoading(false)
      })
    }

    const removeBBPacks = () => {
      setPackageInfo({...packageInfo, bbPackages: ''})
      setSelectedBBObj({})
    }

  const removePeoPacks = () => {
      setPackageInfo({...packageInfo, peoTvPackages: ''})
      setSelectedPeoObj({})
  }

  const removeVoicePacks = () => {
    setPackageInfo({...packageInfo, voicePackages: ''})
    setSelectedVioceObj({})
}

  const sameAsBilling = () => {
      if (!packageInfo.isSameAsBilling) {
          setPackageInfo({
              ...packageInfo,
              isSameAsBilling: true,
              city: locationInfo.city,
              postalCode: locationInfo.postalCode,
              address1: locationInfo.address1,
              address2: locationInfo.address2,
              district: locationInfo.district
          })
      } else {
          setPackageInfo({
              ...packageInfo,
              isSameAsBilling: false,
              city: '',
              postalCode: '',
              address1: '',
              address2: '',
              district: ''
          })
      }
  }

  const handleChange = event => {
      const {name, value} = event.target
      setPackageInfo({...packageInfo, [name]: value})

  }

  const validation = () => {
    let errors = {};
    let formIsValid = true;

    if (!packageInfo.bbPackages && packageInfo.connection !== "cVoice" ) {
      setError('Please select an internet package.')
      setIsError(true)
      formIsValid = false
    } else if (!packageInfo.voicePackages && packageInfo.connection !== "cBroadBand" ) {
      setError('Please select an Voice package.')
      setIsError(true)
      formIsValid = false
    }
    else if (packageInfo.bbPackages === '' && packageInfo.connection !== "cVoice" ) { 
      errors['pack'] = 'Please select a package'
      formIsValid = false
    }

    if (formIsValid) {
      // saveDraft()
      setState(2)
    } else {
        setErrorList(errors)
    }
  }


  useEffect(() => {
    setPackageInfo({
      ...packageInfo,
      peoTvPackages: '',
      bbPackages: '',
      voicePackages: '',
      bbPackagesData: {},
      voicePackagesData: {}
  })
  }, [packageInfo.connection]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className='packageInformationLTE'>
      {
        isError ? <ErrorAlert error={error} setError={setError} setIsError={setIsError}/> : null
      }
      {isOpenPeo ?
                <SelectPeoTvPackages packageInfo={packageInfo} setPackageInfo={setPackageInfo}
                                     setIsOpenPeo={setIsOpenPeo} data={dataPeo}
                                     setSelectedPeoObj={setSelectedPeoObj}/> : null}
      {isOpenBb ? <SelectLTEPackage packageInfo={packageInfo} setPackageInfo={setPackageInfo}
                                    setIsOpenBb={setIsOpenBb} data={dataBB}
                                    setSelectedBBObj={setSelectedBBObj}/> : null}
      {isOpenVoice ? <SelectVoicePackage packageInfo={packageInfo} setPackageInfo={setPackageInfo}
                                    setIsOpenVoice={setIsOpenVoice} data={dataVioce}
                                    setSelectedVioceObj={setSelectedVioceObj}/> : null}
      <div className='bodyView'>
        <div className='form-container'>
          { loading ? <div className='form-inputs-container'><LoadingBbBody/></div> : 
          <div className='pkg-selection-container'>
            <div className='w-50'>
                <div className='input-container'>
                  <label>Connection Type</label>
                  <div className="radio-group-container">
                    <div className='radio-input'>
                      <input type="radio" id="connection-v" name="connection" value={'cVoice'}
                             checked={packageInfo.connection === 'cVoice'}
                             onChange={handleChange}/><label htmlFor="connection-v">Voice Only</label>
                    </div>
                    <div className='radio-input'>
                      <input type="radio" id="connection-br" name="connection" value={'cBroadBand'}
                              checked={packageInfo.connection === 'cBroadBand'}
                              onChange={handleChange}/><label htmlFor="connection-br">BroadBand Only</label>
                    </div>
                    <div className='radio-input'>
                      <input type="radio" id="connection-b" name="connection" value={'cBoth'}
                              checked={packageInfo.connection === 'cBoth'}
                              onChange={handleChange}/><label htmlFor="connection-b">Both</label>
                    </div>
                  </div>
                </div>
              </div>

              <label >Select Your Package {errorList.packs ?
                <p className="d-inline red m-0 ml-1">{errorList.packs}</p>
                    : null}
              </label>
              
              <div className='package-set'>

                {
                  packageInfo.connection !== "cBroadBand" ?
                    packageInfo.voicePackages ? 
                    <div className="package-item-contianer">
                      <div className="package-item ">
                        <div className="item-container voice">
                            <div className="name">{packageInfo.voicePackages}</div>
                            {/* <div className="price">{selectedVioceObj.MONTHLY_RENTAL} LKR</div> */}
                            <div className="price">{packageInfo.voicePackagesData.MONTHLY_RENTAL} LKR +
                                Tax</div>
                            <div className="voice-icon"/>
                        </div>
                        <div className="change">
                            <div className="name">Change Selection</div>
                            <div className="edit" onClick={() => setIsOpenVoice(true)}>Edit</div>
                            <div className='remove' onClick={() => removeVoicePacks()}>Remove</div>
                        </div>
                      </div>
                    </div>
                    :
                    <div className="package-item-contianer">
                      <div className="package-item" onClick={() => setIsOpenVoice(true)}>
                        <div className="item-container">
                          <div className="plus"/>
                          <div className="name">Select Voice Package</div>
                        </div>
                      </div>
                    </div>
                  : null
                }
                
                {
                  packageInfo.connection !== "cVoice" ?
                    packageInfo.bbPackages ? 
                    <div className="package-item-contianer">
                      <div className="package-item ">
                        <div className="item-container internet">
                          <div className="name">{packageInfo.bbPackages}</div>
                          {/* <div
                              className="pkg-size">{selectedBBObj.STANDARD_GB !== '0' ? selectedBBObj.STANDARD_GB : 'Unlimited Data'}{selectedBBObj.FREE_GB !== '0' ? ' + ' + selectedBBObj.FREE_GB : null}{selectedBBObj.STANDARD_GB !== '0' ? ' GB' : null}</div>
                          <div
                              className="pkg-price">Rs.{selectedBBObj ? selectedBBObj.MONTHLY_RENTAL : 'N/A'} +
                                Tax
                          </div> */}
                          <div
                              className="pkg-size">{packageInfo.bbPackagesData.STANDARD_GB !== '0' ? packageInfo.bbPackagesData.STANDARD_GB : 'Unlimited Data'}{packageInfo.bbPackagesData.FREE_GB !== '0' ? ' + ' + packageInfo.bbPackagesData.FREE_GB : null}{selectedBBObj.STANDARD_GB !== '0' ? ' GB' : null}</div>
                          <div
                              className="pkg-price">Rs.{packageInfo.bbPackagesData ? packageInfo.bbPackagesData.MONTHLY_RENTAL : 'N/A'} +
                                Tax
                          </div>
                          <div className="pkg-month">(Per Month)</div>
                          <div className="internet-icon"/>
                        </div>

                        <div className="change">
                          <div className="name">Change Selection</div>
                          <div className="edit" onClick={() => setIsOpenBb(true)}>Edit</div>
                          <div className='remove' onClick={() => removeBBPacks()}>Remove</div>
                        </div>
                      </div>
                    </div>
                    :
                    <div className="package-item-contianer">
                      <div className="package-item" onClick={() => setIsOpenBb(true)}>
                        <div className="item-container">
                          <div className="plus"/>
                          <div className="name">Select Internet Package</div>
                        </div>
                      </div>
                    </div>
                  : null
                 
                }

                {/* {
                  packageInfo.peoTvPackages ? 
                  <div className="package-item-contianer">
                    <div className="package-item">
                      <div className="item-container peo">
                        <div className="channels">
                          <span className="number">{selectedPeoObj.NO_OF_CHANNELS}</span>
                          <span>Channels</span>
                        </div>
                        <div className="name">{selectedPeoObj.PEO_PACKAGE_NAME}</div>
                        <div className="name">{selectedPeoObj.MONTHLY_RENTAL} LKR</div>
                        <div className="peo-icon"/>
                      </div>

                      <div className="change">
                        <div className="name">Change Selection</div>
                        <div className="edit" onClick={() => setIsOpenPeo(true)}>Edit</div>
                        <div className='remove' onClick={() => removePeoPacks()}>Remove</div>
                      </div>
                    </div>
                  </div>
                  :
                  <div className="package-item-contianer">
                    <div className="package-item" onClick={() => setIsOpenPeo(true)}>
                      <div className="item-container">
                        <div className="plus"/>
                        <div className="name">Select PeoTV Package</div>
                      </div>
                    </div>
                  </div>
                } */}
                

              </div>

                {/* {
                  packageInfo.connection !== "cVoice" ? 
                    <div className='package-info'> 
                      <p className='d-inline red m-0 ml-1'>*</p>It is required to select an internet package.
                    </div> 
                  : null
                } */}
              
                        
          </div>}

          <div className='form-inputs-container'>
            <div className='d-flex'>

              <div className='w-50'>
                <div className='input-container'>
                  <label>Billing Mode</label>
                  <div className="radio-group-container">
                    <div className='radio-input'>
                      <input type="radio" id="billType-e" name="billType" value={'eBill'}
                             checked={packageInfo.billType === 'eBill'}
                             onChange={handleChange}/><label htmlFor="billType-e">E-bill
                                (via email)</label>
                    </div>
                    {/* <div className='radio-input'>
                      <input type="radio" id="billType-p" name="billType" value={'post'}
                              checked={packageInfo.billType === 'post'}
                              onChange={handleChange}/><label htmlFor="billType-p">Printed bill
                                (via post)</label>
                    </div> */}
                    <div className='radio-input'>
                      <input type="radio" id="billType-a" name="billType" value={'app'}
                              checked={packageInfo.billType === 'app'}
                              onChange={handleChange}/><label htmlFor="billType-a">e-Statement by MySLT APP
                                </label>
                    </div>
                    <div className='radio-input'>
                      <input type="radio" id="billType-s" name="billType" value={'sms'}
                              checked={packageInfo.billType === 'sms'}
                              onChange={handleChange}/><label htmlFor="billType-s">e-Statement by SMS
                               </label>
                    </div>
                  </div>
                </div>
              </div>

              <div className='w-50'>
                <div className='input-container'>
                  <label>Do you want to deactivate IDD ?</label>
                  <div className="radio-group-container">
                    <div className='radio-input'>
                      <input type="radio" id="iddActivate-y" name="iddActivate"
                             value={true}
                             checked={packageInfo.iddActivate}
                             onChange={handleChange}/><label
                             htmlFor="iddActivate-y">Yes</label>
                    </div>
                    <div className='radio-input'>

                      <input type="radio" id="iddActivate-n" name="iddActivate"
                             value={false}
                             checked={packageInfo.iddActivate}
                             onChange={handleChange}/><label
                             htmlFor="iddActivate-n">No</label>
                    </div>
                  </div>

                </div>
              </div>

            </div>
          </div>


        </div>
      </div>
        <div className='text-center font-weight-bold text-danger'>
                {errorList.pack}
        </div>
        <div className='container-footer'>
          <div className="action-container right">
            {/* <button className="btn-back" onClick={() => setState(1)}>Back</button> */}
            <button onClick={() => validation()} disabled={savingDraft}>Next</button>
          </div>
        </div>
    </div>
  )
}

export default PackageSelect
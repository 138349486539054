import DailyUsage from "./DailyUsage";
import React, { useEffect, useState } from 'react';
import DetailedReport from "./DetailedReport";

function DailyDetails() {
    const [dataObj, setDataObj] = useState(null);

    // console.log(dataObj)
    if(dataObj)
        return <DetailedReport dataObj={dataObj} setDataObj={setDataObj}/>
    else
        return <DailyUsage setDataObj={setDataObj}/>
}
export default DailyDetails
import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import getBillHistory, {numberWithCommas} from "../../redux/action/bill/billHistory.action";
import getBillDownloadRequest from '../../redux/action/bill/billDownloadRequest.action';
import LoadingBbBody from "../boardBand/LoadingBbBody";
import {eBillResendRequest, ebillDownloadRequestUrl} from "../../redux/URL";
import axiosInstance from "../../redux/axiosInstance";
import {CATCH_ERROR_MSG,} from "../../redux/Types";


function BillHistory({
                         accountList,
                         getBillHistory,
                         loading,
                         billHistory,
                         billStatus,
                         getBillDownloadRequest,
                         billDownload,
                         loadingBillDownload,
                         errorBillDownload,
                         service_list,
                         setSuccess,
                         setError,
                         setIsError
                     }) {

    const [servList, setServList] = useState(service_list);

    useEffect(() => {
        const telNo = localStorage.getItem('selectedAcc');
        if (accountList) {
            getBillHistory(telNo ?? accountList[0].telephoneno, service_list.accountNo)
        }


    }, [getBillHistory, accountList, service_list.accountNo]);


    useEffect(() => {
        if (servList !== service_list) {
            const telNo = localStorage.getItem('selectedAcc');
            if (accountList) {
                getBillHistory(telNo ?? accountList[0].telephoneno, service_list.accountNo)
            }
            setServList(...[service_list])
        }

    }, [service_list, getBillHistory, servList])// eslint-disable-line react-hooks/exhaustive-deps

    const reSendEmail = (month, viewMonth) => {
      
        let tpNo = localStorage.getItem('selectedAcc')
        let m = month.split('/')
        let billMonth = (m[2] + '/' + m[0])
        
        if (viewMonth !== billMonth) {
            m[0] =  m[0] -1;
         
            billMonth =  (m[2] + '/' + m[0]);
        }

        let accNo = service_list.accountNo
        let url = eBillResendRequest + 'eContact=&accountNo=' + accNo + '&ebillMonth=' + billMonth + '&tpNo=' + tpNo
        axiosInstance.post(url).then(response => {
            if (response.data.isSuccess) {
                setSuccess(true)
            } else {
                setError(response.data.errorShow)
                setIsError(true)
            }
        }).catch(() => {
            setError(CATCH_ERROR_MSG)
            setIsError(true)
        })
    }

    const downloadEbill = (email, month, viewMonth) => {
      
        let tpNo = localStorage.getItem('selectedAcc')
        let m = month.split('/')
        let billMonth = (m[2] + '/' + m[0])
        
        if (viewMonth !== billMonth) {
            m[0] =  m[0] -1;
         
            billMonth =  (m[2] + '/' + m[0]);
        }

        let accNo = service_list.accountNo

        getBillDownloadRequest(email, tpNo, accNo, viewMonth, setSuccess, setIsError, setError)
       
    }

    if (loading || loadingBillDownload) {
        return <div className='history'><LoadingBbBody/></div>
    } else if (billHistory) {
        return (
            <div className='history'>
{/* <img src={`data:image/jpeg;base64,${billDownload}`} /> */}
                {
                    billHistory.listofBillHistoryDetail.map((val, key) => {
                        return (
                            <div className='details' key={key}>
                                <div className='firstRow'>
                                    <div>Outstanding : {numberWithCommas(val.outstanding)}</div>
                                    {/* {
                                        billHistory.listofBillHistoryDetail.length - 1 > key ? billHistory.listofBillHistoryDetail[key + 1].eBillAvailability === 'N' ? null :
                                            <button className='mt-2'
                                                    onClick={() => reSendEmail(billHistory.listofBillHistoryDetail[key + 1].billMonth, billHistory.listofBillHistoryDetail[key + 1].billViewMonth)}>Email
                                                Now</button> : null
                                    } */}

                                    {
                                        billHistory.listofBillHistoryDetail.length - 1 > key ? billHistory.listofBillHistoryDetail[key].eBillAvailability === 'N' ? null :
                                        billHistory.listofBillHistoryDetail[key].billCode !== billStatus.bill_code ? null :
                                        billHistory.listofBillHistoryDetail[key].billCode === "02"  ?
                                            <button className='mt-2'
                                                    onClick={() => reSendEmail(billHistory.listofBillHistoryDetail[key].billMonth, billHistory.listofBillHistoryDetail[key].billViewMonth)}>Email
                                                Now</button> :  billHistory.listofBillHistoryDetail[key].billCode === "23"  ?
                                             <button className='mt-2'
                                             onClick={() => downloadEbill(billStatus.email ,billHistory.listofBillHistoryDetail[key].billMonth, billHistory.listofBillHistoryDetail[key].billViewMonth)}> Download Bill</button> :null
                                                 : null
                                    }

                                </div>
                                <div className='secondRow'>Bill Value : Rs.{numberWithCommas(val.billValue)} at : {val.billMonth}</div>
                                <div className='thirdRow'>Payments :Rs.{numberWithCommas(val.payments)}</div>
                            </div>

                        )
                    })
                }

            </div>
        );
    } else
        return null

}


const mapStateToProps = state => ({
    accountList: state.accountDetails.account_Details,
    service_list: state.accountDetails.service_list,
    billHistory: state.billHistory.dataBundle,
    billStatus: state.billStatus.dataBundle,
    billDownload: state.billDownload.dataBundle,
    loading: state.billHistory.loading,
    error: state.billHistory.error,
    loadingBillDownload: state.billDownload.loading,
    errorBillDownload: state.billDownload.error,
})

export default connect(mapStateToProps, {getBillHistory, getBillDownloadRequest})(BillHistory);

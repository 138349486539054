import React, {useState} from 'react';
import OtpInput from "react-otp-input";
import axiosInstance from "../../redux/axiosInstance";
import {ebillOtpAuthUrl} from "../../redux/URL";
import ErrorAlert from "../alerts/ErrorAlert";
import {CATCH_ERROR_MSG} from "../../redux/Types";

function ActiveEBill({
                         setLoading,
                         error,
                         setError,
                         isError,
                         setIsError,
                         isMail,
                         email,
                         mobile,
                         setOtpStatus,
                         eBillRegister
                     }) {

    const [OTP, setOTP] = useState("");

    const validateOtp = () => {
        let econtact = isMail ? email : mobile
        if (OTP.length === 6) {
            let url = ebillOtpAuthUrl + 'econtact=' + econtact + '&otpCode=' + OTP
            setLoading(true)
            axiosInstance.post(url).then(response => {
                if (response.data.isSuccess) {
                    eBillRegister(isMail ? 'EMAIL' : 'MOBILE')
                } else if (response.data.errorShow) {
                    setError(response.data.errorShow)
                    setIsError(true)
                    setLoading(false)
                }
            }).catch(error => {
                setError(CATCH_ERROR_MSG)
                setIsError(true)
                setOtpStatus(false)
                setLoading(false)
            })
        } else {
            setError('Enter Valid Otp')
            setIsError(true)
            setLoading(false)
        }
    }

    return (
        <div className='mainBody eBill common-panel '>
            <div className="body-container">
                {isError ? <ErrorAlert error={error} setError={setError} setIsError={setIsError}/> : null}
                <div className='title'>
                    <p>Enter OTP</p>
                </div>

                <div className='wrapper'>

                    <div className='content'>
                        <div className='card'>
                            <div className='text-center mt-3 font-weight-bold'>Otp send
                                to {isMail ? email : mobile}</div>
                            <div className='form d-flex justify-content-center'>

                                <OtpInput
                                    value={OTP}
                                    onChange={setOTP}
                                    numInputs={6}
                                    isInputNum={true}
                                    shouldAutoFocus={true}
                                    inputStyle='otp'
                                />

                            </div>
                            <div className='text-center mb-3'>
                                <button className='medium blue' onClick={() => validateOtp()}>Submit</button>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>

    );
}

export default ActiveEBill;
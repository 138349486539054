import {
    ERROR_GET_PACKAGE_UPGRADE,
    ERROR_GET_PACKAGE_UPGRADE_LIST,
    GET_PACKAGE_UPGRADE_LIST, SUCCESS_GET_PACKAGE_CURRENT_LIST,
    SUCCESS_GET_PACKAGE_UPGRADE_LIST, SUCCESS_UPGRADE_PACKAGE

} from "../../Types";
//call two api for the get package upgrade details
const initialState = {
    loading: true,
    dataBundle: {},
    currentPackage: {},
    error:'',

}

export default function packageUpgradeReducer(state= initialState, action){
    switch (action.type){

        case GET_PACKAGE_UPGRADE_LIST : {
            return {
                ...state,
                loading: true
            }
        }

        case SUCCESS_GET_PACKAGE_UPGRADE_LIST : {
            return {
                ...state,
                dataBundle: action.payload
            }
        }

        case SUCCESS_GET_PACKAGE_CURRENT_LIST: {
            return {
                ...state,
                loading: false,
                currentPackage: action.payload
            }
        }

        case ERROR_GET_PACKAGE_UPGRADE_LIST : {
            return {
                ...state,
                error: action.payload,
                dataBundle: {},
                currentPackage: {},
                loading: false,
            }
        }

        case ERROR_GET_PACKAGE_UPGRADE : {
            return {
                ...state,
                error: action.payload,
                loading: false,
            }
        }



        case SUCCESS_UPGRADE_PACKAGE : {
            return {
                ...state,
                loading: false
            }
        }

        default :
            return state
    }
}

import React, { useEffect, useState } from "react";
import { ProgressBar, Table } from "react-bootstrap";
import { connect } from "react-redux";
import getDailyUsage from "../../../redux/action/broadBand/BroadBandDailyUsage.action";
import LoadingBbBody from "../LoadingBbBody";
import { Link, useHistory } from "react-router-dom";
import ConfirmAlert from "../../alerts/ConfirmAlert";

function DailyUsage({
  getDailyUsage,
  profile,
  loadingProfile,
  current,
  loading,
  prevMonth2,
  prevMonth1,
  setDataObj,
}) {
  const [selectTab, setSelectTab] = useState(0);
  const [alertShow, setAlertShow] = useState(false);

  const history = useHistory();
  useEffect(() => {
    getDailyUsage(profile.subscriberid, profile.billing_date);
  }, [getDailyUsage, profile]);

  const onSelect = () => {
    if (selectTab === 0) {
      return current;
    } else if (selectTab === 1) {
      return prevMonth1;
    } else {
      return prevMonth2;
    }
  };

  const selectColor = (sorter) => {
    if (sorter === 1) return "#00D300";
    else if (sorter === 2) return "#FFDD00";
    else if (sorter === 3) return "#ED4872";
    else return "#00FFFF";
  };

  const confirmOnClick = () => {
    history.push("/boardBand/enableProtocol");
  };

  const confirmAlert = () => {
    return (
      <ConfirmAlert
        msg={"By clicking continue , you can enable detailed report."}
        onClick={confirmOnClick}
        setConfirmShow={setAlertShow}
      />
    );
  };

  if (loadingProfile) {
    return <h5>loading</h5>;
  } else if (
    loading ||
    !prevMonth1.hasOwnProperty("dailylist") ||
    !current.hasOwnProperty("dailylist")
  ) {
    return (
      <div className="bbSubBody">
        <div className="body-container">
          <div className="title">Daily Usage</div>
        </div>
        <LoadingBbBody loading={loading} border={false} />
      </div>
    );
  } else {
    return (
      <div className="bbSubBody">
        {alertShow ? confirmAlert() : null}

        <div className="body-container">
          <div className="title">Daily Usage</div>
          <div className="wrapper">
            <div className="monthRow">
              <div
                className={selectTab === 0 ? "current" : null}
                onClick={() => setSelectTab(0)}
              >
                Current Month
              </div>
              <div
                className={selectTab === 1 ? "current" : null}
                onClick={() => setSelectTab(1)}
              >
                {current.previousmonths.previous1}
              </div>
              <div
                className={selectTab === 2 ? "current" : null}
                onClick={() => setSelectTab(2)}
              >
                {current.previousmonths.previous2}
              </div>
            </div>

            <div className="colorRow">
              <div>
                {" "}
                <div className="bullet" style={{ background: selectColor(1) }} />
                <div>Base Bundle</div>
              </div>
              <div>
                {" "}
                <div className="bullet" style={{ background: selectColor(2) }} />
                <div>Loyalty</div>
              </div>
              <div>
                {" "}
                <div className="bullet" style={{ background: selectColor(3) }} />
                <div>Extra GB</div>
              </div>
              <div>
                {" "}
                <div className="bullet" style={{ background: selectColor(4) }} />
                <div>VAS bundles</div>
              </div>
            </div>

            <div>
              <Table striped bordered className="table">
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Total Usage</th>
                    <th className="usage">Usage</th>
                    <th>Report</th>
                  </tr>
                </thead>
                <tbody style={{ fontSize: "12px" }}>
                  {onSelect().dailylist.map((val, index) => {
                    return (
                      <tr key={index}>
                        <td style={{ width: "70px" }}>{val.displaydate}</td>

                        <td>
                          {val.daily_total_usage}
                          {val.volumeunit}
                        </td>
                        {val.hasOwnProperty("usages") && val.usages ? (
                          <td className="pt-3">
                            <div>
                              <ProgressBar style={{ height: "8px" }}>
                                {val.usages.map((obj, key) => {
                                  return (
                                    <ProgressBar
                                      style={{
                                        backgroundColor: selectColor(
                                          obj.sorter
                                        ),
                                      }}
                                      now={
                                        (val.daily_percentage / 100) *
                                        obj.percentage
                                      }
                                      key={key}
                                    />
                                  );
                                })}
                              </ProgressBar>
                            </div>

                            <div
                              className="text-center mt-1 link"
                              onClick={() => setDataObj(val)}
                            >
                              View Detailed Usage
                            </div>
                          </td>
                        ) : (
                          <td>
                            <div className="title text-center">No data</div>
                          </td>
                        )}

                        {profile.priviledges.protocol_report ? (
                          <td>
                            <Link to={"/boardBand/protocolReport/" + val.date}>
                              <button className="btn-more">More</button>
                            </Link>
                          </td>
                        ) : (
                          <td>
                            <button
                              onClick={() => setAlertShow(true)}
                              className="btn-more"
                            >
                              View
                            </button>
                          </td>
                        )}
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  loadingProfile: state.broadBand.loadingProfile,
  profile: state.broadBand.profile,
  loading: state.dailyUsage.loading,
  current: state.dailyUsage.current,
  prevMonth1: state.dailyUsage.prev1,
  prevMonth2: state.dailyUsage.prev2,
});

export default connect(mapStateToProps, { getDailyUsage })(DailyUsage);

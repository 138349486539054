import React, { useState } from "react";
import { useParams, Link } from "react-router-dom";
import Querystring from "querystring";
import NewFTTHLandingBannerViews from "../common/NewFTTHLandingBannerViews";
import {
  AuthonticationOpenFTTHLogin,
  verifyOTPOpenFTTH,
  verifyOTPOpenFTTHV2,
} from "../../redux/URL";
import { CATCH_ERROR_MSG } from "../../redux/Types";
import OTPValidationFTTH from "./ftth/userInformation/OTPValidationFTTH";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import axiosInstanceFtth from "../../redux/axiosInstanceFtth";

function ConnectionDirectPage(props) {
  const { path } = useParams();

  const [userId, setName] = useState("");
  const [userNameError, setUserNameError] = useState("");
  const [otpState, setOtpState] = useState(false);
  const [loading, setLoading] = useState(false);

  const history = useHistory();

  const submit = () => {
    // e.preventDefault()
    if (!userId) {
      setUserNameError("Order ID cannot be empty");
    } else {
      let body = Querystring["stringify"]({
        userName: userId,
        userType: "MOBILE",
        channelID: "WEB",
      });

      axiosInstanceFtth
        .post(AuthonticationOpenFTTHLogin, body)
        .then((response) => {
          if (response.data.isSuccess) {
            setOtpState(true);
            setLoading(false);
            setUserNameError("");
            // console.log(response)
          } else {
            setUserNameError(response.data.errorShow);
            setLoading(false);
          }
        })
        .catch(() => {
          setUserNameError(CATCH_ERROR_MSG);
          setLoading(false);
        });
    }
  };

  const otpValidation = (otp) => {
    setLoading(true);

    let body = Querystring["stringify"]({
      'code':userId,
      'channelID':'WEB',
      'recievedOtp':otp,
    });

    axiosInstanceFtth
      .post(verifyOTPOpenFTTHV2, body)
      .then((response) => {
        if (response.data.isSuccess) {
          setOtpState(false);
          setUserNameError("");

          localStorage.setItem("userName", response.data.dataBundle.user_id);
          localStorage.setItem(
            "slt_ftth_accessToken",
            response.data.dataBundle.accessToken
          );
          localStorage.setItem(
            "slt_ftth_refreshToken",
            response.data.dataBundle.refreshToken
          );

          setLoading(false);

          history.push("/applyonline/fibre/statustracking/" + userId);
        } else {
          setUserNameError(response.data.errorShow);
          setLoading(false);
        }
      })
      .catch(() => {
        setUserNameError(CATCH_ERROR_MSG);
        setLoading(false);
      });
  };

  return (
    <>
      <div className="imageBanner" hidden={path && !props.loggedIn}>
        <NewFTTHLandingBannerViews />
      </div>

      <div className="landingFTHOuter">
        {otpState ? (
          <OTPValidationFTTH
            otpValidation={otpValidation}
            email=""
            mobile={userId}
            loading={loading}
            otpType={"MOBILE"}
            setOtpState={setOtpState}
            sendOTPRequest={submit}
          />
        ) : null}

        <div className="formView">
          <div className="form  d-block m-auto new-ftth-langing-form">
            <p className="titles" style={{ fontWeight: "bold" }}>
              Get a Life with Fibre
            </p>

            <div className="connectionContainer">
              <p className="titles">
                Sign up for a new Fibre connection in just a few simple steps.
              </p>

              <div className="connectionTypeContainer">
                <Link
                  to="/applyonline/fiber"
                  onClick={() => {
                    localStorage.setItem("userName", "");
                    localStorage.setItem("slt_ftth_accessToken", "");
                    localStorage.setItem("slt_ftth_refreshToken", "");
                  }}
                  className="connectionType"
                >
                  <div>Register Now</div>
                </Link>
              </div>

              <div className="mt-4">
                <p className="line">
                  <span className="or">Or Check Your Order Status</span>
                </p>
              </div>
            </div>

            <div className="connectionStatusCkeckContainer">
              <fieldset>
                <div className="input-group">
                  <div
                    className={
                      userNameError ? "input-container" : "input-container"
                    }
                  >
                    <span className="error-message float-right">
                      {userNameError}
                    </span>
                    <input
                      type="text"
                      className=""
                      value={userId}
                      onChange={(e) => {
                        setName(e.target.value.trim());
                        setUserNameError("");
                      }}
                      placeholder="Order Id"
                    />
                  </div>
                  <button
                    disabled={props.loading}
                    className="medium blue float-right"
                    onClick={() => submit()}
                  >
                    {props.loading ? "Please wait..." : "Continue"}
                  </button>
                </div>
              </fieldset>
            </div>
            <div className="payDetails">
              <div className="text">Pay With</div>
              <div className="icons">
                <div className="visa" />
                <div className="masterCard" />
                {/* <div className="payHere" /> */}
              </div>
              <div className="text ml-5">
                Return to{" "}
                <a
                  href="https://www.sltmobitel.lk/sltmobitel/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  sltmobitel.lk
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ConnectionDirectPage;

import React, {useState} from 'react';
import {connect} from "react-redux";
import {addTelAccountPostPaid, addTelAccountPrePaid} from "../../../redux/action/accountDetails.action";

function AddTelephones({addTelAccountPostPaid, addTelAccountPrePaid, loadingAddAccount, setSuccess, setError, setIsError}) {
    const [isPostPaid, setIsPostPaid] = useState(true);
    const [telephone, setTelephone] = useState('');
    const [errorTelephone, setErrorTelephone] = useState('');
    const [sltAccNo, setSltAcc] = useState('');
    const [errorSltAcc, setErrorSltAcc] = useState('');
    const [nic, setNic] = useState('');
    const [errorNic, setErrorNic] = useState('');


    function validateNic() {
        if (nic) {
            
            if (nic.length === 10 || nic.length === 12) {
                if (nic.length === 10 && nic.match("^[0-9]{9}[a-zA-Z]{1}$")) {
                    setErrorNic('')
                    return true
                }else if ( nic.length === 12  && nic.match("^[0-9]{12}$")) {
                    setErrorNic('')
                    return true
                } else {
                    setErrorNic('Invalid NIC number')
                    return false
                }

            } else {
                setErrorNic('Invalid NIC number')
                return false
            }
        } else {
            setErrorNic('Cannot be empty')
            return false
        }

    }

    function validateTel() {

        if (!telephone) {
            setErrorTelephone('Cannot be empty')
            return false
        } else {
            if (telephone.match("[0-9]+")) {
                if (telephone.startsWith("0")) {
                    if (telephone.length === 10) {
                        setErrorTelephone('')
                        return true

                    } else {
                        setErrorTelephone('Invalid telephone number')
                        return false

                    }
                } else if (telephone.startsWith("9")) {

                    if (telephone.length === 11) {
                        setErrorTelephone('')
                        return true
                    } else {
                        setErrorTelephone('Invalid telephone number')
                        return false
                    }
                } else {
                    setErrorTelephone('Invalid telephone number')
                    return false
                }
            } else {
                setErrorTelephone('Invalid telephone number')
                return false
            }
        }
    }

    function validateAcc() {
        if (!sltAccNo) {
            setErrorSltAcc('Invalid account number')
            return false
        } else {
            if (sltAccNo.length === 10) {
                setErrorSltAcc('')
                return true
            } else {
                setErrorSltAcc('Invalid account number')
                return false
            }
        }
    }

    function onSubmit() {
        if (isPostPaid &&validateTel() && validateAcc() && validateNic()) {
            addTelAccountPostPaid(sltAccNo, telephone, nic, setSuccess, setIsError, setError)
        }else if(!isPostPaid && validateTel() && validateNic()) {
            addTelAccountPrePaid(telephone, nic, setSuccess, setIsError, setError)
        }
    }

    return (
      <div className="mx-3 mt-2">
        <div className="radio-group-container ml-2 mt-2">
          <div className="radio-input">
            <label className="d-block mx-2">Post paid</label>
            <input
              type="radio"
              className="pl-2"
              checked={isPostPaid}
              onChange={() => {
                setIsPostPaid(!isPostPaid);
              }}
            />
          </div>
          <div className="radio-input">
            <label className="d-block mx-2">Pre paid</label>
            <input
              type="radio"
              className="pl-2"
              checked={!isPostPaid}
              onChange={() => {
                setIsPostPaid(!isPostPaid);
              }}
            />
          </div>
        </div>

        <div className="mt-2">
          <span className="error-message float-right">{errorTelephone}</span>
          <label className="d-block">
            Telephone<p className="d-inline red">*</p>
          </label>
          <input
            type="number"
            className="pl-2"
            value={telephone}
            onChange={(event) => setTelephone(event.target.value)}
          />
        </div>

        {isPostPaid ? (
          <div className="mt-2">
            <span className="error-message float-right">{errorSltAcc}</span>
            <label className="d-block">
              SLT Account Number <p className="d-inline red">*</p>
            </label>
            <input
              type="text"
              className="pl-2"
              value={sltAccNo}
              onChange={(event) => setSltAcc(event.target.value)}
            />
          </div>
        ) : null}

        <div className="mt-2">
          <span className="error-message float-right">{errorNic}</span>
          <label className="d-block">
            National Identity Card Number <p className="d-inline red">*</p>
          </label>
          <input
            type="text"
            className="pl-2"
            value={nic}
            onChange={(event) => setNic(event.target.value)}
          />
        </div>

        <div className="mt-4">
          <button
            className="medium blue float-right"
            onClick={() => onSubmit()}
          >
            {loadingAddAccount ? "Loading..." : "Submit"}
          </button>
        </div>
      </div>
    );
}

const mapStateToProps = state => ({
    loadingAddAccount: state.accountDetails.loadingAddAccount,
})

export default connect(mapStateToProps, {addTelAccountPostPaid, addTelAccountPrePaid})(AddTelephones);

import axios from "axios";
import {
    externalLoginUrl,
    forgotPasswordAuthenticateUrl,
    loginUrl,
    otpAuthenticateUrl,
    otpResendUrl,
    signUpUrl,
} from "../URL";
import {
    ERROR_LOGIN,
    LOG_OUT,
    REQUEST_LOGIN,
    REQUEST_SUCCESS,
    SUCCESS_LOGIN,
    SETPUBLICTOINITIALSTATE,
    CATCH_ERROR_MSG
} from "../Types";


export function login(body, setError, setIsError) {

    return (dispatch) => {
        dispatch(requestLogin())
        axios.post(loginUrl, body).then((response) => {
            if (response.data.hasOwnProperty('errorShow')) {
                dispatch(errorLogin(response.data.errorShow))

            } else if (response.status === 200) {

                localStorage.setItem('slt_accessToken', response.data.accessToken)
                localStorage.setItem('slt_refreshToken', response.data.refreshToken)
                dispatch(successLogin())

            } else {
                dispatch(errorLogin(response.data.errorShow))
                setError(response.data.errorShow)
                setIsError(true)

            }
        }).catch(err => {
            if (err) {
                if (err.response.status === 401) {
                    dispatch(errorLogin('Invalid user name and password'))
                    setError('Invalid user name and password')
                    setIsError(true)
                } else {
                    dispatch(errorLogin(CATCH_ERROR_MSG))
                    setError(CATCH_ERROR_MSG)
                    setIsError(true)
                }
            } else {
                dispatch(errorLogin(CATCH_ERROR_MSG))
                setError(CATCH_ERROR_MSG)
                setIsError(true)
            }

        })
    }

}

export function externalLogin(body, setError, setIsError) {
    return (dispatch) => {
        dispatch(requestLogin())
        axios.post(externalLoginUrl, body).then((response) => {
            if (response.status === 200 && response.data.isSuccess) {
                localStorage.setItem('userName', response.data.dataBundle.user_id)
                localStorage.setItem('slt_accessToken', response.data.dataBundle.accessToken)
                localStorage.setItem('slt_refreshToken', response.data.dataBundle.refreshToken)

                dispatch(successLogin())

            } else {
                dispatch(errorLogin(response.data.errorShow))
                setError(response.data.errorShow)
                setIsError(true)
            }
        }).catch(() => {
            dispatch(errorLogin(CATCH_ERROR_MSG))
            setError(CATCH_ERROR_MSG)
            setIsError(true)
        })
    }
}

export function logOut(history) {
    return (dispatch) => {
        localStorage.removeItem('slt_accessToken')
        localStorage.removeItem('slt_refreshToken')
        localStorage.removeItem('userImage')
        localStorage.removeItem('userName')
        localStorage.removeItem('userType')
        localStorage.removeItem('selectedAcc')
        sessionStorage.removeItem('notificationBannerPopupChecked')
        dispatch(logoutSuccess())
        if(localStorage.getItem('loginBy') === 'ftth')
            history.push('/promo/buyonline')
        else
            history.push('/')
        localStorage.removeItem('loginBy')
        window.location.reload()
    }

}

export function register(obj, history, setError, setIsError, setState, setRegId) {

    return (dispatch) => {
        dispatch(requestLogin())
        axios.post(signUpUrl, obj).then((response) => {
            if (response.status === 200 && response.data.isSuccess) {
                dispatch(successRequest())
                if (setState) {
                    setState('regotp')
                    setRegId(response.data.dataBundle)
                } else
                    history.push('/registerOtp/' + response.data.dataBundle)
            } else {
                dispatch(errorLogin(response.data.errorShow))
                setError(response.data.errorShow)
                setIsError(true)
            }
        }).catch(() => {
            dispatch(errorLogin('Register Failed'))
            setError(CATCH_ERROR_MSG)
            setIsError(true)
        })
    }

}

export function otpVerification_Register(obj, setError, setIsError) {
    return (dispatch) => {
        axios.post(otpAuthenticateUrl, obj).then((response) => {
            if (response.status === 200 && response.data.isSuccess) {
                localStorage.setItem('slt_accessToken', response.data.dataBundle.accessToken)
                localStorage.setItem('slt_refreshToken', response.data.dataBundle.refreshToken)
                dispatch(successLogin())
            } else {
                dispatch(errorLogin(response.data.errorShow))
                setError(response.data.errorShow)
                setIsError(true)
            }
        }).catch(() => {
            dispatch(errorLogin(CATCH_ERROR_MSG))
            setError(CATCH_ERROR_MSG)
            setIsError(true)
        })
    }

}

export function resendOTP(history, setError, setIsError, setState) {
    const Querystring = require('querystring');

    let body = Querystring['stringify']({
        userName: localStorage.getItem('userName'),
        userType: localStorage.getItem('userType'),

    })
    return (dispatch) => {
        dispatch(requestLogin())
        axios.post(otpResendUrl, body).then((response) => {

            if (response.status === 200 && response.data.isSuccess) {
                dispatch(successRequest())
                if (history)
                    history.push('/otpValidation')
                if (setState)
                    setState('otp')
            } else {
                dispatch(errorLogin(response.data.errorShow))
                setError(response.data.errorShow)
                setIsError(true)
            }
        }).catch(() => {
            dispatch(errorLogin(CATCH_ERROR_MSG))
            setError(CATCH_ERROR_MSG)
            setIsError(true)
        })
    }
}

export function resetPasswordOTP(body, history, setError, setIsError, setState) {
    return (dispatch) => {
        dispatch(requestLogin())
        axios.post(forgotPasswordAuthenticateUrl, body).then((response) => {

            if (response.status === 200 && response.data.isSuccess) {
                if (setState)
                    setState('signin')
                else
                    history.push('/')
            } else {
                dispatch(errorLogin(response.data.errorShow))
                setError(response.data.errorShow)
                setIsError(true)
            }
        }).catch(() => {
            dispatch(errorLogin(CATCH_ERROR_MSG))
            setError(CATCH_ERROR_MSG)
            setIsError(true)
        })
    }
}


export function setPublicActionToInitialState() {
    return (dispatch) => {
        dispatch(setinitial())
    }
}


function requestLogin() {
    return {
        type: REQUEST_LOGIN
    }
}

function successLogin() {
    return {
        type: SUCCESS_LOGIN
    }
}

function successRequest() {
    return {
        type: REQUEST_SUCCESS
    }
}

function errorLogin(errorMessage) {
    // AlertView(errorMessage)
    return {
        type: ERROR_LOGIN,
        payload: errorMessage
    }
}

function logoutSuccess() {
    return {
        type: LOG_OUT
    }
}

function setinitial() {
    return {
        type: SETPUBLICTOINITIALSTATE
    }
}

import axiosInstance from "../../axiosInstance";
import {dispatchError, dispatchSuccess, loadingDispatch} from "../../DispatchFunctions";
import {
    CATCH_ERROR_MSG,
    ERROR_GET_EXTRA_GB_PACKAGES_DETAILS,
    GET_EXTRA_GB_PACKAGES_DETAILS,
    SUCCESS_GET_EXTRA_GB_PACKAGES_DETAILS,
} from "../../Types";
import {getExtraGBDetailsUrl} from "../../URL";

export default function getExtraGBDetails(subscriberID, packageName) {
    let url = getExtraGBDetailsUrl + 'subscriberID=' + subscriberID + '&packageName=' + packageName
    return (dispatch) => {
        dispatch(loadingDispatch(GET_EXTRA_GB_PACKAGES_DETAILS))
        axiosInstance.get(url).then((response) => {
            if (response.status === 200 && response.data.isSuccess) {
                dispatch(dispatchSuccess(SUCCESS_GET_EXTRA_GB_PACKAGES_DETAILS, response.data.dataBundle))
            } else {
                dispatch(dispatchError(ERROR_GET_EXTRA_GB_PACKAGES_DETAILS, response.data.errorShow))
            }
        }).catch((error) => {
            dispatch(dispatchError(ERROR_GET_EXTRA_GB_PACKAGES_DETAILS, CATCH_ERROR_MSG))
        })
    }

}

import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {register, setPublicActionToInitialState} from "../../redux/action/login.action";
import eyeIcon from "../../assests/images/loginUI/br2128px.png";
import backIcon from "../../assests/images/loginUI/back.png"
import {connect} from "react-redux";
import {firebaseID} from "../../redux/URL";
import eyeCloseIcon from "../../assests/images/loginUI/br1128px.png";
import LoadingBbBody from "../boardBand/LoadingBbBody";
import ErrorAlert from "../alerts/ErrorAlert";
import TermsConditons from "../common/TermsConditons";


const Querystring = require('querystring');

function Register(props) {

    const [userName, setUserName] = useState("");
    const [Name, setName] = useState("");
    const [password, setPassword] = useState("");

    const [agree, setAgree] = useState(false);
    const [showTerms, setShowTerms] = useState(false);

    //used to error handling
    const [agreeClass, setAgreeClass] = useState('');
    const [userNameError, setUserNameError] = useState('');
    const [nameError, setNameError] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [isPasswordShown, setIsPasswordShown] = useState(false);

    const [isError, setIsError] = useState(false);
    const [error, setError] = useState('');

    const togglePasswordVisiblity = () => {
        setIsPasswordShown(!isPasswordShown)

    }


    useEffect(() => {
        props.setPublicActionToInitialState()
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    const checkStatus = (value) => {
        setUserNameError('')
        let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        let regExp = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im

        if (!value) {
            setUserNameError('User ID cannot be empty')
            return false
        } else if (re.test(value))
            return 'EMAIL'
        else if (value.match(regExp)) {
            return 'MOBILE'
        } else {
            setUserNameError('Enter valid email or mobile number')
            return false
        }


    }

    const submit = () => {
        if (checkStatus(userName) !== false) {
            if (agree) {

                if (password.length < 6) {
                    setPasswordError('Minimum 6 characters')
                }else if (!Name){
                    setNameError('Name cannot be empty')
                } else {
                    let body = Querystring['stringify']({
                        userName: userName,
                        password: password,
                        Name: Name,
                        userType: checkStatus(userName),
                        firebaseId: firebaseID,
                        appVersion: '1.0',
                        osType: 'iOS',
                        confirmPassword: password
                    })
                    localStorage.setItem('userType', checkStatus(userName))
                    localStorage.setItem('userName', userName)
                    props.register(body, props.history, setError, setIsError)
                }

            } else {
                setAgreeClass('red')
            }

        }


    }

    if (props.loading)
        return <LoadingBbBody/>
    if(showTerms)
        return <div>
            <TermsConditons setShowTerms={setShowTerms} setAgree={setAgree}/>
        </div>
    return (
        <div>
            {isError ? <ErrorAlert error={error} setError={setError} setIsError={setIsError}/> : null}

            <div className='form d-block m-auto login-form'>
                <p className='titles'>Register</p>

                <div className="input-group">
                <div className={userNameError? "input-container error":"input-container"}>
                    <span className='error-message float-right'>{userNameError}</span>
                    <label className='d-block'>User ID <p className='d-inline red'>*</p></label>
                    <input type='text' className='pLeft' value={userName} placeholder='Email or mobile number'
                           onChange={(e) => setUserName(e.target.value.trim())}/>

                </div>

                <div className={nameError? "input-container error":"input-container"}>
                    <span className='error-message float-right'>{nameError}</span>
                    <label className='d-block'>Name <p className='d-inline red'>*</p></label>
                    <input type='text' className='pLeft' value={Name} onChange={(e) => setName(e.target.value)} placeholder='Full Name'/>

                </div>

                <div className={passwordError? "input-container error":"input-container"}>

                    <span className='error-message float-right'>{passwordError}</span>
                    <label className='d-block'>Password <p className='d-inline red'>*</p></label>
                    <img src={isPasswordShown ? eyeIcon : eyeCloseIcon} alt='eyeLogo' className='eye'
                         onClick={() => togglePasswordVisiblity()}/>
                    <input type={isPasswordShown ? "text" : "password"} className='pLeft' value={password}  placeholder='Minimum 6 characters'
                           onChange={(e) => setPassword(e.target.value.trim())}/>

                </div>
                </div>

                <div className='d-flex agree'>
                    <input type='checkbox' id="sign" className='sign' checked={agree}
                           onChange={(e) => setAgree(e.target.checked)}/>
                    <label  className={agreeClass}>I agree to <strong  onClick={()=>setShowTerms(true)} > terms & conditions</strong></label>
                </div>


                <div className='mt-4 clearfix'>
                    <button className='medium blue float-right'
                            onClick={(e) => submit(e)}>{props.loading ? 'Please wait...' : 'Register'}</button>
                </div>
            </div>

            <Link to='/' className="btn-back">
                <div className='back'>
                    <img src={backIcon} alt='backLogo' className='back'/>
                    <p>Back to sign in</p>
                </div>
            </Link>
        </div>
    )
}

const mapStateToProps = state => ({
    loading: state.login.loading,
    error: state.login.error
})

export default connect(mapStateToProps, {register, setPublicActionToInitialState})(Register);

import React from 'react';
import {connect} from "react-redux";
import LoadingBbBody from "../boardBand/LoadingBbBody";

function MyVoicePackage({loading, serviceList}) {

    if (loading) {
        return <div className='rightSide loading'> <LoadingBbBody loading={loading} border={false}/> </div>
    } else if (serviceList.hasOwnProperty('listofPEOService')) {
        if (serviceList.listofVoiceService.length === 0) {
            return <h3>No account</h3>
        } else {
            return (
                <div className='rightSide'>
                    <div className='myPackage'>
                        <div>
                            <div className='d-flex m-auto align-items-center'>
                                {
                                    serviceList.listofVoiceService.map((val, index) => {
                                        return <div className='bodyView' key={index}>
                                            <div className='firstRow'>Service ID: {val.serviceID}</div>
                                            <div className='secondRow'>{val.packageName}</div>
                                            <div className='thirdRow'>
                                                <div>Status</div>
                                                <div className='blue'>{val.serviceStatus}</div>
                                            </div>
                                        </div>
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

    } else
        return null

}


const mapStateToProps = state => ({
    loading: state.accountDetails.loadingServiceList,
    serviceList: state.accountDetails.service_list,
})
export default connect(mapStateToProps, {})(MyVoicePackage);
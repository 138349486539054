import React, {useState} from 'react'
import axiosInstance from '../../../redux/axiosInstance'
import { CheckExistingCustomer,  OTPRequest, OTPValidation, updatesContacttnfoUrl, saveDraftDetailsLTE, updateDraftDetailsLTE, updateDraftDetails } from '../../../redux/URL'
import OTPValidationLTE from './OTPValidationLTE'
import ErrorAlert from '../../alerts/ErrorAlert'
import CustomerStateAlert from '../../alerts/CustomerStateAlert'
import { CATCH_ERROR_MSG } from '../../../redux/Types'
import TextField from "@material-ui/core/TextField";
import Autocomplete, {createFilterOptions} from "@material-ui/lab/Autocomplete";
import {nationality} from "../../ftth/userInformation/nationality";
import { useHistory } from 'react-router-dom'
import Querystring from "querystring";

const CustomerDetails = ({userInfo, setUserInfo, packageInfo, setPackageInfo, locationInfo, setState, loading, setLoading, setError, setIsError, error, isError, setOrderRef, orderRef}) => {

const history = useHistory();

  const logName = localStorage.getItem('userName')
  const logType = localStorage.getItem('userType')

  const [otpState, setOtpState] = useState(false);
  const [otpType, setOtpType] = useState('');

  const [isCusStateAlert, setIsCusStateAlert] = useState(false);
  const [cusStateType, setCusStateType] = useState('');
  const [cusStateMainMassege, setCusStateMainMassege] = useState('');
  const [cusStateMassege, setCusStateMassege] = useState('');

  const [errorList, setErrorList] = useState({});

  const handleChange = event => {
    setErrorList({})
    const {name, value} = event.target
    if (name === 'mobile') {
        if (value === logName || (userInfo.altContact && value === userInfo.altContact))
            setUserInfo({...userInfo, [name]: value, isMobileVerify: true})
        else
            setUserInfo({...userInfo, [name]: value, isMobileVerify: false})
    } else if (name === 'email') {
        if (value === logName || (userInfo.altContact && value === userInfo.altContact))
            setUserInfo({...userInfo, [name]: value, isEmailVerify: true})
        else
            setUserInfo({...userInfo, [name]: value, isEmailVerify: false})
    } else if (name === 'title') {
        if (value === 'Mr') {
            setUserInfo({...userInfo, [name]: value, gender: 'Male', nic: '', isNicVerify: false, dateOfBirth: ''})
        } else {
            setUserInfo({...userInfo, [name]: value, gender: 'Female', nic: '', isNicVerify: false, dateOfBirth: ''})
        }

    } else if (name === 'dateOfBirth') {
        if (userInfo.nationality === 'Sri Lankan' && userInfo.nic) {
            setUserInfo({...userInfo, [name]: getDobByNIC(userInfo.nic)})
        } else {
            setUserInfo({...userInfo, [name]: value})
        }
    } else if (name === 'nic' || name === 'passport') {
        setUserInfo({...userInfo, [name]: value.toUpperCase(), isNicVerify: false})
    } else {
        setUserInfo({...userInfo, [name]: value})
    }
  }

  const handleChangeBillAddress = event => {
    const {name, value} = event.target
    setPackageInfo({...packageInfo, [name]: value})

}

  const setNationality = (e, option) => {

    if (option) {
        setUserInfo({...userInfo, nationality: option, nic: '', passport: '', isNicVerify: false, dateOfBirth: ''})
    } else {
        setUserInfo({...userInfo, nationality: '', nic: '', passport: '', isNicVerify: false, dateOfBirth: ''})
    }
  }

  const sendOTPRequest = (type) => {
    let emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let mobileRegex = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im

    if (type === 'EMAIL') {
        if (!emailRegex.test(userInfo.email)) {
            setError('Enter valid email')
            setIsError(true)
        } else {
            setLoading(true)
            let val = type === 'EMAIL' ? userInfo.email : userInfo.mobile
            let url = OTPRequest + 'requestType=FTTH&requestPeriod=10&otpSource=' + type + '&otpContact=' + val

            axiosInstance.post(url).then((response) => {

                if (response.status === 200 && response.data.isSuccess) {
                    setOtpState(true)
                    setLoading(false)
                } else {
                    setError(response.data.errorShow)
                    setIsError(true)
                    setLoading(false)
                }
            }).catch(() => {
                setError(CATCH_ERROR_MSG)
                setIsError(true)
                setLoading(false)
            })
        }
    } else if (type === 'MOBILE') {
        if (!mobileRegex.test(userInfo.mobile)) {
            setError('Enter valid mobile number')
            setIsError(true)
        } else {
            setLoading(true)
            let val = type === 'EMAIL' ? userInfo.email : userInfo.mobile
            let url = OTPRequest + 'requestType=FTTH&requestPeriod=10&otpSource=' + type + '&otpContact=' + val

            axiosInstance.post(url).then((response) => {

                if (response.status === 200 && response.data.isSuccess) {
                    setOtpState(true)
                    setLoading(false)
                } else {
                    setError(response.data.errorShow)
                    setIsError(true)
                    setLoading(false)
                }
            }).catch(() => {
                setError(CATCH_ERROR_MSG)
                setIsError(true)
                setLoading(false)
            })
        }
    }
  }

  const otpValidation = (otp) => {
    setLoading(true)
    let loginType = localStorage.getItem('userType')
    let url = OTPValidation + 'requestType=FTTH&otpSource=' + otpType + '&otpCode=' + otp
    axiosInstance.post(url).then((response) => {
        if (response.status === 200 && response.data.isSuccess) {
            setOtpState(false)
            setErrorList({})
            if (otpType === 'MOBILE') {
                setUserInfo({...userInfo, isMobileVerify: true})
            } else {
                setUserInfo({...userInfo, isEmailVerify: true})
            }
            if (loginType === 'MOBILE' && otpType === 'EMAIL') {
                updateContact(userInfo.email)
            }
            if (loginType !== 'MOBILE' && otpType === 'MOBILE') {
                updateContact(userInfo.mobile)
            }
            setLoading(false)
        } else {
            setError(response.data.errorShow)
            setIsError(true)
            setLoading(false)
        }
    }).catch(() => {
        setError(CATCH_ERROR_MSG)
        setIsError(true)
        setLoading(false)
    })
  } 

  const updateContact = (value) => {
    setLoading(true)
    const Querystring = require('querystring');
    let body = Querystring['stringify']({
        userName: localStorage.getItem('userName'),
        name: userInfo.fName + ' ' + userInfo.lName,
        altrContact: value
    })
    axiosInstance.post(updatesContacttnfoUrl, body).then(response => {
        if (response.data.isSuccess) {
            setLoading(false)
            // setSuccess(true)
        } else {
            setError(response.data.erroShow)
            setIsError(true)
            setLoading(false)
        }
    }).catch(() => {
        setError(CATCH_ERROR_MSG)
        setIsError(true)
        setLoading(false)
    })
  }

  const checkNicExistingUser = () => {
    let nicRegex = /^([0-9]{9}[x|X|v|V]|[0-9]{12})$/

    if (userInfo.nationality === 'Sri Lankan') {
        if (userInfo.nic) {
            if (nicRegex.test(userInfo.nic)) {
                if (getGenderByNIC(userInfo.nic, userInfo.gender)) {
                    let url = CheckExistingCustomer + 'NIC=' + userInfo.nic
                    axiosInstance.get(url).then(response => {
                        // console.log(response)
                        if (response.data.isSuccess) {
                            let obj = response.data.dataBundle
                            // console.log(obj) 

                            if (obj.eligibility === 'N') {
                                // setError('Oops, you are not eligible to request this service..!')
                                setError(response.data.errorMessege)
                                setIsError(true)
                                setUserInfo({...userInfo, nic: ''})

                                if(obj.customer_exist === 'Y'){
                                    setCusStateType('notEligible')
                                    setCusStateMainMassege('You are an Existing customer!')
                                    setCusStateMassege('Please visit the regional office.')
                                    setIsCusStateAlert(true)
                                }else{
                                    setCusStateType('notEligible')
                                    setCusStateMainMassege('You are not an Existing customer!')
                                    setCusStateMassege('Please visit the regional office.')
                                    setIsCusStateAlert(true)
                                }

                            } else if (obj.eligibility === 'Y') {
                                if (obj.customer_exist === 'Y') {

                                    // setUserInfo(prevState => ({...prevState, isExistCus: true, sltnic: obj.sltnic, isNicVerify: true}))
                                    setUserInfo({
                                        ...userInfo,
                                        isExistCus: true,
                                        sltnic: obj.sltnic,
                                        isNicVerify: true,
                                        dateOfBirth: getDobByNIC(userInfo.nic)
                                    })

                                    if(obj.outstanding === 'Y'){
                                        setCusStateType('outstanding')
                                        setCusStateMainMassege('You are an Existing customer!')
                                        setCusStateMassege('Check your outstanding amount.')
                                        setIsCusStateAlert(true)
                                    }


                                } else {
                                    setUserInfo({
                                        ...userInfo,
                                        isExistCus: false,
                                        sltnic: obj.sltnic,
                                        isNicVerify: true,
                                        dateOfBirth: getDobByNIC(userInfo.nic)
                                    })
                                }

                            }
                        } else {
                            setError(response.data.errorShow)
                            setIsError(true)
                        }
                    }).catch((err) => {

                        setError('Oops, something went wrong..!')
                        setIsError(true)
                    })
                } else {
                    let errors = {};
                    errors['title'] = 'Not match with Nic'
                    errors['nic'] = 'Not match with title'
                    setErrorList(errors)
                    setError('NIC and your name title not match')
                    setUserInfo({...userInfo, nic: ''})
                    setIsError(true)
                }
            } else {
                setError('Enter Valid NIC Number')
                setIsError(true)
            }

        }
    } else {
        if (userInfo.passport && userInfo.passport.length < 10) {
            let url = CheckExistingCustomer + 'PP=' + userInfo.passport
            axiosInstance.get(url).then(response => {

                if (response.data.isSuccess) {
                    let obj = response.data.dataBundle
                    if (obj.customer_exist === 'Y') {
                        setUserInfo({...userInfo, isExistCus: true})
                    } else {
                        setUserInfo({...userInfo, isExistCus: false})
                    }
                    if (obj.eligibility === 'N' && obj.outstanding === 'Y') {
                        setError('Please settle the current outstanding balance..!')
                        setIsError(true)
                        setUserInfo({...userInfo, passport: ''})
                    } else if (obj.eligibility === 'N' && obj.outstanding === 'Y') {
                        setError('Oops, you are not eligible to request this service..!')
                        setIsError(true)
                        setUserInfo({...userInfo, passport: ''})
                    } else if (obj.eligibility === 'Y') {
                        setUserInfo({...userInfo, isNicVerify: true})
                    }
                } else {
                    setError(response.data.errorShow)
                    setIsError(true)
                }
            }).catch((err) => {

                setError('Oops, something went wrong..!')
                setIsError(true)
            })
        } else {
            setError('Enter Valid Passport Number')
            setIsError(true)
        }
    }

  }

  const getGenderByNIC = (NIC, Gender) => {

    var validGender = false;

    if (NIC.length === 10) {
        let dates = Number(NIC.substring(2, 5));
        if (dates < 500) {
            if (Gender === "Male") {
                validGender = true;
            }
        } else {
            if (Gender === "Female") {
                validGender = true;
            }
        }
    } else if (NIC.length === 12) {
        let datesN = Number(NIC.substring(4, 7));
        if (datesN < 500) {
            if (Gender === "Male") {
                validGender = true;
            }
        } else {
            if (Gender === "Female") {
                validGender = true;
            }
        }
    }

    return validGender;
  }

  const getDateMonth = (date) => {
    let dateMonth;
    if (date > 31) {
        if (date > 60) {
            if (date > 91) {
                if (date > 121) {
                    if (date > 152) {
                        if (date > 182) {
                            if (date > 213) {
                                if (date > 244) {
                                    if (date > 274) {
                                        if (date > 305) {
                                            if (date > 335) {
                                                date = date - 335;
                                                dateMonth = "12-" + (date + "").padStart(2, '0');
                                            } else {
                                                date = date - 305;
                                                dateMonth = "11-" + (date + "").padStart(2, '0');
                                            }

                                        } else {
                                            date = date - 274;
                                            dateMonth = "10-" + (date + "").padStart(2, '0');
                                        }

                                    } else {
                                        date = date - 244;
                                        dateMonth = "09-" + (date + "").padStart(2, '0');
                                    }

                                } else {
                                    date = date - 213;
                                    dateMonth = "08-" + (date + "").padStart(2, '0');
                                }

                            } else {
                                date = date - 182;
                                dateMonth = "07-" + (date + "").padStart(2, '0');
                            }

                        } else {
                            date = date - 152;
                            dateMonth = "06-" + (date + "").padStart(2, '0');
                        }

                    } else {
                        date = date - 121;
                        dateMonth = "05-" + (date + "").padStart(2, '0');
                    }

                } else {
                    date = date - 91;
                    dateMonth = "04-" + (date + "").padStart(2, '0');
                }

            } else {
                date = date - 60;
                dateMonth = "03-" + (date + "").padStart(2, '0');
            }

        } else {
            date = date - 31;
            dateMonth = "02-" + (date + "").padStart(2, '0');
        }

    } else {
        dateMonth = "01-" + (date + "").padStart(2, '0');
    }

    return dateMonth;
  }

  const getDobByNIC = (NIC) => {
    let dob = "";
    if (NIC.length === 10) {
        let year = Number(19 + NIC.substring(0, 2));
        let dates = Number(NIC.substring(2, 5));
        if (dates < 500) {
            dob = year + "-" + getDateMonth(dates);
        } else {
            dates = dates - 500;
            dob = year + "-" + getDateMonth(dates);
        }
    } else if (NIC.length === 12) {
        let year = Number(NIC.substring(0, 4));
        let dates = Number(NIC.substring(4, 7));
        if (dates < 500) {
            dob = year + "-" + getDateMonth(dates);
        } else {
            dates = dates - 500;
            dob = year + "-" + getDateMonth(dates);
        }
    }

    return dob;
  }

  const validation = () => {
    let errors = {};
    let formIsValid = true;

    if (!userInfo.title) {
        errors['title'] = 'Cannot be Empty'
        formIsValid = false
    } else if (!userInfo.fName) {
        errors['fName'] = 'Cannot be Empty'
        formIsValid = false
    } else if (!userInfo.lName) {
        errors['lName'] = 'Cannot be Empty'
        formIsValid = false
    } else if (!userInfo.isMobileVerify) {
        errors['mobile'] = 'Should be verified'
        formIsValid = false
    } else if (!userInfo.isEmailVerify) {
        errors['email'] = 'Should be verified'
        formIsValid = false
    } else if (!userInfo.dateOfBirth) {

        errors['dateOfBirth'] = 'Cannot be Empty'
        formIsValid = false
    } else if (userInfo.iType === 'nic') {
        if (!userInfo.isNicVerify) {
            errors['nic'] = 'Should be verified'
            formIsValid = false
        }
    } else if (userInfo.iType === 'pp') {
        if (!userInfo.passport) {
            errors['passport'] = 'Cannot be Empty'
            formIsValid = false
        }
    }else if (!packageInfo.city) {
        errors['city'] = 'Cannot be Empty'
        formIsValid = false
    } else if (!packageInfo.postalCode) {
        errors['postalCode'] = 'Cannot be Empty'
        formIsValid = false
    } else if (!packageInfo.postalCode) {
        errors['postalCode'] = 'Cannot be Empty'
        formIsValid = false
    } else if (!packageInfo.address1) {
        errors['address1'] = 'Cannot be Empty'
        formIsValid = false
    } else if (!packageInfo.address2) {
        errors['address2'] = 'Cannot be Empty'
        formIsValid = false
    }

    if (formIsValid) {
        // setState(4)
    //    console.log(orderRef)

    //    console.log(!orderRef)
       
        if(!orderRef){
            saveDraft()
        }else{ 
            updateDraft()
        }
        
    } else {
        setErrorList(errors)
        // setFormIsValid(formIsValid)
    }

  }


  const saveDraft = () => {

    // let params = 'NIC=' + userInfo.nic + '&PP=' + userInfo.passport + '&TITLE=' + userInfo.title + '&FIRSTNAME=' +
    //     userInfo.fName + '&LASTNAME=' + userInfo.lName + '&BIRTH_DATE=' + userInfo.dateOfBirth + '&NATIONALITY=' + userInfo.nationality +
    //     '&SERVICE_ADD_NO=' + 60 + '&SERVICE_ADD_STREET1=' + locationInfo.address1 + '&SERVICE_ADD_STREET2=' + locationInfo.address2 + '&SERVICE_ADD_CITY=' + locationInfo.city +
    //     '&SERVICE_ADD_DISTRICT=' + locationInfo.district + '&BILLING_ADD_NO=' + 60 +
    //     '&BILLING_ADD_STREET1=' + packageInfo.address1 + '&BILLING_ADD_STREET2=' + packageInfo.address2 + '&BILLING_ADD_CITY=' + packageInfo.city + '&SERVICE_ADD_POSTAL_CODE=' + locationInfo.postalCode + '&RTOM=' + locationInfo.rTom +
    //     // eslint-disable-next-line no-useless-concat
    //     '&EQTYPE=' + 'FDP' + '&LONGITUDE=' + locationInfo.lng + '&LATITUDE=' + locationInfo.lat +
    //     // eslint-disable-next-line no-useless-concat
    //     ' &SERVICE_TYPE=' + 'FTTH' + '&SERVICE_ADD_PROVINCE=' + '' + ' &BILLING_ADD_PROVINCE=' + '' +
    //     // eslint-disable-next-line no-useless-concat
    //     '&VOICE_PACKAGE=' + 'Home 1st Line - My Phone' + ' &BB_PACKAGE=' + packageInfo.bbPackages + '&PEO_PACKAGE=' + packageInfo.peoTvPackages + '&BILLING_ADD_POSTAL_CODE=' + packageInfo.postalCode + '&gender=' + userInfo.gender + '&iddstatus=' + packageInfo.iddActivate + '&billinG_MODE=' + packageInfo.billType +
    //     // eslint-disable-next-line no-useless-concat
    //     '&email=' + userInfo.email + '&mobileno=' + userInfo.mobile + '&LOOP_AVAILABLITY=' + 'Y' + '&SLTNIC=' + userInfo.sltnic + '&REF_NO=' + orderRef

    let body = Querystring['stringify']({
        'NIC': userInfo.nic,
        'PP': userInfo.passport,
        'TITLE': userInfo.title,
        'FIRSTNAME': userInfo.fName,
        'LASTNAME': userInfo.lName,
        'BIRTH_DATE': userInfo.dateOfBirth,
        'NATIONALITY': userInfo.nationality,
        'SERVICE_ADD_NO': 60,
        'SERVICE_ADD_STREET1': locationInfo.address1,
        'SERVICE_ADD_STREET2': locationInfo.address2,
        'SERVICE_ADD_CITY': locationInfo.city,
        'SERVICE_ADD_DISTRICT': locationInfo.district,
        'BILLING_ADD_NO': 60,
        'BILLING_ADD_STREET1': packageInfo.address1,
        'BILLING_ADD_STREET2': packageInfo.address2,
        'BILLING_ADD_CITY': packageInfo.city,
        'SERVICE_ADD_POSTAL_CODE': locationInfo.postalCode,
        'RTOM': locationInfo.rTom,
        'EQTYPE': 'FDP',
        'LONGITUDE': locationInfo.lng,
        'LATITUDE': locationInfo.lat,
        'SERVICE_TYPE': 'LTE',
        'SERVICE_ADD_PROVINCE': '',
        'BILLING_ADD_PROVINCE': '',
        'VOICE_PACKAGE': packageInfo.voicePackages,
        'BB_PACKAGE': packageInfo.bbPackages,
        'PEO_PACKAGE': packageInfo.peoTvPackages,
        'BILLING_ADD_POSTAL_CODE': packageInfo.postalCode,
        'gender': userInfo.gender,
        'iddstatus': packageInfo.iddActivate,
        'billinG_MODE': packageInfo.billType,
        'email': userInfo.email,
        'mobileno': userInfo.mobile,
        'LOOP_AVAILABLITY': 'Y',
        'SLTNIC': userInfo.sltnic
        // 'REF_NO': orderRef
    })

    // setSavingDraft(true)
    axiosInstance.post(saveDraftDetailsLTE, body).then((response) => {
        // console.log(response)
        if (response.data.isSuccess) {
            setOrderRef(response.data.dataBundle.orderRefLTE) 
            // setSavingDraft(false)
            setState(5)
        } else {
            // setSavingDraft(false)
            let errors = {};
            errors['pack'] = 'Save draft failed'
            setErrorList(errors)
        }
    }).catch(() => {
        let errors = {};
        errors['pack'] = 'Save draft failed'
        setErrorList(errors)
        // setSavingDraft(false)
    })
}

const updateDraft = (availability) => {

    let body = Querystring['stringify']({
        'NIC': userInfo.nic,
        'PP': userInfo.passport,
        'TITLE': userInfo.title,
        'FIRSTNAME': userInfo.fName,
        'LASTNAME': userInfo.lName,
        'BIRTH_DATE': userInfo.dateOfBirth,
        'NATIONALITY': userInfo.nationality,
        'SERVICE_ADD_NO': 60,
        'SERVICE_ADD_STREET1': locationInfo.address1,
        'SERVICE_ADD_STREET2': locationInfo.address2,
        'SERVICE_ADD_CITY': locationInfo.city,
        'SERVICE_ADD_DISTRICT': locationInfo.district,
        'BILLING_ADD_NO': 60,
        'BILLING_ADD_STREET1': packageInfo.address1,
        'BILLING_ADD_STREET2': packageInfo.address2,
        '&BILLING_ADD_CITY': packageInfo.city,
        'SERVICE_ADD_POSTAL_CODE': locationInfo.postalCode,
        'RTOM': locationInfo.rTom,
        'EQTYPE': 'FDP',
        'LONGITUDE': locationInfo.lng,
        'LATITUDE': locationInfo.lat,
        'SERVICE_TYPE': 'LTE',
        'SERVICE_ADD_PROVINCE': '',
        'BILLING_ADD_PROVINCE': '',
        'VOICE_PACKAGE': packageInfo.voicePackages,
        'BB_PACKAGE': packageInfo.bbPackages,
        'PEO_PACKAGE': packageInfo.peoTvPackages,
        'BILLING_ADD_POSTAL_CODE': packageInfo.postalCode,
        'gender': userInfo.gender,
        'iddstatus': packageInfo.iddActivate,
        'billinG_MODE': packageInfo.billType,
        'email': userInfo.email,
        'mobileno': userInfo.mobile,
        'LOOP_AVAILABLITY': availability,
        'SLTNIC': userInfo.sltnic,
        'REF_NO': orderRef
    })

    let url = updateDraftDetailsLTE + '?NIC=' + userInfo.nic + '&PP='+ userInfo.passport + '&TITLE='+ userInfo.title +'&FIRSTNAME='+ userInfo.fName +
    '&LASTNAME=' + userInfo.lName + '&BIRTH_DATE =' + userInfo.dateOfBirth + '&NATIONALITY='+ userInfo.nationality + '&SERVICE_ADD_NO='+ 60 +
    '&SERVICE_ADD_STREET1='+ locationInfo.address1 + '&SERVICE_ADD_STREET2='+ locationInfo.address2 + '&SERVICE_ADD_CITY='+ locationInfo.city + 
    '&SERVICE_ADD_DISTRICT='+ locationInfo.district + '&BILLING_ADD_NO='+ 60 + '&BILLING_ADD_STREET1='+ packageInfo.address1 + '&BILLING_ADD_STREET2='+ packageInfo.address2 +
    '&BILLING_ADD_CITY='+ packageInfo.city + '&SERVICE_ADD_POSTAL_CODE='+ locationInfo.postalCode + '&RTOM='+ locationInfo.rTom + '&EQTYPE='+ 'FDP'+ '&LONGITUDE='+ locationInfo.lng +
    '&LATITUDE='+ locationInfo.lat + '&SERVICE_TYPE='+ 'LTE' + '&SERVICE_ADD_PROVINCE='+ '' + '&BILLING_ADD_PROVINCE='+ '' + '&VOICE_PACKAGE='+ packageInfo.voicePackages + '&BB_PACKAGE='+ packageInfo.bbPackages +
    '&PEO_PACKAGE='+ packageInfo.peoTvPackages + '&BILLING_ADD_POSTAL_CODE='+ packageInfo.postalCode + '&gender='+ userInfo.gender + '&iddstatus='+ packageInfo.iddActivate +
    '&billinG_MODE='+ packageInfo.billType + '&email='+ userInfo.email + '&mobileno='+ userInfo.mobile +
    '&LOOP_AVAILABLITY='+ availability + '&SLTNIC='+ userInfo.sltnic + '&REF_NO='+ orderRef

    axiosInstance.post(updateDraftDetailsLTE, body).then((response) => { //updateDraftDetailsLTE, body
        if (response.data.isSuccess) {
            setState(5)
        } else {

            let errors = {};
            errors['pack'] = response.data.errorShow
            setErrorList(errors)
        }
        setLoading(false)
    }).catch(() => {
        let errors = {};
        errors['pack'] = 'Update draft failed'
        setErrorList(errors)
    })
}

  const checkNationality = () => {
    return nationality.includes(userInfo.nationality)
  }

  const checkCusStateAlert = () => {
    setIsCusStateAlert(false)
    history.push('/boardBand/summary')
    // return nationality.includes(userInfo.nationality)
  }

  const filterOptions = createFilterOptions({
    matchFrom: 'start',
    stringify: option => option,
  });


  const sameAsBilling = () => {
    if (!packageInfo.isSameAsBilling) {
        setPackageInfo({
            ...packageInfo,
            isSameAsBilling: true,
            city: locationInfo.city,
            postalCode: locationInfo.postalCode,
            address1: locationInfo.address1,
            address2: locationInfo.address2,
            district: locationInfo.district
        })
    } else {
        setPackageInfo({
            ...packageInfo,
            isSameAsBilling: false,
            city: '',
            postalCode: '',
            address1: '',
            address2: '',
            district: ''
        })
    }
}

// console.log(userInfo)
// console.log(packageInfo)
// console.log(orderRef) 
// console.log(locationInfo)
  return (
    <div className='customerDetails'>
      {otpState ?
          <OTPValidationLTE otpValidation={otpValidation} email={userInfo.email} mobile={userInfo.mobile}
                                   loading={loading}
                                   otpType={otpType} setOtpState={setOtpState}
                                   sendOTPRequest={sendOTPRequest}/> : null}
      {
          isError ? <ErrorAlert error={error} setError={setError} setIsError={setIsError}/> : null
      }
      {
        isCusStateAlert ? <CustomerStateAlert checkCusStateAlert={checkCusStateAlert} 
                                                cusStateType={cusStateType} 
                                                cusStateMassege={cusStateMassege}
                                                cusStateMainMassege ={cusStateMainMassege}/> :null
      }
      <div className='customerDetailForm'>
        <div className="form-container clearfix">
          <div className="form-inputs-container">

            <div className='d-flex'>

              <div className='input-container title-input w-auto'>
                <label>
                  <div className="label">Title</div>
                  <p className='d-inline red m-0 ml-1'>*</p>
                </label>

                <div className="input-field"> 
                  {errorList.title ?
                  <div className="error-msg mt-2">{errorList.title}</div> : null}
                  <select name='title' value={userInfo.title}
                    onChange={handleChange}>
                      <option value='' hidden={true}/>
                      <option value='Mr'>Mr</option>
                      <option value='Mrs'>Mrs</option>
                      <option value='Ms'>Ms</option>
                  </select>
                </div>
              </div>

              <div className='input-container'>
                <label>
                  <div className="label">First Name</div>
                  <p className='d-inline red m-0 ml-1'>*</p>
                </label>

                <div className="input-field">
                  {errorList.fName ? <div className="error-msg">{errorList.fName}</div> : null}
                  <input type='text' className={errorList.fName ? 'w-100 error' : 'w-100'}
                      name='fName' value={userInfo.fName}
                      onChange={handleChange}/>
                </div>

              </div>

            </div>

            <div  className='input-container'>
              <label>
                <div className="label">Last Name</div>
                <p className='d-inline red m-0 ml-1'>*</p>
              </label>
              <div className="input-field">{errorList.lName ?
                <div className="error-msg">{errorList.lName}</div> : null}
                <input type='text' className={errorList.lName ? 'w-100 error' : 'w-100'}
                    name='lName' value={userInfo.lName}
                    onChange={handleChange}/>
              </div>
            </div>

            <div className='input-container verified'>
              <label>
                <div className="label">Mobile</div>
                <p className='d-inline red m-0 ml-1'>*</p>
              </label>
              <div className="input-field">
                  {errorList.mobile ? <div className="error-msg">{errorList.mobile}</div> : null}
                  <input type='text' className={errorList.mobile ? 'w-100 error' : 'w-100'}
                        disabled={logType === 'MOBILE'}
                        name='mobile' value={userInfo.mobile}
                        onChange={handleChange}/>
                  {!userInfo.isMobileVerify ?
                      <button disabled={loading} className='btn-verify' onClick={() => {
                          setOtpType('MOBILE')
                          sendOTPRequest('MOBILE')
                          }}>{loading ? 'Verifying' : 'Verify'}
                      </button> :
                      <span className='confirm'><span className="icon-confirm"/></span>}
                </div>
            </div>

            <div className='input-container verified'> 
              <label>
                <div className="label">Email</div>
                <p className='d-inline red m-0 ml-1'>*</p>
              </label>
              <div className="input-field">
                  {errorList.email ? <div className="error-msg">{errorList.email}</div> : null}
                  <input type='text' className={errorList.email ? 'w-100 error' : 'w-100'}
                    disabled={logType !== 'MOBILE'}
                    name='email' value={userInfo.email}
                    onChange={handleChange}/>
                  {!userInfo.isEmailVerify ?
                  <button disabled={loading} className='btn-verify' onClick={() => {
                      setOtpType('EMAIL')
                      sendOTPRequest('EMAIL')
                                    }}>{loading ? 'Verifying' : 'Verify'}
                  </button> :
                  <span className='confirm'><span className="icon-confirm"/></span>
                  }
              </div>
            </div>

            <div  className='input-container'>
                <label>Billing Address <p className='d-inline red m-0 ml-1'>*</p></label>
                <div className="check-input">
                    <div className="input-field">
                        <input type='checkbox' id="same" name="samebill" className='sign'
                               checked={packageInfo.isSameAsBilling}
                               onChange={() => sameAsBilling()}
                             />
                        <label htmlFor="same"> Same as service address</label>
                    </div>
                </div>
              
            </div>

            <div  className='input-container'>
                <label>
                    <div className="label">Billing Address Line 1</div>
                        <p className='d-inline red m-0 ml-1'>*</p>
                </label>
                <div className="input-field">

                    {errorList.address1 ? <div className="error-msg">{errorList.address1}</div>
                        : null}
                        <input className={errorList.address1 ? 'w-100 error' : 'w-100'} type='text'
                               name='address1' value={packageInfo.address1}
                               disabled={packageInfo.isSameAsBilling}
                               onChange={handleChangeBillAddress}/>
                </div>
            </div>

            <div className='input-container'>

                <label>
                    <div className="label">City</div>
                    <p className='d-inline red m-0 ml-1'>*</p>
                </label>
                <div className="input-field">
                    {errorList.city ? <div className="error-msg">{errorList.city}</div> : null}
                       <input className={errorList.city ? 'w-100 error' : 'w-100'} type='text'
                              name='city'
                              value={packageInfo.city}
                              disabled={packageInfo.isSameAsBilling}
                              onChange={handleChangeBillAddress}/>
                </div>
            </div>

          </div>

          <div  className="form-inputs-container">

            <div className='d-flex'>
              <div className="input-container">
                <label>Nationality</label>

                <div className="input-field">
                  <Autocomplete
                    className='autoCom'
                    options={nationality}
                    getOptionLabel={(option) => option}
                    // onInputChange={(e, option) => setNationality(e, option)}
                    onChange={(e, option) => setNationality(e, option)}
                    value={nationality.find(obj => {
                      return obj === userInfo.nationality
                    })}
                    freeSolo
                    filterOptions={filterOptions}
                    renderInput={(params) => <TextField  {...params} label="" variant="outlined"
                                                                             style={{margin: '0px'}}/>}
                  />
                </div>
              </div>
            </div>

            <>
              {
                userInfo.nationality === 'Sri Lankan' ? 
                <div className='input-container'>
                  <label>
                    <div className="label">NIC Number</div>
                    <p className='d-inline red m-0 ml-1'>*</p>
                  </label>
                  <div className="input-field">
                    {errorList.nic ? <div className="error-msg">{errorList.nic}</div> : null}
                    <input type='text' className={errorList.nic ? 'w-100 error' : 'w-100'}
                          name='nic' value={userInfo.nic} disabled={!checkNationality()}
                          onChange={handleChange} onBlur={() => checkNicExistingUser()}/>
                    {userInfo.isNicVerify ?
                        <span className='confirm'><span
                              className="icon-confirm"/></span> : null
                    }
                  </div>

                  </div> : 
                  <div className='input-container verified'>
                    <label>
                      <div className="label">Passport Number</div>
                      <p className='d-inline red m-0 ml-1'>*</p>
                    </label>
                    <div className="input-field">
                        {errorList.passport ?
                        <div className="error-msg">{errorList.passport}</div> : null}
                        <input type='text' className={errorList.passport ? 'w-100 error' : 'w-100'}
                              name='passport' disabled={!checkNationality()}
                              value={userInfo.passport}
                              onChange={handleChange} onBlur={() => checkNicExistingUser()}/>
                    </div>
                  </div>
              }
              
                  <div className='input-container'>
                      <label>
                        <div className="label">Date of Birth</div>
                        <p className='d-inline red m-0 ml-1'>*</p>
                      </label>
                      <div className="input-field"> {errorList.dateOfBirth ?
                        <div className="error-msg">{errorList.dateOfBirth}</div> : null}
                        <input type='date' className={errorList.dateOfBirth ? 'w-100 error' : 'w-100 '}
                              disabled={userInfo.isNicVerify && userInfo.nic}
                              name='dateOfBirth' dataformatas={'dd/mm/yyyy'} placeholder={'dd/mm/yyyy'}
                              value={userInfo.dateOfBirth}
                              onChange={handleChange}/>
                        </div>
                  </div>
            </>

            <div  className='input-container'>
                <label >
                    <div className="label"> </div>
                    <p className='d-inline red m-0 ml-1'></p>
                </label>
                <div className="input-field" >
                    <br /><br /> <br />
                </div>
            </div>
            <div  className='input-container'>
                <label >
                    <div className="label"> </div>
                    <p className='d-inline red m-0 ml-1'></p>
                </label>
                <div className="input-field" >
                    <br /><br />
                </div>
            </div>

            {/* <div  className='input-container'>
                <label>Billing Address <p className='d-inline red m-0 ml-1'>*</p></label>
                <div className="check-input">
                    <div className="input-field">
                        <input type='checkbox' id="same" name="samebill" className='sign'
                               checked={packageInfo.isSameAsBilling}
                               onChange={() => sameAsBilling()}
                             />
                        <label htmlFor="same"> Same as service address</label>
                    </div>
                </div>
              
            </div> */}

            <div  className='input-container'>

              <label>
                <div className="label">Billing Address Line 2</div>
                <p className='d-inline red m-0 ml-1'>*</p>
              </label>
             
             {errorList.address2 ? <div className="error-msg">{errorList.address2}</div>
                : null}
                <input className={errorList.address2 ? 'w-100 error' : 'w-100'} type='text'
                       name='address2' value={packageInfo.address2}
                       disabled={packageInfo.isSameAsBilling}
                       onChange={handleChangeBillAddress}/>
              
            </div>

            <div className='input-container'>

                <label>
                    <div className="label">Postal Code</div>
                    <p className='d-inline red m-0 ml-1'>*</p>
                </label>
                <div className="input-field">
                    {errorList.postalCode ? <div className="error-msg">{errorList.postalCode}</div>
                        : null}

                    <input className={errorList.postalCode ? 'w-100 error' : 'w-100'} type='number'
                           name='postalCode' value={packageInfo.postalCode}
                           disabled={packageInfo.isSameAsBilling}
                           onChange={handleChangeBillAddress}/>
                </div>
            </div>

          </div>

        </div>
      </div>

      <div className='container-footer'>
          <div className="action-container right">
          <button className="btn-back" onClick={() => setState(2)}>Back</button>
              <button onClick={() => validation()} disabled={!userInfo.isNicVerify}>Next</button>
          </div>
      </div>

    </div>
  )
}

export default CustomerDetails
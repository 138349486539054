import React, {useEffect, useState} from 'react';
import {useHistory, useParams, Redirect} from "react-router-dom";
import axiosInstance from "../../redux/axiosInstance";
import {paymentsLogs} from "../../redux/URL";
import LoadingBbBody from "../boardBand/LoadingBbBody";
import {connect} from "react-redux";
import {getDraftDetailFTTH} from "../../redux/action/ftth/ftth.action";


function PaySuccess({getDraftDetailFTTH}) {

    let {orderId, status} = useParams();
    const history = useHistory();

    const [state] = useState(4);
    const [loading, setLoading] = useState(true);
    const [isError, setIsError] = useState(false);
    const [counter, setCounter] = useState(5);


    useEffect(() => {

        if (status === '1') {
            axiosInstance.get(paymentsLogs + orderId).then((response) => {

                if (response.data.isSuccess && response.data.dataBundle.length > 0) {
                    if (response.data.dataBundle[0].gw_status !== '1') {
                        setIsError(true)
                        setLoading(false)
                    } else {
                        setLoading(false)
                        getDraftDetailFTTH()
                        setInterval(() => {
                            setCounter(prevCount => prevCount - 1); // <-- Change this line!

                        }, 1000);

                    }
                } else {
                    setIsError(true)
                    setLoading(false)
                }
            })
        } else {
            setIsError(true)
            setLoading(false)
        }
        return () => {
            for (let i = 0; i < 25; i++) {
                clearInterval(i)
            }
        };
    }, [])// eslint-disable-line react-hooks/exhaustive-deps

    if (counter === 0) {
        return <Redirect to={'/connectionOrder'}/>
    }

    return (
        <div className='mainViewFTTH'>
            <div className='header'>
                {/*<div className='btn-back'>*/}
                {/*    <div className='grey'><BiArrowBack/></div>*/}
                {/*    <div className='grey'>Back</div>*/}
                {/*</div>*/}
                <div className='title'>NEW SERVICE REQUEST- SLTMOBITEL FIBRE HOME LINE</div>
            </div>


            <div className='bodyFTTH wizard-container'>
                <div className='navigation'>

                    <div className="wizard-navigation-set">
                        <div className="wizard-item-container">
                            <div className={state === 1 ? "wizard-item active" : state > 1 ? "wizard-item done" : "wizard-item"}>
                                <div className="item-icon">1</div>
                                <div className="item-name">Customer Details</div>
                            </div>
                            <div className={state === 2 ? "wizard-item active" : state > 2 ? "wizard-item done" : "wizard-item"}>
                                <div className="item-icon">2</div>
                                <div className="item-name">Feasibility Check</div>
                            </div>
                            <div className={state === 3 ? "wizard-item active" : state > 3 ? "wizard-item done" : "wizard-item"}>
                                <div className="item-icon">3</div>
                                <div className="item-name">Package Selection</div>
                            </div>
                            <div className={state === 4 && (status === '1' && !isError) ? "wizard-item done" : state > 4 ? "wizard-item done" : "wizard-item failed"}>
                                <div className="item-icon">4</div>
                                <div className="item-name">Payment</div>
                            </div>
                        </div>
                    </div>

                </div>

                <div className='successPay'>
                    {
                        loading ? <LoadingBbBody/> :
                            <div className='bodyView'>

                                <div className='img-container'>
                                    {/* <img src={completeSuccess}/> */}
                                    {(status === '1' && !isError) ? <div className="success-img"/> :
                                        <div className="failed-img"/>}
                                </div>

                                {
                                    (status === '1' && !isError) ? <div className='msg-container'>
                                        <div className='head'>Your Order is Successfully Submitted .</div>
                                        <div className='refNO'>Order Reference : {orderId}</div>


                                        <div className='closeButton'>
                                            <button onClick={() => history.push('/connectionOrder')}>Continue</button>
                                        </div>
                                        <p className='mt-3 text-white'>We are redirecting in {counter} seconds</p>
                                    </div> : <div className='msg-container'>
                                        <div className='head failed'>Your Order is Unsuccessful .</div>
                                        <div className='closeButton'>
                                            <button onClick={() => history.push('/noAccount')}>Close</button>
                                        </div>
                                    </div>
                                }


                            </div>
                    }


                </div>
            </div>
        </div>
    );
}


export default connect(null, {getDraftDetailFTTH})(PaySuccess);


import React, {useState} from 'react'
import { connect } from 'react-redux'

import { applyRedeemVoucher } from '../../../redux/action/redeemVoucher/redeemVoucher.action'
import LoadingBbBody from '../LoadingBbBody';
import SuccessAlert from '../../alerts/SuccessAlert'; 
import ErrorAlert from '../../alerts/ErrorAlert';

const RedeemVoucher = (props) => {

    const [splitText, setSplitText] = useState('');
    const [voucher, setVoucher] = useState('');

    const [success, setSuccess] = useState(false)
    const [isError, setIsError] = useState(false)
    const [error, seterror] = useState('')


    const splitVoucher = (test) =>{


        let voucherTestArry = test.split("-")

        let voucherTest = voucherTestArry.join('')//voucherTestArry.toString()


        setVoucher(voucherTest)
        renderInput(voucherTest)

    }

    // const voucherIsError = () =>{
    //     // <Link to ='/complaints'/>
    //     // history.push('/complaints')
    //   }

    const renderInput = (value) => {
      
        let splitArry = value.match(/.{1,4}/g)

        let splitTest = '';

        if(splitArry){
            splitTest = splitArry.join('-');
        }
        
        setSplitText(splitTest)
       
      };

    return (
        <div className="redeemVoucher">
             {
                       props.loading?( 
                        <LoadingBbBody loading={props.loading}/>
                    ):(
                        isError?(
                            <ErrorAlert error ={error} setError = {seterror} setIsError = {setIsError}/>
                        ):(
                            success?(
                            <SuccessAlert msg='Congratulations. Your promo code has been redeemed successfully' setSuccess={setSuccess}/>
                            ):(<div className="redeemBorder">
                            <div className="voucherHeader">
                                <h6>Enter the promo code on the voucher to avail yourself with the offer .</h6>
                            </div>
                           
                            <div className="voucherInput">
                                {/*<div className='row'>*/}
                                    <div className='vInputField'>
                                        <label htmlFor="serviceType">Enter your voucher ID :</label>
                                    </div>
                                    <div className='vInputField'>
                                        <input type="text" id="voucher" name="voucher"  value={splitText} onChange={(e) => {
                                            splitVoucher(e.target.value)
                                        }}/>
                                    </div>
                                {/*</div>*/}
                            </div>
                            <div className="submitRedeemVoucher">
                                <button className="submitRedeemVoucherButton"  onClick={() => props.applyRedeemVoucher(props.profile.subscriberid, voucher,'SCP' , setSuccess, setIsError, seterror)} >REDEEM VOUCHER</button>
                            </div>
                        </div>)
                            

                        )
                    )
                }

             

        </div>
    )
}

const mapStateToProps = state => ({
    loading: state.voucher.loading,
    voucherResponse: state.voucher.dataBundle,
    error: state.voucher.error,
  
    // serviceList: state.accountDetails.service_list,
    profile: state.broadBand.profile,
})

export default connect(mapStateToProps, {applyRedeemVoucher})( RedeemVoucher)

import axios from "axios";
import {refreshTokenUrl} from "./URL";
import {logOut} from "./action/login.action";
import {connect} from "react-redux";

const axiosInstance = axios.create();

axiosInstance.interceptors.request.use(
    config => {
        const accessToken = localStorage.getItem('slt_accessToken')
        if (accessToken) {
            config.headers['Authorization'] = 'bearer ' + accessToken;
        }
        config.headers['Content-Type'] = 'application/x-www-form-urlencoded';
        config.headers['X-IBM-Client-Id'] = '41aed706-8fdf-4b1e-883e-91e44d7f379b'; // prod
        //config.headers['X-IBM-Client-Id'] = 'e8282d66-d974-4b26-bafd-be1b0cf1f55f';  //staging
        
        return config;
    },
    error => {
        Promise.reject(error)
    });


axiosInstance.interceptors.response.use((response) => {
    return response
}, function (error) {

    const originalRequest = error.config;
    const accessToken = localStorage.getItem('slt_accessToken')
    const refreshToken = localStorage.getItem('slt_refreshToken')

    if (accessToken && refreshToken) {

        if (error.response.status === 401 && originalRequest.url ===
            refreshTokenUrl) {

            logOut()
            localStorage.removeItem('slt_accessToken')
            localStorage.removeItem('slt_refreshToken')
            localStorage.removeItem('userImage')
            window.location.reload()

        } else if (error.response.status === 401 && !originalRequest._retry) {

            originalRequest._retry = true;

            const refreshToken = localStorage.getItem('slt_refreshToken')
            const Querystring = require('querystring');
            let body = Querystring['stringify']({
                "UserName": localStorage.getItem('userName'),
                "RefreshToken": refreshToken,
                channelID: 'WEB'
            })

            return axiosInstance.post(refreshTokenUrl, body).then(response => {

                if (response.status === 200) {
                    localStorage.setItem('slt_accessToken', response.data.access_token)
                    localStorage.setItem('slt_refreshToken', response.data.refresh_token)
                    axiosInstance.defaults.headers.common['Authorization'] = 'bearer ' + response.data.access_token;
                    return axiosInstance(originalRequest);
                } else {
                    logOut()
                    localStorage.removeItem('slt_accessToken')
                    localStorage.removeItem('slt_refreshToken')
                    localStorage.removeItem('userImage')
                    window.location.reload()
                }
            }).catch(() => {
                logOut()
                localStorage.removeItem('slt_accessToken')
                localStorage.removeItem('slt_refreshToken')
                localStorage.removeItem('userImage')
                window.location.reload()
            })
        }
    }


    return Promise.reject(error);
});


export default connect(null, {logOut})(axiosInstance);

import React from 'react';
import {HashLoader} from "react-spinners";
import { css } from "@emotion/react";
const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;
function LoadingGraphs({loading}) {
    return (
        <>
            <div className='graphBody-wrapper'><div className='graphBody d-flex'>
                <div className='m-auto'>
                    <HashLoader
                         css={override}
                        size={30}
                        // width={5}
                        // margin={0}
                        color={"#0056A3"}
                        loading={loading}
                    />
                </div></div>

            </div>
        </>
    );
}

export default LoadingGraphs;

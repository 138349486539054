import React from 'react';
import {useHistory} from "react-router-dom";

function NoPeoTvAccount(props) {
    const history = useHistory();
    return (
        <div className='noPeoTvAccount'>
           <div className='text-center'> Request for Peo TV</div>
            <button className='medium blue ml-4' onClick={() => history.push('/newService/upgrade/PEO TV')}>Request</button>
        </div>
    );
}

export default NoPeoTvAccount;
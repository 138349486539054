import axiosInstance from "../../axiosInstance";
import {dispatchError, dispatchSuccess, loadingDispatch} from "../../DispatchFunctions";
import {
    CATCH_ERROR_MSG,
    ERROR_GET_PROTOCOL_REPORT,
    LOADING_PROTOCOL_REPORT,
    SUCCESS_GET_PROTOCOL_REPORT,

} from "../../Types";
import {getProtocolReportUrl} from "../../URL";

export default function getProtocolReport(subscriberID, date) {
    let url = getProtocolReportUrl + '&subscriberID=' + subscriberID + '&date=' + date

    return (dispatch) => {
        dispatch(loadingDispatch(LOADING_PROTOCOL_REPORT))
        axiosInstance.get(url).then((response) => {
            if (response.status === 200 && response.data.isSuccess) {
                dispatch(dispatchSuccess(SUCCESS_GET_PROTOCOL_REPORT, response.data.dataBundle))
            } else {
                dispatch(dispatchError(ERROR_GET_PROTOCOL_REPORT, response.data.errorShow))
            }
        }).catch((error) => {
            dispatch(dispatchError(ERROR_GET_PROTOCOL_REPORT, CATCH_ERROR_MSG))
        })
    }

}

import React, {useEffect, useState} from 'react';
import axiosInstance from "../../../redux/axiosInstance";
import Autocomplete, {createFilterOptions} from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import {Map, Marker, GoogleApiWrapper} from 'google-maps-react';
import SearchLocation from "./SearchLocation";
import {
    getCityListUrl,
    getFeasibilityCheck,
    saveDraftDetails, updateDraftDetails
} from "../../../redux/URL";
import ErrorAlert from "../../alerts/ErrorAlert";
import {CATCH_ERROR_MSG} from "../../../redux/Types";
import mapMarkerBlue from '../../../assests/images/mapMarkerBlue.svg'
import {MAP_KEY} from "../../../redux/MAPKEYS";
import LoadingBbBody from "../../boardBand/LoadingBbBody"


function LocationInformation({
                                 locationInfo,
                                 setLocationInfo,
                                 setState,
                                 setCityList,
                                 cityList,
                                 google,
                                 userInfo,
                                 packageInfo,
                                 orderRef,
                                 setOrderRef
                             }) {

    const containerStyle = {
        position: 'relative',
        width: '100%',
        height: '100%'
    }

    const Querystring = require('querystring');

    const [zoom, setZoom] = useState(7);

    const [errorList, setErrorList] = useState({});
    const [checkFesibilty, setCheckFesibilty] = useState(null);

    const [loading, setLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [error, setError] = useState('');

    const [isCitySelect, setIsCitySelect] = useState(true);

    const [valueLocSearch, setValueLocSearch] = useState('');

    useEffect(() => {
        if (cityList.length === 0)
            getCityList()

    }, [])// eslint-disable-line react-hooks/exhaustive-deps

    const getCityList = () => {
        setLoading(true)
        axiosInstance.get(getCityListUrl).then((response) => {

            if (response.data.isSuccess) {
                setCityList(...[response.data.dataBundle])
                setLoading(false)
            } else {
                setError(CATCH_ERROR_MSG)
                setIsError(true)
                setLoading(false)
            }
        }).catch(() => {
            setError(CATCH_ERROR_MSG)
            setIsError(true)
            setLoading(false)
        })
    }

    const handleChange = event => {
        setErrorList({})
        const {name, value} = event.target
        setLocationInfo({...locationInfo, [name]: value})

    }

    const dragEndLatLon = (coord) => {
        const {latLng} = coord;
        const lat = latLng.lat();
        const lng = latLng.lng();

        setCheckFesibilty(null)
        setLocationInfo({...locationInfo, lat: lat, lng: lng, dLat: lat, dLng: lng})
        setZoom(16)
    };

    const searchLatLon = (place) => {
        const {location} = place.geometry;
        const lat = location.lat();
        const lng = location.lng();
        setCheckFesibilty(null)

        setLocationInfo(prevState => ({...prevState, lat: null, lng: null, dLat: lat, dLng: lng}))
        setZoom(16)
    };

    const setCity = (e, option) => {

        if (option) {
            setLocationInfo({
                ...locationInfo,
                district: option.district,
                city: option.city,
                rTom: option.rtom,
                dLat: parseFloat(option.latitude),
                dLng: parseFloat(option.longitude),
                postalCode: option.postalcode,
                address1: '',
                address2: '',
                lat: null,
                lng: null,
            })
            setZoom(14)
            setCheckFesibilty(null)
            setIsCitySelect(true)
            setValueLocSearch('')
        } else {
            setLocationInfo({
                ...locationInfo,
                district: '',
                city: '',
                rTom: '',
                postalCode: '',
                address1: '',
                address2: '',
                lat: null,
                lng: null,
            })
            setZoom(14)
            setCheckFesibilty(null)
            setIsCitySelect(false)
            setValueLocSearch('')
        }


    }

    // const InputChanges = () => {
    //     setLocationInfo({
    //         ...locationInfo,
    //         district: '',
    //         city: '',
    //         rTom: '',
    //         postalCode: '',
    //         address1: '',
    //         address2: '',
    //         lat: null,
    //         lng: null,
    //     })
    //     setZoom(14)
    //     setCheckFesibilty(null)
    //     setIsCitySelect(false)
    // }

    const saveDraft = (availability) => {

        // let params = 'NIC=' + userInfo.nic + '&PP=' + userInfo.passport + '&TITLE=' + userInfo.title + '&FIRSTNAME=' +
        //     userInfo.fName + '&LASTNAME=' + userInfo.lName + '&BIRTH_DATE=' + userInfo.dateOfBirth + '&NATIONALITY=' + userInfo.nationality +
        //     '&SERVICE_ADD_NO=' + 60 + '&SERVICE_ADD_STREET1=' + locationInfo.address1 + '&SERVICE_ADD_STREET2=' + locationInfo.address2 + '&SERVICE_ADD_CITY=' + locationInfo.city +
        //     '&SERVICE_ADD_DISTRICT=' + locationInfo.district + '&BILLING_ADD_NO=' + 60 +
        //     '&BILLING_ADD_STREET1=' + packageInfo.address1 + '&BILLING_ADD_STREET2=' + packageInfo.address2 + '&BILLING_ADD_CITY=' + packageInfo.city + '&SERVICE_ADD_POSTAL_CODE=' + locationInfo.postalCode + '&RTOM=' + locationInfo.rTom +
        //     // eslint-disable-next-line no-useless-concat
        //     '&EQTYPE=' + 'FDP' + '&LONGITUDE=' + locationInfo.lng + '&LATITUDE=' + locationInfo.lat +
        //     // eslint-disable-next-line no-useless-concat
        //     ' &SERVICE_TYPE=' + 'FTTH' + '&SERVICE_ADD_PROVINCE=' + '' + ' &BILLING_ADD_PROVINCE=' + '' +
        //     // eslint-disable-next-line no-useless-concat
        //     '&VOICE_PACKAGE=' + 'NA' + ' &BB_PACKAGE=' + packageInfo.bbPackages + '&PEO_PACKAGE=' + packageInfo.peoTvPackages + '&BILLING_ADD_POSTAL_CODE=' + packageInfo.postalCode + '&gender=' + userInfo.gender + '&iddstatus=' + packageInfo.iddActivate + '&billinG_MODE=' + packageInfo.billinG_MODE +
        //     '&email=' + userInfo.email + '&mobileno=' + userInfo.mobile + '&LOOP_AVAILABLITY=' + availability + '&SLTNIC=' + userInfo.sltnic
        let body = Querystring['stringify']({
            'NIC': userInfo.nic,
            'PP': userInfo.passport,
            'TITLE': userInfo.title,
            'FIRSTNAME': userInfo.fName,
            'LASTNAME': userInfo.lName,
            'BIRTH_DATE': userInfo.dateOfBirth,
            'NATIONALITY': userInfo.nationality,
            'SERVICE_ADD_NO': 60,
            'SERVICE_ADD_STREET1': locationInfo.address1,
            'SERVICE_ADD_STREET2': locationInfo.address2,
            'SERVICE_ADD_CITY': locationInfo.city,
            'SERVICE_ADD_DISTRICT': locationInfo.district,
            'BILLING_ADD_NO': 60,
            'BILLING_ADD_STREET1': packageInfo.address1,
            'BILLING_ADD_STREET2': packageInfo.address2,
            '&BILLING_ADD_CITY': packageInfo.city,
            'SERVICE_ADD_POSTAL_CODE': locationInfo.postalCode,
            'RTOM': locationInfo.rTom,
            'EQTYPE': 'FDP',
            'LONGITUDE': locationInfo.lng,
            'LATITUDE': locationInfo.lat,
            'SERVICE_TYPE': 'FTTH',
            'SERVICE_ADD_PROVINCE': '',
            'BILLING_ADD_PROVINCE': '',
            'VOICE_PACKAGE': 'NA',
            'BB_PACKAGE': packageInfo.bbPackages,
            'PEO_PACKAGE': packageInfo.peoTvPackages,
            'BILLING_ADD_POSTAL_CODE': packageInfo.postalCode,
            'gender': userInfo.gender,
            'iddstatus': packageInfo.iddActivate,
            'billinG_MODE': packageInfo.billinG_MODE,
            'email': userInfo.email,
            'mobileno': userInfo.mobile,
            'LOOP_AVAILABLITY': availability,
            'SLTNIC': userInfo.sltnic
        })
        axiosInstance.post(saveDraftDetails, body).then((response) => {
            if (response.data.isSuccess) {
                setOrderRef(response.data.dataBundle.orderRef)
            } else {
                setError(response.data.errorShow)
                setIsError(true)
                setCheckFesibilty(null)
            }
            setLoading(false)
        }).catch(() => {
            setLoading(false)
            setError('Save draft failed')
            setIsError(true)
            setCheckFesibilty(null)
        })
    }

    const updateDraft = (availability) => {

        // let params = 'NIC=' + userInfo.nic + '&PP=' + userInfo.passport + '&TITLE=' + userInfo.title + '&FIRSTNAME=' +
        //     userInfo.fName + '&LASTNAME=' + userInfo.lName + '&BIRTH_DATE=' + userInfo.dateOfBirth + '&NATIONALITY=' + userInfo.nationality +
        //     '&SERVICE_ADD_NO=' + 60 + '&SERVICE_ADD_STREET1=' + locationInfo.address1 + '&SERVICE_ADD_STREET2=' + locationInfo.address2 + '&SERVICE_ADD_CITY=' + locationInfo.city +
        //     '&SERVICE_ADD_DISTRICT=' + locationInfo.district + '&BILLING_ADD_NO=' + 60 +
        //     '&BILLING_ADD_STREET1=' + packageInfo.address1 + '&BILLING_ADD_STREET2=' + packageInfo.address2 + '&BILLING_ADD_CITY=' + packageInfo.city + '&SERVICE_ADD_POSTAL_CODE=' + locationInfo.postalCode + '&RTOM=' + locationInfo.rTom +
        //     // eslint-disable-next-line no-useless-concat
        //     '&EQTYPE=' + 'FDP' + '&LONGITUDE=' + locationInfo.lng + '&LATITUDE=' + locationInfo.lat +
        //     // eslint-disable-next-line no-useless-concat
        //     ' &SERVICE_TYPE=' + 'FTTH' + '&SERVICE_ADD_PROVINCE=' + '' + ' &BILLING_ADD_PROVINCE=' + '' +
        //     // eslint-disable-next-line no-useless-concat
        //     '&VOICE_PACKAGE=' + 'NA' + ' &BB_PACKAGE=' + packageInfo.bbPackages + '&PEO_PACKAGE=' + packageInfo.peoTvPackages + '&BILLING_ADD_POSTAL_CODE=' + packageInfo.postalCode + '&gender=' + userInfo.gender + '&iddstatus=' + packageInfo.iddActivate + '&billinG_MODE=' + packageInfo.billinG_MODE +
        //     '&email=' + userInfo.email + '&mobileno=' + userInfo.mobile + '&LOOP_AVAILABLITY=' + availability + '&SLTNIC=' + userInfo.sltnic + '&REF_NO=' + orderRef

        let body = Querystring['stringify']({
            'NIC': userInfo.nic,
            'PP': userInfo.passport,
            'TITLE': userInfo.title,
            'FIRSTNAME': userInfo.fName,
            'LASTNAME': userInfo.lName,
            'BIRTH_DATE': userInfo.dateOfBirth,
            'NATIONALITY': userInfo.nationality,
            'SERVICE_ADD_NO': 60,
            'SERVICE_ADD_STREET1': locationInfo.address1,
            'SERVICE_ADD_STREET2': locationInfo.address2,
            'SERVICE_ADD_CITY': locationInfo.city,
            'SERVICE_ADD_DISTRICT': locationInfo.district,
            'BILLING_ADD_NO': 60,
            'BILLING_ADD_STREET1': packageInfo.address1,
            'BILLING_ADD_STREET2': packageInfo.address2,
            '&BILLING_ADD_CITY': packageInfo.city,
            'SERVICE_ADD_POSTAL_CODE': locationInfo.postalCode,
            'RTOM': locationInfo.rTom,
            'EQTYPE': 'FDP',
            'LONGITUDE': locationInfo.lng,
            'LATITUDE': locationInfo.lat,
            'SERVICE_TYPE': 'FTTH',
            'SERVICE_ADD_PROVINCE': '',
            'BILLING_ADD_PROVINCE': '',
            'VOICE_PACKAGE': 'NA',
            'BB_PACKAGE': packageInfo.bbPackages,
            'PEO_PACKAGE': packageInfo.peoTvPackages,
            'BILLING_ADD_POSTAL_CODE': packageInfo.postalCode,
            'gender': userInfo.gender,
            'iddstatus': packageInfo.iddActivate,
            'billinG_MODE': packageInfo.billinG_MODE,
            'email': userInfo.email,
            'mobileno': userInfo.mobile,
            'LOOP_AVAILABLITY': availability,
            'SLTNIC': userInfo.sltnic,
            'REF_NO': orderRef
        })

        axiosInstance.post(updateDraftDetails, body).then((response) => {
            if (response.data.isSuccess) {

            } else {
                setError(response.data.errorShow)
                setIsError(true)
                setCheckFesibilty(null)
            }
            setLoading(false)
        }).catch(() => {
            setLoading(false)
            setError('Update draft failed')
            setIsError(true)
            setCheckFesibilty(null)
        })
    }

    const validation = () => {
        let errors = {};
        let formIsValid = true;

        // if (!locationInfo.district) {
        //     errors['district'] = 'Cannot be Empty'
        //     formIsValid = false
        // } else
        if (!locationInfo.city) {
            errors['city'] = 'Cannot be Empty'
            formIsValid = false
        } else if (!locationInfo.postalCode) {
            errors['postalCode'] = 'Cannot be Empty'
            formIsValid = false
        } else if (locationInfo.postalCode.length !== 5) {
            errors['postalCode'] = 'invalid postal code'
            formIsValid = false
        } else if (!locationInfo.address1) {
            errors['address1'] = 'Cannot be Empty'
            formIsValid = false
        } else if (!locationInfo.address2) {
            errors['address2'] = 'Cannot be Empty'
            formIsValid = false
        }

        if (formIsValid) {
            setState(3)
        } else {
            setErrorList(errors)
        }
    }

    const isFesibilty = () => {
        if (locationInfo.city && locationInfo.lat && isCitySelect) {
            let url = getFeasibilityCheck + 'rtom=' + locationInfo.rTom + '&eqtype=FDP&longitude=' + locationInfo.lng + '&latitude=' + locationInfo.lat

            setLoading(true)
            axiosInstance.get(url).then(response => {
                if (response.data.isSuccess) {

                    if (response.data.dataBundle.dpexist === 'Y') {
                        setCheckFesibilty(true)
                    } else {
                        setCheckFesibilty(false)
                    }
                    setLoading(false)
                } else {
                    setLoading(false)
                    setError(response.data.errorShow)
                    setIsError(true)
                }
            }).catch(() => {
                setLoading(false)
                setError(CATCH_ERROR_MSG)
                setIsError(true)
            })
        } else {
            setError('Please Select Your Service Delivery Location')
            setIsError(true)
        }

    }

    const filterOptions = createFilterOptions({
        matchFrom: 'start',
        stringify: option => option.city,
    });

    if (loading) {
        return (<div className='userInformation'><LoadingBbBody/></div>);
    }

    return (
        <div className='locationInformation'>
            {
                isError ? <ErrorAlert error={error} setError={setError} setIsError={setIsError}/> : null
            }
            <div className='bodyView'>
                <div className="form-container">

                    <div className="form-inputs-container">


                        <div className='input-container'>

                            <label className='white large'>City/Home Town <p
                                className='d-inline red m-0 ml-1'>* {!isCitySelect ? 'Please select a city' : null}</p>
                            </label>

                            <Autocomplete
                                className='autoCom'
                                options={cityList}
                                getOptionLabel={(option) => option.city}
                                // style={{height: 40, padding: 0}}
                                onChange={setCity}
                                value={cityList.find(obj => {
                                    return obj.city === locationInfo.city
                                })}
                                filterOptions={filterOptions}
                                // onInputChange={()=>InputChanges()}
                                freeSolo
                                renderInput={(params) => <TextField  {...params} label="" placeholder="First select your nearest city/home town/village name" variant="outlined"
                                                                     style={{margin: '0px'}}/>}
                            />
                        </div>

                        <div className='map-container' disabled={true}>
                            <p className='text-white'>Place the pin in your home/business location</p>
                            {!locationInfo.city ?   <div className='mask'/> : null}
                          
                            <SearchLocation searchLatLon={searchLatLon} valueLocSearch={valueLocSearch}
                                            setValueLocSearch={setValueLocSearch}/>
                            <span className='btn-remove' hidden={!valueLocSearch}
                                  onClick={() => setValueLocSearch('')}/>
                            <Map google={google} zoom={zoom} containerStyle={containerStyle}
                                // initialCenter={{lat: locationInfo.dLat, lng: locationInfo.dLng}}
                                 center={{lat: locationInfo.dLat, lng: locationInfo.dLng}}
                                 mapTypeControl={false}
                                 onClick={(t, map, coord) => dragEndLatLon(coord)}

                            >

                                {
                                    locationInfo.lat ? <Marker
                                        name={'Current location'} draggable={true}
                                        position={{lat: locationInfo.lat, lng: locationInfo.lng}}
                                        onDragend={(t, map, coord) => dragEndLatLon(coord)}
                                        icon={{
                                            url: mapMarkerBlue,
                                            scaledSize: new google.maps.Size(40, 70)
                                        }}
                                    /> : null
                                }


                            </Map>
                        </div>


                    </div>
                    <div className="form-inputs-container d-flex flex-column justify-content-start mt-2 pl-5">
                        <p className='text-white'>Your Postal Address</p>

                        <div className='input-container'>
                            <label>
                                <div className="label">Address Line 1</div>
                                <p className='d-inline red m-0 ml-1'>*</p>
                            </label>
                            <div className="input-field">
                                {errorList.address1 ? <div className="error-msg">{errorList.address1}</div> : null}
                                <input type='text' name='address1' disabled={!locationInfo.city || !locationInfo.lat}
                                       className={errorList.address1 ? 'w-100 error' : 'w-100'}
                                       value={locationInfo.address1}
                                       onChange={handleChange}/>
                            </div>
                        </div>

                        <div className='input-container'>
                            <label>
                                <div className="label">Address Line 2</div>
                                <p className='d-inline red m-0 ml-1'>*</p>
                            </label>
                            <div className="input-field">
                                {errorList.address2 ? <div className="error-msg">{errorList.address2}</div> : null}
                                <input type='text' name='address2' disabled={!locationInfo.city || !locationInfo.lat}
                                       className={errorList.address2 ? 'w-100 error' : 'w-100'}
                                       value={locationInfo.address2}
                                       onChange={handleChange}/>
                            </div>
                        </div>

                        <div className='input-container'>
                            <label>
                                <div className="label">Postal Code</div>
                                <p className='d-inline red m-0 ml-1'>*</p>
                            </label>
                            <div className="input-field">

                                {errorList.postalCode ? <div className="error-msg">{errorList.postalCode}</div> : null}
                                <input autoComplete="new-password" type='number' name='postalCode' disabled={true}
                                       className={errorList.postalCode ? 'w-100 error' : 'w-100'}
                                       value={locationInfo.postalCode}
                                       onChange={handleChange}/>
                            </div>
                        </div>

                        <div className='attempt-info'><p className='d-inline red m-0 ml-1'>*</p>You have maximum 3
                            attempts per day.
                        </div>

                    </div>
                </div>


            </div>

            {
                checkFesibilty === null ? null : checkFesibilty ? <div className='successFeasibility'>
                    We are happy to inform you that
                    SLTMOBITEL Fibre is <strong>available </strong> for your area.
                    <div>Click next to proceed</div>
                </div> : <div className='noSuccessFeasibility'>
                    Sorry SLT Fiber is <strong> not available </strong> at the selected location, You can request SLT
                    Megaline service.
                </div>
            }


            <div className='container-footer'>
                <div className="action-container right">
                    <button className="btn-back" onClick={() => setState(1)}>Back</button>
                    {checkFesibilty !== null && checkFesibilty ?
                        <button onClick={() => validation()}>Next</button> : <button disabled={loading}
                                                                                     onClick={() => isFesibilty()}>{loading ? 'Checking' : 'Check'}  </button>}
                </div>
            </div>
        </div>
    );
}


export default GoogleApiWrapper({
    apiKey: (MAP_KEY)
})(LocationInformation)

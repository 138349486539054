import React, {useEffect, useState} from 'react';
import axios from "axios";
import LoadingBbBody from "../boardBand/LoadingBbBody";
import MainChannels from "./packages/MainChannels";


function PeoTvPackages(props) {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [selected, setSelected] = useState(null);
    const [isError, setIssError] = useState(false);

    useEffect(() => {
        setLoading(true)
        let instance = axios.create();
        delete instance.defaults.headers.common['X-IBM-Client-Id'];
        instance.get('https://www.peotv.com/packages_api?api-key=71285B346E88BABEEE5167DB27B48').then(response => {
            if (response.status === 200) {
                setData(...[response.data])
                setLoading(false)
            }else
                setIssError(true)
        }).catch(err => {
            setLoading(false)
            setIssError(true)
        })
    }, []);

    if (loading) {
        return <div className='loading'> <LoadingBbBody loading={loading} border={false}/></div>
    } else if (selected) {
        return <MainChannels obj={selected} setSelected={setSelected} />
    } else if(isError) {
        return <div className='peoTVPackages'> Packages Loading Failed </div>
    }
    else {

        return (

            <div className='peoTVPackages'>
                {
                    data.map((obj, index) => {
                        return  <div key={index} onClick={()=>setSelected(obj.channels)}>
                                <img src={obj.package.Package_image} alt={'searchIcon'}/>
                            </div>

                    })
                }

                {/*<div className='text-center' >*/}
                {/*    <button className='blue p-1 pl-3 pr-3'>Compare</button>*/}
                {/*</div>*/}
            </div>
        );
    }
}

export default PeoTvPackages;

import React from "react";
import fbImage from "../../assests/images/new_ftth/facebook.png";
import instaImage from "../../assests/images/new_ftth/insta.png";
import tweetImage from "../../assests/images/new_ftth/tweeet.png";
import youtubeImage from "../../assests/images/new_ftth/youtube.png";
import allRightRes from "../../assests/images/new_ftth/all_rights_reserved.png";

function Footer() {
  return (
    <nav>
      <div className="footer-container clearfix">
        <div className="copyrights">
          <img src={allRightRes} alt="allRightRes" />
          <span>SLT-MOBITEL, all rights reserved.</span>
        </div>
        <div className="icons">
          <a href="https://www.facebook.com/SLTMobitel" target="_blank" rel="noopener noreferrer">
            <img src={fbImage} alt="fbImage" />
          </a>
          <a href="https://twitter.com/slt_mobitel" target="_blank" rel="noopener noreferrer">
            <img src={tweetImage} alt="tweetImage" />
          </a>
          <a href="https://www.instagram.com/sltmobitel_official/" target="_blank" rel="noopener noreferrer">
            <img src={instaImage} alt="instaImage" />
          </a>
          <a href="https://www.youtube.com/@SLTMobitel" target="_blank" rel="noopener noreferrer">
            <img src={youtubeImage} alt="youtubeImage" className="youtube" />
          </a>
        </div>
      </div>
    </nav>
  );
}

export default Footer;
